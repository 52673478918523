import {
  AfterViewInit,
  EventEmitter,
  Component,
  Input,
  Output,
  ViewChild,
  Renderer2,
  HostListener
} from '@angular/core';

import {
  Adjust
} from '@awesome-cordova-plugins/adjust/ngx';

import {
  IonSlides
} from '@ionic/angular';

import {
  ConfigService, StatsService
} from '@app/services';

import {
  EasyDebugDecorator
} from '@app/app.decorators';

@EasyDebugDecorator
@Component({
  selector: 'ios-att-slider-comp',
  templateUrl: 'ios-att-slider.html',
  styleUrls: ['ios-att-slider.scss']
})
export class IOSAttSlider implements AfterViewInit {
  @ViewChild(IonSlides, { static: true }) slides: IonSlides;
  @Input() template: number;
  @ViewChild('fxLottie') fxLottie: any;
  @Output() outputIOSATT = new EventEmitter<any>();

  lottieAnim = null;

  templates = [
    {
      title: `Avant de continuer...`,
      text: `Pense à nous autoriser à suivre ton utilisation de l'app pour en profiter pleinement et ne jamais être seul dans ton apprentissage.`
    },
    {
      title: `Ravis de te revoir !`,
      text: `Avant de poursuivre, pense à nous autoriser à suivre ton utilisation de l'app pour ne jamais être seul dans ton apprentissage.`
    },
    {
      title: `Dernière étape importante !`,
      text: `Laisse-nous suivre ton utilisation de l'app pour ne jamais être seul dans ton apprentissage.`
    }
  ];

  title: string;
  text: string;

  config: any;
  isDesktop = (window.innerWidth > 767) ? true : false;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.isDesktop = (window.innerWidth > 767) ? true : false;
  }

  constructor(
    private adjust: Adjust,
    private configService: ConfigService,
    private renderer: Renderer2,
    private statsService: StatsService
  ) {
  }

  async ngAfterViewInit() {
    if (!!this.slides) {
      this.slides.lockSwipes(true);
    }

    this.config = await this.configService.getAppConfig();
    this.template = 1;

    if (this.config.iOSATTFirstTime) {
      await this.configService.switchBoolean('iOSATTFirstTime');
      this.template = 0;
    }

    if (this.config.iOSATTFromRegister) {
      await this.configService.switchBoolean('iOSATTFromRegister');
      this.template = 0;
    }

    this.title = this.templates[this.template].title;
    this.text = this.templates[this.template].text;


    setTimeout(() => {
      if (!!this.fxLottie && !!this.fxLottie.nativeElement) {
        const lottieAnims = [
          {
            delay: this.isDesktop ? 450 : 750,
            anim: 'tracking',
            var: 'tracking',
          }
        ];
        this.lottieAnim = lottieAnims[0];
        setTimeout(() => {
          this.renderer.addClass(
            this.fxLottie.nativeElement,
            this.lottieAnim.anim
          );
        }, 600);
      }
    }, 60);
  }

  closeSlider() {
    this.outputIOSATT.emit('close');
  }

  async openIOSATT() {
    const status = await this.adjust.requestTrackingAuthorizationWithCompletionHandler().then(
      res => {
        return res;
      }
    );

    switch (status) {
      case 0:
        // console.log('NotDetermined');
        break;
      case 1:
        // console.log('Restricted');
        break;
      case 2:
        this.statsService.send({name: 'user:ios_consent', payload: {label: 'activate_tracking', category: 'sign_up', value: false}});
        await this.configService.switchBoolean('iOSATTAnswered');
        // console.log('Denied');
        break;
      case 3:
        this.statsService.send({name: 'user:ios_consent', payload: {label: 'activate_tracking', category: 'sign_up', value: true}});
        await this.configService.switchBoolean('iOSATTAnswered');
        // console.log('Authorized');
        break;
    }

    this.closeSlider();
  }
}
