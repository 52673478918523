import { Injectable } from '@angular/core';

import { Initialable } from './app-init.service';

import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { File } from '@awesome-cordova-plugins/file/ngx';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@Injectable({
  providedIn: 'root'
})
@Initialable({step: `init0`, initializer: `init`, services: [Storage]})
@EasyDebugDecorator
export class StorageService {
  // Storage Lock n'est présent que sur les pages nécéssitant le Local Storage
  storageLocked = false;
  minDiskSpace = 10000;

  constructor(public storage: Storage,
    private file: File,
    private platform: Platform) {
    // console.log('Storage service instanciate');
  }

  async init() {
    // console.log(`Launching storage init`);
    return this.storage.ready().then(
      async res => {
        // console.log('init storage done');
        if (this.platform.is('cordova')) {
          this.storageLocked = !await this.checkEnougthSpace();
          await this.setStorageLock(this.storageLocked);
        } else {
          await this.setStorageLock(false);
        }
        return res;
      }
    ).catch(
      err => {
        // console.log('init storage failed');
        return null;
      }
    );
  }

  async getDiskSpace() {
    let space = this.platform.is('cordova') ? await this.file.getFreeDiskSpace() : 0;
    if (this.platform.is('cordova') && this.platform.is('ios')) {
      space = space / 1000 || 0;
    } else {
      space = space;
    }

    return space;
  }

  async checkEnougthSpace() {
    let space = this.platform.is('cordova') ? await this.file.getFreeDiskSpace() : 0;
    if (this.platform.is('cordova') && this.platform.is('ios')) {
      space = space / 1000 || 0;
    } else {
      space = space;
    }

    if (space > this.minDiskSpace) {
      return true;
    }
    return false;
  }

  get(key: string): Promise<any> {
    // console.log('get -> ', key);
    return this.storage.get(key).catch(
      err => {
        // console.log('get error -> ', err);
        return null;
    });
  }

  set(key: string, value: any): Promise<any> {
    // console.log('set -> ', key, value);
    return this.storage.set(key, value).catch(
      err => {
        return null;
    });
  }

  clear(): Promise<any> {
    // console.log('clear');
    return this.storage.clear();
  }

  remove(key: string): Promise<any> {
    // console.log('removed -> ', key);
    return this.storage.remove(key);
  }

  keys(): Promise<any> {
    return this.storage.keys();
  }


  async getStorageLock(): Promise<boolean> {
    const storageLock = await this.get('storageLock');
    return storageLock;
  }

  async setStorageLock(isLocked: boolean): Promise<boolean> {
    return await this.set('storageLock', isLocked);
  }
}
