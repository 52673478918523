import { Session } from '../../models/session';
import { SessionsStrategy } from './sessionsStrategy.interface';

import { Student } from '../../models/student';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
export class SessionsContext implements SessionsStrategy {
  private strategy: SessionsStrategy;

  constructor(private strategyInterface: SessionsStrategy) {
    this.strategy = this.strategyInterface;
  }

  init() {
  }

  setStrategy(strategy: SessionsStrategy) {
    this.strategy = strategy;
  }

  getSessions(student: Student, local?: boolean): Promise<any> {
    return this.strategy.getSessions(student, local);
  }

  getSession(sessionId: string, student: Student): Promise<any> {
    return this.strategy.getSession(sessionId, student);
  }

  setSessions(sessions: Session[], student: Student): Promise<Session[]> {
    return this.strategy.setSessions(sessions, student);
  }

  setSession(session: Session, student: Student) {
    return this.strategy.setSession(session, student);
  }

  getErrorSession(student: Student): Promise<any> {
    return this.strategy.getErrorSession(student);
  }
}
