import { EvsRecord } from './evsRecord';

export interface IPayment {
  id: string;
  type: string;
  offer_id: string;
  offer_associated: any; // to link offer to payment
  order_reference: string;
  amount: number;
  created_at: string;
  updated_at: string;
  card_last4: string;
  card_exp: string;
  quantity: number;
  payment_plan_user: any;
  payment_plan_user_id: any;
  additional_infos: any;
  is_offer_trial: boolean;
  is_trial_completed: boolean;
  plan_position: number;
  invoice_number: string;
  offer_name: string;
  offer_type: string;
  refunds: any; // can be an object
  coupons: any; // can be an object
}

export class PaymentModel extends EvsRecord implements IPayment {
  id: string;
  type: string;
  offer_id: string;
  offer_associated: any; // to link offer to payment
  order_reference: string;
  amount: number;
  created_at: string;
  updated_at: string;
  card_last4: string;
  card_exp: string;
  quantity: number;
  payment_plan_user: any;
  payment_plan_user_id: any;
  additional_infos: any;
  is_offer_trial: boolean;
  is_trial_completed: boolean;
  plan_position: number;
  invoice_number: string;
  offer_name: string;
  offer_type: string;
  refunds: any; // can be an object
  coupons: any; // can be an object

  constructor(obj: IPayment) {
    super(obj);
  }
}
