import { EvsRecord } from './evsRecord';

export interface IBooklet {
  id: string;
  category: string;
  statusTitle: string;
  evaluation: string; // A: Acquis | nil: Non évoqué | EVA: En Voie d'Acquisition
  teacher_id: number;
  topic: string;
}

export class BookletModel extends EvsRecord implements IBooklet {
  id: string;
  category: string;
  statusTitle: string;
  evaluation: string; // A: Acquis | nil: Non évoqué | EVA: En Voie d'Acquisition
  teacher_id: number;
  topic: string;

  constructor(obj: IBooklet) {
    super(obj);
  }
}
