import { AfterViewInit, Component, HostListener, Renderer2, ViewChild, Output, EventEmitter } from '@angular/core';
import { NavigationService, ConfigUserService, } from '@app/services';
import { IonSlides } from '@ionic/angular';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'presentation-slider-comp',
  templateUrl: 'presentation-slider.html',
  styleUrls: ['presentation-slider.scss']
})
export class PresentationSlider implements AfterViewInit {
  @ViewChild(IonSlides, { static: true }) slides: IonSlides;
  @ViewChild('fxLottie') fxLottie: any;
  @Output() endSlider = new EventEmitter<boolean>();

  lottieAnim = null;
  iconPath = './uikit-assets/icons/orion-svg-sprite.svg#';

  sliderIndex = 0;
  isDesktop = (window.innerWidth > 767) ? true : false;
  showMotion = false;

  student = null;
  configUser = null;

  tunnelTestAB = null;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.isDesktop = (window.innerWidth > 767) ? true : false;
  }

  constructor(
    private navigationService: NavigationService,
    private renderer: Renderer2,
    private configUserService: ConfigUserService,
  ) {
  }

  async ngAfterViewInit() {
    if (!!this.configUserService.tunnelTestAB) {
      this.tunnelTestAB = this.configUserService.tunnelTestAB;
    }
    if (!!this.slides) {
      this.slides.lockSwipes(true);
    }
    setTimeout(() => {
      if (!!this.fxLottie && !!this.fxLottie.nativeElement) {
        const lottieAnims = [
          {
            delay: this.isDesktop ? 450 : 750,
            anim: 'heureux-kass',
            var: 'heureux-kass',
          }
        ];
        this.lottieAnim = lottieAnims[0];
        setTimeout(() => {
          this.renderer.addClass(
            this.fxLottie.nativeElement,
            this.lottieAnim.anim
          );
          this.showMotion = true;
        }, 600);
      }
    }, 60);
  }

  slideChanged() {
    this.slides.getActiveIndex().then(index => {
      this.sliderIndex = index;
    });
  }

  previousSlide() {
    this.slides.slidePrev();
  }

  nextSlide() {
    this.slides.slideNext();
  }

  closeSlider() {
    this.navigationService.open('code');
  }

  openAuth() {
    this.navigationService.open('login/signIn');
  }
  openCreateAccount() {
    this.navigationService.open('login/register');
  }
}
