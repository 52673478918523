import { Injectable } from '@angular/core';

import { Camera, CameraOptions } from '@awesome-cordova-plugins/camera/ngx';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@Injectable({ providedIn: 'root' })
@EasyDebugDecorator
export class CameraService {
  options: CameraOptions = {
    quality: 100,
    destinationType: this.camera.DestinationType.DATA_URL,
    encodingType: this.camera.EncodingType.JPEG,
    mediaType: this.camera.MediaType.PICTURE,
    sourceType: null
  };

  constructor(
    private camera: Camera,
  ) {
  }

  takePicture(): Promise<string> {
    this.options.sourceType = this.camera.PictureSourceType.CAMERA;
    return this.pickPicture();
  }

  getPicture(): Promise<string> {
    this.options.sourceType = this.camera.PictureSourceType.PHOTOLIBRARY;
    return this.pickPicture();
  }

  pickPicture(): Promise<string> {
    return this.camera.getPicture(this.options).then(
      img => {
        const base64Img = `data:image/jpeg;base64,${img}`;
        return base64Img;
      },
      err => {
        return err;
      }
    );
  }
}
