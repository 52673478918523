<!-- <div class="evs_radial-container" [ngStyle]="{'font-size': size + 'px'}">
  <div class="pie-wrapper thin" [ngClass]="['progress-' + percent + ' ' + colorClass]">
    <span *ngIf="showData" class="label">{{score}}/{{scoreMax}}</span>
    <div class="pie">
      <div class="left-side half-circle"></div>
      <div class="right-side half-circle"></div>
    </div>
    <div class="shadow"></div>
  </div>
</div> -->

<div class="evs-radial-percent" [ngClass]="['' + colorClass]" #radialElt>
  <svg>
    <circle></circle>
    <circle class="percent-bar active" [ngClass]="{'display-none': percent == 0}"></circle>
  </svg>
  <div *ngIf="showData" class="number">
    <h3>{{score}}</h3><p>/{{scoreMax}}</p>
  </div>
</div>