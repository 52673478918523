import { Question } from './question';

export class Questions {
  private _questions: Question[] = [];

  constructor() {
  }

  serializeQuestions(datas: any[]): Question[] {
    const questions: Question[] = [];
    if (!!datas && datas.length > 0) {
      for (const data of datas) {
        const question = new Question(data);
        questions.push(question);
      }
    }
    return questions;
  }


  fillQuestions(data: Array<any>, type: string): Array<Question> {
    const questions: Question[] = [];
    if (!!data && data.length > 0) {
      for (const dataQuestion of data) {
        if (!!type && type === 'remote') {
          const questionFilled = this.fillQuestion(dataQuestion);
          if (!!questionFilled) {
            questions.push(questionFilled);
          }
        } else {
          questions.push(new Question(dataQuestion));
        }
      }
    }
    return questions;
  }


  fillQuestion(data): Question {
    let question = null;
    if (!!data && !!data.attributes) {
      const correctAnswers = (!!data.attributes.correct_answer) ? data.attributes.correct_answer : [];
      const assets = this.createQuestionsAssets(data);
      const text_1_split = (!!data.attributes.text_1) ? data.attributes.text_1.split('\\n') : [];

      if (text_1_split.length > 0 && text_1_split[0].slice(-1) === ':') {
        text_1_split[0] = text_1_split[0].replace(/\ :$/, ':');
      }
      question = new Question({
        id: data.id,
        question_text: (!!text_1_split && text_1_split.length > 0) ? text_1_split[0] : null,
        text1: (!!text_1_split && text_1_split.length > 1) ? text_1_split[1] : null,
        text2: (!!data.attributes.text_2) ? data.attributes.text_2 : null,
        series: [],
        assets: null,
        answers: null,
        answer_a: (!!data.attributes.answer_a) ? data.attributes.answer_a : null,
        answer_b: (!!data.attributes.answer_b) ? data.attributes.answer_b : null,
        answer_c: (!!data.attributes.answer_c) ? data.attributes.answer_c : null,
        answer_d: (!!data.attributes.answer_d) ? data.attributes.answer_d : null,
        correctAnswers: correctAnswers,
        correction: (!!data.attributes.correction) ? data.attributes.correction.split('\\n') : null,
        updatedAt: (!!data.attributes.updated_at) ? data.attributes.updated_at : null,
        question_img1: (!!assets && !!assets.question_img1) ? assets.question_img1 : null,
        question_img1_type: (!!assets && !!assets.question_img1_type) ? assets.question_img1_type : null,
        question_img1_geometries: (!!assets && !!assets.question_img1_geometries) ? assets.question_img1_geometries : null,
        question_img2: (!!assets && !!assets.question_img2) ? assets.question_img2 : null,
        question_img2_type: (!!assets && !!assets.question_img2_type) ? assets.question_img2_type : null,
        question_img2_geometries: (!!assets && !!assets.question_img2_geometries) ? assets.question_img2_geometries : null,
        question_audio: (!!assets && !!assets.question_audio) ? assets.question_audio : null,
        question_video: (!!assets && !!assets.question_video) ? assets.question_video : null,
        answer_img1: (!!assets && !!assets.answer_img1) ? assets.answer_img1 : null,
        answer_img1_type: (!!assets && !!assets.answer_img1_type) ? assets.answer_img1_type : null,
        answer_img1_geometries: (!!assets && !!assets.answer_img1_geometries) ? assets.answer_img1_geometries : null,
        answer_img2: (!!assets && !!assets.answer_img2) ? assets.answer_img2 : null,
        answer_img2_type: (!!assets && !!assets.answer_img2_type) ? assets.answer_img2_type : null,
        answer_img2_geometries: (!!assets && !!assets.answer_img2_geometries) ? assets.answer_img2_geometries : null,
        answer_audio: (!!assets && !!assets.answer_audio) ? assets.answer_audio : null,
        answer_video: (!!assets && !!assets.answer_video) ? assets.answer_video : null,
        assets_size: (!!assets && !!assets.size) ? assets.size : null,
        themes: (!!data.attributes.themes) ? data.attributes.themes : null,
        off_question_img1: null,
        off_question_img2: null,
        off_question_audio: null,
        off_question_video: null,
        off_answer_img1: null,
        off_answer_img2: null,
        off_answer_audio: null,
        downloadState: false,
      });
    }
    return question;
  }

  createQuestionsAssets(data: any) {
    const assets = {
      question_img1: null,
      question_img1_type: null,
      question_img1_geometries: null,
      question_img2: null,
      question_img2_type: null,
      question_img2_geometries: null,
      question_audio: null,
      question_video: null,
      answer_img1: null,
      answer_img1_type: null,
      answer_img1_geometries: null,
      answer_img2: null,
      answer_img2_type: null,
      answer_img2_geometries: null,
      answer_audio: null,
      answer_video: null,
      size: 0
    };

    if (!!data.attributes && !!data.attributes.question_img_1 && !!data.attributes.question_img_1.data) {
      assets.question_img1 = (!!data.attributes.question_img_1.data.links && data.attributes.question_img_1.data.links.url) ? data.attributes.question_img_1.data.links.url : null;
      assets.question_img1_type = (!!data.attributes.question_img_1.data.attributes && data.attributes.question_img_1.data.attributes.type) ? data.attributes.question_img_1.data.attributes.type : null;
      assets.question_img1_geometries = (!!data.attributes.question_img_1.data.attributes && data.attributes.question_img_1.data.attributes.geometries) ? data.attributes.question_img_1.data.attributes.geometries : null;
      assets.size += (!!data.attributes.question_img_1.data.attributes && data.attributes.question_img_1.data.attributes.attachment_file_size) ? data.attributes.question_img_1.data.attributes.attachment_file_size : 0;
    } else {
      assets.question_img1 = null;
    }
    if (!!data.attributes && !!data.attributes.question_img_2 && !!data.attributes.question_img_2.data) {
      assets.question_img2 = (!!data.attributes.question_img_2.data.links && data.attributes.question_img_2.data.links.url) ? data.attributes.question_img_2.data.links.url : null;
      assets.question_img2_type = (!!data.attributes.question_img_2.data.attributes && data.attributes.question_img_2.data.attributes.type) ? data.attributes.question_img_2.data.attributes.type : null;
      assets.question_img2_geometries = (!!data.attributes.question_img_2.data.attributes && data.attributes.question_img_2.data.attributes.geometries) ? data.attributes.question_img_2.data.attributes.geometries : null;
      assets.size += (!!data.attributes.question_img_2.data.attributes && data.attributes.question_img_2.data.attributes.attachment_file_size) ? data.attributes.question_img_2.data.attributes.attachment_file_size : 0;
    } else {
      assets.question_img2 = null;
    }
    if (!!data.attributes && !!data.attributes.question_audio) {
      assets.question_audio = (!!data.attributes.question_audio.data && !!data.attributes.question_audio.data.links.url) ? data.attributes.question_audio.data.links.url : null;
      assets.size += (!!data.attributes.question_audio.data && !!data.attributes.question_audio.data.attributes && !!data.attributes.question_audio.data.attributes.attachment_file_size) ? data.attributes.question_audio.data.attributes.attachment_file_size : 0;
    } else {
      assets.question_audio = null;
    }
    if (!!data.attributes && !!data.attributes.question_video) {
      assets.question_video = (!!data.attributes.question_video.data && !!data.attributes.question_video.data.links.url) ? data.attributes.question_video.data.links.url : null;
      assets.size += (!!data.attributes.question_video.data && !!data.attributes.question_video.data.attributes && !!data.attributes.question_video.data.attributes.attachment_file_size) ? data.attributes.question_video.data.attributes.attachment_file_size : 0;
    } else {
      assets.answer_video = null;
    }
    if (!!data.attributes && !!data.attributes.answer_img_1) {
      assets.answer_img1 = (!!data.attributes.answer_img_1.data && !!data.attributes.answer_img_1.data.links && !!data.attributes.answer_img_1.data.links.url) ? data.attributes.answer_img_1.data.links.url : null;
      assets.answer_img1_type = (!!data.attributes.answer_img_1.data && !!data.attributes.answer_img_1.data.attributes && !!data.attributes.answer_img_1.data.attributes.type) ? data.attributes.answer_img_1.data.attributes.type : null;
      assets.answer_img1_geometries = (!!data.attributes.answer_img_1.data && !!data.attributes.answer_img_1.data.attributes && !!data.attributes.answer_img_1.data.attributes.geometries) ? data.attributes.answer_img_1.data.attributes.geometries : null;
      assets.size += (!!data.attributes.answer_img_1.data && !!data.attributes.answer_img_1.data.attributes && !!data.attributes.answer_img_1.data.attributes.attachment_file_size) ? data.attributes.answer_img_1.data.attributes.attachment_file_size : 0;
    } else {
      assets.answer_img1 = null;
    }
    if (!!data.attributes && !!data.attributes.answer_img_2) {
      assets.answer_img2 = (!!data.attributes.answer_img_2.data && !!data.attributes.answer_img_2.data.links && !!data.attributes.answer_img_2.data.links.url) ? data.attributes.answer_img_2.data.links.url : null;
      assets.answer_img2_type = (!!data.attributes.answer_img_2.data && !!data.attributes.answer_img_2.data.attributes && !!data.attributes.answer_img_2.data.attributes.type) ? data.attributes.answer_img_2.data.attributes.type : null;
      assets.answer_img2_geometries = (!!data.attributes.answer_img_2.data && !!data.attributes.answer_img_2.data.attributes && !!data.attributes.answer_img_2.data.attributes.geometries) ? data.attributes.answer_img_2.data.attributes.geometries : null;
      assets.size += (!!data.attributes.answer_img_2.data && !!data.attributes.answer_img_2.data.attributes && !!data.attributes.answer_img_2.data.attributes.attachment_file_size) ? data.attributes.answer_img_2.data.attributes.attachment_file_size : 0;
    } else {
      assets.answer_img2 = null;
    }
    if (!!data.attributes && !!data.attributes.answer_audio) {
      assets.answer_audio = (!!data.attributes.answer_audio.data && !!data.attributes.answer_audio.data.links.url) ? data.attributes.answer_audio.data.links.url : null;
      assets.size += (!!data.attributes.answer_audio.data && !!data.attributes.answer_audio.data.attributes && !!data.attributes.answer_audio.data.attributes.attachment_file_size) ? data.attributes.answer_audio.data.attributes.attachment_file_size : 0;
    } else {
      assets.answer_audio = null;
    }
    if (!!data.attributes && !!data.attributes.answer_video) {
      assets.answer_video = (!!data.attributes.answer_video.data && !!data.attributes.answer_video.data.links.url) ? data.attributes.answer_video.data.links.url : null;
      assets.size += (!!data.attributes.answer_video.data && !!data.attributes.answer_video.data.attributes && !!data.attributes.answer_video.data.attributes.attachment_file_size) ? data.attributes.answer_video.data.attributes.attachment_file_size : 0;
    } else {
      assets.answer_video = null;
    }
    return assets;
  }

  public get questions(): Question[] { return this._questions; }
  public set questions(value: Question[]) { this._questions = value; }
}