import { EvsRecord } from './evsRecord';

export interface ICerfa {
  id: string;
  type: string;
  created_at: string;
  confirmed_at: string;
  updated_at: string;
  document_type: string;
  recto_content_type: string;
  recto_file_name: string;
  recto_file_size: number;
  recto_updated_at: string;
  recto_url: string;
  verso_content_type: string;
  verso_file_name: string;
  verso_file_size: number;
  verso_updated_at: string;
  verso_url: string;
}

export class CerfaModel extends EvsRecord implements ICerfa {
  id: string;
  type: string;
  created_at: string;
  confirmed_at: string;
  updated_at: string;
  document_type: string;
  recto_content_type: string;
  recto_file_name: string;
  recto_file_size: number;
  recto_updated_at: string;
  recto_url: string;
  verso_content_type: string;
  verso_file_name: string;
  verso_file_size: number;
  verso_updated_at: string;
  verso_url: string;

  constructor(obj: ICerfa) {
    super(obj);
  }
}
