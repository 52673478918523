<ion-slides class="ios-att-slider animated fadeIn" #slides>

<div class="fx-lottie default-lottie" #fxLottie>
  <fireworks-comp *ngIf="!!lottieAnim && !!lottieAnim.anim" [compOptions]="{addY: -80, startAt: lottieAnim.delay}"></fireworks-comp>
  <lottie-player *ngIf="!!lottieAnim && lottieAnim.anim === 'tracking'" src="../../assets/lottie/tracking.json" background="transparent" autoplay></lottie-player>
</div>

<ion-slide>   
    <div class="ios-container">
      <!-- <div class="background-ios-att">
        <img src="assets/svg/asset-ios-att.svg" alt="iosatt">
      </div> -->  
      <div class="ios-att-text">
        <h1>{{ title }}</h1>
        <p>{{ text }}</p>
      </div>

      <div class="btn-ios-att animated fadeIn">
        <!-- <button class="btn light" (click)="closeSlider()">Plus tard</button> -->
        <button class="btn outline" (click)="openIOSATT()">Suivant</button>
      </div>
    </div>
  </ion-slide>
</ion-slides>