import { StatsService } from './../../../app/services/stats.service';
import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  Renderer2,
  HostListener,
  OnInit
} from '@angular/core';

import { NavController, ModalController } from '@ionic/angular';

import { ModalMarketingComponent, ModalIframeComponent } from '@app/modals';

import { environment } from '../../../environments/environment';

import { UtilsService } from '@commons/services';

import { Student, CDRLesson, Paragraph } from '@app/models';
import { StudentService, CDRLessonsService, NavigationService } from '@app/services';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'square-card-comp',
  templateUrl: 'square-card.html',
  styleUrls: ['square-card.scss'],
})
export class SquareCardComponent implements OnInit, OnChanges {
  @Input() compOptions: any;

  student: Student;
  userLifeCycle: any;

  iconPath = './uikit-assets/icons/orion-svg-sprite.svg#';
  iconThemePath = './uikit-assets/icons/orion-svg-sprite-theme.svg#';
  environment: any = environment;

  isDebug = false;

  cours: any = null;
  index: number = null;
  width: number = null;
  height: number = null;
  maxSquareOnScreen = 1;
  text = '';
  link: any = null;

  isDesktop = (window.innerWidth > 767) ? true : false;

  icons = [
    { icon: 'direction-road-sign—cdr-theme', bg: 'signalisation' },
    { icon: 'traffic-light—cdr-theme', bg: 'circulation' },
    { icon: 'road-curve—cdr-theme', bg: 'route' },
    { icon: 'street-view—cdr-theme', bg: 'autres-usagers' },
    { icon: 'steering-wheel—cdr-theme', bg: 'conducteur' },
    { icon: 'compass-direction—cdr-theme', bg: 'divers' },
    { icon: 'way—cdr-theme', bg: 'prendre-quitter-le-vehicule' },
    { icon: 'highway—cdr-theme', bg: 'securite' },
    { icon: 'configuration—cdr-theme', bg: 'mecanique-equipement' },
    { icon: 'earth-globe—cdr-theme', bg: 'environnement' },
    { icon: 'injury—cdr-theme', bg: 'accident-secours' },
  ];

  CDRSections: Array<Paragraph>;
  CDRSubthemes: Array<CDRLesson>;

  constructor(
    private utils: UtilsService,
    private navController: NavController,
    private renderer: Renderer2,
    private cdrLessonsService: CDRLessonsService,
    private studentService: StudentService,
    private navigationService: NavigationService,
    private modalController: ModalController,
    private statsService: StatsService
  ) {
    this.userLifeCycle = this.studentService.getUserLifeCycle(this.student);
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.isDesktop = (window.innerWidth > 767) ? true : false;
  }

  ngOnChanges(changes: SimpleChanges) {
    // detect @inputs changes
    const compOptions: SimpleChange = changes.compOptions;
    if (typeof compOptions !== 'undefined') {
      this.compOptions = compOptions.currentValue;
      this.setGlobals();
    }
  }

  ngOnInit() {
    this.setGlobals();
  }

  setGlobals() {
    this.student = this.studentService.student;
    this.userLifeCycle = this.studentService.getUserLifeCycle(this.student);

    this.isDebug = (!!this.compOptions && typeof this.compOptions.debug !== 'undefined') ? this.compOptions.debug : this.isDebug;
    this.cours = (!!this.compOptions && typeof this.compOptions.cours !== 'undefined') ? this.compOptions.cours : this.cours;
    this.link = (!!this.compOptions && typeof this.compOptions.link !== 'undefined') ? this.compOptions.link : this.link;
    this.text = (!!this.compOptions && typeof this.compOptions.text !== 'undefined') ? this.compOptions.text : this.text;
    if (this.text === 'pubInApp') {
      const item = {
        item_id: 'big_pack',
        item_promo_type: 'regular',
        item_location_id: `cours_themes`,
        item_location_format: 'promo_card',
        item_promo_content: `livret`,
      };

      this.statsService.send({
        name: 'item:viewed', payload: {
          item: item
        }
      });
    }
    this.index = (!!this.compOptions && typeof this.compOptions.index !== 'undefined') ? this.compOptions.index : this.index;
    if ((this.userLifeCycle.isUserGuest || this.userLifeCycle.isUserRegistered) && this.text !== '') {
      this.icons = [
        { icon: 'direction-road-sign—cdr-theme', bg: 'signalisation' },
        { icon: 'direction-road-sign—cdr-theme', bg: 'signalisation' },
        { icon: 'traffic-light—cdr-theme', bg: 'circulation' },
        { icon: 'road-curve—cdr-theme', bg: 'route' },
        { icon: 'street-view—cdr-theme', bg: 'autres-usagers' },
        { icon: 'steering-wheel—cdr-theme', bg: 'conducteur' },
        { icon: 'compass-direction—cdr-theme', bg: 'divers' },
        { icon: 'way—cdr-theme', bg: 'prendre-quitter-le-vehicule' },
        { icon: 'highway—cdr-theme', bg: 'securite' },
        { icon: 'configuration—cdr-theme', bg: 'mecanique-equipement' },
        { icon: 'earth-globe—cdr-theme', bg: 'environnement' },
        { icon: 'injury—cdr-theme', bg: 'accident-secours' },
      ];
      this.index++;
    }
    this.width = (!!this.compOptions && typeof this.compOptions.width !== 'undefined') ? this.compOptions.width : this.width;
    this.height = (!!this.compOptions && typeof this.compOptions.height !== 'undefined') ? this.compOptions.height : this.height;
    this.maxSquareOnScreen = (!!this.compOptions && typeof this.compOptions.maxSquareOnScreen !== 'undefined') ? this.compOptions.maxSquareOnScreen : this.maxSquareOnScreen;
  }

  async openModalIframe(url: any, type?: string, noClose = true) {
    if (!type) {
      type = 'iframe';
    }
    let modal;
    if (noClose) {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        backdropDismiss: false,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          'type': type,
          'url': url,
        },
      });
    } else {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          'type': type,
          'url': url,
        },
      });
    }
    return await modal.present();
  }

  async openLink(link) {
    if (this.text !== '') {
      if (this.text === 'pubInApp') {
        const item = {
          item_id: 'big_pack',
          item_promo_type: 'regular',
          item_location_id: `cours_themes`,
          item_location_format: 'promo_card',
          item_promo_content: `livret`,
        };

        this.statsService.send({
          name: 'item:clicked', payload: {
            item: item
          }
        });
      }
      let callbackUrl;
      callbackUrl = await this.navigationService.open(link, null);
      if (!!callbackUrl && callbackUrl !== '') {
        this.openModalIframe(callbackUrl);
      }
    }
    return false;
  }

}
