import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { NavController } from '@ionic/angular';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {

  private renderer: Renderer2;

  toasterContainer: any;
  tlContainer: any;
  trContainer: any;
  blContainer: any;
  brContainer: any;
  tcContainer: any;
  bcContainer: any;

  allToaster = [];
  toasterDuration = 4000;
  toasterPosition = 'tr';
  toasterType = 'toaster';
  stickyBorderColor = 'border-left-danger';
  toasterAnimationDuration = 400;
  allTimeOut = [];

  learningContractClicked = new Subject();
  learningContractClicked$ = this.learningContractClicked.asObservable();

  validateEmailClicked = new Subject();
  validateEmailClicked$ = this.validateEmailClicked.asObservable();

  regulariserPaymentClicked = new Subject();
  regulariserPaymentClicked$ = this.regulariserPaymentClicked.asObservable();

  constructor(
    private rendererFactory: RendererFactory2,
    private navController: NavController,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  initVars() {
    this.toasterType = 'toaster';
    this.toasterPosition = 'tr';
    this.toasterDuration = 4000;
  }

  create(config?: any): void {
    if (!!config) {
      this.initVars();
      if (typeof config === 'object') {
        if (typeof config.text === 'undefined' || config.text === '') {
          return;
        }
        if (!!config.duration || config.duration > 0) {
          this.toasterDuration = config.duration;
        }
        if (!!config.type && config.type !== '') {
          this.toasterType = config.type;
        }
        if (!!config.position && config.position !== '') {
          this.toasterPosition = config.position;
        }
      }
      const toastercontainer = document.getElementById('evs_toaster_container');
      // create toaster container
      if (this.allToaster.length === 0 || typeof toastercontainer === 'undefined') {
        // global container
        this.toasterContainer = document.createElement('div');
        this.toasterContainer.id = 'evs_toaster_container';
        this.toasterContainer.className = 'evs_toaster_container';
        document.body.appendChild(this.toasterContainer);
        // top left
        this.tlContainer = document.createElement('div');
        this.tlContainer.id = 'evs_toaster_container_tl';
        this.tlContainer.className = 'evs_toaster_container_part evs_tc_part_tl';
        this.toasterContainer.appendChild(this.tlContainer);
        // top right
        this.trContainer = document.createElement('div');
        this.trContainer.id = 'evs_toaster_container_tr';
        this.trContainer.className = 'evs_toaster_container_part evs_tc_part_tr';
        this.toasterContainer.appendChild(this.trContainer);
        // bottom left
        this.blContainer = document.createElement('div');
        this.blContainer.id = 'evs_toaster_container_bl';
        this.blContainer.className = 'evs_toaster_container_part evs_tc_part_bl';
        this.toasterContainer.appendChild(this.blContainer);
        // bottom right
        this.brContainer = document.createElement('div');
        this.brContainer.id = 'evs_toaster_container_br';
        this.brContainer.className = 'evs_toaster_container_part evs_tc_part_br';
        this.toasterContainer.appendChild(this.brContainer);
        // top center
        this.tcContainer = document.createElement('div');
        this.tcContainer.id = 'evs_toaster_container_tc';
        this.tcContainer.className = 'evs_toaster_container_part evs_tc_part_tc';
        this.toasterContainer.appendChild(this.tcContainer);
        // bottom center
        this.bcContainer = document.createElement('div');
        this.bcContainer.id = 'evs_toaster_container_bc';
        this.bcContainer.className = 'evs_toaster_container_part evs_tc_part_bc';
        this.toasterContainer.appendChild(this.bcContainer);
      }
      const toasterNum = this.allToaster.length;

      const toaster = document.createElement('div');
      toaster.className = 'evs_toaster';
      if (this.toasterType === 'sticky') {
        toaster.className = 'evs_toaster_sticky';
        this.toasterPosition = 'bl';
      }

      if (this.toasterPosition.slice(-1) === 'l') {
        toaster.style.margin = 'initial';
        this.renderer.setStyle(
          toaster,
          'align-self',
          'flex-start'
        );
      } else if (this.toasterPosition.slice(-1) === 'r') {
        toaster.style.margin = 'initial';
        this.renderer.setStyle(
          toaster,
          'align-self',
          'flex-end'
        );
      }

      if (this.toasterPosition.charAt(0) === 't') {
        toaster.style.animation = 'showUp .3s ease-in-out forwards';
        toaster.style.marginBottom = '.5em';
      } else if (this.toasterPosition.charAt(0) === 'b') {
        toaster.style.animation = 'showDown .3s ease-in-out forwards';
        toaster.style.marginTop = '.5em';
      }
      if (typeof config === 'object') {
        if (!!config.bgcolor || config.bgcolor !== '') {
          toaster.style.backgroundColor = config.bgcolor;
        }
        if (!!config.color || config.color !== '') {
          toaster.style.color = config.color;
        }
      }
      let btnClose = null;
      let cta = null;
      if (this.toasterType !== 'sticky') {
        btnClose = document.createElement('div');
        btnClose.className = 'evs_toaster_close_btn';
        if (typeof config === 'object') {
          if (!!config.color || config.color !== '') {
            btnClose.style.color = config.color;
          }
        }
        btnClose.innerHTML = '<ion-icon name="close-outline"></ion-icon>';
      }
      if (this.toasterType === 'toaster') {
        if (typeof config === 'string') {
          toaster.innerHTML += '<span>' + config + '</span>';
        }
        if (typeof config === 'object') {
          toaster.innerHTML += '<span>' + config.text + '</span>';
        }
      }
      if (this.toasterType === 'sticky') {
        if (typeof config === 'string') {
          toaster.innerHTML += '<blockquote class="' + this.stickyBorderColor + ' small-text">' + config + '</blockquote>';
        }
        if (typeof config === 'object') {
          if (!!config.borderColor && config.borderColor !== '') {
            this.stickyBorderColor = 'border-left-' + config.borderColor;
          } else {
            this.stickyBorderColor = 'border-left-danger';
          }
          toaster.innerHTML += '<blockquote class="' + this.stickyBorderColor + ' small-text">' + config.text + '</blockquote>';
        }
        if (!!config.ctaText && !!config.ctaLink) {
          cta = document.createElement('button');
          cta.className = 'btn xsmall light margin-left-10';
          cta.innerHTML = config.ctaText;
        }
      }
      if (this.toasterType !== 'sticky' && !!btnClose) {
        toaster.appendChild(btnClose);
        btnClose.onclick = () => {
          this.hide(toaster, toasterNum);
        };
      } else {
        if (!!cta) {
          toaster.firstChild.appendChild(cta);
          cta.onclick = () => {
            this.hide(toaster, toasterNum);
            if (config.ctaLink === 'learningContract') {
              this.learningContractClicked.next(true);
            } else if (config.ctaLink === 'validateEmail') {
              this.validateEmailClicked.next(true);
            } else if (config.ctaLink === 'regulariserPayment') {
              this.regulariserPaymentClicked.next(true);
            } else {
              this.navController.navigateRoot(config.ctaLink);
            }
          };
        }
      }

      if (this.toasterPosition === 'tl') {
        this.tlContainer.appendChild(toaster);
      } else if (this.toasterPosition === 'tr') {
        this.trContainer.appendChild(toaster);
      } else if (this.toasterPosition === 'bl') {
        this.blContainer.appendChild(toaster);
      } else if (this.toasterPosition === 'br') {
        this.brContainer.appendChild(toaster);
      } else if (this.toasterPosition === 'tc') {
        this.tcContainer.appendChild(toaster);
      } else if (this.toasterPosition === 'bc') {
        this.bcContainer.appendChild(toaster);
      }

      this.allToaster.push({toaster: toaster, position: this.toasterPosition});

      if (this.toasterType !== 'sticky') {
        this.allTimeOut[toasterNum] = setTimeout(() => {
          this.hide(toaster, toasterNum);
        }, this.toasterDuration);
      }
    }
  }

  hide(elt, index) {
    clearTimeout(this.allTimeOut[index]);

    if (this.allToaster[index].position.slice(-1) === 'l') {
      elt.style.animation = 'hideLeft .2s ease-in-out forwards';
    } else if (this.allToaster[index].position.slice(-1) === 'r') {
      elt.style.animation = 'hideRight .2s ease-in-out forwards';
    } else if (this.allToaster[index].position === 'tc') {
      elt.style.animation = 'hideUp .2s ease-in-out forwards';
    } else if (this.allToaster[index].position === 'bc') {
      elt.style.animation = 'hideDown .2s ease-in-out forwards';
    }

    setTimeout(() => {
      if (this.allToaster[index].position === 'tl') {
        this.tlContainer.removeChild(elt);
      } else if (this.allToaster[index].position === 'tr') {
        this.trContainer.removeChild(elt);
      } else if (this.allToaster[index].position === 'bl') {
        this.blContainer.removeChild(elt);
      } else if (this.allToaster[index].position === 'br') {
        this.brContainer.removeChild(elt);
      } else if (this.allToaster[index].position === 'tc') {
        this.tcContainer.removeChild(elt);
      } else if (this.allToaster[index].position === 'bc') {
        this.bcContainer.removeChild(elt);
      }
    }, this.toasterAnimationDuration);
  }

}
