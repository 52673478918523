import { QuestionsStrategy } from './questionsStrategy.interface';

import { Student } from '../../models/student';
import { Question } from '../../models/question';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
export class QuestionsContext implements QuestionsStrategy {
  private strategy: QuestionsStrategy;

  constructor(private strategyInterface: QuestionsStrategy) {
    this.strategy = this.strategyInterface;
  }

  init() {
    this.strategy.init();
  }

  setStrategy(strategy: QuestionsStrategy) {
    this.strategy = strategy;
  }

  getQuestion(questionId, student: Student, local?: boolean): Promise<any> {
    return this.strategy.getQuestion(questionId, student, local);
  }

  getQuestions(student: Student, local?: boolean): Promise<any> {
    return this.strategy.getQuestions(student, local);
  }

  setQuestion(question: Question, student: Student): Promise<Question> {
    return this.strategy.setQuestion(question, student);
  }

  setQuestions(questions: Question[], student: Student): Promise<Question[]> {
    return this.strategy.setQuestions(questions, student);
  }
}
