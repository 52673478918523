import {
  Component,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  Renderer2,
  HostListener,
  AfterViewInit,
  Output,
  EventEmitter
} from '@angular/core';

import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'loading-button-comp',
  templateUrl: 'loading-button.html',
  styleUrls: ['loading-button.scss']
})
export class LoadingButtonComponent implements OnChanges {
  @ViewChild('loadingButton') loadingButton: any;
  @ViewChild('ionSpinner') ionSpinner: any;
  @Input() compOptions: any;
  @Output() loadingButtonClicked = new EventEmitter<boolean>();

  isDebug = false;

  id: any;
  disabled = false;
  customClass = [];
  spinnerName = 'bubbles';
  spinnerColor = '';
  buttonType = 'button';
  buttonText = 'Valider';
  loadingText: string;
  loadingWithText = true;
  iconPosition = 'right';
  isLoading = false;

  constructor(
    private renderer: Renderer2,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    // detect @inputs changes
    const compOptions: SimpleChange = changes.compOptions;
    if (typeof compOptions !== 'undefined') {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  setGlobals() {
    this.isDebug = (this.compOptions && typeof this.compOptions.debug !== 'undefined') ? this.compOptions.debug : this.isDebug;
    this.id = (this.compOptions && typeof this.compOptions.id !== 'undefined') ? this.compOptions.id : null;
    this.disabled = (this.compOptions && typeof this.compOptions.disabled !== 'undefined') ? this.compOptions.disabled : this.disabled;
    this.customClass = (this.compOptions && typeof this.compOptions.customClass !== 'undefined') ? this.compOptions.customClass : this.customClass;
    this.spinnerColor = (this.compOptions && typeof this.compOptions.spinnerColor !== 'undefined') ? this.compOptions.spinnerColor : this.spinnerColor;
    this.buttonText = (this.compOptions && typeof this.compOptions.text !== 'undefined') ? this.compOptions.text : this.buttonText;
    this.loadingText = (this.compOptions && typeof this.compOptions.loadingText !== 'undefined') ? this.compOptions.loadingText : this.loadingText;
    this.buttonType = (this.compOptions && typeof this.compOptions.type !== 'undefined') ? this.compOptions.type : this.buttonType;
    this.spinnerName = (this.compOptions && typeof this.compOptions.spinner !== 'undefined') ? this.compOptions.spinner : this.spinnerName;
    this.iconPosition = (this.compOptions && typeof this.compOptions.iconPosition !== 'undefined') ? this.compOptions.iconPosition : this.iconPosition;
    this.loadingWithText = (this.compOptions && typeof this.compOptions.showText !== 'undefined') ? this.compOptions.showText : this.loadingWithText;
    this.isLoading = (this.compOptions && typeof this.compOptions.loading !== 'undefined') ? this.compOptions.loading : this.isLoading;

    if (this.customClass.length > 0) {
      for (const customClass of this.customClass) {
        this.renderer.addClass(
          this.loadingButton.nativeElement,
          customClass
        );
      }
    }
  }

  outputClick(event) {
    if (!this.isLoading) {
      this.loadingButtonClicked.emit(true);
    } else {
      event.preventDefault();
    }
  }

}
