import { Component, Input, HostListener, ViewChild, Renderer2 } from '@angular/core';

import { Location } from '@angular/common';
import { Router } from '@angular/router';

import { ModalController, NavParams, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';

import { NavigationService } from '../../services/navigation.service';
import { AppInitService } from '../../services/app-init.service';
import { StudentService } from '../../services/student.service';
import { ConfigUserService } from '../../services/config-user.service';
import { OffersService } from '../../services/offers.service';
import { StatsService } from '../../services/stats.service';
import { EvsApiHeaderService } from '../../../commons/services/EvsApiHeader.service';

import { Student } from '../../models/student';

import {
  UtilsService,
  ToasterService,
} from '@commons/services';
import { ActivatedRoute } from '@angular/router';
import { StudentPurshasesDetails } from '@app/models';
import { DomSanitizer } from '@angular/platform-browser';

import { EasyDebugDecorator } from '../../decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'modal-iframe-component',
  templateUrl: 'modal-iframe.component.html',
  styleUrls: ['modal-iframe.component.scss'],
})
export class ModalIframeComponent {

  ///////////////////////////////////////////////////////////////////////////////////
  // ATTENTION L'IFRAME NE FONCTION SUR IOS QU'AVEC UN BUILD SOUS XCODE VERSION 11 //
  ///////////////////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////////////////
  // ATTENTION L'IFRAME NE FONCTION SUR IOS QU'AVEC UN BUILD SOUS XCODE VERSION 11 //
  ///////////////////////////////////////////////////////////////////////////////////

  @ViewChild('iframeApp') iframeApp: any;
  @ViewChild('iframeLoader', { static: true }) iframeLoader: any;
  @ViewChild('btnPrev', { static: true }) btnPrev: any;
  @ViewChild('flipBox') flipBox: any;
  @ViewChild('fakeLoaderBar', { static: true }) fakeLoaderBar: any;

  // Data passed in by componentProps
  @Input() type: string;

  currentRoute: string;

  isDebug = true;

  student: Student = null;
  configUser: any;
  userLifeCycle: any;

  fetchOffersFailed = false;

  iconPath = './uikit-assets/icons/orion-svg-sprite.svg#';

  modalType = '';

  hasPromos = [];
  isPromo = false;

  isCordova = false;

  delayForCssProcess = 300;
  retry = 0;
  maxRetry = 3;

  siteOpenerConfig: any = {};
  paramUrl = 'iframe_rendering=true&url_parent=' + encodeURIComponent(window.location.href);

  iframeUrl: any;
  iframeUrlRedirect: any;
  finalUrl: any;
  size = 'full';
  reset = 0;

  urlToPost = null;
  urlToSiteOpener = null;

  logUrl: any;
  forceCloseIframe = false;
  forceReloadAccount = false;
  updateUser = false;
  redirectToTypeform = false;
  justCloseEvent= false;

  isDesktop = (window.innerWidth > 767) ? true : false;

  displayModalLoadingData = false;
  myInterval: any;

  // FX : rotating sentences
  rotateDeg = 0;
  isBoxFx = false;
  degAddValue = (this.isBoxFx) ? 540 : 180;

  closeModalLaunched = false;

  sentences = [
    'Entraîne-toi en conditions réelles grâce au mode Examen',
    'Renforce tes acquis avec nos Séries thématiques',
    'Pense à télécharger tes séries pour t’entraîner hors ligne',
    'Réserve tes leçons, de 7h à 23h, même le week-end',
    'Trouve ton enseignant, change-en quand tu veux',
    'Constate tes progrès depuis la page Statistiques',
    'Retrouve ton score par thème à la fin de chaque série',
    'Terminer le mode “Mes erreurs” déclenche un lâcher de confettis&nbsp;!',
    'Nos enseignants sont notés 4,8/5 en moyenne par la communauté',
    'Suis ton avancement dans ton livret d’apprentissage',
    'Prêt à passer le code ? Réserve ton examen en moins de 48h',
    'Notre équipe t’accompagne tout au long de ta formation',
    'Nos enseignants sont tous diplômés d’Etat',
  ];
  sentencesBucket = JSON.parse(JSON.stringify(this.sentences));
  sentenceCpt = 0;
  sentence1 = this.sentences[this.sentenceCpt];
  sentence2: any;
  cptTours = 0;
  rotateSentencesFinished = false;

  actionsRules: any = {
    "arrayMatching": {
      // "lecons": ["/lessons", "/lessons/calendrier", {"regex":"/lesson/[0-9]+"}],
      // "proposer": ["/lessons#new-lesson-by-teacher"],
      // "disponibilites": ["/disponibilites"],
      // "profil": ["/enseignants/mon-compte", "/enseignants/lieux-desservis", "/factures", "/users/edit"]
    },
    "nav": {
      "general": {
        "url": {
          "value": "*",
          "action": [
            {"type": "redirect", "value": "", "class": "alert-link", "whitelist": []},
            {"type": "absoluteUrl", "value": ""}
          ]
        }
      },
      "conduite": {
        "liste": {
          "url": {
            "value": "/conduite",
            "action": [
              {"type": "css", "value": "display: none", "id": "flash-messages"},
            ]
          }
        },
      },
      // "lessons": {
      //   "liste": {
      //     "url": {
      //       "value": "/lessons",
      //       "action": [
      //         {"type": "css", "value": "display: none", "id": "btn-new-lesson"},
      //         {"type": "redirect", "value": "/lessons#new-lesson-by-teacher", "whitelist": ["/lessons", "/lessons/calendrier", {"regex":"/lesson/[0-9]+"}]},
      //         {"type": "css", "value": "max-width: 100%;width: 100%;height: 100%", "id": "confirm-proposal-modal-dialog"},
      //         {"type": "css", "value": "border-radius: 0;height: 100%", "id": "confirm-proposal-modal-content"}
      //       ]
      //     }
      //   },
      //   "calendrier": {
      //     "url": {
      //       "value": "/lessons/calendrier",
      //       "action": [
      //         {"type": "css", "value": "display: none", "id": "btn-lessons-list"},
      //         {"type": "css", "value": "display: inline-block", "id": "btn-new-lesson"},
      //         {"type": "redirect", "value": "/lessons", "whitelist": ["/lessons", "/lessons/calendrier", {"regex":"/lesson/[0-9]+"}]}
      //       ]
      //     }
      //   },
      //   "lesson": {
      //     "url": {
      //       "regex": "/lesson/[1-9]+",
      //       "action": []
      //     }
      //   }
      // },
      // "proposer": {
      //   "url": {
      //     "value": "/lessons#new-lesson-by-teacher",
      //     "action": [
      //       {"type": "modale", "value": "show", "id": "new-lesson-by-teacher"},
      //       {"type": "css", "value": "display: none", "id": "btn-cancel"},
      //       {"type": "css", "value": "display: none", "id": "btn-close-modal"},
      //       {"type": "css", "value": "max-width: 100%;width: 100%;height: 100%", "id": "new-proposal-modal-dialog"},
      //       {"type": "css", "value": "border-radius: 0;height: 100%", "id": "new-proposal-modal-content"}
      //     ]
      //   }
      // },
      // "disponibilites": {
      //   "url": {
      //     "value": "/disponibilites",
      //     "action": []
      //   }
      // },
      // "profil": {
      //   "infos_perso": {
      //     "url": {
      //       "value": "/enseignants/mon-compte",
      //       "action": [
      //         {"type": "css", "value": "display:none", "id": "btn-facturation"},
      //         {"type": "css", "value": "display:none", "id": "btn-lieux-desservis"}
      //       ]
      //     },
      //     "modif-mot-de-passe": {
      //       "url": {
      //         "value": "/users/edit",
      //         "action": []
      //       }
      //     }
      //   },
      //   "lieux-desservis": {
      //     "url": {
      //       "value": "/enseignants/lieux-desservis",
      //       "action": [
      //         {"type": "css", "value": "display:none", "id": "back-link"}
      //       ]
      //     }
      //   },
      //   "facturation": {
      //     "url": {
      //       "value": "/factures",
      //       "action": []
      //     }
      //   }
      // }
    }
  };

  studentPurshases: StudentPurshasesDetails;
  payments: any = [];
  gifts = [];
  coupons = [];
  paymentsGiftsCoupons: any = [];
  offers: any = [];
  isPackPermis = false;
  isPackCode = false;
  isPackCodePremium = false;
  isHourOnly = false;
  hasHours = false;
  paymentsDone = false;

  subscribe = null;

  // DRAG IFRAME
  marginTop = 0;
  marginTopDrag = 0;

  posX = 0;
  posY = 0;
  isDrag = false;
  hasMoved = false;

  backdropIframe: any = document.getElementsByClassName('backdrop-iframe')[0];
  fakeWindow: any = document.getElementsByClassName('fake-window')[0];
  preHeader: any = document.getElementsByClassName('pre-header')[0];

  // GA
  trackingId: any;
  clientId: any;
  ionicPlatform: string;
  ionicPlatforms: any;

  // fakeLoaderBar
  fakeLoaderBarPercent = 0;
  fakeLoaderBarInterval = null;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.isDesktop = (window.innerWidth > 767) ? true : false;
  }

  constructor(
    public evsApiHeaderService: EvsApiHeaderService,
    public utils: UtilsService,
    public navParams: NavParams,
    private modalController: ModalController,
    public appInitService: AppInitService,
    public studentService: StudentService,
    public configUserService: ConfigUserService,
    public navigationService: NavigationService,
    public offersService: OffersService,
    private statsService: StatsService,
    private activatedRoute: ActivatedRoute,
    private toasterService: ToasterService,
    private router: Router,
    public platform: Platform,
    public renderer: Renderer2,
    public sanitizer: DomSanitizer,
    public storage: Storage,
  ) {
    this.isCordova = this.platform.is('cordova');
    this.modalType = this.navParams.get('type');
    this.iframeUrl = this.navParams.get('url');

    if (!!this.iframeUrl) {
      this.navigationService.iframeAlreadyOpen = true;
    }

    // reset
    this.rotateDeg = 0;
    this.isBoxFx = false;
    this.degAddValue = (this.isBoxFx) ? 540 : 180;
    this.displayModalLoadingData = false;
    this.logUrl = null;
    this.sentencesBucket = JSON.parse(JSON.stringify(this.sentences));
    this.sentenceCpt = 0;
    this.sentence1 = this.sentences[this.sentenceCpt];
    this.sentence2 = null;
    this.cptTours = 0;
    this.rotateSentencesFinished = false;
    this.forceCloseIframe = false;
    this.forceReloadAccount = false;

    this.userLifeCycle = this.studentService.getUserLifeCycle(this.student); // if student not ready return guest
    this.subscribe = this.appInitService.onStepChange().subscribe(
      (state) => {
        if (state?.initDone) {
          this.initPage();
        }
      }
    );
  }

  ionViewWillLeave() {
    this.subscribe.unsubscribe();
  }

  @HostListener('touchstart', ['$event']) onTouchStart(event: any) {
    if (!event.target.className.includes('btn-circle')) {
      const iframeElt = document.getElementById('evs-iframe');
      iframeElt.classList.toggle('no-events');
      this.backdropIframe = (!this.backdropIframe || this.backdropIframe === undefined) ? document.getElementsByClassName('backdrop-iframe')[0] : this.backdropIframe;
      this.fakeWindow = (!this.fakeWindow || this.fakeWindow === undefined) ? document.getElementsByClassName('fake-window')[0] : this.fakeWindow;
      this.preHeader = (!this.preHeader || this.preHeader === undefined) ? document.getElementsByClassName('pre-header')[0] : this.preHeader;
      this.preHeader.style.transition = 'none';
      this.backdropIframe.style.transition = 'none';
      this.processMouseDown(event);
    }
  }
  @HostListener('touchmove', ['$event']) onTouchMove(event: any) {
    this.processMouseMove(event);
  }
  @HostListener('touchend', ['$event']) onTouchEnd(event: any) {
    this.processMouseUp(event);
  }

  @HostListener('mousedown', ['$event']) onMouseDown(event: any) {
    if (!event.target.className.includes('btn-circle')) {
      const iframeElt = document.getElementById('evs-iframe');
      iframeElt.classList.toggle('no-events');
      this.backdropIframe = (!this.backdropIframe || this.backdropIframe === undefined) ? document.getElementsByClassName('backdrop-iframe')[0] : this.backdropIframe;
      this.fakeWindow = (!this.fakeWindow || this.fakeWindow === undefined) ? document.getElementsByClassName('fake-window')[0] : this.fakeWindow;
      this.preHeader = (!this.preHeader || this.preHeader === undefined) ? document.getElementsByClassName('pre-header')[0] : this.preHeader;
      this.preHeader.style.transition = 'none';
      this.backdropIframe.style.transition = 'none';
      this.processMouseDown(event);
    }
  }
  @HostListener('mousemove', ['$event']) onMouseMove(event: any) {
    this.processMouseMove(event);
  }
  @HostListener('mouseup', ['$event']) onMouseUp(event: any) {
    this.processMouseUp(event);
  }

  processMouseDown(event) {
    // remove transition during drag
    this.posX = this.utils.unify(event).clientX;
    this.posY = this.utils.unify(event).clientY;
    this.isDrag = true;
    this.hasMoved = false;
  }

  processMouseMove(event) {
    if (this.isDrag) {
      this.utils.preventDefault(event);
      const diffX = (this.utils.unify(event).clientX) - this.posX;
      const diffY = (this.utils.unify(event).clientY) - this.posY;
      const tmpDiffX = (diffX < 0) ? (diffX * -1) : diffX;
      const tmpDiffY = (diffY < 0) ? (diffY * -1) : diffY;
      if (tmpDiffY > tmpDiffX) {
        this.marginTopDrag = this.marginTop + diffY;
        this.marginTopDrag = (this.marginTopDrag < 0) ? 0 : this.marginTopDrag;
        // move only if necessary
        if (this.marginTopDrag !== this.marginTop) {
          this.hasMoved = true;
          this.preHeader.style.top = this.marginTopDrag + 'px';
          const percent = this.utils.getPercent(this.marginTopDrag, document.body.offsetHeight);
          this.backdropIframe.style.opacity = ((100 - percent) / 100).toFixed(1);
          let percentH = 16 - (16 * ((percent * 2) / 100));
          percentH = (percentH < 0) ? 0 : percentH;
          percentH = (percentH > 16) ? 16 : percentH;
          this.fakeWindow.style.height = percentH + 'px';
        } else {
          this.hasMoved = false;
        }
      }
    }
  }

  processMouseUp(event) {
    if (this.isDrag) {
      const offsetHeight = document.body.offsetHeight;
      this.backdropIframe.style.transition = 'all .3s ease-out';
      this.fakeWindow.style.transition = 'all .3s ease-out';
      this.preHeader.style.transition = 'all .3s ease-out';
      if (this.marginTopDrag > Math.ceil(offsetHeight / 3)) {
        // close iframe
        this.preHeader.style.top = (offsetHeight + 10) + 'px';
        this.backdropIframe.style.opacity = 0;
        this.fakeWindow.style.height = '0px';
        setTimeout(() => {
          this.closeModalIframe();
        }, 300);
      } else {
        // return to top
        this.preHeader.style.top = 0;
        this.backdropIframe.style.opacity = 1;
        this.fakeWindow.style.height = '16px';
        const iframeElt = document.getElementById('evs-iframe');
        iframeElt.classList.toggle('no-events');
      }
      this.isDrag = false;
    }
  }

  // listen message
  @HostListener('window:message', ['$event']) getPostMessage(event: any) {
    const message = event;
    // créer un objet trustedOrigins plutot que ca || ca || etc
    if (event.origin.includes('envoituresimone') || event.origin.includes('herokuapp')) {
      if (this.isDebug) {
        console.log('--------------------------------------------');
        console.log('Event Message', message);
        console.log('Event', event);
        console.log('Message', event.data);
        console.log('Origin', event.origin);
        console.log('--------------------------------------------');
      }
      if (!!event.data && typeof event.data === "string") {
        const jsonObj = JSON.parse(event.data);
        // console.log('jsonObj', jsonObj);
        if (!!jsonObj.action) {
          if (jsonObj.action === 'redirect') {
            if (!!jsonObj.route && !!jsonObj.url && jsonObj.route !== '') {
              if (this.isDebug) {
                // il va falloir faire un check des urls pour voir si on affiche l'iframe avec une url spécifique ou si on l'affiche via son point d'entrée classique
                // convention : si on l'affiche avec une url spécifique, on utilise pas iframe_rendering mais starting_point
                // iframe_rendering servira à Carmen à déterminer les points d'entrées de l'app lors des navigations au sein d'un groupe d'url d'un groupe de l'app
                // (ex: /lesson & /lesson/calendrier appartiennent au groupe /lesson mais le calendrier avec un starting_point /proposer doit s'afficher différement)
                console.log('jsonObj', jsonObj);
                console.log('REDIRECTED TO : ' + jsonObj.route);
                // save dans Storage l'url reçue pour remplacer l'url de base de la page
                // dès qu'on la récupérée, on la retire du storage
                // ex: lecon = /lessons mais si param url alors lecon = /lesson/3081457618
                // et c'est là qu'on mets le starting_point = lecons (ex: https://dev.envoituresimone.com/lesson/3081457618?iframe_rendering=true&starting_point=lecons)
              }
              if (jsonObj.url !== '') {
                this.setStorage('IframeUrlRedirect', jsonObj);
              }
              // console.log(jsonObj.route);
              // this.navController.navigateRoot(jsonObj.route);
            }
          }
          if (jsonObj.action === 'closeModalIframe') {
            if (!!jsonObj.value && jsonObj.value !== '') {
              this.justCloseEvent = true;
              this.closeModalIframe();
            }
          }
          if (jsonObj.action === 'backBtn') {
            if (!!jsonObj.value && jsonObj.value !== '') {
              this.updatePrevBtn(jsonObj.value);
            }
          }
          if (jsonObj.action === 'userLogged') {
            if (!!jsonObj.value && jsonObj.value !== '') {
              // console.log('userLogged', jsonObj.value);
              this.logUrl = jsonObj.value;
            }
          }
          if (jsonObj.action === 'forceUserToLog') {
            if (!!jsonObj.value && jsonObj.value !== '') {
              this.forceCloseIframe = true;
              this.logUrl = jsonObj.value;
              // console.log('forceUserToLog', jsonObj.value);
              this.closeModalIframe();
            }
          }
          if (jsonObj.action === 'userPpuResolved') {
            if (!!jsonObj.value && jsonObj.value !== '') {
              this.logUrl = jsonObj.value;
              this.forceReloadAccount = true;
            }
          }
          if (jsonObj.action === 'iframeSource') {
            if (!!jsonObj.value && jsonObj.value !== '') {
              this.urlToPost = jsonObj.value;
              this.sendActionsRules();
            }
          }
          if (jsonObj.action === 'siteOpener') {
            if (!!jsonObj.value && jsonObj.value !== '') {
              if (this.isDebug) {
                console.log('####################################');
                console.log('SITEOPENER TO : ' + jsonObj.value);
                console.log('####################################');
              }
              this.forceCloseIframe = true;
              this.urlToSiteOpener = jsonObj.value;
              this.closeModalIframe();
            }
          }
          if (jsonObj.action === 'updateStudent') {
            this.updateUser = true;
          }
          if (jsonObj.action === 'typeformRedirect') {
            this.redirectToTypeform = true;
            this.closeModalIframe();
          }
        }
      }
    }
  }

  async initPage() {
    this.fakeLoaderBarInterval = setInterval(() => {
      this.fakeLoaderBarPercent += this.utils.randomMinMax(5, 15);
      if (this.fakeLoaderBarPercent > 80) {
        this.fakeLoaderBarPercent = 80;
      }
      this.setLoaderBarProgress();
    }, 600);
    this.backdropIframe = document.getElementsByClassName('backdrop-iframe')[0];
    this.fakeWindow = document.getElementsByClassName('fake-window')[0];
    this.preHeader = document.getElementsByClassName('pre-header')[0];
    await this.getGA();
    this.getStorage();
    this.statsService.send({ name: 'modal:view', payload: { page: `/modal/${this.modalType}` } });
    this.student = this.studentService.student;
    await this.resetStickyToasters();
    this.userLifeCycle = this.studentService.getUserLifeCycle(this.student); // if student not ready return guest
    this.getPayments();
  }

  async getGA() {
    if (!this.platform.is('ios')) {
      if (!!(window as any).ga) {
        this.trackingId = await ((window as any).ga.getAll()[0].get('trackingId'));
        if (!this.trackingId) {
          (window as any).ga('create', 'UA-64583996-1', 'auto');
        }
        await (window as any).ga(function(tracker: any) {
          this.clientId = tracker.get('clientId');
        });
        if (!this.clientId) {
          this.clientId = await ((window as any).ga.getAll()[0].get('clientId'));
        }
      } else {
        console.warn('Google Analytics not defined in window!');
      }
    }
    if (this.platform.is('cordova') && this.platform.is('ios')) {
      this.ionicPlatform = 'ios';
    } else if (this.platform.is('cordova') && this.platform.is('android')) {
      this.ionicPlatform = 'android';
    } else  {
      this.ionicPlatform = 'browser';
    }
    this.ionicPlatforms = this.platform.platforms();
  }

  setLoaderBarProgress() {
    this.renderer.setStyle(
      this.fakeLoaderBar.nativeElement,
      'width',
      this.fakeLoaderBarPercent + '%',
    );
    if (this.fakeLoaderBarPercent === 100) {
      setTimeout(() => {
        this.renderer.setStyle(
          this.fakeLoaderBar.nativeElement,
          'opacity',
          '0'
        );
        setTimeout(() => {
          this.renderer.setStyle(
            this.fakeLoaderBar.nativeElement,
            'transition',
            'none'
          );
          this.renderer.setStyle(
            this.fakeLoaderBar.nativeElement,
            'width',
            0
          );
          this.fakeLoaderBarPercent = 0;
        }, 300);
      }, 200);
    }
  }

  getPayments() {
    this.studentPurshases = this.studentService.getStudentPurshases();
    const resGetOffers = this.studentPurshases.offers;
    if (!!resGetOffers && !!resGetOffers.errorCode && resGetOffers.errorCode === 'E301') {
      this.fetchOffersFailed = true;
    } else {
      this.offers = resGetOffers;
    }
    this.gifts = this.studentPurshases.gifts;
    this.studentPurshases.linkOfferTopayment();
    this.studentPurshases.removeCouponsIfPresentInPayments();
    this.payments = this.studentPurshases.payments;
    this.coupons = this.studentPurshases.coupons;

    this.hasHours = this.studentPurshases.hasHours();
    this.isHourOnly = this.studentPurshases.isHourOnly();
    this.isPackCode = this.studentPurshases.isPackCode();
    this.isPackCodePremium = this.studentPurshases.isPackCodePremium();
    this.isPackPermis = this.studentPurshases.isPackPermis();

    const tmp = this.gifts.concat(this.coupons);
    this.paymentsGiftsCoupons = tmp.concat(this.payments);

    this.paymentsDone = true;
  }

  initIframe() {
    if (!!this.iframeApp) {
      this.iframeApp.nativeElement.onload = (event: any) => {
        clearInterval(this.fakeLoaderBarInterval);
        this.fakeLoaderBarPercent = 100;
        this.setLoaderBarProgress();
        setTimeout(() => {
          this.renderer.setStyle(
            this.iframeApp.nativeElement,
            'opacity',
            '1'
          );
          this.renderer.setStyle(
            this.iframeLoader.nativeElement,
            'display',
            'none'
          );
          this.sendActionsRules();
          this.iframeApp.nativeElement.ondrag = (e: any) => {
            // console.log(e);
          };
        }, this.delayForCssProcess);
      };
      this.iframeApp.nativeElement.onerror = (err: any) => {
        console.log(err);
      };
    } else {
      if (this.retry < this.maxRetry) {
        setTimeout(() => {
          this.retry++;
          this.initIframe();
        }, 50);
      }
    }
  }

  sendActionsRules() {
    const target: any = document.getElementById('evs-iframe');
    if (!!target && !!this.iframeUrl) {
      const receiver = target.contentWindow;
      const queryStringParam = (this.iframeUrl.includes('?')) ? '&' : '?';
      let url = this.iframeUrl + queryStringParam + 'iframe_rendering=true&url_parent=' + encodeURIComponent(window.location.hostname);
      if (!!this.urlToPost) {
        url = this.urlToPost;
      }
      // console.log('url', url);
      if (!!receiver) {
        if (!!this.clientId) {
          this.actionsRules.clientId = this.clientId;
        }
        if (!!this.ionicPlatform) {
          this.actionsRules.ionic_platform = this.ionicPlatform;
        }
        if (!!this.ionicPlatforms) {
          this.actionsRules.ionic_platforms = this.ionicPlatforms.join(',');
        }
        // send message
        receiver.postMessage(this.actionsRules, url);
        if (this.isDebug) {
          console.log(this.actionsRules, ' sent to ' + url);
        }
      }
    }
  }

  sendGoBackHistory() {
    const target: any = document.getElementById('evs-iframe');
    if (!!target && !!this.iframeUrl) {
      const receiver = target.contentWindow;
      const queryStringParam = (this.iframeUrl.includes('?')) ? '&' : '?';
      let url = this.iframeUrl + queryStringParam + 'iframe_rendering=true&url_parent=' + encodeURIComponent(window.location.hostname);
      if (!!this.urlToPost) {
        url = this.urlToPost;
      }
      const message = {"action": "goBack"};
      // console.log('url', url);
      if (!!receiver) {
        // send message
        receiver.postMessage(message, url);
        if (this.isDebug) {
          console.log(message, ' sent to ' + url);
        }
      }
    }
  }

  getStorage() {
    this.storage.get('IframeUrlRedirect').then(
      iframeUrlRedirect => {
        this.iframeUrlRedirect = iframeUrlRedirect;
        // console.log('iframeUrlRedirect', this.iframeUrlRedirect);
        // reset
        this.siteOpenerConfig = {};
        this.siteOpenerConfig.teacherApp = true;

        let queryStringParam = '?';

        if (!!this.iframeUrlRedirect) {
          this.removeKeyStorage('IframeUrlRedirect');

          this.iframeUrl = this.iframeUrlRedirect.url;
          this.transformToLinkUrl();
          // console.log('avant includes avec iframeUrlRedirect', this.iframeUrl);
          queryStringParam = (this.iframeUrl.includes('?')) ? '&' : '?';
          this.paramUrl = 'iframe_rendering=true&url_parent=' + encodeURIComponent(window.location.hostname) + '&starting_point=' + encodeURIComponent(this.iframeUrlRedirect.route);

          if (!!this.iframeUrlRedirect.id && this.iframeUrlRedirect.id !== '') {
            this.siteOpenerConfig.id = this.iframeUrlRedirect.id;
          }
        } else {
          // console.log('avant includes sans iframeUrlRedirect', this.iframeUrl);
          if (!!this.iframeUrl) {
            queryStringParam = (this.iframeUrl.includes('?')) ? '&' : '?';
          }
        }
        this.finalUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeUrl + queryStringParam + this.paramUrl);
        this.initIframe();
      }
    );
  }

  setStorage(key, value) {
    this.storage.set(key, value).catch(
      err => {
        console.log('Set Storage error', err);
      }
    );
  }

  transformToLinkUrl() {
    const saveOld = this.iframeUrl;
    this.iframeUrl = this.iframeUrl.replace(/\//g, '-');
    const tmp = this.iframeUrl.substr(0, 1);
    if (tmp === '-') {
      this.iframeUrl = this.iframeUrl.substr(1, this.iframeUrl.length);
    }
    // console.log(saveOld, this.iframeUrl);
  }

  removeKeyStorage(key) {
    this.storage.remove(key).catch(
      err => {
        // console.log('Clear Storage error', err);
      }
    );
  }

  updatePrevBtn(value: any) {
    setTimeout(() => {
      if (value === 'show') {
        this.renderer.removeClass(
          this.btnPrev.nativeElement,
          'invisible'
        );
      }
      if (value === 'hide') {
        this.renderer.addClass(
          this.btnPrev.nativeElement,
          'invisible'
        );
      }
    }, 0);
  }

  fetchOffers() {
    this.studentPurshases = this.studentService.getStudentPurshases();
    const resGetOffers = this.studentPurshases.offers;
    if (!!resGetOffers && !!resGetOffers.errorCode && resGetOffers.errorCode === 'E301') {
      this.fetchOffersFailed = true;
    } else {
      this.offers = resGetOffers;
    }
  }

  async closeModalIframe() {
    if (!this.closeModalLaunched) {
      this.navigationService.iframeAlreadyOpen = false;
      this.closeModalLaunched = true;
      this.statsService.send({
        name: 'modal:unview', payload: {
          referer: `/modal/${this.modalType}`,
          page: `/${this.activatedRoute.firstChild.snapshot.routeConfig.path}`
        }
      });
      if (this.userLifeCycle.isFrozen) {
        if (!!this.student.subStatus && this.student.subStatus.includes('payment_failed')) {
          this.iframeUrl = null;
          await this.showStickyImpayes();
        }
      }
      const currentRoute = this.router.url.substring(1);
      
      this.navigationService.closedIframeObs.next({close: true});
      if (this.justCloseEvent || currentRoute === 'reservation-examen-code' || currentRoute === 'mon-inscription-examen') {
        if (!!this.modalController) {
          this.modalController.dismiss();
        }
        return;
      }
      if (this.redirectToTypeform) {
        if (!!this.modalController) {
          this.modalController.dismiss();
        }
        this.openLink('terminer-mon-inscription');
        return;
      }
      if (!!this.logUrl && this.logUrl !== '') {
        const isSignedInHard = await this.studentService.isSignedInHard();
        if (!isSignedInHard || this.forceReloadAccount) {
          // this.startLoadingModal();
          const student = this.studentService.autoLogPromise(this.decodeEntities(this.logUrl));
          // const student = await this.studentService.autoLogPromise(this.decodeEntities(this.logUrl));
          // this.rotateSentencesFinished = true;
          this.redirectWhenFinished();
        } else {
          if (this.updateUser) {
            // this.startLoadingModal();
            // const resetInit = await this.appInitService.init0();
            const resetInit = this.appInitService.init0();
            // this.rotateSentencesFinished = true;
            this.redirectWhenFinished();
          } else if (!!this.urlToSiteOpener && this.urlToSiteOpener !== '') {
            this.openLink(this.urlToSiteOpener, {target: '_system', forceOutLink: true});
            this.iframeUrl = null;
            if (!!this.modalController) {
              this.modalController.dismiss();
            }
          } else {
            this.appInitService.runLightInit().then(
              res => {
                this.redirectWhenFinished();
              }
            )
            this.iframeUrl = null;
            if (!!this.modalController) {
              this.modalController.dismiss();
            }
          }
        }
      } else {
        if (!!this.urlToSiteOpener && this.urlToSiteOpener !== '') {
          this.openLink(this.urlToSiteOpener, {target: '_system', forceOutLink: true});
          this.iframeUrl = null;
          if (!!this.modalController) {
            this.modalController.dismiss();
          }
        } else {
          this.iframeUrl = null;
          if (!!this.modalController) {
            this.modalController.dismiss();
          }
        }
      }
    }
  }

  launchToaster(config) {
    this.toasterService.create(config);
  }

  async resetStickyToasters() {
    const stickyToasters = document.getElementsByClassName('evs_toaster_sticky');
    for (const el of <any>stickyToasters) {
      el.parentNode.removeChild(el);
    }
    if (!this.configUser || this.configUser == null) {
      this.configUser = await this.configUserService.getUserConfig(this.student);
    }
    this.configUser.impayesStickyToasterShown = false;
    await this.configUserService.setUserConfig(this.student, this.configUser);
  }

  async showStickyImpayes() {
    if (!this.configUser || this.configUser == null) {
      this.configUser = await this.configUserService.getUserConfig(this.student);
    }
    if (!!this.configUser && !this.configUser.impayesStickyToasterShown && !this.configUser.outstandingPaymentModalShown) {
      this.configUser.impayesStickyToasterShown = true;
      this.configUser.outstandingPayment = true;
      await this.configUserService.setUserConfig(this.student, this.configUser);
      this.launchToaster({text: 'Une de tes échéances de paiement semble avoir échoué.', type: 'sticky', borderColor: 'danger', ctaText: 'PAYER', ctaLink: 'regulariserPayment'});
    }
  }

  redirectWhenFinished() {
    if (!!this.urlToSiteOpener && this.urlToSiteOpener !== '') {
      this.openLink(this.urlToSiteOpener, {target: '_system', forceOutLink: true});
      this.iframeUrl = null;
      if (!!this.modalController) {
        this.modalController.dismiss();
      }
    } else {
      const currentRoute = this.router.url.substr(1);
      let forceRedirectUrl = null;
      if (!!this.logUrl) {
        forceRedirectUrl = this.logUrl.replace(/^.*\/\/[^\/]+/, '').substr(1).split('?')[0];
        // if (!this.forceCloseIframe || (this.forceCloseIframe && currentRoute === forceRedirectUrl)) {
        //   this.iframeUrl = null;
        //   this.openLink('splash');
        // }
      }
      // this.hideModalLoadingData();
      if (!this.forceCloseIframe) {
        // this.openLink(currentRoute);
        if (this.userLifeCycle.isUserGuest || this.userLifeCycle.isUserRegistered) {
          this.openLink('code');
        } else {
          if (this.isPackPermis || this.isPackCode || this.isPackCodePremium || this.hasHours) {
            this.openLink('dashboard');
          } else {
            this.openLink('code');
          }
        }
      } else {
        // redirect to path
        this.openLink(forceRedirectUrl);
      }
      this.statsService.send({ name: 'user:login' });
      this.iframeUrl = null;
      if (!!this.modalController) {
        this.modalController.dismiss();
      }
      // setTimeout(() => {
      //   // currentRoute
      //   this.hideModalLoadingData();
      //   if (!this.forceCloseIframe) {
      //     // this.openLink(currentRoute);
      //     this.openLink('dashboard');
      //   } else {
      //     // redirect to path
      //     this.openLink(forceRedirectUrl);
      //   }
      //   this.statsService.send({ name: 'user:login' });
      //   this.iframeUrl = null;
      //   if (!!this.modalController) {
      //     this.modalController.dismiss();
      //   }
      // }, 1000);
    }
  }

  startLoadingModal() {
    this.showModalLoadingData();
    // animate sentences
    this.rotateSentencesFinished = false;
    this.sentence1 = (this.forceReloadAccount || this.updateUser) ? 'Mise à jour de tes données en cours' : 'Chargement de tes données en cours';
    this.myInterval = setInterval(() => {
      if (!this.rotateSentencesFinished) {
        setTimeout(() => {
          this.sentenceChange();
        }, 0);
      } else {
        clearInterval(this.myInterval);
        const finalSentence = (this.forceReloadAccount || this.updateUser) ? 'Mise à jour terminée&nbsp;!' : 'Chargement terminé&nbsp;!';
        this.sentenceChange(finalSentence);
        if (!!this.urlToSiteOpener && this.urlToSiteOpener !== '') {
          this.openLink(this.urlToSiteOpener, {target: '_system', forceOutLink: true});
          this.iframeUrl = null;
          if (!!this.modalController) {
            this.modalController.dismiss();
          }
        } else {
          const currentRoute = this.router.url.substr(1);
          let forceRedirectUrl = null;
          if (!!this.logUrl) {
            forceRedirectUrl = this.logUrl.replace(/^.*\/\/[^\/]+/, '').substr(1).split('?')[0];
            // if (!this.forceCloseIframe || (this.forceCloseIframe && currentRoute === forceRedirectUrl)) {
            //   this.iframeUrl = null;
            //   this.openLink('splash');
            // }
          }
          this.hideModalLoadingData();
          if (!this.forceCloseIframe) {
            // this.openLink(currentRoute);
            if (this.userLifeCycle.isUserGuest || this.userLifeCycle.isUserRegistered) {
              this.openLink('code');
            } else {
              if (this.isPackPermis || this.isPackCode || this.isPackCodePremium || this.hasHours) {
                this.openLink('dashboard');
              } else {
                this.openLink('code');
              }
            }
          } else {
            // redirect to path
            this.openLink(forceRedirectUrl);
          }
          this.statsService.send({ name: 'user:login' });
          this.iframeUrl = null;
          if (!!this.modalController) {
            this.modalController.dismiss();
          }
          // setTimeout(() => {
          //   // currentRoute
          //   this.hideModalLoadingData();
          //   if (!this.forceCloseIframe) {
          //     // this.openLink(currentRoute);
          //     this.openLink('dashboard');
          //   } else {
          //     // redirect to path
          //     this.openLink(forceRedirectUrl);
          //   }
          //   this.statsService.send({ name: 'user:login' });
          //   this.iframeUrl = null;
          //   if (!!this.modalController) {
          //     this.modalController.dismiss();
          //   }
          // }, 1000);
        }
      }
    }, 2000);
  }

  sentenceChange(text = null) {
    if (!!this.flipBox && !!this.flipBox.nativeElement) {
      this.cptTours++;
      this.rotateDeg += this.degAddValue;
      this.renderer.setStyle(this.flipBox.nativeElement, 'transform', 'translateZ(-75px) rotateX(' + this.rotateDeg + 'deg)');
      if (this.sentenceCpt === this.sentences.length - 2) {
        this.sentenceCpt = 0;
      } else {
        this.sentenceCpt ++;
      }
      if (this.sentencesBucket.length === 0) {
        this.sentencesBucket = JSON.parse(JSON.stringify(this.sentences));
      }
      const randomIndex = this.utils.randomMinMax(0, this.sentencesBucket.length - 1);
      const sentence = this.sentencesBucket[randomIndex];
      this.sentencesBucket.splice(randomIndex, 1); // remove elt from bucket
      if (this.cptTours % 2 === 0) {
        this.sentence1 = (!!text) ? text : sentence;
      } else {
        this.sentence2 = (!!text) ? text : sentence;
      }
    } else {
      setTimeout(() => {
        this.sentenceChange();
      }, 0);
    }
  }

  hideModalLoadingData() {
    this.displayModalLoadingData = false;
  }

  showModalLoadingData() {
    this.displayModalLoadingData = true;
  }

  decodeEntities(encodedString: string) {
    const textArea = document.createElement('textarea');
    textArea.innerHTML = encodedString;
    return textArea.value;
  }

  openLink(link: any, options?: any) {
    this.navigationService.open(link, options);
  }

}
