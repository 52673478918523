import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'frenchDatePipe' })

export class FrenchDatePipe implements PipeTransform {

  frenchDays = [
    'dimanche',
    'lundi',
    'mardi',
    'mercredi',
    'jeudi',
    'vendredi',
    'samedi',
  ];

  frenchMonths = [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ];

  transform(value: any, format?: string): any {
    /*
    d = date: number
    j = jour: string
    m = mois: string
    y = annee: string
    */
    if (value) {
      const date: any = new Date(value);
      if (!!format) {
        let returnedValue = '';
        if (format.indexOf('j') !== -1) {
          returnedValue += (returnedValue !== '') ? ' ' : '';
          returnedValue += this.frenchDays[date.getDay()];
        }
        if (format.indexOf('d') !== -1) {
          returnedValue += (returnedValue !== '') ? ' ' : '';
          returnedValue += date.getDate();
        }
        if (format.indexOf('m') !== -1) {
          returnedValue += (returnedValue !== '') ? ' ' : '';
          returnedValue += this.frenchMonths[date.getMonth()];
        }
        if (format.indexOf('y') !== -1) {
          returnedValue += (returnedValue !== '') ? ' ' : '';
          returnedValue += date.getFullYear();
        }
        return returnedValue;
      } else {
        return date.getDate() + ' ' + this.frenchMonths[date.getMonth()] + ' ' + date.getFullYear();
      }
    }
    return '';
  }
}