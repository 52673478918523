import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Initialable } from './app-init.service';
import { NetworkStatusService } from './network-status.service';

import { CPFSubscriptionModel } from '../models/CPFSubscription';
import { Student } from '../models/student';
import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import { UserErrorHandlerService } from '../../app/services/user-error-handler.service';

@Injectable({ providedIn: 'root' })
@Initialable({ step: 'init2', initializer: 'onInit' })
@EasyDebugDecorator
export class CPFSubscriptionsService {
  private _CPFSubscriptions: Array<CPFSubscriptionModel> = [];
  constructor(private http: HttpClient,
    private networkService: NetworkStatusService,
    private storageService: StorageService,
    private userErrorHandlerService: UserErrorHandlerService,
  ) { }

  async onInit() {
    return 'CPFSubscriptions done';
  }

  fetchCPFSubscriptionsByUserId(accountId: string): Observable<CPFSubscriptionModel[]> {
    let url: string;

    if (this.networkService?.isOffline()) {
      return of([]);
    }

    if (!!accountId) {

      url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${accountId}/cpf_subscriptions`

      return this.http.get(url).pipe(
        switchMap(data => {
          this.storageService.set(this.storageKey(accountId), data);
          this._CPFSubscriptions = this.buildCPFSubscriptionsFromRawData(data);
          return of(this._CPFSubscriptions);
        }),
        catchError(
          async err => {
            this.userErrorHandlerService.addError({criticity: 1, service: 'cpf-subscriptions', platform: 'both', data: err, errorCode: 'gsfgbuid'});
            return [];
          }
        )
      );
    }
  }

  async loadStudentCPFSubscriptions(student: Student) {
    if (student.isGuest()) {
      this._CPFSubscriptions = [];
    } else {
      const transformed = await this.storageService.get('transformed');

      if (this.networkService?.isOffline() || (!!transformed && transformed)) {
        this.loadFromStorage(student);
      } else {
        this.fetchCPFSubscriptionsByUserId(student.remoteId).subscribe(res => {
          this._CPFSubscriptions = res;
        });
      }
    }
  }

  ///////////////////////////////////
  /////          GETTERS       /////
  ///////////////////////////////////

  get CPFSubscriptions(): Array<CPFSubscriptionModel> {
    return this._CPFSubscriptions;
  }

  private


  async loadFromStorage(student: Student) {
    this.storageService.get(this.storageKey(student.remoteId)).then((res) => {
      this._CPFSubscriptions = !!res ? this.buildCPFSubscriptionsFromRawData(res) : [];
    });
  }

  storageKey(accountId: String): string {
    return `${accountId}-CPFSubscriptions`;
  }

  buildCPFSubscriptionFromRawData(data: any): CPFSubscriptionModel {
    return new CPFSubscriptionModel({
      id: data.id,
      type: data.type,
      created_at: data.attributes.created_at,
      updated_at: data.attributes.updated_at,
      offer_id: data.attributes.cpf_offer.data.id,
      name: data.attributes.cpf_offer.data.attributes.name,
      offer_type: data.attributes.cpf_offer.data.attributes.offer_type,
      ttc_price: data.attributes.cpf_offer.data.attributes.ttc_price,
      external_id: data.attributes.external_id,
      offer_external_id: data.attributes.cpf_offer.data.attributes.external_id,
      active: data.attributes.cpf_offer.data.attributes.active,
      credits: data.attributes.cpf_offer.data.attributes.credits,
      code_exam_credits: data.attributes.cpf_offer.data.attributes.code_exam_credits,
      driving_exam_credits: data.attributes.cpf_offer.data.attributes.driving_exam_credits,
      cpf_subscription_displayed_at: data.attributes.cpf_subscription_displayed_at,
      video_displayable: data.attributes.video_displayable,
      session_start_date: data.attributes.session_start_date,
      state: data.attributes.state
    });
  }

  buildCPFSubscriptionsFromRawData(data: any): Array<CPFSubscriptionModel> {
    const arr: Array<CPFSubscriptionModel> = [];
    if (!!data && !!data.data && data.data.length > 0) {
      for (const CPFSubscription of data.data) {
        arr.push(this.buildCPFSubscriptionFromRawData(CPFSubscription));
      }
    }
    return arr;
  }

  updateExamReservationDisplayed(student: Student, CPFSubscriptionIDs: Array<string>): Observable<any> {
    let url: string;

    if (this.networkService?.isOffline()) {
      return of(null);
    }

    url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${student.remoteId}/cpf_subscriptions/display_modal`
    let data = {
      cpf_subscription_ids: CPFSubscriptionIDs
    };

    return this.http.post(url, data).pipe(
      switchMap(
        async res => {
          await this.loadStudentCPFSubscriptions(student);
          return of(res);
        }
      ),
      catchError(
        err => {
          return of(err);
        }
      )
    );
  }

  getCPFEligibilitySteps(CPFSubscriptionID: string): Promise<any> {
    let url: string;

    if (this.networkService?.isOffline()) {
      return of({}).toPromise();
    }

    if (!!CPFSubscriptionID) {

      url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/cpf_subscription/${CPFSubscriptionID}/step`

      return this.http.get(url).pipe(
        switchMap(
          async data => {
            return data['data'];
          }
        ),
        catchError(
          async err => {
            this.userErrorHandlerService.addError({criticity: 1, service: 'cpf-subscriptions', platform: 'both', data: err, errorCode: 'gsfgbuid'});
            return err;
          }
        )
      ).toPromise();
    } else {
      return of({}).toPromise()
    }
  }
}
