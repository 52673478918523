import {
  Component,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  Renderer2,
  HostListener,
  EventEmitter,
  Output,
  AfterViewInit
} from '@angular/core';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'expand-results-comp',
  templateUrl: 'expand-results.html',
  styleUrls: ['expand-results.scss']
})
export class ExpandResultsComponent implements AfterViewInit, OnChanges {
  @Input() compOptions: any;
  @ViewChild('collapsible', { static: true }) collapsible: any;
  @ViewChild('collapsibleHeader', { static: true }) collapsibleHeader: any;
  @ViewChild('contentContainer', { static: true }) contentContainer: any;
  @ViewChild('contentInner', { static: true }) contentInner: any;

  @Output() hasClosed = new EventEmitter<any>();

  iconPath = './uikit-assets/icons/orion-svg-sprite.svg#';

  isDebug = false;

  isClose = true;
  firstState = false; // means close by default

  id: any;
  title = 'Voir plus';
  iconTitle = '';
  onlyContent = false;

  isProgressBar = false;
  percent = 0;
  isCounter = false;
  counter: any;
  isCounterMax = false;
  counterMax: any;

  keepOpen = false;
  clickInside = true;
  prepareToClose = false;
  forceOpen: any;

  ixElt = null;

  refresh: any;

  constructor(
    private renderer: Renderer2,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    // detect @inputs changes
    const compOptions: SimpleChange = changes.compOptions;
    if (typeof compOptions !== 'undefined') {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  @HostListener('mousedown') clickin() {
    this.clickInside = true;
  }

  @HostListener('document:mousedown') clickout() {
    if (!this.clickInside && !this.isClose && !this.keepOpen) {
      this.prepareToClose = true;
    } else {
      this.prepareToClose = false;
    }
    this.clickInside = false;
  }

  @HostListener('document:mouseup') close() {
    if (this.prepareToClose) {
      this.toggle();
    } else {
      setTimeout(() => {
        this.calculExpand();
      }, 300);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.forceOpen) {
        this.toggle();
        this.forceOpen = false;
      }
    }, 1000);
  }

  setGlobals() {
    this.isDebug = (this.compOptions && typeof this.compOptions.debug !== 'undefined') ? this.compOptions.debug : this.isDebug;
    this.id = (this.compOptions && typeof this.compOptions.id !== 'undefined') ? this.compOptions.id : null;
    this.refresh = (this.compOptions && typeof this.compOptions.refresh !== 'undefined') ? this.compOptions.refresh : null;
    this.iconTitle = (this.compOptions && typeof this.compOptions.icontitle !== 'undefined') ? this.compOptions.icontitle : this.iconTitle;
    this.title = (this.compOptions && typeof this.compOptions.title !== 'undefined') ? this.compOptions.title : this.title;
    this.counter = (this.compOptions && typeof this.compOptions.counter !== 'undefined') ? this.compOptions.counter : null;
    this.isCounter = (!!this.counter) ? true : false;
    this.counterMax = (this.compOptions && typeof this.compOptions.countermax !== 'undefined') ? this.compOptions.countermax : null;
    this.isCounterMax = (this.isCounter && !!this.counterMax) ? true : false;
    this.isProgressBar = (this.compOptions && typeof this.compOptions.progressbar !== 'undefined') ? this.compOptions.progressbar : this.isProgressBar;
    this.ixElt = (this.compOptions && typeof this.compOptions.ixElt !== 'undefined') ? this.compOptions.ixElt : this.ixElt;
    if (this.isProgressBar && this.counter !== null && this.counterMax !== null) {
      this.percent = Math.ceil((this.counter / this.counterMax) * 100);
    }
    this.keepOpen = (this.compOptions && typeof this.compOptions.keepopen !== 'undefined') ? this.compOptions.keepopen : this.keepOpen;
    this.onlyContent = (this.compOptions && typeof this.compOptions.onlycontent !== 'undefined') ? this.compOptions.onlycontent : this.onlyContent;

    if ((this.compOptions && typeof this.compOptions.toggle !== 'undefined') && typeof this.forceOpen === 'undefined') {
      this.forceOpen = (this.compOptions.toggle) ? true : false;
    } else {
      // only for calls from external
      if (this.compOptions && typeof this.compOptions.toggle !== 'undefined') {
        if (!this.isClose !== this.compOptions.toggle) {
          this.keepOpen = true;
          this.toggle();
        }
      }
    }

    if (typeof this.collapsible !== 'undefined' && typeof this.id !== 'undefined') {
      this.collapsible.nativeElement.setAttribute('id',  this.id);
    }

    if (typeof this.collapsibleHeader !== 'undefined' && this.onlyContent) {
      this.keepOpen = true;
      this.renderer.setStyle(
        this.collapsibleHeader.nativeElement,
        'display',
        'none'
      );
    }

    if (this.id !== '') {
      this.collapsible.nativeElement.id = this.id;
    }

    if (!!this.refresh) {
      setTimeout(() => {
        this.calculExpand();
      }, 300);
    }
  }

  randomMinMax(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  toggle() {
    this.isClose = !this.isClose;
    this.calculExpand();
    if (this.isClose) {
      this.hasClosed.emit({state: true, ix: this.ixElt});
    } else {
      this.hasClosed.emit({state: false, ix: this.ixElt});
    }
  }

  calculExpand() {
    if (!this.isClose) {
      this.renderer.setStyle(
        this.contentContainer.nativeElement,
        'height',
        this.contentInner.nativeElement.offsetHeight + 'px'
      );
    } else {
      this.renderer.removeStyle(
        this.contentContainer.nativeElement,
        'height'
      );
    }
  }

  // RESIZE EVENT
  @HostListener('window:resize', ['$event']) onResize(event) {
    setTimeout(() => {
      this.calculExpand();
    }, 0);
  }

}
