import { Serie } from './serie';

export class Series {

  private _series: Serie[] = [];
  private _seriesList: Serie[] = [];
  private _individualSeries: Serie[] = [];
  private _individualSeriesWithQuestions: Serie[] = [];
  private _themes: any[] = [];

  constructor() {
  }

  fillSerie(data: any): Serie {
    // console.log('fillSerie', data);
    let serie: Serie = null;
    if (!!data) {
      const attributes = (!!data.attributes) ? data.attributes : null;
      if (!!attributes && !!data.id) {
        const trainingSession: string = (!!attributes.last_session && !!attributes.last_session.training) ? ((!!attributes.last_session.training.data && !!attributes.last_session.training.data.id) ? attributes.last_session.training.data.id : null) : null;
        const examSession: string = (!!attributes.last_session && !!attributes.last_session.exam) ? ((!!attributes.last_session.exam.data && attributes.last_session.exam.data.id) ? attributes.last_session.exam.data.id : null) : null;
        serie = new Serie(
          {
            id: data.id,
            position: (!!attributes.position || attributes.position === 0) ? attributes.position : null,
            type: (!!attributes.type) ? attributes.type : null,
            scope: (!!attributes.scope) ? attributes.scope : null,
            theme: (!!attributes.theme) ? attributes.theme : null,
            downloadState: 'none',
            size: 0,
            updatedAt: (!!attributes.updated_at) ? attributes.updated_at : null,
            questions: [],
            lastSessions: [trainingSession, examSession],
            nbQuestions: attributes.questions_count
          }
        );
      }
    }
    return serie;
  }

  fillSeries(seriesData: any): Serie[] {
    const series = [];
    if (!!seriesData && seriesData.length > 0) {
      // console.log('fillSeries', seriesData);
      for (const serie of seriesData) {
        const newSerie = this.fillSerie(serie);
        if (!!newSerie) {
          series.push(newSerie);
        } else {
          // C'est louche :)
        }
      }
    }
    return series;
  }

  fillSeriesList(seriesData) {
    // console.log('fillSeriesList', seriesData);
    const seriesList = [];
    for (const serie of seriesData) {
      const lastSessions: string[] = [];
      if (!!serie) {
        lastSessions.push(!!serie.attributes.last_session ? !!serie.attributes.last_session.training.data ? serie.attributes.last_session.training.data.id : null : null);
        lastSessions.push(!!serie.attributes.last_session ? !!serie.attributes.last_session.exam.data ? serie.attributes.last_session.exam.data.id : null : null);
        const newSerie = new Serie({
          id: serie.id,
          position: (!!serie.attributes.position || serie.attributes.position === 0) ? serie.attributes.position : null,
          scope: serie.attributes.scope,
          updatedAt: serie.attributes.updated_at,
          lastSessions: lastSessions,
          type: serie.attributes.type,
          nbQuestions: serie.attributes.questions_count
        });

        seriesList.push(newSerie);
      }
    }
  }

  fillThemes(themesData) {
    const themes = [];
    if (!!themesData && themesData.length > 0) {
      for (const themeData of themesData) {
        const theme = {
          name: (!!themeData.attributes && !!themeData.attributes.name) ? themeData.attributes.name : 'Autres',
          questions: (!!themeData.relationships && !!themeData.relationships.questions && !!themeData.relationships.questions.data) ? themeData.relationships.questions.data.map(elt => elt.id) : [],
        };
        themes.push(theme);
      }
    }
    return themes;
  }

  public get series(): Serie[] { return this._series; }
  public set series(value: Serie[]) { this._series = value; }
  public get themes(): any[] { return this._themes; }
  public set themes(value: any[]) { this._themes = value; }
  public get seriesList() { return this._seriesList; }
  public set seriesList(value) { this._seriesList = value; }
  public get individualSeries() { return this._individualSeries; }
  public set individualSeries(value) { this._individualSeries = value; }
  public get individualSeriesWithQuestions() { return this._individualSeriesWithQuestions; }
  public set individualSeriesWithQuestions(value) { this._individualSeriesWithQuestions = value; }
}
