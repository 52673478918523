import { Component, HostListener, Input, OnChanges, OnDestroy, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';

import {
  AppInitService,
  CPFSubscriptionsService,
  ConfigUserService,
  NavigationService,
  StorageService,
  StudentService,
} from '@app/services';

import { Student, StudentPurshasesDetails } from '@app/models';

import { ModalIframeComponent, ModalMarketingComponent } from '@app/modals';
import { ModalController, Platform } from '@ionic/angular';

import * as moment from 'moment-timezone';

import { 
  ToasterCustomService,
  ToasterService,
  UtilsService
} from '@commons/services';

import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'top-nav-component',
  templateUrl: 'top-nav.component.html',
  styleUrls: ['top-nav.component.scss'],
})
export class TopNavComponent implements OnChanges, OnDestroy {
  @Input() compOptions: any;
  @ViewChild('topNav', { static: true }) topNav: any;

  titlePage: string;
  activeLink: string;

  openLinkAllowed = false;

  navOpened = false;

  student: Student = null;
  configUser: any;
  userLifeCycle: any;

  studentPurshases: StudentPurshasesDetails;
  payments: any = [];
  gifts = [];
  coupons = [];
  paymentsGiftsCoupons: any = [];
  offers: any = [];
  fetchOffersFailed = false;
  isPackPermis = false;
  isPackCode = false;
  isPackCodePremium = false;
  isHourOnly = false;
  hasHours = false;
  paymentsDone = false;

  topnavLinks = null;

  impayesAllowedRoutes = [
    '/dashboard',
    '/code',
    '/profil',
  ];

  initPageDone = false; // for shimmers
  fnInitPageLaunched = false; // for controllers

  learningContractClickedSubscription: Subscription;
  validateEmailClickedSubscription: Subscription;
  regulariserPaymentClickedSubscription: Subscription;
  simpleToasterClickedSubscription: Subscription;

  isDesktop = (window.innerWidth > 900) ? true : false;
  isCordova = false;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.isDesktop = (window.innerWidth > 900) ? true : false;
  }

  @HostListener('document:mousedown', ['$event']) keydown(event) {
    if (this.navOpened && !this.topNav.nativeElement.contains(event.target)) {
      this.toggleNav();
    }
  }
  @HostListener('document:touchstart', ['$event']) touchStart(event) {
    if (this.navOpened && !this.topNav.nativeElement.contains(event.target)) {
      this.toggleNav();
    }
  }

  constructor(
    public appInitService: AppInitService,
    private studentService: StudentService,
    private configUserService: ConfigUserService,
    public navigationService: NavigationService,
    private modalController: ModalController,
    public utils: UtilsService,
    public toasterService: ToasterService,
    public toasterCustomService: ToasterCustomService,
    private storageService: StorageService,
    private router: Router,
    public platform: Platform,
    public cpfSubscriptionsService: CPFSubscriptionsService
  ) {
    this.userLifeCycle = this.studentService.getUserLifeCycle(this.student); // if student not ready return guest
    this.isCordova = this.platform.is('cordova');
    this.appInitService.onStepChange().subscribe(
      async (state) => {
        if (state?.init >= 3 && state?.stepDone && !this.fnInitPageLaunched) {
          this.fnInitPageLaunched = true;
          this.initPage();
        }
      }
    );
    this.setGlobals();
  }

  ngOnChanges(changes: SimpleChanges) {
    // detect @input changes
    const compOptions: SimpleChange = changes.compOptions;
    if (this.utils.isDefined(this.compOptions)) {
      this.compOptions = compOptions.currentValue;
      this.setGlobals();
    }
  }

  toggleNav() {
    this.navOpened = !this.navOpened;
  }

  ngOnDestroy() {
    if (!!this.learningContractClickedSubscription) {
      this.learningContractClickedSubscription.unsubscribe();
    }
    if (!!this.validateEmailClickedSubscription) {
      this.validateEmailClickedSubscription.unsubscribe();
    }
    if (!!this.regulariserPaymentClickedSubscription) {
      this.regulariserPaymentClickedSubscription.unsubscribe();
    }
    if (!!this.simpleToasterClickedSubscription) {
      this.simpleToasterClickedSubscription.unsubscribe();
    }
  }

  async initPage() {
    this.student = this.studentService.student;
    // console.log('called => ', this.student);
    this.userLifeCycle = this.studentService.getUserLifeCycle(this.student);
    this.setNavItems();
    this.initPageDone = true;
    // temporary close cgaac
    // if ((!this.student.cgaacAcceptedAt || this.student.cgaacAcceptedAt == null) && !this.userLifeCycle.isUserGuest && !this.navigationService.iframeAlreadyOpen) {
    //   this.openModalPack(null, 'modalCgaac', true);
    // }
    if (this.userLifeCycle.isFrozen) {
      if (!!this.student.subStatus && this.student.subStatus.includes('payment_failed')) {
        if (this.impayesAllowedRoutes.includes(this.router.url)) {
          await this.showStickyImpayes();
        }
      }
    }
    await this.showStickyLearningContract();
    // await this.showStickyValidateEmail();

    this.learningContractClickedSubscription = this.toasterService.learningContractClicked$.subscribe(
      async clicked => {
        if (clicked) {
          if (!this.configUser || this.configUser == null) {
            this.configUser = await this.configUserService.getUserConfig(this.student);
          }
          // hide learningContractStickyToaster
          if (!!this.configUser && this.configUser.learningContractStickyToasterShown) {
            this.configUser.learningContractStickyToasterShown = false;
            this.configUserService.setUserConfig(this.student, this.configUser);
            this.openLink('acceptLearningContact'); // siteOpener
          }
        }
      }
    );
    this.validateEmailClickedSubscription = this.toasterService.validateEmailClicked$.subscribe(
      async clicked => {
        if (clicked) {
          if (!this.configUser || this.configUser == null) {
            this.configUser = await this.configUserService.getUserConfig(this.student);
          }
          // hide validateEmailStickyToaster
          if (!!this.configUser && this.configUser.validateEmailStickyToasterShown) {
            this.configUser.validateEmailStickyToasterShown = false;
            this.configUser.validateEmailStickyToasterClicked = true;
            this.configUserService.setUserConfig(this.student, this.configUser);
            this.postValidateEmail();
          }
        }
      }
    );
    this.regulariserPaymentClickedSubscription = this.toasterService.regulariserPaymentClicked$.subscribe(
      async clicked => {
        if (clicked) {
          if (!this.configUser || this.configUser == null) {
            this.configUser = await this.configUserService.getUserConfig(this.student);
          }
          // hide stickyToaster
          await this.goToRegulariser();
        }
      }
    );

    this.getPayments();

    this.setNavItems();
    this.openLinkAllowed = true;
  }

  setNavItems() {
    if (this.userLifeCycle.isUserGuest) {
      this.topnavLinks = [
        {title: 'Code de la route', link: 'code-de-la-route-gratuit', group: 'code'},
        {title: 'Permis de conduire', link: 'conduite', group: 'conduite'},
        {title: 'Offres', link: 'offres', group: 'offres'},
        {title: 'Aide', link: 'faq', group: 'faq'},
        {title: 'Se connecter', link: 'login', group: 'profil'},
      ];
    } else if (this.userLifeCycle.isUserRegistered) {
      this.topnavLinks = [
        {title: 'Code de la route', link: 'code', group: 'code'},
        {title: 'Permis de conduire', link: 'conduite', group: 'conduite'},
        {title: 'Offres', link: 'offres', group: 'offres'},
        {title: 'Aide', link: 'faq', group: 'faq'},
        {title: 'Profil', link: 'profil', group: 'profil'},
      ];
    } else {
      if (this.isPackCode || this.isPackCodePremium || this.isPackPermis || this.hasHours) {
        this.topnavLinks = [
          {title: 'Accueil', link: 'dashboard', group: 'dashboard'},
          {title: 'Code de la route', link: 'code', group: 'code'},
          {title: 'Conduite', link: 'conduite', group: 'conduite'},
          {title: 'Offres', link: 'offres', group: 'offres'},
          {title: 'Aide', link: 'faq', group: 'faq'},
          {title: 'Profil', link: 'profil', group: 'profil'},
        ];
      } else {
        this.topnavLinks = [
          {title: 'Code de la route', link: 'code', group: 'code'},
          {title: 'Permis de conduire', link: 'conduite', group: 'conduite'},
          {title: 'Offres', link: 'offres', group: 'offres'},
          {title: 'Aide', link: 'faq', group: 'faq'},
          {title: 'Profil', link: 'profil', group: 'profil'},
        ];
      }
    }
    // if (!!this.topnavLinks) {
    //   if (!environment.production) {
    //     this.topnavLinks.push({title: 'EvsLab', link: 'home-lab'});
    //   }
    // }
  }

  async goToRegulariser() {
    let ppu = null;
    for (const payment of this.payments) {
      if (!!payment.payment_plan_user && !!payment.payment_plan_user.data && !!payment.payment_plan_user.data.attributes && !!payment.payment_plan_user.data.attributes.status) {
        if (payment.payment_plan_user.data.attributes.status === 'failed') {
          if (!!payment.payment_plan_user_id) {
            ppu = payment.payment_plan_user_id;
          }
        }
      }
    }
    if (!!ppu) {
      this.configUser.impayesStickyToasterShown = false;
      await this.configUserService.setUserConfig(this.student, this.configUser);
      this.openLink('regulariserPayment', {ppu: ppu});
    }
  }

  launchToaster(config) {
    this.toasterService.create(config);
  }

  async showStickyLearningContract() {
    if (!this.configUser || this.configUser == null) {
      this.configUser = await this.configUserService.getUserConfig(this.student);
    }
    if (!!this.student) {
      if (!!this.cpfSubscriptionsService.CPFSubscriptions && this.cpfSubscriptionsService.CPFSubscriptions.length === 0) {
        if (this.student.evalPostAchat == null) {
          if (!!this.configUser && !this.configUser.learningContractStickyToasterShown && this.student.showLearningContract &&
            (!this.student.learningContractAcceptedAt || this.student.learningContractAcceptedAt == null)) {
            // this.launchToaster({text: 'Bravo pour ta première leçon 🎉 Pense à signer ton contrat d’apprentissage', type: 'sticky', borderColor: 'warning', ctaText: 'Signer', ctaLink: 'dashboard'});
            this.configUser.learningContractStickyToasterShown = true;
            this.configUser = await this.configUserService.setUserConfig(this.student, this.configUser);
            this.launchToaster({text: 'Bravo pour ta leçon 🎉<br>Pense à signer ton contrat', type: 'sticky', borderColor: 'warning', ctaText: 'Signer', ctaLink: 'learningContract'});
          }
        } else {
          if (this.student.evalPostAchat) {
            if (!!this.configUser && !this.configUser.learningContractStickyToasterShown && this.student.showLearningContract &&
              (!this.student.learningContractAcceptedAt || this.student.learningContractAcceptedAt == null)) {
              // this.launchToaster({text: 'Bravo pour ta première leçon 🎉 Pense à signer ton contrat d’apprentissage', type: 'sticky', borderColor: 'warning', ctaText: 'Signer', ctaLink: 'dashboard'});
              this.configUser.learningContractStickyToasterShown = true;
              this.configUser = await this.configUserService.setUserConfig(this.student, this.configUser);
              this.launchToaster({text: 'Bravo pour ta leçon 🎉<br>Pense à signer ton contrat', type: 'sticky', borderColor: 'warning', ctaText: 'Signer', ctaLink: 'learningContract'});
            }
          }
        }
      }
    }
  }

  async showStickyImpayes() {
    if (!this.configUser || this.configUser == null) {
      this.configUser = await this.configUserService.getUserConfig(this.student);
    }
    if (!!this.configUser && !this.configUser.impayesStickyToasterShown && !this.configUser.outstandingPaymentModalShown) {
      this.configUser.impayesStickyToasterShown = true;
      this.configUser.outstandingPayment = true;
      await this.configUserService.setUserConfig(this.student, this.configUser);
      this.launchToaster({text: 'Une de tes échéances de paiement semble avoir échoué.', type: 'sticky', borderColor: 'danger', ctaText: 'PAYER', ctaLink: 'regulariserPayment'});
    }
  }

  dateToFormatFr(input?: any) {
    // console.log('-------------------');
    // console.log('dateToFormatFr input', input, typeof input);
    let output = this.dateToMoment(); // today by default
    if (!!input) {
      output = this.dateToMoment(input);
      if (typeof input === 'string') {
        output = this.dateToMoment(input);
        // if (!input.includes('+')) {
        //   console.log('input string', input);
        // }
        if (input.includes('/')) {
          console.error('input string', input);
        }
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'number') {
        // timestamp
        output = this.dateToMoment(input);
        // console.log('input timestamp', input);
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'object') {
        if (!moment.isMoment(input) && !!input.year && !!input.month && !!input.day) {
          // custom object
          const year = input.year;
          const month = (Number(input.month) < 10) ? '0' + Number(input.month) : Number(input.month);
          const day = (input.day < 10) ? '0' + input.day : input.day;
          const str = year + '-' + month + '-' + day + ' 00:00:00';
          output = this.dateToMoment(str);
          // console.log('input custom object', input, str);
          // console.log('output', output);
          // console.log('-------------------');
        } else {
          // date object
          output = this.dateToMoment(input);
          // console.log('input date', input, input.isValid());
          // console.log('output', output);
        }
      }
    }
    if (!output.isValid()) {
      console.log('-------------------');
      console.error('dateToFormatFr input', input, typeof input);
      console.error('dateToFormatFr output', output, output.toString(), output.isValid());
      console.error('-------------------');
    }
    // console.log('dateToFormatFr output', output, output.toString(), output.isValid());
    // console.log('-------------------');
    return output;
  }

  dateToMoment(input?: any) {
    const zone = "Europe/Paris";
    moment.tz.setDefault(zone);
    moment.locale('fr');
    if (!!input) {
      return moment(input).tz(zone);
    }
    return moment().tz(zone);
  }

  async showStickyValidateEmail() {
    if (!this.configUser || this.configUser == null) {
      this.configUser = await this.configUserService.getUserConfig(this.student);
    }
    // console.log('emailConfirmedAt', this.student.emailConfirmedAt);
    // console.log('dateLimitToConfirmEmail', this.student.dateLimitToConfirmEmail);
    // console.log('validateEmailStickyToasterShown', this.configUser.validateEmailStickyToasterShown);
    if (this.student.provider === 'email' && (!this.student.emailConfirmedAt || this.student.emailConfirmedAt == null)) {
      // DATE TO CHANGE
      const today: any = this.dateToFormatFr();
      if (!!this.student.dateLimitToConfirmEmail && this.student.dateLimitToConfirmEmail !== '' && !this.configUser.validateEmailStickyToasterShown && !this.configUser.validateEmailStickyToasterClicked) {
        // DATE TO CHANGE
        const dateLimit: any = this.dateToFormatFr(this.student.dateLimitToConfirmEmail);
        const nbHours = Math.abs(dateLimit - today) / 36e5;
        // console.log(today, dateLimit);
        // console.log(nbHours);
        if (nbHours <= 48) {
          this.launchToaster({text: 'Pense à confirmer ton compte via le lien reçu par mail', type: 'sticky', borderColor: 'warning', ctaText: 'Renvoyer', ctaLink: 'validateEmail'});
          this.configUser.validateEmailStickyToasterShown = true;
          // console.log('validateEmailStickyToasterShown', this.configUser.validateEmailStickyToasterShown);
          await this.configUserService.setUserConfig(this.student, this.configUser);
        }
      }
    }
  }

  postValidateEmail() {
    this.studentService.validateEmail(this.student.remoteId).subscribe(
      (res) => {
        // console.log(res);
        this.launchToaster({text: 'Le mail a été envoyé avec succès', bgcolor: 'var(--color-info)', color: 'var(--color-font)'});
      },
      (err) => {
        console.error('POST Validate Email failed: ' + JSON.stringify(err));
      }
    );
  }

  getPayments() {
    this.studentPurshases = this.studentService.getStudentPurshases();
    const resGetOffers = this.studentPurshases.offers;
    if (!!resGetOffers && !!resGetOffers.errorCode && resGetOffers.errorCode === 'E301') {
      this.fetchOffersFailed = true;
    } else {
      this.offers = resGetOffers;
    }
    this.gifts = this.studentPurshases.gifts;
    this.studentPurshases.linkOfferTopayment();
    this.studentPurshases.removeCouponsIfPresentInPayments();
    this.payments = this.studentPurshases.payments;
    this.coupons = this.studentPurshases.coupons;

    this.hasHours = this.studentPurshases.hasHours();
    this.isHourOnly = this.studentPurshases.isHourOnly();
    this.isPackCode = this.studentPurshases.isPackCode();
    this.isPackCodePremium = this.studentPurshases.isPackCodePremium();
    this.isPackPermis = this.studentPurshases.isPackPermis();

    const tmp = this.gifts.concat(this.coupons);
    this.paymentsGiftsCoupons = tmp.concat(this.payments);

    this.paymentsDone = true;
  }

  setGlobals() {
    // SERIES
    this.titlePage = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.page)) ? this.compOptions.page : 'En Voiture Simone';
    this.activeLink = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.active)) ? this.compOptions.active : 'Dashboard';
  }

  async openModalPack(bannerInfo?: any, type?: string, noClose = false, customClass = '') {
    // evs-global.scss : small-modal | medium-modal
    if (!type) {
      type = 'marketingRegular';
    }
    let props;
    if (!!bannerInfo) {
      props = {
        'type': type,
        bannerInfo: {
          item_id: bannerInfo.itemId,
          item_promo_type: bannerInfo.itemPromoType,
          item_location_id: bannerInfo.itemLocationId,
          item_location_format: bannerInfo.itemLocationFormat,
          item_promo_content: bannerInfo.itemPromoContent,
        }
      };
    } else {
      props = {
        'type': type,
        bannerInfo: null
      };
    }
    let modal;
    if (noClose) {
      modal = await this.modalController.create({
        component: ModalMarketingComponent,
        backdropDismiss: false,
        cssClass: customClass,
        componentProps: props
      });
    } else {
      modal = await this.modalController.create({
        component: ModalMarketingComponent,
        cssClass: customClass,
        componentProps: props
      });
    }
    return await modal.present();
  }

  async openModalIframe(url: any, type?: string, noClose = true) {
    if (!type) {
      type = 'iframe';
    }
    let modal;
    if (noClose) {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        backdropDismiss: false,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          'type': type,
          'url': url,
        },
      });
    } else {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          'type': type,
          'url': url,
        },
      });
    }
    return await modal.present();
  }

  closeModals() {
    const modals = document.getElementsByTagName('ion-modal');
    while (modals.length > 0) {
      modals[0].parentNode.removeChild(modals[0]);
    }
  }

  goSite() {
    let logoLink = 'home';
    if (this.isPackPermis || this.hasHours) {
      logoLink = 'dashboard';
    }
    this.openLink(logoLink);
  }

  async openLink(link, options?) {
    if (!options) {
      options = {forceRoot: true};
    } else {
      options.forceRoot = true;
    }

    if (link === 'our-cars') {
      link = 'https://voitures.envoituresimone.com';
      options = {target: '_system', forceOutLink: true};
    }
    const callbackUrl = await this.navigationService.open(link, options);
    if (!!callbackUrl && callbackUrl !== '') {
      this.openModalIframe(callbackUrl);
    }
  }
}
