import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common'; // get Angular pipes etc.
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx';

// COMPONENTS
import { AudioComponent } from './audio/audio.component';
import { AutocompleteComponent } from './autocomplete/autocomplete';
import { AutocompleteLocalComponent } from './autocomplete-local/autocomplete-local';
import { AutocompleteTestComponent } from './autocomplete-test/autocomplete-test';
import { BlocErrorComponent } from './bloc-error/bloc-error';
import { BlocHeaderComponent } from './bloc-header/bloc-header';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb';
import { CalendarComponent } from './calendar/calendar';
import { CalendarCodeExamReservationComponent } from './calendar-code-exam-reservation/calendar-code-exam-reservation';
import { CalendarDailyStreakComponent } from './calendar-daily-streak/calendar-daily-streak';
import { CalendarDashboardComponent } from './calendar-dashboard/calendar-dashboard';
import { CardComponent } from './card/card';
import { CheckboxesSerieComponent } from './checkboxes/checkboxes';
import { ConfettisComponent } from './confettis/confettis';
import { CoursCardComponent } from './cours-card/cours-card';
import { CustomRadioComponent } from './custom-radio/custom-radio';
import { DynamicFormComponent } from './dynamic-form/dynamic-form';
import { ExpandComponent } from './expand/expand';
import { ExpandResultsComponent } from './expand-results/expand-results';
import { FavTeacherModalComponent } from './fav-teacher-modal/fav-teacher-modal';
import { FooterNavComponent } from './footer-nav/footer-nav.component';
import { GifComponent } from './gif/gif.component';
import { ImageComponent } from './image/image.component';
import { IOSAttSlider } from './ios-att-slider/ios-att-slider';
import { LoaderBarComponent } from './loader-bar/loader-bar';
import { LoadingButtonComponent } from './loading-button/loading-button';
import { MediaComponent } from './media/media.component';
import { ModalComponent } from './modal/modal';
import { NotificationCardComponent } from './notification-card/notification-card';
import { OpeningSlider } from './opening-slider/opening-slider';
import { PageLoaderComponent } from './page-loader/page-loader';
import { PaginationComponent } from './pagination/pagination';
import { ParticlesComponent } from './fireworks/fireworks';
import { PresentationSlider } from './presentation-slider/presentation-slider';
import { ProgressBarComponent } from './progress-bar/progress-bar';
import { RadialProgressComponent } from './radial-progress/radial-progress';
import { SimpleCardComponent } from './simple-card/simple-card';
import { SquareCardComponent } from './square-card/square-card';
import { StatsCalendar } from './stats-calendar/stats-calendar';
import { StatsProgressionComponent } from './stats-progression/stats-progression';
import { StatsTextComponent } from './stats-text/stats-text';
import { SvgIconComponent } from './svg-icon/svg-icon';
import { TopNavComponent } from './top-nav/top-nav.component';
import { TunnelAlreadyCodeComponent } from './tunnel-already-code/tunnel-already-code';
import { TunnelAlreadyDriveComponent } from './tunnel-already-drive/tunnel-already-drive';
import { TunnelEmailComponent } from './tunnel-email/tunnel-email';
import { TunnelErrorComponent } from './tunnel-error/tunnel-error';
import { TunnelFPEmailComponent } from './tunnel-fp-email/tunnel-fp-email';
import { TunnelLoadingComponent } from './tunnel-loading/tunnel-loading';
import { TunnelLocationComponent } from './tunnel-location/tunnel-location';
import { TunnelNoCodeComponent } from './tunnel-no-code/tunnel-no-code';
import { TunnelPasswordComponent } from './tunnel-password/tunnel-password';
import { TunnelRegisterComponent } from './tunnel-register/tunnel-register';
import { TunnelSelectHourComponent } from './tunnel-select-hour/tunnel-select-hour';
import { VideoComponent } from './video/video.component';

// PIPES
import { PipesModule } from '@commons/pipes';

// COMMONS DIRECTIVES
import { DirectivesModule } from '../directives/directives.module';

const components = [
  AudioComponent,
  AutocompleteComponent,
  AutocompleteLocalComponent,
  AutocompleteTestComponent,
  BlocErrorComponent,
  BlocHeaderComponent,
  BreadcrumbComponent,
  CalendarComponent,
  CalendarCodeExamReservationComponent,
  CalendarDailyStreakComponent,
  AutocompleteTestComponent,
  CalendarDashboardComponent,
  CardComponent,
  CheckboxesSerieComponent,
  ConfettisComponent,
  CoursCardComponent,
  CustomRadioComponent,
  DynamicFormComponent,
  ExpandComponent,
  ExpandResultsComponent,
  FavTeacherModalComponent,
  FooterNavComponent,
  GifComponent,
  ImageComponent,
  IOSAttSlider,
  LoaderBarComponent,
  LoadingButtonComponent,
  MediaComponent,
  ModalComponent,
  NotificationCardComponent,
  OpeningSlider,
  PageLoaderComponent,
  PaginationComponent,
  ParticlesComponent,
  PresentationSlider,
  ProgressBarComponent,
  RadialProgressComponent,
  SimpleCardComponent,
  SquareCardComponent,
  StatsCalendar,
  StatsProgressionComponent,
  StatsTextComponent,
  SvgIconComponent,
  TopNavComponent,
  TunnelAlreadyCodeComponent,
  TunnelAlreadyDriveComponent,
  TunnelEmailComponent,
  TunnelErrorComponent,
  TunnelFPEmailComponent,
  TunnelLoadingComponent,
  TunnelLocationComponent,
  TunnelNoCodeComponent,
  TunnelPasswordComponent,
  TunnelRegisterComponent,
  TunnelSelectHourComponent,
  VideoComponent
];

const modules = [
  IonicModule,
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  PipesModule,
  DirectivesModule,
];

@NgModule({
  declarations: components,
  imports: modules,
  exports: components,
  providers: [
    WebView
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})

export class CommonsComponentsModule { }
