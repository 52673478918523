import { EvsRecord } from './evsRecord';

export interface IOffers {
  id: string;
  offer_type: string;
  amount: number;
  max_quantity: number;
  min_quantity: number;
  discount: number;
  offer_campaign: object;
  name: string;
  credits: number;
  code: boolean;
  code_exam_credits: number;
  active: boolean;
  offer_credit_type: string;
  title: string;
  description: string;
  text: string;
  display_offer_type: string;
  price_desc: string;
  ranking: number;
  key_points: Array<string>;
  displayable: boolean;
  special_offer: boolean;
  created_at: string;
  updated_at: string;
}

export enum OfferTypes {
  BigPack = 'big_pack',
  SmallPack = 'small_pack',
  Hour = 'hour',
  Eval = 'eval',
  Code = 'code',
  CodePremium = 'code_premium',
  CodeExam = 'code_exam',
  DrivingExam = 'driving_exam',
  HourPack = 'hour_pack'
}

export class OfferModel extends EvsRecord implements IOffers {
  id: string;
  offer_type: OfferTypes;
  amount: number;
  max_quantity: number;
  min_quantity: number;
  discount: number;
  offer_campaign: object;
  name: string;
  credits: number;
  code: boolean;
  code_exam_credits: number;
  active: boolean;
  offer_credit_type: string;
  title: string;
  description: string;
  text: string;
  display_offer_type: string;
  price_desc: string;
  ranking: number;
  key_points: Array<string>;
  displayable: boolean;
  special_offer: boolean;
  created_at: string;
  updated_at: string;

  constructor(obj: IOffers) {
    super(obj);
    this.offer_type = <OfferTypes>obj.offer_type;
  }


  isPackPermis(): boolean {
    return this.offer_type === OfferTypes.BigPack;
  }

  isPackCode(): boolean {
    return this.offer_type === OfferTypes.Code;
  }

  isHourOnly(): boolean {
    return this.offer_type === OfferTypes.Hour;
  }

}
