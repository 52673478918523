<ng-container *ngIf="showCalendar == 1">
  <div class="margin-top-5 margin-bottom-15" [ngClass]="{'calendarIpadFix': !!isIpad}">
    <div class="todayButton" (click)="changeMonth(0)">aujourd'hui</div>
    <div class="dateSelection text-center">
      <ion-icon name="chevron-back" class="margin-left-5 left-0" (click)="changeMonth(-1)"></ion-icon>
      <ion-icon name="chevron-forward" class="margin-right-5 right-0" (click)="changeMonth(1)"></ion-icon>
      <h3 class="currentDate">{{months[month]}} {{year}}</h3>
    </div>
  </div>
  <div class="Rtable Rtable--7cols margin-top-10">
    <div class="Rtable-cell  margin-bottom-10" *ngFor="let day of daysWithLetter">
      <!-- <h4 [appTooltip]="day.day">{{day.letter | uppercase}}</h4> -->
      <h5>{{day.letter | uppercase}}</h5>
    </div>
    <div class="Rtable-cell" *ngFor="let item of daysDate">
      <ng-container *ngIf="item.class == ''">
        &nbsp;
      </ng-container>
      <ng-container *ngIf="item.class != ''">
        <!-- <span *ngIf="item.tooltip.date == 'none'" [ngClass]="item.class" [appTooltip]="item.tooltip.text">{{item.text}}</span> -->
        <span *ngIf="item.tooltip.date == 'none'" [ngClass]="item.class">{{item.text}}</span>
        <span *ngIf="item.tooltip.date != 'none'" [ngClass]="item.class" (click)="showModalFn(item)">{{item.text}}</span>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="showCalendar == 0">
  <div class="skeleton dateSelection margin-top-20 text-center" [ngStyle]="{'display': 'block', 'width': '100%'}">
    <div class="margin-left-20 left-0" [ngStyle]="{'width': '25px'}">
      <div class="rectangle-25h rounded-10 self-center"></div>
    </div>
    <div class="margin-right-20 right-0" [ngStyle]="{'width': '25px'}">
      <div class="rectangle-25h rounded-10 self-center"></div>
    </div>
    <div [ngStyle]="{'width': '200px', 'margin': 'auto'}">
      <div class="rectangle-25h rounded-10 self-center"></div>
    </div>
  </div>
  <div class="skeleton Rtable Rtable--7cols">
    <div class="Rtable-cell  margin-bottom-10" *ngFor="let day of daysWithLetter">
      <div [ngStyle]="{'width': '25px', 'margin': '3px auto'}">
        <div class="rectangle-25h rounded-25 self-center"></div>
      </div>
    </div>
    <div class="Rtable-cell" *ngFor="let day of daysWithLetter">
      <ng-container *ngIf="item?.class == ''">
        <div [ngStyle]="{'width': '20px', 'margin': '5px auto'}">
          <div class="self-center"></div>
        </div>
      </ng-container>
      <ng-container *ngIf="item?.class != ''">
        <div [ngStyle]="{'width': '20px', 'margin': '5px auto'}">
          <div class="rectangle-20h rounded-20 self-center"></div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>

<modal-comp [compOptions]="{'id': 'customModalCalendar'}" *ngIf="showModal" (canClose)="hideModal()" #modalCalendar>
  <div class="contentContainer">
    <div class="closeBtn" (click)="hideModal()"><i class="fa fa-times"></i></div>
    <ion-row class="fix-button-access">
      <ion-col size="2" class="content-center">
        <i class="icon ion-md-calendar icon-size-modal"></i>
      </ion-col>
      <ion-col size="10">
        {{modalObj.tooltip?.date}} <br>
        {{modalObj.tooltip?.time}} <br>
        <b [class]="modalObj.tooltip?.class">{{modalObj.tooltip?.text}}</b>
      </ion-col>
    </ion-row>
    <ion-row *ngIf="!!modalObj.tooltip?.teacher">
      <ion-col size="2" class="content-center">
        <i class="icon ion-md-person icon-size-modal"></i>
      </ion-col>
      <ion-col size="10">
        {{modalObj.tooltip?.teacher}}
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="2" class="content-center">
        <i class="icon ion-md-pin icon-size-modal"></i>
      </ion-col>
      <ion-col size="10">
        {{modalObj.tooltip?.location}}
      </ion-col>
    </ion-row>
    <div class="btnContainer margin-top-40" *ngIf="modalObj.tooltip?.showBtn">
      <button class="btn small full-width inline-block" (click)="lessonDetails(modalObj.tooltip?.link)">Détails de la leçon</button>
    </div>
  </div>
</modal-comp>