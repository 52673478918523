import { NetworkStatusService } from './network-status.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Initialable } from './app-init.service';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import { ServiceLocator, StatsService } from '@app/app.services';

import * as Sentry from '@sentry/angular-ivy';

@Injectable({
  providedIn: 'root'
})
@Initialable({step: `init1`, initializer: `init`})
@EasyDebugDecorator
export class SentryService {

  // ########################################################
  // # SENTRYSERVICE IS USED TO SEND CUSTOM ERROR TO SENTRY #
  // ########################################################

  private statsService: StatsService;
  private initDone = false;

  constructor(
    private http: HttpClient,
    private networkStatusService: NetworkStatusService,
    ) {
  }

  async init() {
    this.statsService = ServiceLocator.injector.get(StatsService);
    this.initDone = true;
    return 'Sentry done';
  }

  async sendToSentry(error, studentData = null) {
    if (!this.networkStatusService?.isOffline()) {
      console.error('SentryService : ' + error);
      let context = {id: 'guest'};

      if (this.initDone) {
        const student = studentData;
        const statsContext = (!!student) ? student.status !== 'guest' ? await this.statsService.processUser() : null : null;
        context = { id: (!!student) ? student.remoteId : 'guest', ...statsContext };
      }

      Sentry.withScope(function (scope: any) {
        scope.setUser(context);
        Sentry.captureException(error.originalError || error);
      });
    }
    return;
  }
}
