<div class="tunnel-already-drive-container">
  <div class="content">
    <div class="content-container">
      <div class="content-header">
        <div class="tunnel-back-controls">
          <div class="back-btn" (click)="sendCompData({action: 'navigate', data: {direction: 'prev'}})">
            <img src="assets/svg/chevron-left-red.svg" alt="EnVoitureSimone">
          </div>
        </div>
        <div class="basket-container">
          <div class="basket" (click)="toggleBasketDetails()">
            <img src="assets/svg/basket.svg" alt="EnVoitureSimone">
          </div>
          <div id="pack-permis-text" class="basket-details" [ngClass]="{'show': showBasket && !!basketInfos}">
            <div class="header">
              <div *ngIf="!!basketInfos.price" class="price">{{basketInfos.price}}€</div>
              <div *ngIf="!!basketInfos.title" class="title">{{basketInfos.title}}</div>
              <div *ngIf="!!basketInfos.prefecture" class="prefecture">{{basketInfos.prefecture}}</div>
            </div>
            <div class="list" *ngIf="!!basketInfos.list && basketInfos.list.length > 0">
              <div class="item" *ngFor="let list of basketInfos.list">
                <img src="assets/svg/tunnel-checkbox-ico.svg" alt="EnVoitureSimone">
                <span>{{list}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <div class="already-drive-form">
          <h5 id="question-title">As-tu déjà réalisé des leçons de conduite en auto-école ?</h5>
          <div class="form-container">
            <form [formGroup]="alreadyDriveForm" class="evs-form">
              <div>
                <input *ngIf="!isActive" type="radio" id="bouton-yes" formControlName="alreadyDrive" value="oui" disabled>
                <input *ngIf="isActive" type="radio" id="bouton-yes" formControlName="alreadyDrive" value="oui">
                <div class="evs-card-ui light fake-label" [ngClass]="{'selected': !!getAlreadyDriveForm.alreadyDrive.value && getAlreadyDriveForm.alreadyDrive.value === 'oui'}" (click)="setAlreadyDrive('oui')">Oui</div>
                <input *ngIf="!isActive" type="radio" id="bouton-no" formControlName="alreadyDrive" value="non" disabled>
                <input *ngIf="isActive" type="radio" id="bouton-no" formControlName="alreadyDrive" value="non">
                <div class="evs-card-ui light fake-label" [ngClass]="{'selected': !!getAlreadyDriveForm.alreadyDrive.value && getAlreadyDriveForm.alreadyDrive.value === 'non'}" (click)="setAlreadyDrive('non')">Non</div> 
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="next-controls" *ngIf="isDesktop">
        <button id="bouton-confirm" [disabled]="!formCompleted || !isActive || dataSent" class="btn full-width" (click)="sendCompData({action: 'navigate', data: {direction: 'next'}})">Valider</button>
      </div>
    </div>
  </div>
  <div id="image" class="visuel"></div>
  <div class="next-controls" *ngIf="!isDesktop">
    <button id="bouton-confirm" [disabled]="!formCompleted || dataSent" class="btn full-width" (click)="sendCompData({action: 'navigate', data: {direction: 'next'}})">Valider</button>
  </div>
</div>