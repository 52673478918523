<div (click)="triggerClick()" class="media-parent-container" [ngClass]="{'media-fullscreen': fullscreen}">
  <div class="close-btn" *ngIf="fullscreen">
    <img src="assets/2022/icons/close-off.svg" alt="EnVoitureSimone" height="24">
  </div>
  <div class="media-div" *ngIf="isReloaded">
    <div class="media-container" *ngIf="type === 'video'">
      <video-comp [ngClass]="{'display-none': videoEnd}" class='video' *ngIf="!!videoSrc" [compOptions]="{src: videoSrc, pause: isPaused, debug: isDebug, stop: isStoped}" (videoEvent)='handleVideoEvent($event)' (failEvent)='handleFailEvent($event)'></video-comp>
      <image-comp class='img' *ngIf="!!imgSrc" [compOptions]="{src: imgSrc, debug: isDebug}" (imageEvent)='handleImageEvent($event)' (failEvent)='handleFailEvent($event)'></image-comp>
      <div class="black-screen" [ngClass]="{'display-none': videoStarted}"></div>
      <audio-comp *ngIf="!!audioSrc && imageLoaded && videoEnd" [compOptions]="{src: audioSrc, pause: isPaused, debug: isDebug, stop: isStoped, mute: isMuted, question: question, serieType: serieType}" (audioEvent)='handleAudioEvent($event)' (failEvent)='handleFailEvent($event)'></audio-comp>
    </div>
    <div class="media-container" *ngIf="type === 'gif'" appZoom>
      <gif-comp *ngIf="!!gifSrcs" [compOptions]="{src: gifSrcs, stop: isStoped}" (gifEvent)='handleGifEvent($event)' (failEvent)='handleFailEvent($event)'></gif-comp>
      <audio-comp *ngIf="!!audioSrc && gifLoaded" [compOptions]="{src: audioSrc, pause: isPaused, debug: isDebug, stop: isStoped, mute: isMuted, question: question, serieType: serieType}" (audioEvent)='handleAudioEvent($event)' (failEvent)='handleFailEvent($event)'></audio-comp>
    </div>
    <div class="media-container" *ngIf="type === 'image'" appZoom>
      <image-comp class='img' *ngIf="!!imgSrc" [compOptions]="{src: imgSrc}" (imageEvent)='handleImageEvent($event)' (failEvent)='handleFailEvent($event)'></image-comp>
      <audio-comp *ngIf="!!audioSrc && imageLoaded && !isResults" [compOptions]="{src: audioSrc, pause: isPaused, debug: isDebug, stop: isStoped, mute: isMuted, question: question, serieType: serieType}" (audioEvent)='handleAudioEvent($event)' (failEvent)='handleFailEvent($event)'></audio-comp>
    </div>
  </div>
  <div class="media-div" *ngIf="!isReloaded" appZoom>
    <div class="media-container" *ngIf="type === 'video'">
      <video-comp [ngClass]="{'display-none': videoEnd}" class='video' *ngIf="!!videoSrc" [compOptions]="{src: videoSrc, pause: isPaused, debug: isDebug, stop: isStoped}" (videoEvent)='handleVideoEvent($event)' (failEvent)='handleFailEvent($event)'></video-comp>
      <image-comp class='img' *ngIf="!!imgSrc" [compOptions]="{src: imgSrc, debug: isDebug}" (imageEvent)='handleImageEvent($event)' (failEvent)='handleFailEvent($event)'></image-comp>
      <div class="black-screen" [ngClass]="{'display-none': videoStarted}"></div>
      <audio-comp *ngIf="!!audioSrc && imageLoaded && videoEnd" [compOptions]="{src: audioSrc, pause: isPaused, debug: isDebug, stop: isStoped, mute: isMuted, question: question, serieType: serieType}" (audioEvent)='handleAudioEvent($event)' (failEvent)='handleFailEvent($event)'></audio-comp>
    </div>
    <div class="media-container" *ngIf="type === 'gif'" appZoom>
      <gif-comp *ngIf="!!gifSrcs" [compOptions]="{src: gifSrcs, stop: isStoped}" (gifEvent)='handleGifEvent($event)' (failEvent)='handleFailEvent($event)'></gif-comp>
      <audio-comp *ngIf="!!audioSrc && gifLoaded" [compOptions]="{src: audioSrc, pause: isPaused, debug: isDebug, stop: isStoped, mute: isMuted, question: question, serieType: serieType}" (audioEvent)='handleAudioEvent($event)' (failEvent)='handleFailEvent($event)'></audio-comp>
    </div>
    <div class="media-container" *ngIf="type === 'image'" appZoom>
      <image-comp class='img' *ngIf="!!imgSrc" [compOptions]="{src: imgSrc}" (imageEvent)='handleImageEvent($event)' (failEvent)='handleFailEvent($event)'></image-comp>
      <audio-comp *ngIf="!!audioSrc && imageLoaded && !isResults" [compOptions]="{src: audioSrc, pause: isPaused, debug: isDebug, stop: isStoped, mute: isMuted, question: question, serieType: serieType}" (audioEvent)='handleAudioEvent($event)' (failEvent)='handleFailEvent($event)'></audio-comp>
    </div>
  </div>
</div>