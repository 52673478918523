import { Injectable } from '@angular/core';
import { Initialable } from '../../app/services/app-init.service';
import { BehaviorSubject } from 'rxjs';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

import * as moment from 'moment-timezone';

@Injectable({providedIn: 'root'})
@Initialable({ step: 'init0', initializer: 'onInit' })
@EasyDebugDecorator
export class PageLoaderService {

  fakeFirstStep = false;
  currentStep = 0;
  nbSteps = 0;

  stepsState = { stepsState: '' };
  stepChanged: BehaviorSubject<any> = new BehaviorSubject<any>(this.stepsState);
  stepChanged$ = this.stepChanged.asObservable();

  startTimeOnSplash: any;
  timeSpent: any;

  constructor() {}

  async onInit() {
    return 'PageLoader done';
  }

  dateToFormatFr(input?: any) {
    // console.log('-------------------');
    // console.log('dateToFormatFr input', input, typeof input);
    let output = this.dateToMoment(); // today by default
    if (!!input) {
      output = this.dateToMoment(input);
      if (typeof input === 'string') {
        output = this.dateToMoment(input);
        // if (!input.includes('+')) {
        //   console.log('input string', input);
        // }
        if (input.includes('/')) {
          console.error('input string', input);
        }
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'number') {
        // timestamp
        output = this.dateToMoment(input);
        // console.log('input timestamp', input);
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'object') {
        if (!moment.isMoment(input) && !!input.year && !!input.month && !!input.day) {
          // custom object
          const year = input.year;
          const month = (Number(input.month) < 10) ? '0' + Number(input.month) : Number(input.month);
          const day = (input.day < 10) ? '0' + input.day : input.day;
          const str = year + '-' + month + '-' + day + ' 00:00:00';
          output = this.dateToMoment(str);
          // console.log('input custom object', input, str);
          // console.log('output', output);
          // console.log('-------------------');
        } else {
          // date object
          output = this.dateToMoment(input);
          // console.log('input date', input, input.isValid());
          // console.log('output', output);
        }
      }
    }
    if (!output.isValid()) {
      console.log('-------------------');
      console.error('dateToFormatFr input', input, typeof input);
      console.error('dateToFormatFr output', output, output.toString(), output.isValid());
      console.error('-------------------');
    }
    // console.log('dateToFormatFr output', output, output.toString(), output.isValid());
    // console.log('-------------------');
    return output;
  }

  dateToMoment(input?: any) {
    const zone = "Europe/Paris";
    moment.tz.setDefault(zone);
    moment.locale('fr');
    if (!!input) {
      return moment(input).tz(zone);
    }
    return moment().tz(zone);
  }

  setSteps(currentStep: number, nbSteps: number) {
    this.currentStep = currentStep;
    this.nbSteps = nbSteps;
    this.stepsState.stepsState = 'update';
    this.stepChanged.next(this.stepsState);
   }

   stepsDone() {
    this.stepsState.stepsState = 'done';
    this.stepChanged.next(this.stepsState);
  }

  getSteps() {
    return {currentStep: this.currentStep, nbSteps: this.nbSteps};
  }

  splashScreenStartTime() {
    // DATE TO CHANGE
    this.startTimeOnSplash = this.dateToFormatFr().valueOf(); // timesptamp milli
  }

  countTimeSpent() {
    if (!!this.startTimeOnSplash) {
      // DATE TO CHANGE
      const now = this.dateToFormatFr().valueOf(); // timestamp milli
      this.timeSpent = Math.round((now - this.startTimeOnSplash) / 1000);
    }
  }

  getStartTimeOnSplash() {
    return this.startTimeOnSplash;
  }

  getTimeSpent() {
    return this.timeSpent;
  }
}
