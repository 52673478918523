import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { NavController } from '@ionic/angular';
import { NavigationService, StudentService } from '@app/services';

export class RouteGuard {
  fullUrl: string;
  prodUrl = 'https://app.envoituresimone.com';
  devUrl = 'https://app-dev.envoituresimone.com';
  localUrl = 'http://localhost';

  needSpecificAccount: string[] = [

  ];

  constructor() {
  }
}

@Injectable({
  providedIn: 'root'
})
export class UniversalLinkGuard extends RouteGuard implements CanActivate {

  constructor(
    public navController: NavController,
    public studentService: StudentService,
    public navigationService: NavigationService
  ) {
    super();
  }

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const link = this.getLink(state.url.toString());

    if (state.url.toString().includes('profil') && this.navigationService.lastLink != null) {
      const student = this.studentService.student;
      if (!student || (!!student && !!student.remoteId && student.remoteId === 'GUEST' )) {
        this.navController.navigateRoot('login');
      }
    } else if (state.url.toString().includes('profil') && this.navigationService.lastLink === null) {
      const student = await this.studentService.onInit();
      if (!student || (!!student && !!student.remoteId && student.remoteId === 'GUEST' )) {
        this.navController.navigateRoot('login');
      }
    }

    if (!!link) {
      this.navController.navigateRoot(link);
    }
    return true;
  }

  getLink (url: any) {
    this.fullUrl = url;
    let link = null;
    if (this.fullUrl.includes('/ul/')) {
      link = this.fullUrl.replace(`/ul/`, '');
    }
    return link;
  }
}
