import { Observable, of, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { Account } from './../models/account';
import { catchError, map, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '@app/env';
import { StorageService } from './storage.service';

export interface EvsToken {
  token: string;
  created_at: string;
}

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  baseUrl: string = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}`;

  constructor(public http: HttpClient,
              private storageService: StorageService) {
  }

  signIn(account: Account): Observable<any> {
    if (!!account) {
      const data = { email: account.email, password: account.password };
      return this.http.post(`${environment.token_auth_config.apiBase}/auth/sign_in`, data)
    }
    return of({ errorCode: 'E101', errorMessage: 'Sign In Token Service missing account', errorOriginal: null });
  }

  register(account: Account): Observable<any> {
    if (!!account) {
      const data = {
        email: account.email,
        password: account.password,
        passwordConfirmation: account.passwordConfirmation,
        additionalData: account.additionalData,
        confirm_success_url: window.location.href
      }
      return this.http.post(`${environment.token_auth_config.apiBase}/auth`, data);
    }
    return of({ errorCode: 'E101', errorMessage: 'Register Token Service missing account', errorOriginal: null });
  }

  signOut(): Observable<any> {
    return this.http.delete(`${environment.token_auth_config.apiBase}/v3/auth/revoke_jwt`).pipe(
      switchMap(
        async res => {
          await this.storageService.remove('jwtToken');
          await this.storageService.set('Student', {_remoteId: 'GUEST', _status: 'guest'});
          return res;
        }
      ),
      catchError(
        async err => {
          return { errorCode: 'E201', errorMessage: 'Sign Out Token Service error catched', errorOriginal: err };
        }
      )
    );
  }

  updatePassword(account: Account): Observable<any> {
    if (!!account) {
      const data = {
        password: account.password,
        passwordConfirmation: account.passwordConfirmation,
        passwordCurrent: account.oldPassword
      }
      return this.http.put(`${environment.token_auth_config.apiBase}/auth`, data)
    }
    return of({ errorCode: 'E101', errorMessage: 'Update Password Token Service missing account', errorOriginal: null });
  }

  resetPassword(account: Account): Observable<any> {
    if (!!account) {
      const data = {
        email: account.email,
        redirect_url: window.location.href
      }
      return this.http.post(`${environment.token_auth_config.apiBase}/auth/password`, data)
    }
    return of({ errorCode: 'E101', errorMessage: 'Reset Password Token Service missing account', errorOriginal: null });
  }

  getToken(cmds: string, args: any = {}, student: any): Observable<EvsToken | any> {
    if (!!cmds) {
      const url = `${this.baseUrl}/token`;
      return this.http.post(url, { cmd: cmds, params: args }, {headers: {'client': student.remoteId}}).pipe(
        switchMap(
          async res => {
            if (!!res) {
              const textRes = JSON.stringify(res);
              const parsedData = JSON.parse(textRes);
              const data = parsedData;
              return data;
            }
            return { errorCode: 'E101', errorMessage: 'Get Token Token Service missing res', errorOriginal: null };
          }
        ),
        catchError(
          async err => {
            return { errorCode: 'E201', errorMessage: 'Get Token Token Service error catched', errorOriginal: err };
          }
        )
      );
    }
    return of({ errorCode: 'E101', errorMessage: 'Get Token Token Service missing cmds', errorOriginal: null });
  }

  getTokenForSiteOpen(url: string, student: any): Observable<EvsToken | any> {
    if (!!url) {
      return this.getToken('OPEN_PAGE', { path: url }, student).pipe(
        switchMap(
          async res => {
            return res;
          }
        ),
        catchError(
          async err => {
            return { errorCode: 'E101', errorMessage: 'Get Token For Site Open Token Service error catched', errorOriginal: err };
          }
        )
      );
    }
    return of({ errorCode: 'E101', errorMessage: 'Get Token For Site Open Token Service missing options or baseUrl', errorOriginal: null });
  }
}
