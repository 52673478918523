<ion-toolbar>
  <div class="footernav-container">
    <ng-container *ngIf="!openLinkAllowed">
      <div *ngFor="let item of utils.numberToArray(4)">
        <span class="circle-35 shimmer"></span>
      </div>
    </ng-container>
    <ng-container *ngIf="openLinkAllowed">
      <div class="small-clickable" *ngFor="let item of navItems" (click)="openLink(item.link)">
        <div class="nav-icon">
          <img *ngIf="activeLink !== item.link" [src]="'assets/2022/icons/' + item.icon + '.svg'" alt="EnVoitureSimone">
          <img *ngIf="activeLink === item.link" [src]="'assets/2022/icons/' + item.iconActive + '.svg'" alt="EnVoitureSimone">
        </div>
        <span [ngClass]="{'active': activeLink == item.link}">{{item.title}}</span>
      </div>
    </ng-container>
  </div>
</ion-toolbar>