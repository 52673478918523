<div *ngIf="debug && !!audioErrors && audioErrors.length > 0">
  <button *ngIf='playAudioFail' class="btn" (click)='playAudio()'>PLAY</button>
  <blockquote *ngFor="let error of audioErrors" class="border-danger margin-10">
    {{ error }}
  </blockquote>
</div>
<div class="force-play-container force-audio" *ngIf="playAudioFail" (click)="playAudio()">
  <div class="btn-play-container">
    <i class="icon ion-ios-volume-mute margin-right-10"></i>
  </div>
</div>