import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// PIPES
// import { PipesModule } from '../pipes/pipes.module';

// DIRECTIVES
import { DateTimePickerDirective } from './datetimepicker.directive';
import { LazyLoadingDirective } from './lazy.directive';
import { ScrollerDirective } from './scroller.directive';
import { ScrollerInAppDirective } from './scroller-in-app.directive';
import { SliderDirective } from './slider.directive';
import { SliderLocalDirective } from './slider-local.directive';
import { SwipeDirective } from './swipe.directive';
import { TooltipDirective } from './tooltip.directive';
import { ZoomDirective } from './zoom.directive';

const directives = [
  DateTimePickerDirective,
  LazyLoadingDirective,
  ScrollerDirective,
  ScrollerInAppDirective,
  SliderDirective,
  SliderLocalDirective,
  SwipeDirective,
  TooltipDirective,
  ZoomDirective
];

const modules = [
  CommonModule,
  FormsModule,
  RouterModule
  // PipesModule
];

@NgModule({
  declarations: directives,
  imports: modules,
  exports: directives
})
export class DirectivesModule { }
