import {
  NgZone,
  Component,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  Renderer2,
  HostListener,
  EventEmitter,
  Output,
  ElementRef,
  AfterViewInit,
} from '@angular/core';

import { UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';

import {
  MailcheckerService,
} from '@app/services';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'tunnel-email-comp',
  templateUrl: 'tunnel-email.html',
  styleUrls: ['tunnel-email.scss']
})
export class TunnelEmailComponent implements OnChanges {
  @Input() compOptions: any;
  @Output() compDataSent = new EventEmitter<any>();

  iconPath = './uikit-assets/icons/orion-svg-sprite.svg#';

  isDebug = false;
  userId = null;

  isActive: Boolean = false;

  emailForm: UntypedFormGroup;
  formCompleted = false;

  lastFieldFocused: any = null;

  notices = [];
  textNotif = '';

  showNotif = false;
  userMail = '';
  userPass = '';
  mailSuggestion: any = null;
  mailAlreadyTaken: any = null;
  displayEMailPatternError: any = false;

  regexEmail = '[a-zA-Z0-9._+-]{1,}@[a-zA-Z0-9._+-]{2,}[.]{1}[a-zA-Z]{2,}';
  regexPhoneNumber = '^(0|\\+33)[6-7]([-. ]?[0-9]{2}){4}$';

  allowNextStep: any = false;

  compDataToSend: any = null;
  dataSent = false;

  isDesktop = (window.innerWidth > 767) ? true : false;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.ngZone.run(() => {
      this.isDesktop = (window.innerWidth > 767) ? true : false;
    });
  }

  constructor(
    private ngZone: NgZone,
    private mailcheckerService: MailcheckerService,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.constructForms();
   }

  ngOnChanges(changes: SimpleChanges) {
    // detect @inputs changes
    const compOptions: SimpleChange = changes.compOptions;
    if (typeof compOptions !== 'undefined') {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  setGlobals() {
    this.isDebug = (this.compOptions && typeof this.compOptions.debug !== 'undefined') ? this.compOptions.debug : this.isDebug;
    this.userId = (this.compOptions && typeof this.compOptions.userId !== 'undefined') ? this.compOptions.userId : this.userId;
    this.isActive = (this.compOptions && typeof this.compOptions.isActive !== 'undefined') ? this.compOptions.isActive : this.isActive;
    if (this.isDebug) {
      // console.log('TunnelAlreadyDriveComponent - compOptions: ', this.compOptions);
    }
    this.dataSent = false; // reset
  }

  constructForms() {
    this.emailForm = this.formBuilder.group({
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern(this.regexEmail),
      ])],
    });

    this.emailForm.valueChanges.subscribe(() => {
      // console.log('valueChanges', this.emailForm);
      // this.formHasErrors();
      Object.keys(this.emailForm.controls).forEach(async key => {
        // console.log('key', key);
        if (document.activeElement.id === key) {
          this.lastFieldFocused = key;
        }
        const isRequired = (!!this.emailForm.controls[key].validator) ? true : false;
        if (this.emailForm.controls[key].status === 'VALID') {
          if (key === 'email' && isRequired) {
            await this.checkMail(this.emailForm.controls[key].value);
          }
        } else {
          this.mailSuggestion = null;
        }
      });
      let hasInvalid = false;
      if (this.emailForm.controls['email'].status !== 'VALID') {
        hasInvalid = true;
      }
      this.allowNextStep = (hasInvalid) ? false : true;
    });
  }

  get getEmailForm() {
    return this.emailForm.controls;
  }

  tunnelFormHasErrors() {
    Object.keys(this.emailForm.controls).forEach(key => {
      const isRequired = (!!this.emailForm.controls[key].validator) ? true : false;
    });
    // console.log('this.lastFieldFocused', this.lastFieldFocused);
    if (!!this.lastFieldFocused) {
      if (this.lastFieldFocused === 'email') {
        this.checkMail(this.emailForm.controls[this.lastFieldFocused].value);
      }
      this.lastFieldFocused = null;
    }
  }

  async checkMail(email) {
    if (this.emailForm.controls['email'].valid) {
      this.mailAlreadyTaken = false;
      this.mailSuggestion = this.mailcheckerService.checkeMail(email);
    } else {
      this.mailSuggestion = null;
    }
    if (this.emailForm.controls['email'].invalid) {
      this.displayEMailPatternError = true;
    } else {
      this.displayEMailPatternError = false;
    }
  }

  async updateMail(form) {
    this.emailForm.controls['email'].patchValue(this.mailSuggestion.full);
    this.emailForm.controls['email'].markAsTouched();
    await this.checkMail(this.mailSuggestion.full);
  }

  setCompDataToSend(data: any) {
    this.compDataToSend = {
      'login': {
        'email': this.emailForm.value.email || null // string
      }
    };
    // console.log('compDataToSend: ', this.emailForm.value.email, this.compDataToSend);
  }

  sendCompData(data: any, loginFromEmail = true) {
    if (!this.dataSent) {
      this.dataSent = true;
      // console.log('TunnelLocationComponent - sendCompData: ', data);
      if (loginFromEmail) {
        this.setCompDataToSend(null);
      }
      if (!!data) {
        data.compData = this.compDataToSend;
        this.compDataSent.emit(data);
      } else {
        this.compDataSent.emit({action: 'error', data: 'No data sent from TunnelLocationComponent'});
      }
    }
  }

  signInGoogle() {
    this.compDataToSend = {
      'login': {
        'provider': 'google' // string
      }
    };
    this.sendCompData({action: 'navigate', data: {direction: 'next'}}, false);
  }

  signInFacebook() {
    this.compDataToSend = {
      'login': {
        'provider': 'facebook' // string
      }
    };
    this.sendCompData({action: 'navigate', data: {direction: 'next'}}, false);
  }
}
