import { NgModule } from '@angular/core';
import { ChangeOriginalPipe } from './changeOriginalPipe';
import { GroupByPipe } from './groupBy';
import { CdrLessonsFormater } from './cdrLessonsFormater.pipe';
import { PricesFormater } from './prices.pipe';
import { FrenchDateCustomFormater } from './frenchDates.pipe';
import { FrenchDatePipe } from './frenchDates';

const pipes = [
  ChangeOriginalPipe,
  GroupByPipe,
  CdrLessonsFormater,
  PricesFormater,
  FrenchDateCustomFormater,
  FrenchDatePipe
];

@NgModule({
  imports: [],
  exports: pipes,
  declarations: pipes,
  providers: [
  ],
})
export class PipesModule { }
