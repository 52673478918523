  <header class="topnav-container" #topNav>
    <div class="logo" (click)="goSite()">
      <img src="assets/2022/logos/logo-horizontal-no-bg.svg" alt="En Voiture Simone" class="topnav-logo-evs" draggable="false">
    </div>
    <div id="menu-toggle" class="menu-toggle small-clickable" (click)="toggleNav()">
      <img src="assets/2022/icons/b-menu-off.svg" alt="EnVoitureSimone">
    </div>
    <div [ngClass]="{'active': navOpened}" class="overlay-black"></div>
    <nav [ngClass]="{'active': navOpened}">
      <ul *ngIf="!openLinkAllowed">
        <li *ngFor="let item of utils.numberToArray(4)">
          <span class="content-center padding-v-19" [ngClass]="{'padding-0': isDesktop}">
            <div class="wpi-80 border-radius-10 padding-12 shimmer" [ngClass]="{'margin-top-5 margin-right-20': isDesktop, 'margin-auto': !isDesktop}"></div>
          </span>
        </li>
      </ul>
      <ul *ngIf="openLinkAllowed">
        <li *ngFor="let item of topnavLinks">
          <span class="small-clickable" [ngClass]="{'active': (activeLink == item.group), 'btn-nav': (item.link === 'login' && isDesktop)}" (click)="openLink(item.link)">{{item.title}}</span>
        </li>
      </ul>
    </nav>
    <div class="clearFix"></div>
  </header>