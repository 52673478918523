import { EvsRecord } from './evsRecord';

export interface IExamen {
  id: string;
  type: string;
  date: string;
  result: string;
  cancelled_at: string;
}

export class ExamenModel extends EvsRecord implements IExamen {
  id: string;
  type: string;
  date: string;
  result: string;
  cancelled_at: string;

  constructor(obj: IExamen) {
    super(obj);
  }
}
