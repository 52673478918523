import { EvsRecord } from './evsRecord';

export interface IGift {
  id: number;
  type: string;
  credits: number;
  driving_exam_credits: number;
  code: boolean;
  description: string;
  // student_id: number;
  created_at: string;
  updated_at: string;
  // sponsorship_id: number;
}

export class GiftModel extends EvsRecord implements IGift {
  id: number;
  type: string;
  credits: number;
  driving_exam_credits: number;
  code: boolean;
  description: string;
  // student_id: number;
  created_at: string;
  updated_at: string;
  // sponsorship_id: number;

  constructor(obj: IGift) {
    super(obj);
  }
}
