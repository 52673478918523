import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { catchError, switchMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { of } from 'rxjs';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@Injectable({ providedIn: 'root' })
@EasyDebugDecorator
export class PostalCodeService {

  labelAttribute = 'name';
  formValueAttribute = '';

  constructor(private http: HttpClient) {

  }

  get(keywords: string) {
    let url: string;
    url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/cities?q=${keywords}`;

    return this.http.get(url).pipe(
      switchMap(
        res => {
          const textRes = JSON.stringify(res);
          const parsedData = JSON.parse(textRes);
          const result = parsedData;
          const arr: Array<any> = [];

          for (const elt of result) {
            const name = elt.name + (!!elt.pc_value ? ` (${elt.pc_value})` : '');
            const pc_id: string = elt.pc_id;
            const pc_value: string = elt.pc_value;
            const prefecture_id: string = elt.prefecture_id;
            arr.push({ name: name, pc_id: pc_id, pc_value: pc_value, prefecture_id: prefecture_id});
          }
          return of(arr);
        }
      ),
      catchError(
        async err => {
          return null;
        }
      )
    );
  }
}
