/* tslint:disable:member-ordering */
import {
  Directive,
  ElementRef,
  HostListener,
  Renderer2,
  Input,
  OnInit,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  SimpleChange,
} from '@angular/core';
import { Router } from '@angular/router';

import { NavController } from '@ionic/angular';
// import { Storage } from '@ionic/storage';

import { UtilsService } from '../services/utils.service';

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements OnInit, AfterViewInit, OnChanges {

  @Input('appTooltip') tooltipData: any;
  @Input() content: any;

  parent: any;
  tooltip: any = null;
  userTourBackground: any = null;
  pieTimer: any;
  tooltipHeader: any = null;
  tooltipFooter: any = null;
  btPrev: any;
  btNext: any;
  btStop: any;

  margin = 10;
  actualTarget: any;
  tooltipTarget = null;
  clonedTarget = null;

  isUserTour = false;
  currentTourStep = 0;

  defaultDuration = 5000;
  tourTimeOut = null;

  storageData = null;

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    // private storage: Storage,
    private router: Router,
    private navController: NavController,
    private utils: UtilsService,
  ) {
    this.parent = this.el.nativeElement;
  }

  ngOnInit() {
    if (!!this.tooltipData) {
      this.isUserTour = (typeof this.tooltipData === 'string') ? false : true;
      if (!this.isUserTour) {
        this.tooltipTarget = this.parent;
      } else {
        this.launchTour();
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // detect @inputs changes - status launch false to true
    const tooltipData: SimpleChange = changes.tooltipData;
    if (!!tooltipData) {
      this.tooltipData = tooltipData.currentValue;
      this.isUserTour = (typeof this.tooltipData === 'string') ? false : true;
      if (this.isUserTour) {
        this.currentTourStep = this.tooltipData.step;
        this.launchTour();
      }
    }
  }

  ngAfterViewInit() {
    // temp - to debug
    // if (!!this.content && !!this.content.ionScrollEnd) {
    //   this.content.ionScrollEnd.subscribe(() => {
    //     this.positionTooltip();
    //   });
    // }
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.positionTooltip();
  }

  @HostListener('document: mouseover', ['$event']) onMouseOver(event: MouseEvent) {
    if (!this.isUserTour) {
      this.actualTarget = event.target;
    }
  }

  @HostListener('mouseenter', ['$event']) onMouseEnter(event: MouseEvent) {
    if (!this.isUserTour && this.tooltipData !== '' && this.tooltip === null) {
      this.createTooltip();
      this.positionTooltip();
      this.showTooltip();
    }
  }

  @HostListener('mouseout', ['$event']) onMouseOut(event: MouseEvent) {
    // this part is tricky, over a child generate a mouseout on parent
    // i check the overed element to be sure it's not a child of parent
    if (!this.isUserTour && !this.utils.isChildren(this.tooltipTarget, document.elementFromPoint(event.x, event.y))) {
      this.removeTooltip();
    }
  }

  hasToDeletePotentialOldUsertour() {
    let foundOldElement = false;
    // tooltip
    const uttt = document.getElementsByClassName('evs_tooltip evs_usertour');
    if (uttt.length > 0) {
      foundOldElement = true;
      this.tooltip = null;
      for (let i = 0; i < uttt.length; i++) {
        if (!!uttt[i]) {
          document.body.removeChild(uttt[i]);
        }
      }
    }
    // background
    const utbg = document.getElementsByClassName('evs_tooltip_bg');
    if (utbg.length > 0) {
      foundOldElement = true;
      this.userTourBackground = null;
      for (let i = 0; i < utbg.length; i++) {
        if (!!utbg[i]) {
          document.body.removeChild(utbg[i]);
        }
      }
    }
    // clonedContent
    const utcc = document.getElementsByClassName('evs_ut_highlight');
    if (utcc.length > 0) {
      this.clonedTarget = null;
      foundOldElement = true;
      for (let i = 0; i < utcc.length; i++) {
        if (!!utcc[i]) {
          document.body.removeChild(utcc[i]);
        }
      }
    }
    if (foundOldElement) {
      return true;
    } else {
      return false;
    }
  }

  createTooltip() {
    if (this.tooltip === null) {
      this.tooltip = document.createElement('div');
      const tooltipClassName = (this.isUserTour) ? 'evs_tooltip evs_usertour' : 'evs_tooltip';
      this.tooltip.className = tooltipClassName;

      // TOOLTIP
      if (!this.isUserTour) {
        this.tooltip.innerHTML = this.tooltipData;
        document.body.appendChild(this.tooltip);
      } else {
      // USER TOUR
        if (this.userTourBackground === null) {
          this.userTourBackground = document.createElement('div');
          this.userTourBackground.className = 'evs_tooltip_bg';
          document.body.appendChild(this.userTourBackground);
        }

        this.pieTimer = document.createElement('div');
        this.pieTimer.className = 'wrapper';
        const animationDuration = (!!this.tooltipData.tour[this.currentTourStep].duration) ? (this.tooltipData.tour[this.currentTourStep].duration / 1000) : (this.defaultDuration / 1000)
        this.pieTimer.innerHTML = '<div class="pie spinner" style="animation-duration: ' + animationDuration + 's"></div><div class="pie filler" style="animation-duration: ' + animationDuration + 's"></div><div class="mask" style="animation-duration: ' + animationDuration + 's"></div>';

        this.tooltipHeader = document.createElement('div');
        this.tooltipHeader.className = 'evs_ut_header';
        this.tooltipHeader.innerHTML = this.tooltipData.tour[this.currentTourStep].title;

        this.tooltip.appendChild(this.tooltipHeader);
        this.tooltipHeader.appendChild(this.pieTimer);

        const userTourContent = '<div class="evs_ut_content">' + this.tooltipData.tour[this.currentTourStep].text + '</div>';
        const userTourInnerHTML = userTourContent;
        this.tooltip.innerHTML += userTourInnerHTML;
        document.body.appendChild(this.tooltip);

        // add buttons + functions
        this.tooltipFooter = document.createElement('div');
        this.tooltipFooter.className = 'evs_ut_footer';
        this.tooltip.appendChild(this.tooltipFooter);

        this.btPrev = document.createElement('button');
        this.btPrev.innerHTML = '<<';
        this.btPrev.className = 'evs_btn';
        this.tooltipFooter.appendChild(this.btPrev);
        this.btPrev.onclick = () => {
          this.prevTourStep();
        };

        this.btNext = document.createElement('button');
        this.btNext.innerHTML = '>>';
        this.btNext.className = 'evs_btn';
        this.tooltipFooter.appendChild(this.btNext);
        this.btNext.onclick = () => {
          this.nextTourStep();
        };

        this.btStop = document.createElement('button');
        this.btStop.className = 'evs_btn evs_btStop';
        this.btStop.innerHTML = 'Stop';
        this.tooltipFooter.appendChild(this.btStop);
        this.btStop.onclick = () => {
          this.stopTour();
        };

        this.checkButtons();
      }
    }
  }

  duplicateTarget() {
    this.removeClonedContent();
    this.clonedTarget = this.tooltipTarget.cloneNode(true);
    this.clonedTarget.className += ' evs_ut_highlight';
    document.body.appendChild(this.clonedTarget);

    this.clonedTarget.style.position = 'absolute';
    this.clonedTarget.style.zIndex = '10002';
    this.clonedTarget.style.top = this.getPosition(this.tooltipTarget).top + 'px';
    this.clonedTarget.style.left = this.getPosition(this.tooltipTarget).left + 'px';
  }

  launchTour() {
    clearTimeout(this.tourTimeOut);
    if (this.tooltipData.launch) {
      if (!this.hasToDeletePotentialOldUsertour()) {
        if (!!this.tooltipData) {
          this.currentTourStep = this.tooltipData.step;
          this.setStorage();
          if (this.checkCurrentRoute()) {
            this.processCurrentTour();
          }
        }
      } else {
        this.stopTour();
      }
    }
  }

  checkCurrentRoute() {
    if (!!this.tooltipData && !!this.tooltipData.tour && this.tooltipData.tour[this.currentTourStep].url) {
      return (this.router.url.substring(1) === this.tooltipData.tour[this.currentTourStep].url) ? true : false;
    } else {
      return false;
    }
  }

  doRedirect() {
    if (!!this.tooltipData.tour[this.currentTourStep].redirect || !this.checkCurrentRoute()) {
      this.removeUsertourBackground();
      this.removeTooltip();
      if (!!this.tooltipData.tour[this.currentTourStep].redirect) {
        if (!this.checkCurrentRoute()) {
          this.navController.navigateForward(this.tooltipData.tour[this.currentTourStep].redirect);
          return true;
        }
      } else {
        if (!this.checkCurrentRoute()) {
          this.navController.navigateForward(this.tooltipData.tour[this.currentTourStep].url);
          return true;
        }
      }
      return false;
    } else {
      return false;
    }
  }

  processCurrentTour() {
    if (this.doRedirect()) {
      return;
    }
    this.tooltipTarget = document.getElementById(this.tooltipData.tour[this.currentTourStep].id);
    this.duplicateTarget();
    this.createTooltip();
    this.positionTooltip();
    this.showTooltip();
    this.tooltipTarget.style.position = 'relative';
    this.tooltipTarget.style.zIndex = '10002';
    // tourTimeOut to allow user to stop or skip a step
    this.tourTimeOut = setTimeout(() => {
      this.nextTourStep();
    }, (!!this.tooltipData.tour[this.currentTourStep].duration) ? this.tooltipData.tour[this.currentTourStep].duration : this.defaultDuration);
  }

  prevTourStep() {
    clearTimeout(this.tourTimeOut);
    this.removeTooltip();
    if (this.currentTourStep > 0) {
      this.currentTourStep--;
      this.setStorage();
      this.checkButtons();
      this.processCurrentTour();
    }
  }

  nextTourStep() {
    clearTimeout(this.tourTimeOut);
    if (!this.hasToDeletePotentialOldUsertour()) {
      clearTimeout(this.tourTimeOut);
      this.tooltipData = {};
      this.tooltip = null;
      this.userTourBackground = null;
      this.clonedTarget = null;
      this.clearStorage();
    }
    this.removeTooltip();
    if (!!this.tooltipData.tour) {
      if (this.currentTourStep < (this.tooltipData.tour.length - 1)) {
        this.currentTourStep++;
        this.setStorage();
        this.checkButtons();
        this.processCurrentTour();
      } else {
        this.stopTour();
      }
    } else {
      this.stopTour();
    }
  }

  stopTour() {
    clearTimeout(this.tourTimeOut);
    this.removeUsertourBackground();
    this.removeTooltip();
    this.tooltipData = {};
    this.clearStorage();
  }

  setStorage() {
    this.tooltipData.step = this.currentTourStep;
    // this.storage.set('evs_usertour', this.tooltipData);
  }

  clearStorage() {
    // this.storage.remove('evs_usertour');
  }

  checkButtons() {
    if (!!this.btPrev) {
      this.btPrev.disabled = (this.currentTourStep > 0) ? false : true;
    }
    if (!!this.btNext) {
      this.btNext.disabled = (this.currentTourStep < (this.tooltipData.tour.length - 1)) ? false : true;
    }
  }

  removeUsertourBackground() {
    this.removeClonedContent();
    if (!!this.userTourBackground) {
      document.body.removeChild(this.userTourBackground);
      this.userTourBackground = null;
    }
  }

  removeTooltip() {
    this.removeClonedContent();
    if (!!this.tooltip) {
      document.body.removeChild(this.tooltip);
      this.tooltip = null;
    }
  }

  removeClonedContent() {
    if (!!this.clonedTarget) {
      document.body.removeChild(this.clonedTarget);
      this.clonedTarget = null;
    }
  }

  showTooltip() {
    if (!!this.tooltip) {
      this.renderer.setStyle(this.tooltip, 'opacity', 1);
    }
  }

  hideTooltip() {
    if (!!this.tooltip) {
      this.renderer.setStyle(this.tooltip, 'opacity', 0);
    }
  }

  positionTooltip() {
    if (!!this.tooltip && !!this.tooltipTarget) {
      let top = this.getPosition(this.tooltipTarget).top - this.margin - this.tooltip.offsetHeight;
      let left = (this.getPosition(this.tooltipTarget).left + (this.tooltipTarget.offsetWidth / 2) - (this.tooltip.offsetWidth / 2));
      const right = left + this.tooltip.offsetWidth + 2;
      const screenSize = window.innerWidth;
      // top limit
      if (top < 2) {
        top = this.getPosition(this.tooltipTarget).top + this.tooltipTarget.offsetHeight + this.margin;
      }
      if (left < 2) {
        left = 2;
      }
      // right limit
      if (right > screenSize) {
        left = screenSize - 2 - this.tooltip.offsetWidth;
      }
      this.renderer.setStyle(this.tooltip, 'left', left + 'px');
      this.renderer.setStyle(this.tooltip, 'top', top + 'px');
    }
  }

  getPosition(el) {
    let x = 0;
    let y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      x += el.offsetLeft - el.scrollLeft;
      y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: y, left: x };
  }

}
