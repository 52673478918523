import {
  Injectable
} from '@angular/core';

import {
  Platform
} from '@ionic/angular';

import {
  HttpClient
} from '@angular/common/http';

import {
  EMPTY,
  Observable,
  of
} from 'rxjs';

import {
  catchError,
  switchMap
} from 'rxjs/operators';

import {
  UtilsService
} from '@commons/services';

import {
  environment
} from '@app/env';

import {
  BadgeModel,
  Student
} from '@app/models';

import {
  Initialable,
  NetworkStatusService,
  StorageService,
  StudentService,
} from '@app/services';

import { UserErrorHandlerService } from '../services/user-error-handler.service';

import {
  EasyDebugDecorator
} from '@app/decorators/easy-debug.decorator';

import * as moment from 'moment-timezone';

@Injectable({ providedIn: 'root' })
@Initialable({ step: 'init3', initializer: 'onInit' })
@EasyDebugDecorator
export class GamificationService {

  private _student: Student;
  private _badges: Array<BadgeModel> = [];
  private _fetchBadgesFailed = false;
  private _studentRank: string;
  private _obtainedBadgesInPercentage: number;
  private _guestSerieInfoAnswers = 0;
  private _guestSerieInfoGoodAnswersInARow = 0;
  private _guestSerieInfoGoodAnswers = 0;
  private _studentSerieInfoAnswers = 0;
  private _studentSerieInfoGoodAnswersInARow = 0;
  private _studentSerieInfoGoodAnswers = 0;
  private _studentSerieInfoSeriesCompleted = 0;
  private _studentSerieInfoSeriesCompletedAndGood = 0;
  private _studentSerieInfoSeriesCompletedAndGoodInARow = 0;
  private _studentSerieInfoUniqueDiscoverySeriesCompleted: Array<string> = [];
  private _badgesObtainedOffline: Array<string> = [];
  private _dailyStreaks: any;

  constructor(
    private http: HttpClient,
    private networkService: NetworkStatusService,
    private platform: Platform,
    private storageService: StorageService,
    private studentService: StudentService,
    private userErrorHandlerService: UserErrorHandlerService,
    private utils: UtilsService,
  ) { }

  async onInit() {
    // console.log('gamification service init');
    this._student = this.studentService.student;

    if (this._student.isGuest()) {
      const guestSerieInfo = await this.storageService.get('GUEST-badgesSerieInfo');

      if (!!guestSerieInfo) {
        this._guestSerieInfoAnswers = guestSerieInfo.answers;
        this._guestSerieInfoGoodAnswers = guestSerieInfo.goodAnswers;
        this._guestSerieInfoGoodAnswersInARow = guestSerieInfo.goodAnswersInARow;

        await this.validateGuestSerieBadges();
      } else {
        await this.setStorageGuestSerieInfo();
      }
    } else {
      this._dailyStreaks = await this.fetchDailyStreaks(this._student.remoteId);
      const studentSerieInfo = await this.storageService.get(`${this._student.remoteId}-badgesSerieInfo`);

      if (!!studentSerieInfo) {
        this._studentSerieInfoAnswers = studentSerieInfo.answers != null ? studentSerieInfo.answers : 0;
        this._studentSerieInfoGoodAnswers = studentSerieInfo.goodAnswers != null ? studentSerieInfo.goodAnswers : 0;
        this._studentSerieInfoGoodAnswersInARow = studentSerieInfo.goodAnswersInARow !== null ? studentSerieInfo.goodAnswersInARow : 0;
        this._studentSerieInfoSeriesCompleted = studentSerieInfo.seriesCompleted != null ? studentSerieInfo.seriesCompleted : 0;
        this._studentSerieInfoSeriesCompletedAndGood = studentSerieInfo.seriesCompletedAndGood != null ? studentSerieInfo.seriesCompletedAndGood : 0;
        this._studentSerieInfoSeriesCompletedAndGoodInARow = studentSerieInfo.seriesCompletedAndGoodInARow != null ? studentSerieInfo.seriesCompletedAndGoodInARow : 0;
        this._studentSerieInfoUniqueDiscoverySeriesCompleted = studentSerieInfo.uniqueDiscoverySeriesCompleted != null ? studentSerieInfo.uniqueDiscoverySeriesCompleted : [];

        if (this.networkService?.isOffline()) {
          await this.validateStudentSerieBadgesObtainedOffline();
        }
      } else {
        await this.setStorageStudentSerieInfo();
      }
    }

    await this.loadBadges();

    if (this.platform.is('cordova')) {
      await this.unlockBadgeInStorage('download_app');
    }
    // console.log('gamification service init done');
    return 'Gamification done';
  }

  private async loadBadges() {
    const fetchedGuestBadges = await this.storageService.get(this.storageKey('GUEST'));
    const isGuestAndBadgesFetched = this._student.isGuest() && !!fetchedGuestBadges && !!fetchedGuestBadges.data && !!fetchedGuestBadges.data.length;

    if (this.networkService?.isOffline() || isGuestAndBadgesFetched) {
      this._badges = await this.loadBadgesFromStorage(this._student.remoteId);
    } else {
      const res = await this.fetchBadges(this._student.remoteId).toPromise();
      if (!!res && !!res.errorCode && res.errorCode === 'E301' && !!res.errorOriginal) {
        this._badges = [];
        this._badges = await this.loadBadgesFromStorage(this._student.remoteId);
        this.userErrorHandlerService.addError({criticity: 40, service: 'badges', platform: 'both', data: res.errorOriginal, errorCode: 'gsfb'});
        this._fetchBadgesFailed = true;
      } else {
        this._fetchBadgesFailed = false;
        this._badges = res;
      }
    }

    if (!this._student.isGuest()) {
      this._badgesObtainedOffline = await this.storageService.get(`${this._student.remoteId}-badgesObtainedOffline`) || [];
      await this.syncBadgesObtainedOffline();
    }

    this.buildDisplayable();
    this._studentRank = this.getStudentRank();
    this._obtainedBadgesInPercentage = this.getObtainedBadgesInPercentage();
  }

  private async loadBadgesFromStorage(accountId: string) {
    const rawBadges = await this.storageService.get(this.storageKey(accountId));

    return this.buildBadgesFromRawData(rawBadges);
  }

  private fetchBadges(accountId: string): Observable<any> {
    if (this.networkService?.isOffline()) {
      return of([]);
    }

    const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/badges`;

    return this.http.get(url).pipe(
      switchMap(
        async data => {
          await this.storageService.set(this.storageKey(accountId), data);

          return this.buildBadgesFromRawData(data);
      }),
      catchError(
        async err => {
          return { errorCode: 'E301', errorMessage: 'Gamification Service failed', errorOriginal: err};
        }
      )
    );
  }

  private storageKey(accountId: string): string {
    return `${accountId}-badges`;
  }

  private buildBadgesFromRawData(data: any): Array<BadgeModel> {
    const badges: Array<BadgeModel> = [];

    if (!!data && !!data.data) {
      for (const badge of data.data) {
        if (badge.attributes.custom_fields.enabled) {
          badges.push(this.buildBadgeFromRawData(badge));
        }
      }
      badges.sort((a, b) => a.position - b.position);
    }

    return badges;
  }

  private buildBadgeFromRawData(data: any): BadgeModel {
    return new BadgeModel({
      id: data.id,
      name: data.attributes.name,
      title: data.attributes.custom_fields.title,
      subtitle: data.attributes.custom_fields.subtitle,
      description: data.attributes.description,
      category: this.getCategoryByName(this.translateCategory(data.attributes.custom_fields.category)),
      obtained_at: data.attributes.obtained_at,
      premium: data.attributes.custom_fields.premium,
      ...this.buildCta(data.attributes.name),
      position: data.attributes.custom_fields.position,
      group_name: data.attributes.custom_fields.group.name,
      group_position: data.attributes.custom_fields.group.position
    });
  }

  // ANTI-COUILLON POUR GARDER MES CATEGORIES EN PLACE
  private translateCategory(category: string): string {
    return category.replace('_', '-');
  }

  private buildCta(badgeName: string): {cta: string, cta_path: string} {
    const { cta, cta_path } = !!this._BADGE_CTA.find(elt => elt.name === badgeName) ? this._BADGE_CTA.find(elt => elt.name === badgeName) : { cta: '', cta_path: '' };

    return {cta: cta, cta_path: cta_path};
  }

  private buildDisplayable(): Array<BadgeModel> {
    return this._badges.map((elt) => {
      elt.displayable = this.isBadgeDisplayable(elt);
      return elt;
    });
  }

  public getBadgeByName(badgeName: string): BadgeModel {
    return this._badges.find(elt => elt.name === badgeName);
  }

  public getAllBadgesByCategories(): Array<any> {
    return this._CATEGORIES.reduce((acc, curr) => {
      const badgesInCategory = this.getBadgesInCategory(curr.classId);
      const obtainedBadgesInCategory = this.getObtainedBadges(badgesInCategory);
      const badgesByCategory = {
        category: curr,
        badges: badgesInCategory,
        totalBadges: badgesInCategory.length,
        totalObtainedBadges: obtainedBadgesInCategory.length
      };

      acc.push(badgesByCategory);

      return acc;
    }, []);
  }

  public getBadgesInCategory(category: string): Array<BadgeModel> {
    return this._badges.filter(elt => elt.category.classId === category);
  }

  public getStudentRank(): string {
    const percentage: number = this.getObtainedBadgesInPercentage();

    return this._RANKS.find(elt => percentage >= elt.min && percentage <= elt.max).rank;
  }

  public getObtainedBadgesInPercentage(badges: Array<BadgeModel> = this._badges): number {
    const value: number = this.getObtainedBadges(badges).length;
    const total: number = badges.length;

    return this.utils.getPercent(value, total);
  }

  dateToFormatFr(input?: any) {
    // console.log('-------------------');
    // console.log('dateToFormatFr input', input, typeof input);
    let output = this.dateToMoment(); // today by default
    if (!!input) {
      output = this.dateToMoment(input);
      if (typeof input === 'string') {
        output = this.dateToMoment(input);
        // if (!input.includes('+')) {
        //   console.log('input string', input);
        // }
        if (input.includes('/')) {
          console.error('input string', input);
        }
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'number') {
        // timestamp
        output = this.dateToMoment(input);
        // console.log('input timestamp', input);
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'object') {
        if (!moment.isMoment(input) && !!input.year && !!input.month && !!input.day) {
          // custom object
          const year = input.year;
          const month = (Number(input.month) < 10) ? '0' + Number(input.month) : Number(input.month);
          const day = (input.day < 10) ? '0' + input.day : input.day;
          const str = year + '-' + month + '-' + day + ' 00:00:00';
          output = this.dateToMoment(str);
          // console.log('input custom object', input, str);
          // console.log('output', output);
          // console.log('-------------------');
        } else {
          // date object
          output = this.dateToMoment(input);
          // console.log('input date', input, input.isValid());
          // console.log('output', output);
        }
      }
    }
    if (!output.isValid()) {
      console.log('-------------------');
      console.error('dateToFormatFr input', input, typeof input);
      console.error('dateToFormatFr output', output, output.toString(), output.isValid());
      console.error('-------------------');
    }
    // console.log('dateToFormatFr output', output, output.toString(), output.isValid());
    // console.log('-------------------');
    return output;
  }

  dateToMoment(input?: any) {
    const zone = "Europe/Paris";
    moment.tz.setDefault(zone);
    moment.locale('fr');
    if (!!input) {
      return moment(input).tz(zone);
    }
    return moment().tz(zone);
  }

  public getSortedByLastObtainedBadges(badges: Array<BadgeModel> = this._badges): Array<BadgeModel> {
    return this.getObtainedBadges(badges).sort((a, b) => {
      // DATE TO CHANGE
      return new Date(b.obtained_at).getTime() - new Date(a.obtained_at).getTime();
    });
  }

  public getObtainedBadges(badges: Array<BadgeModel> = this._badges): Array<BadgeModel> {
    return badges.filter(elt => elt.obtained_at !== null);
  }

  public getCategoryByName(category): {id: number, text: string, classId: string} { 
    return this._CATEGORIES.find(elt => elt.classId === category);
  }

  public isBadgeDisplayable(badge: BadgeModel): boolean {
    if (badge.group_name == null) {
      return true;
    }

    const lastObtainedBadgeInGroup = this.getBadgesInGroup(badge.group_name).sort((a, b) => {
      // DATE TO CHANGE
      return new Date(b.obtained_at).getTime() - new Date(a.obtained_at).getTime();
    })[0];

    if (badge.name === lastObtainedBadgeInGroup.name) {
      return true;
    }

    return false;
  }

  public getNextBadgeInGroup(badge: BadgeModel): BadgeModel | undefined {
    return this.getBadgesInGroup(badge.group_name).find(elt => elt.group_position === badge.group_position + 1);
  }

  public getBadgesInGroup(groupName: string): Array<BadgeModel> {
    return this._badges.filter(elt => elt.group_name === groupName).sort((a, b) => a.group_position - b.group_position);
  }

  public validateBadge(badgeName: string): Observable<any> {
    if (this.networkService?.isOffline()) {
      return EMPTY;
    }

    const badge = this.getBadgeByName(badgeName);

    if (!!badge.obtained_at) {
      return EMPTY;
    }

    const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/badges/${badge.id}/grant_on`;

    return this.http.get(url).pipe(
      switchMap(
        res => {
          // console.log(res);
          return of(res);
        }
      ),
      catchError(
        async err => {
          return null;
        }
      )
    );
  }

  public async validateGuestSerieBadges() {
    if (!this._student.isGuest()) {
      return;
    }

    if (this._guestSerieInfoAnswers >= 5) {
      await this.unlockBadgeInStorage('answered_5_questions');
    }

    if (this._guestSerieInfoAnswers >= 20) {
      await this.unlockBadgeInStorage('answered_20_questions');
    }

    if (this._guestSerieInfoGoodAnswers >= 5) {
      await this.unlockBadgeInStorage('answered_right_5_questions');
    }

    if (this._guestSerieInfoGoodAnswersInARow >= 5) {
      await this.unlockBadgeInStorage('right_5_questions_in_a_row');
    }

    if (this._guestSerieInfoGoodAnswersInARow >= 10) {
      await this.unlockBadgeInStorage('right_10_questions_in_a_row');
    }

    if (this._guestSerieInfoGoodAnswersInARow >= 20) {
      await this.unlockBadgeInStorage('right_20_questions_in_a_row');
    }

    if (this._guestSerieInfoGoodAnswersInARow >= 30) {
      await this.unlockBadgeInStorage('right_30_questions_in_a_row');
    }

    if (this._guestSerieInfoGoodAnswersInARow >= 40) {
      await this.unlockBadgeInStorage('right_40_questions_in_a_row');
    }

    if (this._guestSerieInfoGoodAnswersInARow >= 100) {
      await this.unlockBadgeInStorage('right_100_questions_in_a_row');
    }
  }

  public async setStorageGuestSerieInfo() {
    if (!this._student.isGuest()) {
      return;
    }

    const guestSerieInfo = {
      answers: this._guestSerieInfoAnswers,
      goodAnswersInARow: this._guestSerieInfoGoodAnswersInARow,
      goodAnswers: this._guestSerieInfoGoodAnswers
    };

    await this.storageService.set('GUEST-badgesSerieInfo', guestSerieInfo);
  }

  public async syncGuestBadgesFromLoginOrRegistration(): Promise<any> {
    const rawBadges = await this.storageService.get(this.storageKey('GUEST'));

    if (!rawBadges || !rawBadges.data) {
      return EMPTY;
    }

    const data = rawBadges.data.reduce((acc, curr) => {
      if (!!curr.attributes.obtained_at) {
        acc.push(curr.attributes.id)
      }

      return acc;
    }, []);

    if (data.length === 0) {
      return EMPTY;
    }

    const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/badges/grant_on`;

    return this.http.post(url, { badges_id: data }).pipe(
      switchMap(
        async (res: any) => {
          await this.resetGuestStorage();
          return res;
        }
      ),
      catchError(
        err => {
          return of(err);
        }
      )
    ).toPromise();
  }

  private async resetGuestStorage() {
    await this.storageService.remove('GUEST-badges');
    await this.storageService.remove('GUEST-badgesSerieInfo');

    this._guestSerieInfoAnswers = 0;
    this._guestSerieInfoGoodAnswersInARow = 0;
    this._guestSerieInfoGoodAnswers = 0;
  }

  public async addBadgeObtainedOffline(badgeName: string) {
    if (this._badgesObtainedOffline.indexOf(badgeName) > -1) {
      return;
    }

    const storedBadgesObtainedOffline = await this.storageService.get(`${this._student.remoteId}-badgesObtainedOffline`) || [];
    const newBadgesObtainedOffline = [...storedBadgesObtainedOffline, badgeName];

    await this.storageService.set(`${this._student.remoteId}-badgesObtainedOffline`, newBadgesObtainedOffline);

    this._badgesObtainedOffline = newBadgesObtainedOffline;

    await this.unlockBadgeInStorage(badgeName);
  }

  public async unlockBadgeInStorage(badgeName: string) {
    const rawBadges = await this.storageService.get(this.storageKey(this._student.remoteId));
    // console.log('rawBadges', rawBadges);
    if (!rawBadges) {
      return;
    }

    const index = rawBadges.data.findIndex(elt => elt.attributes.name === badgeName);
    // console.log('index', index);

    if (index < 0 || !!rawBadges.data[index].attributes.obtained_at) {
      // console.log('index < 0 || ....');
      return;
    }

    // DATE TO CHANGE
    rawBadges.data[index].attributes.obtained_at = this.dateToFormatFr().toISOString();

    await this.storageService.set(this.storageKey(this._student.remoteId), rawBadges);
  }

  public async validateStudentSerieBadgesObtainedOffline() {
    if (this._student.isGuest()) {
      return;
    }

    if (this._studentSerieInfoAnswers >= 5) {
      await this.addBadgeObtainedOffline('answered_5_questions');
    }

    if (this._studentSerieInfoAnswers >= 20) {
      await this.addBadgeObtainedOffline('answered_20_questions');
    }

    if (this._studentSerieInfoGoodAnswers >= 5) {
      await this.addBadgeObtainedOffline('answered_right_5_questions');
    }

    if (this._studentSerieInfoGoodAnswersInARow >= 5) {
      await this.addBadgeObtainedOffline('right_5_questions_in_a_row');
    }

    if (this._studentSerieInfoGoodAnswersInARow >= 10) {
      await this.addBadgeObtainedOffline('right_10_questions_in_a_row');
    }

    if (this._studentSerieInfoGoodAnswersInARow >= 20) {
      await this.addBadgeObtainedOffline('right_20_questions_in_a_row');
    }

    if (this._studentSerieInfoGoodAnswersInARow >= 30) {
      await this.addBadgeObtainedOffline('right_30_questions_in_a_row');
    }

    if (this._studentSerieInfoGoodAnswersInARow >= 40) {
      await this.addBadgeObtainedOffline('right_40_questions_in_a_row');
    }

    if (this._studentSerieInfoGoodAnswersInARow >= 100) {
      await this.addBadgeObtainedOffline('right_100_questions_in_a_row');
    }

    if (this._studentSerieInfoSeriesCompleted >= 1) {
      await this.addBadgeObtainedOffline('1_series_finished');
    }

    if (this._studentSerieInfoSeriesCompleted >= 5) {
      await this.addBadgeObtainedOffline('5_series_finished');
    }

    if (this._studentSerieInfoSeriesCompleted >= 10) {
      await this.addBadgeObtainedOffline('10_series_finished');
    }

    if (this._studentSerieInfoSeriesCompleted >= 20) {
      await this.addBadgeObtainedOffline('20_series_finished');
    }

    if (this._studentSerieInfoSeriesCompleted >= 40) {
      await this.addBadgeObtainedOffline('40_series_finished');
    }

    if (this._studentSerieInfoSeriesCompletedAndGood >= 1) {
      await this.addBadgeObtainedOffline('succeeded_1_serie');
    }

    if (this._studentSerieInfoSeriesCompletedAndGood >= 5) {
      await this.addBadgeObtainedOffline('succeeded_5_series');
    }

    if (this._studentSerieInfoSeriesCompletedAndGood >= 10) {
      await this.addBadgeObtainedOffline('succeeded_10_series');
    }

    if (this._studentSerieInfoSeriesCompletedAndGoodInARow >= 2) {
      await this.addBadgeObtainedOffline('succeeded_2_series_in_a_row');
    }

    if (this._studentSerieInfoSeriesCompletedAndGoodInARow >= 3) {
      await this.addBadgeObtainedOffline('succeeded_3_series_in_a_row');
    }

    if (this._studentSerieInfoSeriesCompletedAndGoodInARow >= 4) {
      await this.addBadgeObtainedOffline('succeeded_4_series_in_a_row');
    }

    if (this._studentSerieInfoSeriesCompletedAndGoodInARow >= 5) {
      await this.addBadgeObtainedOffline('succeeded_5_series_in_a_row');
    }

    if (this._studentSerieInfoSeriesCompletedAndGoodInARow >= 10) {
      await this.addBadgeObtainedOffline('succeeded_10_series_in_a_row');
    }

    if (this._studentSerieInfoUniqueDiscoverySeriesCompleted.length >= 1) {
      await this.addBadgeObtainedOffline('1_discovery_serie_finished');
    }

    if (this._studentSerieInfoUniqueDiscoverySeriesCompleted.length >= 4) {
      await this.addBadgeObtainedOffline('all_discovery_series_finished');
    }
  }

  public async setStorageStudentSerieInfo() {
    if (this._student.isGuest()) {
      return;
    }

    const studentSerieInfo = {
      answers: this._studentSerieInfoAnswers,
      goodAnswersInARow: this._studentSerieInfoGoodAnswersInARow,
      goodAnswers: this._studentSerieInfoGoodAnswers,
      seriesCompleted: this._studentSerieInfoSeriesCompleted,
      seriesCompletedAndGood: this._studentSerieInfoSeriesCompletedAndGood,
      seriesCompletedAndGoodInARow: this._studentSerieInfoSeriesCompletedAndGoodInARow,
      uniqueDiscoverySeriesCompleted: this._studentSerieInfoUniqueDiscoverySeriesCompleted
    };

    await this.storageService.set(`${this._student.remoteId}-badgesSerieInfo`, studentSerieInfo);
  }

  public async syncBadgesObtainedOffline(): Promise<any> {
    if (this._badgesObtainedOffline.length === 0 || this.networkService?.isOffline() || this._student.isGuest()) {
      return EMPTY;
    }

    const data = this._badgesObtainedOffline.reduce((acc, curr) => {
      acc.push(this.getBadgeByName(curr).id);

      return acc;
    }, []);

    const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/badges/grant_on`;

    return this.http.post(url, { badges_id: data }).pipe(
      switchMap(
        async (res: any) => {
          await this.resetBadgesObtainedOffline();

          return res;
        }
      ),
      catchError(
        err => {
          return of(err);
        }
      )
    ).toPromise();
  }

  private async resetBadgesObtainedOffline() {
    await this.storageService.remove(`${this._student.remoteId}-badgesObtainedOffline`);

    this._badgesObtainedOffline = [];
  }

  public async fetchDailyStreaks(accountId: string): Promise<any> {
    if (this.networkService?.isOffline()) {
      let dailyStreakInfo = await this.storageService.get(`${this._student.remoteId}-dailyStreakInfo`);
      if (!!dailyStreakInfo) {
        return of(dailyStreakInfo).toPromise();
      }

      dailyStreakInfo = {
        daily_streak_current: 0,
        daily_streak_max: 0,
        dates: []
      };
      return of(dailyStreakInfo).toPromise();
    }

    const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${accountId}/daily_streaks`;

    return this.http.get(url).pipe(
      switchMap(
        async data => {
          this._dailyStreaks = data['data'];
          await this.storageService.set(`${this._student.remoteId}-dailyStreakInfo`, this._dailyStreaks);
          return this._dailyStreaks;
      }),
      catchError(
        async err => {
          return { errorCode: 'E301', errorMessage: 'Gamification Service failed', errorOriginal: err};
        }
      )
    ).toPromise();
  }

  streakDays(timeSpan = "Tout") {
    let dates = this.getRangedDailyStreaks(timeSpan);
    const streaks = [1];
    let streak = 0;
    let previousDate: any;
    for (const date of dates) {
      // DATE TO CHANGE
      const currentDate: any = this.dateToFormatFr(date).valueOf();
      if (!!previousDate) {
        if (Math.abs((previousDate - currentDate) / (1000 * 3600 * 24)) === 1) {
          streak++;
        } else {
          if (Math.abs((previousDate - currentDate) / (1000 * 3600 * 24)) > 1) {
            streaks.push(streak);
            streak = 1;
          }
        }
        previousDate = currentDate;
      } else {
        streak++;
        previousDate = currentDate;
      }
    }
    streaks.push(streak);
    const bestStreak = streaks.sort((a, b) => b - a);
    return bestStreak[0];
  }

  weekRange(): Array<moment.Moment> {
    // DATE TO CHANGE
    const today = this.dateToFormatFr();
    const dates: Array<moment.Moment> = [];
    // DATE TO CHANGE
    let tmp: moment.Moment = this.dateToFormatFr(today);
    let day: number;

    tmp.set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
    day = tmp.day();
    tmp.date(tmp.date() - day + (day === 0 ? -6 : 1));
    dates.push(tmp);

    // DATE TO CHANGE
    tmp = this.dateToFormatFr(today);

    tmp.set({hours: 23, minutes: 59, seconds: 59, milliseconds: 999});
    tmp.date(tmp.date() - (day - 1) + 6);
    dates.push(tmp);

    return dates;
  }

  monthRange(): Array<moment.Moment> {
    // DATE TO CHANGE
    const today = this.dateToFormatFr();
    const dates: Array<moment.Moment> = [];
    let tmp: moment.Moment;

    // DATE TO CHANGE
    tmp = this.dateToFormatFr(moment({years: today.year(), months: today.month(), days: 1}))
    tmp.set({hours: 0, minutes: 0, seconds: 0, milliseconds: 0});
    dates.push(tmp);

    // DATE TO CHANGE
    tmp = this.dateToFormatFr(moment({years: today.year(), months: today.month() + 1, days: 0}))
    tmp.set({hours: 23, minutes: 59, seconds: 59, milliseconds: 999});
    dates.push(tmp);

    return dates;
  }

  getRangedDailyStreaks(timeSpan: string): Array<string> {
    let rangedDailyStreaks: Array<string> = [];
    if (timeSpan === 'Tout') {
      rangedDailyStreaks = this.dailyStreaks['dates'];
    } else {
      let span: Array<moment.Moment>;
      if (timeSpan === 'Mois') {
        span = this.monthRange();
      } else if (timeSpan === 'Semaine') {
        span = this.weekRange();
      }
      rangedDailyStreaks = this.dailyStreaks['dates'].filter((elt) => {
        // DATE TO CHANGE
        const tmpDate = this.dateToFormatFr(elt);
        return tmpDate.valueOf() >= span[0].valueOf() && tmpDate.valueOf() <= span[1].valueOf();
      });
    }
    return rangedDailyStreaks;
  }

  public get fetchBadgesFailed(): Boolean {
    return this._fetchBadgesFailed;
  }

  public get badges(): Array<BadgeModel> {
    return this._badges;
  }

  public set badges(badges: Array<BadgeModel>) {
    this._badges = badges;
  }

  public get studentRank(): string {
    return this._studentRank;
  }

  public set studentRank(studentRank: string) {
    this._studentRank = studentRank;
  }

  public get obtainedBadgesInPercentage(): number {
    return this._obtainedBadgesInPercentage;
  }

  public set obtainedBadgesInPercentage(obtainedBadgesInPercentage: number) {
    this._obtainedBadgesInPercentage = obtainedBadgesInPercentage;
  }

  public get guestSerieInfoAnswers(): number {
    return this._guestSerieInfoAnswers;
  }

  public set guestSerieInfoAnswers(guestSerieInfoAnswers: number) {
    this._guestSerieInfoAnswers = guestSerieInfoAnswers;
  }

  public get guestSerieInfoGoodAnswersInARow(): number {
    return this._guestSerieInfoGoodAnswersInARow;
  }

  public set guestSerieInfoGoodAnswersInARow(guestSerieInfoGoodAnswersInARow: number) {
    this._guestSerieInfoGoodAnswersInARow = guestSerieInfoGoodAnswersInARow;
  }

  public get guestSerieInfoGoodAnswers(): number {
    return this._guestSerieInfoGoodAnswers;
  }

  public set guestSerieInfoGoodAnswers(guestSerieInfoGoodAnswers: number) {
    this._guestSerieInfoGoodAnswers = guestSerieInfoGoodAnswers;
  }

  public get studentSerieInfoAnswers(): number {
    return this._studentSerieInfoAnswers;
  }

  public set studentSerieInfoAnswers(studentSerieInfoAnswers: number) {
    this._studentSerieInfoAnswers = studentSerieInfoAnswers;
  }

  public get studentSerieInfoGoodAnswersInARow(): number {
    return this._studentSerieInfoGoodAnswersInARow;
  }

  public set studentSerieInfoGoodAnswersInARow(studentSerieInfoGoodAnswersInARow: number) {
    this._studentSerieInfoGoodAnswersInARow = studentSerieInfoGoodAnswersInARow;
  }

  public get studentSerieInfoGoodAnswers(): number {
    return this._studentSerieInfoGoodAnswers;
  }

  public set studentSerieInfoGoodAnswers(studentSerieInfoGoodAnswers: number) {
    this._studentSerieInfoGoodAnswers = studentSerieInfoGoodAnswers;
  }

  public get studentSerieInfoSeriesCompleted(): number {
    return this._studentSerieInfoSeriesCompleted;
  }

  public set studentSerieInfoSeriesCompleted(studentSerieInfoSeriesCompleted: number) {
    this._studentSerieInfoSeriesCompleted = studentSerieInfoSeriesCompleted;
  }

  public get studentSerieInfoSeriesCompletedAndGood(): number {
    return this._studentSerieInfoSeriesCompletedAndGood;
  }

  public set studentSerieInfoSeriesCompletedAndGood(studentSerieInfoSeriesCompletedAndGood: number) {
    this._studentSerieInfoSeriesCompletedAndGood = studentSerieInfoSeriesCompletedAndGood;
  }

  public get studentSerieInfoSeriesCompletedAndGoodInARow(): number {
    return this._studentSerieInfoSeriesCompletedAndGoodInARow;
  }

  public set studentSerieInfoSeriesCompletedAndGoodInARow(studentSerieInfoSeriesCompletedAndGoodInARow: number) {
    this._studentSerieInfoSeriesCompletedAndGoodInARow = studentSerieInfoSeriesCompletedAndGoodInARow;
  }

  public get studentSerieInfoUniqueDiscoverySeriesCompleted(): Array<string> {
    return this._studentSerieInfoUniqueDiscoverySeriesCompleted;
  }

  public set studentSerieInfoUniqueDiscoverySeriesCompleted(studentSerieInfoUniqueDiscoverySeriesCompleted: Array<string>) {
    this._studentSerieInfoUniqueDiscoverySeriesCompleted = studentSerieInfoUniqueDiscoverySeriesCompleted;
  }

  public addStudentSerieInfoUniqueDiscoverySeriesCompleted(serieId: string) {
    this._studentSerieInfoUniqueDiscoverySeriesCompleted.push(serieId);
  }

  public get dailyStreaks(): Object {
    return this._dailyStreaks;
  }

  public get categories(): Array<{ id: number, text: string, classId: string }> {
    return this._CATEGORIES;
  }

  private _CATEGORIES: Array<{ id: number, text: string, classId: string }> = [
    {
      id: 1,
      text: 'Mon compte',
      classId: 'account'
    },
    {
      id: 2,
      text: "M'inscrire en préfecture",
      classId: 'prefecture-registration'
    },
    {
      id: 3,
      text: "Réviser le code",
      classId: 'code'
    },
    {
      id: 4,
      text: "Passer l'examen du code",
      classId: 'code-exam'
    },
    {
      id: 5,
      text: "Apprendre à conduire",
      classId: 'driving-lesson'
    },
    {
      id: 6,
      text: 'Passer le permis',
      classId: 'driving-exam'
    },
  ];
  
  private _BADGE_CTA: Array<{name: string, cta: string, cta_path: string}> = [
    {
      name:"registered",
      cta: "Créer mon compte",
      cta_path: "sign-up"
    },
    {
      name:"fill_information",
      cta: "Accéder à mon profil",
      cta_path: "edit-profil"
    },
    {
      name:"add_picture",
      cta: "Accéder à mon profil",
      cta_path: "edit-profil"
    },
    {
      name:"download_app",
      cta: "Télécharger l'app",
      cta_path: "downloadApp"
    },
    {
      name:"rate_app",
      cta: "Noter l'app",
      cta_path: "rateApp"
    },
    {
      name:"sponsorship",
      cta: "Parrainer un ami",
      cta_path: "parrainage"
    },
    {
      name:"fill_typeform",
      cta: "Répondre au formulaire",
      cta_path: "terminer-mon-inscription"
    },
    {
      name:"send_prefecture_registration",
      cta: "Consulter les démarches",
      cta_path: "demarchesPrefecture"
    },
    {
      name:"prefecture_registration",
      cta: "Renseigner mon NEPH",
      cta_path: "documents"
    },
    {
      name:"access_cdr_lesson",
      cta: "Découvrir les cours",
      cta_path: "cours"
    },
    {
      name:"answered_5_questions",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"answered_20_questions",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"fix_cdr_error",
      cta: "Accéder au mode Mes Erreurs",
      cta_path: "series/erreurs/buffer"
    },
    {
      name:"start_cdr_serie",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"start_cdr_serie_exam",
      cta: "Lancer un examen blanc",
      cta_path: "series/examens"
    },
    {
      name:"start_cdr_serie_thematic",
      cta: "Lancer une série thématique",
      cta_path: "series/thematiques"
    },
    {
      name:"answered_right_5_questions",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"1_series_finished",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"5_series_finished",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"10_series_finished",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"20_series_finished",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"40_series_finished",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"2_days_in_a_row",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"5_days_in_a_row",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"10_days_in_a_row",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"25_days_in_a_row",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"50_days_in_a_row",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"100_days_in_a_row",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"right_5_questions_in_a_row",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"right_10_questions_in_a_row",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"right_20_questions_in_a_row",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"right_30_questions_in_a_row",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"right_40_questions_in_a_row",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"right_100_questions_in_a_row",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"succeeded_1_serie",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"succeeded_5_series",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"succeeded_10_series",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"succeeded_2_series_in_a_row",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"succeeded_3_series_in_a_row",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"succeeded_4_series_in_a_row",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"succeeded_5_series_in_a_row",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"succeeded_10_series_in_a_row",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"1_discovery_serie_finished",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"all_discovery_series_finished",
      cta: "Accéder aux séries",
      cta_path: "series/entrainements"
    },
    {
      name:"book_code_exam",
      cta: "Réserver mon examen",
      cta_path: "reservation-examen-code"
    },
    {
      name:"succeeded_code_exam",
      cta: "Renseigner mon résultat",
      cta_path: "mon-inscription-examen"
    },
    {
      name:"bought_big_pack",
      cta: "Acheter le Pack Permis",
      cta_path: "permis"
    },
    {
      name:"booked_driving_lesson",
      cta: "Réserver une leçon",
      cta_path: "reserver-lecon"
    },
    {
      name:"rate_teacher",
      cta: "Noter ma leçon",
      cta_path: "notation"
    },
    {
      name:"finished_driving_lesson_night",
      cta: "Réserver une leçon",
      cta_path: "reserver-lecon"
    },
    {
      name:"1_hour_finished",
      cta: "Réserver une leçon",
      cta_path: "reserver-lecon"
    },
    {
      name:"5_hours_finished",
      cta: "Réserver une leçon",
      cta_path: "reserver-lecon"
    },
    {
      name:"10_hours_finished",
      cta: "Réserver une leçon",
      cta_path: "reserver-lecon"
    },
    {
      name:"20_hours_finished",
      cta: "Réserver une leçon",
      cta_path: "reserver-lecon"
    },
    {
      name:"booked_driving_exam",
      cta: "Accéder à mon dossier",
      cta_path: "dossier"
    },
    {
      name:"driving_exam_validated",
      cta: "Contacter mon enseigant",
      cta_path: "lecons"
    },
    {
      name:"watch_videos",
      cta: "Accéder aux vidéos",
      cta_path: "examenVideo"
    },
    {
      name:"succeeded_driving_exam",
      cta: "Mettre en ligne mon résultat",
      cta_path: "dossier"
    },
    {
      name:"thank_teacher",
      cta: "Contacter mon enseignant",
      cta_path: "lecons"
    }
  ];
  private _RANKS: Array<{min: number, max: number, rank: string}> = [
    {
      min: 0,
      max: 4,
      rank: "Passager curieux"
    },
    {
      min: 5,
      max: 9,
      rank: "Autostoppeur débrouillard"
    },
    {
      min: 10,
      max: 19,
      rank: "Apprenti chauffeur"
    },
    {
      min: 20,
      max: 39,
      rank: "Pilote en herbe"
    },
    {
      min: 40,
      max: 59,
      rank: "Mécano du dimanche"
    },
    {
      min: 60,
      max: 79,
      rank: "Pilote confirmé"
    },
    {
      min: 80,
      max: 99,
      rank: "Mordu de l'asphalte"
    },
    {
      min: 100,
      max: 100,
      rank: "Moniteur de la conduite ?!"
    },
  ];
}
