<video *ngIf="!fullscreen" id="videoPlayer" (ended)='videoEnd()' [controls]="isControls" disablePictureInPicture controlslist="nofullscreen nodownload" [muted]="!withSound" preload="metadata" playsinline webkit-playsinline="webkit-playsinline" webkit-playsinline #moviePlayer></video>
<video *ngIf="fullscreen" id="videoPlayerFullscreen" (ended)='videoEnd()' [controls]="isControls" disablePictureInPicture controlslist="nodownload" [muted]="!withSound" preload="metadata" playsinline webkit-playsinline="webkit-playsinline" webkit-playsinline #moviePlayerFullscreen></video>
<div class="force-play-container" *ngIf="playVideoFail" (click)="playVideo()">
  <div class="btn-play-container">
    <svg width="70" height="70">
      <use xlink:href="" attr.xlink:href="{{iconPath + 'play-2'}}"></use>
    </svg>
  </div>
</div>
<ng-container *ngIf="withTimer && ((!timerEnded && timerValue > 0) || (timerEnded))">
  <div class="timer" (click)="closeVideo()">
    <span *ngIf="!timerEnded && timerValue > 0">{{timerValue}}</span>
    <img *ngIf="timerEnded" src="assets/2022/icons/close-off.svg" alt="EnVoitureSimone" height="18">
  </div>
</ng-container>
<div *ngIf="debug">
  <div *ngIf="!!videoErrors && videoErrors.length > 0">
    <blockquote *ngFor="let error of videoErrors" class="border-danger margin-10">
      {{ error }}
    </blockquote>
  </div>
  <blockquote *ngIf="!!videoLoadStart" class="margin-10">
    loadStart => {{ videoLoadStart | json }}
  </blockquote>
  <blockquote *ngIf="!!videoProgress" class="margin-10">
    Progress => {{ videoProgress | json }}
  </blockquote>
  <blockquote *ngIf="!!videoSuspend" class="margin-10">
    Suspend => {{ videoSuspend | json }}
  </blockquote>
  <blockquote *ngIf="!!videoAbort" class="margin-10">
    Abort => {{ videoAbort | json }}
  </blockquote>
  <blockquote *ngIf="!!videoError" class="margin-10">
    Error => {{ videoError | json }}
  </blockquote>
  <blockquote *ngIf="!!videoEmptied" class="margin-10">
    Emptied => {{ videoEmptied | json }}
  </blockquote>
  <blockquote *ngIf="!!videoStalled" class="margin-10">
    Stalled => {{ videoStalled | json }}
  </blockquote>
  <blockquote *ngIf="!!videoLoadedData" class="margin-10">
    LoadedMetadata => {{ videoLoadedMetadata | json }}
  </blockquote>
  <blockquote *ngIf="!!videoLoadedData" class="margin-10">
    LoadedData => {{ videoLoadedData | json }}
  </blockquote>
  <blockquote *ngIf="!!videoCanPlay" class="margin-10">
    CanPlay => {{ videoCanPlay | json }}
  </blockquote>
  <blockquote *ngIf="!!videoCanPlayThrough" class="margin-10">
    CanPlayThrough => {{ videoCanPlayThrough | json }}
  </blockquote>
  <blockquote *ngIf="!!videoPlaying" class="margin-10">
    Playing => {{ videoPlaying | json }}
  </blockquote>
    <blockquote *ngIf="!!videoWaiting" class="margin-10">
    Waiting => {{ videoWaiting | json }}
  </blockquote>
  <blockquote *ngIf="!!videoSeeking" class="margin-10">
    Seeking => {{ videoSeeking | json }}
  </blockquote>
  <blockquote *ngIf="!!videoSeeked" class="margin-10">
    Seeked => {{ videoSeeked | json }}
  </blockquote>
  <blockquote *ngIf="!!videoEnded" class="margin-10">
    Ended => {{ videoEnded | json }}
  </blockquote>
  <blockquote *ngIf="!!videoDurationChange" class="margin-10">
    DurationChange => {{ videoDurationChange | json }}
  </blockquote>
  <blockquote *ngIf="!!videoTimeUpdate" class="margin-10">
    TimeUpdate => {{ videoTimeUpdate | json }}
  </blockquote>
  <blockquote *ngIf="!!videoPlay" class="margin-10">
    Play => {{ videoPlay | json }}
  </blockquote>
  <blockquote *ngIf="!!videoPause" class="margin-10">
    Pause => {{ videoPause | json }}
  </blockquote>
  <blockquote *ngIf="!!videoRateChange" class="margin-10">
    RateChange => {{ videoRateChange | json }}
  </blockquote>
  <blockquote *ngIf="!!videoResize" class="margin-10">
    Resize => {{ videoResize | json }}
  </blockquote>
  <blockquote *ngIf="!!videoVolumeChange" class="margin-10">
    VolumeChange => {{ videoVolumeChange | json }}
  </blockquote>
</div>