import { Injectable } from '@angular/core';

import { Platform } from '@ionic/angular';
// import { FirebaseX } from '@awesome-cordova-plugins/firebase-x/ngx';
import { Initialable } from './app-init.service';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@Injectable({
  providedIn: 'root'
})
@Initialable({ step: 'init5', initializer: `onInit` })
@EasyDebugDecorator
export class FirebaseService {

  constructor(
    private platform: Platform,
    // public firebase: FirebaseX,
  ) { }

  async onInit() {
    if (this.platform.is('cordova')) {
      // this.firebase.getToken().then(
      //   res => {
      //     // alert(`fcm ${JSON.stringify(res)}`);
      //   }
      // )
    }
    return 'Firebase done';
  }

  objToFirebase(datas: any, tag: {[key: string]: any}): {[key: string]: any} {
    for (const data of Object.entries(datas)) {
      if (!!JSON.stringify(data[1])) {
        tag[data[0]] = JSON.stringify(data[1]).split('"').join('');
      } else {
        tag[data[0]] = null;
      }
    }
    return tag;
  }

  async setUserProperties(datas: any): Promise<any> {
    for (const data of Object.entries(datas)) {
      if (!!JSON.stringify(data[1])) {
        // await this.firebase.setUserProperty(data[0], JSON.stringify(data[1]).split('"').join(''));
      }
    }
    return;
  }

  arrayToFirebase(objs: any[], tag: {[key: string]: any}): {[key: string]: any} {
    for (const obj of objs) {
      tag = this.objToFirebase(obj, tag);
    }
    return tag;
  }

  async sendFirebaseEvent(eventName: string, user: any, env: any, additionalData: any) {
    // console.log('sendFirebaseEvent', eventName, user, env, additionalData);
    let tag: {[key: string]: any} = {};
    // console.log('additional data => ', additionalData);
    if (!!additionalData) {
      tag = this.arrayToFirebase([env, additionalData], tag);
    } else {
      tag = this.arrayToFirebase([env], tag);
    }
    // console.log(eventName, ' user => ', user, ' tags => ', tag);
    await this.setUserProperties(user);
    // this.firebase.logEvent(eventName, tag);
  }
}
