<div class="page-loader" [ngClass]="{'fake-first-step': fakeFirstStep}" #pageLoader></div>
<div class="display-none">
  <span id="call-center-phone">{{ callCenterPhone }}</span>
</div>
<div *ngIf="recordingMode === 'record'" class="recording-mode-header">
  <div class="circle-record"></div>
  <div class="text">Enregistrement</div>
  <div class="controls">
    <button class="btn small light" (click)="sendReport()">Envoyer</button>
  </div>
</div>
<ion-app [ngClass]="{'device': isCordova, 'hideFooterNav': hideFooterNav, 'recording-mode': recordingMode === 'record', 'call-center': displayCallCenter}">
  <ion-router-outlet></ion-router-outlet>
</ion-app>

<modal-comp [compOptions]="{'id': 'customModalDebugMode', closable: false}"  *ngIf="displayModalSendReportDebugMode" (canClose)="hideModalSendReportDebugMode()">
  <div class="text-center">
    <ion-spinner class="customSpinner margin-top-10" name="crescent"></ion-spinner>
    <br>
    <h5 class="margin-0 margin-top-10">Envoi du rapport en cours, merci de ne pas fermer l'application</h5>
    <!-- <div class="alert-info small-text">Ne ferme pas la fenêtre de ton navigateur ou de ton application sinon l'envoi échouera&nbsp;!</div> -->
  </div>
</modal-comp>

<modal-comp [compOptions]="{'id': 'customModalSilentDebugMode', closable: false}"  *ngIf="displayModalSendSilentReportDebugMode" (canClose)="hideModalSendSilentReportDebugMode()">
  <div class="text-center">
    <ion-spinner class="customSpinner margin-top-10" name="crescent"></ion-spinner>
    <br>
    <div *ngIf="splashScreenWentWell" class="margin-0 margin-top-15">
      <h4>Oups&nbsp;!</h4>
      <p class="small-text">L’application a mis plus de temps à se lancer que prévu. Merci de ne pas quitter pendant qu’on enquête 😉.</p>      
    </div>
    <div *ngIf="!splashScreenWentWell" class="margin-0 margin-top-15">
      <h4>Oups&nbsp;!</h4>
      <p class="small-text">L’application prend plus de temps que prévu à démarrer. Merci de ne pas quitter pendant qu’on enquête 😉</p>
    </div>
    <!-- <div class="alert-info small-text">Ne ferme pas la fenêtre de ton navigateur ou de ton application sinon l'envoi échouera&nbsp;!</div> -->
  </div>
</modal-comp>