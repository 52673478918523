import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { UtilsService } from '@commons/services';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'notification-card-comp',
  templateUrl: 'notification-card.html',
  styleUrls: ['notification-card.scss']
})
export class NotificationCardComponent implements OnInit, OnChanges {

  @Input() compOptions: any;

  debug = false;
  title = 'Sans titre !';
  subtitle = 'Sans titre !';
  canClick = true;

  constructor(
    private utils: UtilsService,
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    // detect @input changes
    const compOptions: SimpleChange = changes.compOptions;
    if (this.utils.isDefined(this.compOptions)) {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  ngOnInit() {
    this.setGlobals();
  }

  setGlobals() {
    this.debug = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.debug)) ? this.compOptions.debug : this.debug;
    this.title = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.title)) ? this.compOptions.title : this.title;
    this.subtitle = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.subtitle)) ? this.compOptions.subtitle : this.subtitle;
    this.canClick = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.canClick)) ? this.compOptions.canClick : this.canClick;
  }

  handleClick() {
    if (this.canClick) {
      // console.log('click on ' + this.title);
    }
  }

}
