import { Component, Input, OnChanges, SimpleChanges, SimpleChange, ViewChild, Renderer2 } from '@angular/core';

import { IonContent, ModalController } from '@ionic/angular';
import * as moment from 'moment';

import {
  AppInitService,
  StudentService,
  LessonsService,
  NavigationService,
} from '@app/services';

import { ModalIframeComponent } from '@app/modals';

import { Student } from '@app/models';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'stats-calendar',
  templateUrl: 'stats-calendar.html',
  styleUrls: ['stats-calendar.scss']
})
export class StatsCalendar implements OnChanges {

  @Input() compOptions: any;
  @Input() content: IonContent;
  @ViewChild('calendarScreens') calendarScreens: any;

  isDebug = true;

  student: Student;
  userLifeCycle: any;

  calledFrom = 'dashboard';
  isIpad = false;

  account: any;
  iconPath = './uikit-assets/icons/orion-svg-sprite.svg#';
  showBloc = 0;

  months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
  days = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

  // DATE TO CHANGE
  today = this.dateToFormatFr();
  todayDay = this.today.date(); // 0-31
  todayMonth = this.today.month(); // 0-11
  todayYear = this.today.year(); // 2018
  // DATE TO CHANGE
  selectedDate = this.dateToFormatFr();
  month = this.selectedDate.month(); // 0-11
  year = this.selectedDate.year(); // 2018

  first_date = this.year + '-' + (this.month + 1) + '-0' + 1;
  // DATE TO CHANGE
  tmp = this.dateToFormatFr(this.first_date).toString();
  first_day = this.tmp.substring(0, 3);
  day_name = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  day_no = this.day_name.indexOf(this.first_day);
  // DATE TO CHANGE
  nbDay = this.dateToFormatFr(new Date(this.year, this.month + 1, 0)).date();

  lessons = [];
  fetchLessonFailed = false;

  // DATE TO CHANGE
  previousMonth: any = this.dateToFormatFr();
  // DATE TO CHANGE
  nextMonth: any = this.dateToFormatFr();

  calendarDates = [];
  sliderAnmationDuration = 300;
  calendarSize = 435; // 324
  marginLeft = 0;

  initPageDone = false; // for shimmers
  fnInitPageLaunched = false; // for controllers

  constructor(
    public appInitService: AppInitService,
    private studentService: StudentService,
    private lessonsService: LessonsService,
    private navigationService: NavigationService,
    private modalController: ModalController,
    private renderer: Renderer2,
  ) {
    this.userLifeCycle = this.studentService.getUserLifeCycle(this.student); // if student not ready return guest
    this.appInitService.onStepChange().subscribe(
      (state) => {
        if (state?.init >= 3 && state?.stepDone && !this.fnInitPageLaunched) {
          this.fnInitPageLaunched = true;
          this.initPage();
        }
      }
    );
    // DATE TO CHANGE
    this.previousMonth.month(this.selectedDate.month() - 1)
    this.previousMonth = this.dateToFormatFr(this.previousMonth);
    // DATE TO CHANGE
    this.nextMonth.month(this.selectedDate.month() + 1)
    this.nextMonth = this.dateToFormatFr(this.nextMonth);

    this.calendarDates = [
      this.previousMonth,
      this.selectedDate,
      this.nextMonth,
    ];
  }

  ngOnChanges(changes: SimpleChanges) {
    // detect @input changes
    const compOptions: SimpleChange = changes.compOptions;
    if (typeof compOptions !== 'undefined') {
      this.compOptions = compOptions.currentValue;
    }
    const content: SimpleChange = changes.content;
    if (typeof content !== 'undefined') {
      this.content = content.currentValue;
    }
    this.setGlobals();
  }

  async initPage() {
    this.student = this.studentService.student;
    this.userLifeCycle = this.studentService.getUserLifeCycle(this.student);
    this.setGlobals();

    this.showBloc = 1;
    this.initSlider();
    if (this.userLifeCycle.isUserCanDrive || this.userLifeCycle.isUserDrivingSuccess) {
      this.fetchLessons();
    }
    this.initPageDone = true;
  }

  fetchLessons() {
    // DATE TO CHANGE
    const today = this.dateToFormatFr();
    const getConfirmedLessons = this.lessonsService.confirmedLessons;
    if (!!getConfirmedLessons && !!getConfirmedLessons.errorCode && getConfirmedLessons.errorCode === 'E301') {
      this.fetchLessonFailed = true;
    } else {
      this.lessons = getConfirmedLessons;
    }
    this.initSlider();
  }

  dateToFormatFr(input?: any) {
    // console.log('-------------------');
    // console.log('dateToFormatFr input', input, typeof input);
    let output = this.dateToMoment(); // today by default
    if (!!input) {
      output = this.dateToMoment(input);
      if (typeof input === 'string') {
        output = this.dateToMoment(input);
        // if (!input.includes('+')) {
        //   console.log('input string', input);
        // }
        if (input.includes('/')) {
          console.error('input string', input);
        }
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'number') {
        // timestamp
        output = this.dateToMoment(input);
        // console.log('input timestamp', input);
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'object') {
        if (!moment.isMoment(input) && !!input.year && !!input.month && !!input.day) {
          // custom object
          const year = input.year;
          const month = (Number(input.month) < 10) ? '0' + Number(input.month) : Number(input.month);
          const day = (input.day < 10) ? '0' + input.day : input.day;
          const str = year + '-' + month + '-' + day + ' 00:00:00';
          output = this.dateToMoment(str);
          // console.log('input custom object', input, str);
          // console.log('output', output);
          // console.log('-------------------');
        } else {
          // date object
          output = this.dateToMoment(input);
          // console.log('input date', input, input.isValid());
          // console.log('output', output);
        }
      }
    }
    if (!output.isValid()) {
      console.log('-------------------');
      console.error('dateToFormatFr input', input, typeof input);
      console.error('dateToFormatFr output', output, output.toString(), output.isValid());
      console.error('-------------------');
    }
    // console.log('dateToFormatFr output', output, output.toString(), output.isValid());
    // console.log('-------------------');
    return output;
  }

  dateToMoment(input?: any) {
    const zone = "Europe/Paris";
    moment.tz.setDefault(zone);
    moment.locale('fr');
    if (!!input) {
      return moment(input).tz(zone);
    }
    return moment().tz(zone);
  }

  initSlider() {
    if (!!this.calendarScreens) {
      this.marginLeft = (this.calendarSize * -1);
      this.renderer.setStyle(
        this.calendarScreens.nativeElement,
        'margin-left',
        this.marginLeft + 'px'
      );
      setTimeout(() => {
        this.renderer.setStyle(
          this.calendarScreens.nativeElement,
          'transition',
          'margin-left .3s ease-in-out'
        );
      }, 40);
    } else {
      setTimeout(() => {
        this.initSlider();
      }, 40);
    }
  }

  setGlobals() {
    this.calledFrom = (this.compOptions && typeof this.compOptions.calledFrom !== 'undefined') ? this.compOptions.calledFrom : 'dashboard';
    this.isIpad = (this.compOptions && typeof this.compOptions.isIpad !== 'undefined') ? this.compOptions.isIpad : false;
    this.calendarSize = (this.isIpad) ? 324 : 435;
    this.initSlider();
  }

  updateSlider(sens) {
    if (sens > 0) {
      // DATE TO CHANGE
      this.selectedDate.month(this.selectedDate.month() + 1)
      this.selectedDate = this.dateToFormatFr(this.selectedDate);
      this.marginLeft -= this.calendarSize;
    } else if (sens < 0) {
      // DATE TO CHANGE
      this.selectedDate.month(this.selectedDate.month() - 1)
      this.selectedDate = this.dateToFormatFr(this.selectedDate);
      this.marginLeft += this.calendarSize;
    } else {
      // today
      // DATE TO CHANGE
      this.selectedDate = this.dateToFormatFr();
    }
    this.month = this.selectedDate.month(); // 0-11
    this.year = this.selectedDate.year(); // 2018

    this.first_date = this.year + '-' + (this.month + 1) + '-0' + 1;
    // DATE TO CHANGE
    this.tmp = this.dateToFormatFr(this.first_date).toString();
    this.first_day = this.tmp.substring(0, 3);
    this.day_name = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    this.day_no = this.day_name.indexOf(this.first_day);
    // DATE TO CHANGE
    this.nbDay = this.dateToFormatFr(new Date(this.year, this.month + 1, 0)).date();

    // DATE TO CHANGE
    this.previousMonth.month(this.selectedDate.month() - 1)
    this.previousMonth = this.dateToFormatFr(this.previousMonth);
    // DATE TO CHANGE
    this.nextMonth.month(this.selectedDate.month() + 1)
    this.nextMonth = this.dateToFormatFr(this.nextMonth);

    this.renderer.setStyle(
      this.calendarScreens.nativeElement,
      'margin-left',
      this.marginLeft + 'px'
    );

    setTimeout(() => {
      this.renderer.removeStyle(
        this.calendarScreens.nativeElement,
        'transition',
      );
      setTimeout(() => {
        this.calendarDates = [
          this.previousMonth,
          this.selectedDate,
          this.nextMonth,
        ];
        this.initSlider();
      }, 20);
    }, this.sliderAnmationDuration);
  }

  async openModalIframe(url: any, type?: string, noClose = true) {
    if (!type) {
      type = 'iframe';
    }
    let modal;
    if (noClose) {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        backdropDismiss: false,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          'type': type,
          'url': url,
        },
      });
    } else {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          'type': type,
          'url': url,
        },
      });
    }
    return await modal.present();
  }

  async openLink(link, options?) {
    const callbackUrl = await this.navigationService.open(link, options);
    if (!!callbackUrl && callbackUrl !== '') {
      this.openModalIframe(callbackUrl);
    }
  }


}
