//import { EvsRecord } from './evsRecord';
import { GiftModel } from './gift';
import { PaymentModel } from './payment';
import { CouponModel } from './coupon';
import { OfferTypes, OfferModel } from './offer';
import { CPFOfferTypes, CPFSubscriptionModel } from './CPFSubscription';

export interface IStudentPurshasesDetails {
  gifts: Array<GiftModel>;
  payments: Array<PaymentModel>;
  coupons: Array<CouponModel>;
  offers: Array<OfferModel>;
  studentCustomOffers: Array<OfferModel>;
  cpfSubscriptions: Array<CPFSubscriptionModel>
}

export class StudentPurshasesDetails implements IStudentPurshasesDetails {

  private _gifts: Array<GiftModel>;
  private _payments: Array<PaymentModel>;
  private _coupons: Array<CouponModel>;
  private _offers: Array<any>;
  private _studentCustomOffers: Array<any>;
  private _cpfSubscriptions: Array<CPFSubscriptionModel>

  constructor(obj: IStudentPurshasesDetails) {
    this._gifts = obj.gifts;
    this._coupons = obj.coupons;
    this._payments = obj.payments;
    this._offers = obj.offers;
    this._studentCustomOffers = obj.studentCustomOffers;
    this._cpfSubscriptions = obj.cpfSubscriptions;
  }

  hasCode() {
    // ici on devrait parser les différents types de paiements
    // pour déterminer si il a accès au feature du code ou pas
    // et return juste true / false
  }

  hasHours(): boolean {
    return (this.paymentsNotFullyRefunded(this.paymentsByOfferType(OfferTypes.BigPack)).length > 0
      || this.couponsByOfferType(OfferTypes.BigPack).length > 0
      || this.paymentsNotFullyRefunded(this.paymentsByOfferType(OfferTypes.Hour)).length > 0
      || this.paymentsNotFullyRefunded(this.paymentsByOfferType(OfferTypes.HourPack)).length > 0
      || this.couponsByOfferType(OfferTypes.Hour).length > 0
      || this.couponsByOfferType(OfferTypes.HourPack).length > 0
      || this.CPFSubscriptionByCPFOfferType(CPFOfferTypes.PackHours).length > 0
      || this.CPFSubscriptionByCPFOfferType(CPFOfferTypes.ETGPackHours).length > 0
      || this.giftsWithHours().length > 0);
  }

  isHourOnly(): boolean {
    return (this.paymentsNotFullyRefunded(this.paymentsByOfferType(OfferTypes.Hour)).length > 0
      || this.paymentsNotFullyRefunded(this.paymentsByOfferType(OfferTypes.HourPack)).length > 0
      || this.couponsByOfferType(OfferTypes.Hour).length > 0
      || this.couponsByOfferType(OfferTypes.HourPack).length > 0)
      && (this.paymentsNotFullyRefunded(this.paymentsByOfferType(OfferTypes.BigPack)).length === 0
        || this.couponsByOfferType(OfferTypes.BigPack).length === 0)
      && this.CPFSubscriptionByCPFOfferType(CPFOfferTypes.PackHours).length === 0
      && this.CPFSubscriptionByCPFOfferType(CPFOfferTypes.ETGPackHours).length === 0;
  }

  isPackCode(): boolean {
    return this.paymentsNotFullyRefunded(this.paymentsByOfferType(OfferTypes.Code)).length > 0
      || this.couponsByOfferType(OfferTypes.Code).length > 0
      || this.giftsWithCode().length > 0;
  }

  isPackCodePremium(): boolean {
    return (this.paymentsNotFullyRefunded(this.paymentsByOfferType(OfferTypes.CodePremium)).length > 0
      || this.CPFSubscriptionByCPFOfferType(CPFOfferTypes.ETGPackHours).length > 0
      || this.couponsByOfferType(OfferTypes.CodePremium).length > 0);
  }

  isPackPermis(): boolean {
    return this.paymentsNotFullyRefunded(this.paymentsByOfferType(OfferTypes.BigPack)).length > 0
      || this.couponsByOfferType(OfferTypes.BigPack).length > 0
      || this.CPFSubscriptionByCPFOfferType(CPFOfferTypes.PackHours).length > 0
      || this.CPFSubscriptionByCPFOfferType(CPFOfferTypes.ETGPackHours).length > 0;
  }

  anyPurshases(): boolean {
    return this.paymentsNotFullyRefunded(this._payments).length > 0
      || this._gifts.length > 0
      || this._coupons.length > 0
      || this._cpfSubscriptions.length > 0;
  }

  canZendesk(): boolean {
    return (this.paymentsNotFullyRefunded(this.paymentsByOfferType(OfferTypes.BigPack)).length > 0
    || this.couponsByOfferType(OfferTypes.BigPack).length > 0
    || this.paymentsNotFullyRefunded(this.paymentsByOfferType(OfferTypes.Code)).length > 0
    || this.couponsByOfferType(OfferTypes.Code).length > 0
    || this.giftsWithCode().length > 0
    || this.paymentsNotFullyRefunded(this.paymentsByOfferType(OfferTypes.CodePremium)).length > 0
    || this.couponsByOfferType(OfferTypes.CodePremium).length > 0
    || this.paymentsNotFullyRefunded(this.paymentsByOfferType(OfferTypes.Hour)).length > 0
    || this.paymentsNotFullyRefunded(this.paymentsByOfferType(OfferTypes.HourPack)).length > 0
    || this.couponsByOfferType(OfferTypes.Hour).length > 0
    || this.couponsByOfferType(OfferTypes.HourPack).length > 0
    || this.CPFSubscriptionByCPFOfferType(CPFOfferTypes.PackHours).length > 0
    || this.CPFSubscriptionByCPFOfferType(CPFOfferTypes.ETGPackHours).length > 0
    || this.giftsWithHours().length > 0);
  }

  linkOfferTopayment() {
    for (const payment of this._payments) {
      // offer_id in payment not exists in offers (obfuscated i think)
      const offer = this._offers.find(off => Number(off.id) === Number(payment.offer_id));
      if (!!offer) {
        payment.offer_associated = offer;
      }
    }
  }

  removeCouponsIfPresentInPayments() {
    for (const payment of this._payments) {
      let coupon = null;
      // CHECK IF COUPONS EXISTS ON PLAIN PAYEMENTS
      if (!!payment.coupons && !!payment.coupons.id) {
        coupon = this._coupons.find(coup => Number(coup.id) === Number(payment.coupons.id));
      }
      // CHECK IF COUPONS EXISTS ON PAYEMENTS PLANS
      if (!!payment?.payment_plan_user?.data?.attributes?.coupons?.id) {
        coupon = this._coupons.find(coup => Number(coup.id) === Number(payment.payment_plan_user.data.attributes.coupons.id));
      }
      if (!!coupon) {
        const index = this._coupons.indexOf(coupon);
        if (index !== -1) {
          this._coupons.splice(index, 1);
        }
      }
    }
  }

  //////////////// GETTERS //////////////////
  get gifts(): Array<GiftModel> { return this._gifts; }
  get payments(): Array<PaymentModel> { return this._payments; }
  get coupons(): Array<CouponModel> { return this._coupons; }
  get offers(): any { return this._offers; }
  get studentCustomOffers(): any { return this._studentCustomOffers; }
  get cpfSubscriptions(): Array<CPFSubscriptionModel> { return this._cpfSubscriptions; }

  get nbHoursBougth(): number { return this.nbGiftedHours + this.nbCouponsHours + this.nbPaidHours + this.nbCPFSubscriptionsHours; }

  get nbGiftedHours(): number { return this._gifts.reduce((sum, current) => sum + current.credits, 0); }
  get nbCouponsHours(): number { return this._coupons.reduce((sum, current) => sum + current.free_credits, 0); }
  get nbCPFSubscriptionsHours(): number { return this._cpfSubscriptions.reduce((sum, current) => sum + current.credits, 0); }

  get nbPaidHours(): number {
    let sum = 0;
    for (const payment of this._payments) {
      const offer = this._offers.find(off => Number(off.id) === Number(payment.offer_id));
      if (!!offer || offer.offer_type !== payment.offer_type) {
        console.error(`Bought offer type ${offer.offer_type} (id: ${offer.id}) does not match payment offer type ${payment.offer_type} (id: ${payment.id}`);
      }

      switch (payment.offer_type) {
        case (OfferTypes.BigPack): { sum += offer.credits; break; }
        case (OfferTypes.Code): { break; } // No driving lesson
        case (OfferTypes.CodeExam): { break; } // No driving lesson
        case (OfferTypes.DrivingExam): { break; } // No driving lesson
        case (OfferTypes.Eval): { console.error('Eval offer not implemented'); break; } // This is not used anymore
        case (OfferTypes.Hour): { sum += payment.quantity; break; }
        case (OfferTypes.HourPack): { sum += offer.min_quantity * payment.quantity; break; }
        case (OfferTypes.SmallPack): { console.error('Small pack not implemented'); break; }
        default: { console.error(`${payment.offer_type} not implemented`); break; }
      }
    }
    return sum;
  }

  private couponsByOfferType(offerType: OfferTypes): Array<CouponModel> {
    return this._coupons.filter(coup => coup.offer_type === offerType);
  }

  private paymentsByOfferType(offerType: OfferTypes): Array<PaymentModel> {
    return this._payments.filter(pay => pay.offer_type === offerType);
  }

  private paymentsNotFullyRefunded(payments: Array<PaymentModel>): Array<PaymentModel> {
    return payments.filter((pay) => {
      if (!pay.refunds || pay.refunds.length === 0) return true;

      const paidTotal = pay.amount;
      let refundTotal = 0;

      refundTotal = pay.refunds.reduce((acc, curr) => acc + curr.amount, 0);

      if (refundTotal === paidTotal) return false;

      return true;
    });
  }

  private CPFSubscriptionByCPFOfferType(CPFOfferType: CPFOfferTypes): Array<CPFSubscriptionModel> {
    return this._cpfSubscriptions.filter(cpfsub => cpfsub.offer_type === CPFOfferType);
  }

  private giftsWithHours(): Array<GiftModel> {
    return this._gifts.filter(gg => !!gg.credits);
  }

  private giftsWithCode(): Array<GiftModel> {
    return this._gifts.filter(gg => gg.code);
  }
}
