import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';

import { Initialable } from './app-init.service';
import { NetworkStatusService } from './network-status.service';

import { environment } from '../../environments/environment';
import { UserErrorHandlerService } from './user-error-handler.service';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@Injectable({ providedIn: 'root' })
@Initialable({ step: 'init3', initializer: 'onInit' })
@EasyDebugDecorator
export class ExamenConduiteService {

  constructor(
    private http: HttpClient,
    private networkService: NetworkStatusService,
    private userErrorHandlerService: UserErrorHandlerService,
  ) {

  }

  async onInit() {
    // console.log('ExamConduite done');
    return 'ExamConduite done';
  }

  async fetchDrivingExamenById(drivingExamId: string): Promise<any> {
    let url: string;

    if (this.networkService?.isOffline()) {
      return of(null).toPromise();
    }

    // url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${accountId}/theory_tests`;
    url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/driving_exam/${drivingExamId}`;

    return this.http.get(url).pipe(
      switchMap(data => {
        return of(data);
      }),
      catchError(
        async err => {
          this.userErrorHandlerService.addError({criticity: 40, service: 'fetchDrivingExamenById', platform: 'both', data: err, errorCode: 'ecsfdebi'});
          return { errorCode: 'E301', errorMessage: 'DrivingExam Service failed', errorOriginal: err};
        }
      )
    ).toPromise();
  }
}
