<ng-container>
  <div class="CER-wrapper" #codeExamReservationCalendar>
    <h4 *ngIf="isDesktop"><span class="CER-month-scribble">&nbsp;&nbsp;{{currentMonth}}&nbsp;&nbsp;</span></h4>
    <h5 *ngIf="!isDesktop"><span class="CER-month-scribble">&nbsp;&nbsp;{{currentMonth}}&nbsp;&nbsp;</span></h5>
    <div class="CER-day-picker">
      <div *ngIf="isDesktop" class="circle-btn" (click)="changeWeekEvent('previous')">
        <img [src]="'assets/2022/icons/arrow-left-2-on.svg'" alt="EnvoitureSimone">
      </div>
      <div class="CER-day" [ngClass]="{'selected-day': selectedDay === day.fullDate}" *ngFor="let day of currentWeekDays" (click)="selectDay(day)">
        <p>{{day.dayName}}</p>
        <h6>{{day.dateNumber}}</h6>
      </div>
      <div *ngIf="isDesktop" class="circle-btn" (click)="changeWeekEvent('next')">
        <img [src]="'assets/2022/icons/arrow-right-2-on.svg'" alt="EnvoitureSimone">
      </div>
    </div>
    <div class="CER-available-hours">
      <ng-container *ngIf="!selectedDay">
        <p class="no-session">Sélectionne un jour</p>
      </ng-container>
      <ng-container *ngIf="!!selectedDay && !selectedDaySessions">
        <p class="no-session">Aucun créneau disponible ce jour</p>
      </ng-container>
      <ng-container *ngIf="!!selectedDay && !!selectedDaySessions">
        <p class="session small-clickable" *ngFor="let session of selectedDaySessions" (click)="selectSessionEvent(session)">{{session.formattedHour}}</p>
      </ng-container>
    </div>
  </div>
</ng-container>