import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  OnInit,
  ElementRef,
  HostListener,
  ViewChild,
  Renderer2,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  AfterViewInit,
} from '@angular/core';
import { NavController } from '@ionic/angular';
import { UtilsService } from '../../services/utils.service';

@Component({
  selector: 'calendar-comp',
  templateUrl: 'calendar.html',
  styleUrls: ['calendar.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class CalendarComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() compOptions: any;
  @Output() dateSelected = new EventEmitter<any>();
  @ViewChild('evsCalendar') evsCalendar: any;
  @ViewChild('hoursContainer') hoursContainer: any;
  @ViewChild('minutesContainer') minutesContainer: any;

  id = 'evs_calendar_' + this.utils.randomMinMax(10000, 50000);
  showBloc = 0;

  loaded = false;
  parent = null;
  customSize: any;

  calendarData = []; // used to populate html
  userDate: any;  // custom date passed by user
  userTime: any;  // custom time passed by user
  userText = 'Date sélectionnée'; // text of tooltip

  editable = false; // calendar editable
  editType = 'date'; // date | time | datetime

  userSelectedDate = false;

  hoursData = [12, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  minutesData = ['00', '05', 10, 15, 20, 25, 30, 35, 40, 45, 50, 55];

  showYear = false;
  calendarYears = [];

  showTime = false;
  showHours = true;
  timeIsAm = false;
  showMinutes = false;
  calendarTime = [];
  hourSelected: any;
  minuteSelected: any;
  selectedTime = '00:00';

  caseWidth = 0;

  months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
  days = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

  forNbJours = 35;

  today = new Date();
  todayDay = this.today.getDate(); // 0-31
  todayMonth = this.today.getMonth(); // 0-11
  todayYear = this.today.getFullYear(); // 2018
  selectedDate = new Date();
  month = this.selectedDate.getMonth(); // 0-11
  year = this.selectedDate.getFullYear(); // 2018

  firstDate = (this.month + 1) + '-' + 1 + '-' + this.year;
  tmp = new Date(this.firstDate).toDateString();
  firstDay = this.tmp.substring(0, 3);
  dayName = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  dayNo = this.dayName.indexOf(this.firstDay);
  nbDay = new Date(this.year, this.month + 1, 0).getDate();

  lessons = [];

  myTimeout = null;

  constructor(
    private cdr: ChangeDetectorRef,
    private navController: NavController,
    public utils: UtilsService,
    private el: ElementRef,
    private renderer: Renderer2,
  ) {
    this.setForNbJours();
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.setCaseWidth();
  }

  ngOnChanges(changes: SimpleChanges) {
    // detect @input changes
    const compOptions: SimpleChange = changes.compOptions;
    if (this.utils.isDefined(compOptions)) {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  ngOnInit() {
    this.setGlobals();
  }

  ngAfterViewInit() {
    if (this.utils.isDefined(this.evsCalendar) && this.utils.isDefined(this.customSize) && this.customSize > 0) {
      if (this.utils.isDefined(this.parent)) {
        const posXParent = this.parent.getBoundingClientRect().x + 10; // 10 = margin
        const screenWidth = window.innerWidth;
        this.customSize = ((this.customSize + posXParent) > screenWidth) ? (screenWidth - posXParent) : this.customSize;
        this.setCaseWidth();
      }
      this.renderer.setStyle(
        this.evsCalendar.nativeElement,
        'width',
        this.customSize + 'px'
      );
    }
  }

  setGlobals() {
    this.id = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.id)) ? this.compOptions.id : this.id;
    this.customSize = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.size)) ? this.compOptions.size : this.customSize;
    this.loaded = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.loaded)) ? this.compOptions.loaded : this.loaded;
    this.parent = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.parent)) ? this.compOptions.parent : this.parent;
    this.editable = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.editable)) ? this.compOptions.editable : this.editable;
    this.editType = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.type)) ? this.compOptions.type : this.editType;
    this.userDate = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.date)) ? this.compOptions.date : this.userDate;
    this.userText = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.text)) ? this.compOptions.text : this.userText;

    this.customSize = (this.loaded) ? 300 : (this.customSize > 300) ? 300 : this.customSize; // customSize <= 300 or 300 if loaded = true
    this.showTime = (this.editType === 'time') ? true : false;
    this.setCaseWidth();
    this.showBloc = 1;
  }

  setCaseWidth() {
    const eltRef = (this.utils.isDefined(this.customSize) && this.customSize > 0) ? this.customSize : this.el.nativeElement.parentNode.offsetWidth;
    this.caseWidth = Math.floor((Math.floor(eltRef / 7) / 6) * 5); // 7 nbJours
    if (this.caseWidth === 0) {
      this.myTimeout = setTimeout(() => {
        this.setCaseWidth();
      });
    } else {
      clearTimeout(this.myTimeout);
      if (this.utils.isDefined(this.userDate)) {
        this.setDate(this.userDate);
      } else {
        this.setCalendarData();
      }
    }
  }

  setEditableDate(date) {
    if (this.editable) {
      const cleanDate = (this.utils.isDefined(date)) ? this.utils.dateFr(date) : '';
      this.userDate = new Date(cleanDate);
      this.userSelectedDate = true;
      this.setDate(date);
    }
  }

  setDate(date?) {
    const cleanDate = (this.utils.isDefined(date)) ? this.utils.dateFr(date) : '';
    this.selectedDate = (cleanDate !== '') ? new Date(cleanDate) : new Date();

    this.month = this.selectedDate.getMonth(); // 0-11
    this.year = this.selectedDate.getFullYear(); // 2018

    this.firstDate = (this.month + 1) + '-' + 1 + '-' + this.year;
    this.tmp = new Date(this.firstDate).toDateString();
    this.firstDay = this.tmp.substring(0, 3);
    this.dayName = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    this.dayNo = this.dayName.indexOf(this.firstDay);
    this.nbDay = new Date(this.year, this.month + 1, 0).getDate();
    if (this.editType === 'date') {
      this.setForNbJours();
      this.setCalendarData();
      let dateToSend = '';
      // if date => send too
      if (this.utils.isDefined(this.userDate)) {
        if (this.userDate instanceof Date) {
          this.userDate = (this.userDate.getMonth() + 1) + '-' + this.userDate.getDate() + '-' +  this.userDate.getFullYear();
        }
        const cleanDateToSend = (this.utils.isDefined(this.userDate)) ? this.utils.dateFr(this.userDate) : '';
        dateToSend += cleanDateToSend;
      }
      this.dateSelected.emit(dateToSend);
    } else if (this.editType === 'datetime') {
      this.showTime = true;
      this.showHours = true;
      this.showMinutes = false;
    } else {
      console.error('UIKIT CalendarComponent: Calendar editable type unknown!');
    }
  }

  setCalendarData() {
    let i = 0;
    this.calendarData = [];
    for (const day of this.utils.numberToArray(this.forNbJours)) {
      this.calendarData.push(
        {
          day: (i < this.dayNo) ? '' : (i >= this.dayNo && this.checkIndex(i)) ? (i + 1 - this.dayNo) : '',
          class: this.addClasses(i),
          tooltip: (this.editable && this.editType !== 'datetime') ? {} : this.populateTooltip(i),
        }
      );
      i++;
    }
    this.showBloc = 1;
    // Say to angular to apply new Controller vars to View
    this.cdr.detectChanges();
  }

  checkIndex(value) {
    return (Number(value + 1 - this.dayNo) <= this.nbDay) ? true : false;
  }

  setForNbJours() {
    this.forNbJours = Math.ceil((this.nbDay + this.dayNo) / 7) * 7;
  }

  prevNext(sens) {
    // YEARS
    if (this.editable && this.showYear) {
      if (sens > 0) {
        this.setYearsData(this.calendarYears[this.calendarYears.length - 1].year + (this.calendarYears[this.calendarYears.length - 1].year - this.calendarYears[0].year));
      } else if (sens < 0) {
        this.setYearsData(this.calendarYears[0].year);
      } else {
      // TODAY
        this.showYear = false;
        this.selectedDate = new Date();
      }
    } else {
    // MONTHS
      if (sens > 0) {
        this.selectedDate.setMonth(this.selectedDate.getMonth() + 1);
      } else if (sens < 0) {
        this.selectedDate.setMonth(this.selectedDate.getMonth() - 1);
      } else {
      // TODAY
        this.showYear = false;
        this.selectedDate = new Date();
      }
      this.month = this.selectedDate.getMonth(); // 0-11
      this.year = this.selectedDate.getFullYear(); // 2018

      this.firstDate = (this.month + 1) + '-' + 1 + '-' + this.year;
      this.tmp = new Date(this.firstDate).toDateString();
      this.firstDay = this.tmp.substring(0, 3);
      this.dayName = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
      this.dayNo = this.dayName.indexOf(this.firstDay);
      this.nbDay = new Date(this.year, this.month + 1, 0).getDate();
      this.setForNbJours();
      this.setCalendarData();
    }
  }

  changeYear(year) {
    this.selectedDate.setFullYear(year);

    this.month = this.selectedDate.getMonth(); // 0-11
    this.year = this.selectedDate.getFullYear(); // 2018

    this.firstDate = (this.month + 1) + '-' + 1 + '-' + this.year;
    this.tmp = new Date(this.firstDate).toDateString();
    this.firstDay = this.tmp.substring(0, 3);
    this.dayName = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
    this.dayNo = this.dayName.indexOf(this.firstDay);
    this.nbDay = new Date(this.year, this.month + 1, 0).getDate();

    this.userDate = new Date(this.selectedDate);

    this.toggleYear();
    this.setForNbJours();
    this.setCalendarData();
  }

  toggleYear() {
    if (this.editable) {
      this.userSelectedDate = false;
      this.showYear = !this.showYear;
      if (this.showYear) {
        this.setForNbJours();
        this.setYearsData(this.year);
      } else {
        this.setForNbJours();
        this.setCalendarData();
      }
    }
  }

  setYearsData(year) {
    this.calendarYears = [];
    for (let j = 24; j > 0; j--) { // 30 = 4(items) * 6(lines)
      this.calendarYears.push(
        {
          year: year - (j - 1),
        }
      );
    }
  }

  addClasses(value) {
    const classes: any = {};
    // check lessons
    const isLesson = '';
    if (this.lessons.length > 0) {
      for (const lesson of this.lessons) {
        const lessonDate = new Date(lesson.starts_at);
        const lessonDay = lessonDate.getDate(); // 0-31
        const lessonMonth = lessonDate.getMonth(); // 0-11
        const lessonYear = lessonDate.getFullYear(); // 2018
        if ((lessonMonth + '-' + lessonDay + '-' + lessonYear) === (this.month + '-' + (value + 1 - this.dayNo) + '-' + this.year)) {
          if (lesson.status === 'awaiting confirmation' || lesson.status === 'awaiting student confirmation') {
            if (lessonDate < this.today) {
              if (lesson.driving_exam_id !== null) {
                classes.pastWaitingExam = true;
              } else {
                classes.pastWaitingLesson = true;
              }
            } else {
              if (lesson.driving_exam_id !== null) {
                classes.waitingExam = true;
              } else {
                classes.waitingLesson = true;
              }
            }
          }
          if (lesson.status === 'confirmed') {
            if (lessonDate < this.today) {
              if (lesson.driving_exam_id !== null) {
                classes.pastConfirmedExam = true;
              } else {
                classes.pastConfirmedLesson = true;
              }
            } else {
              if (lesson.driving_exam_id !== null) {
                classes.confirmedExam = true;
              } else {
                classes.confirmedLesson = true;
              }
            }
          }
          if (
            lesson.status === 'cancelled by student not due' ||
            lesson.status === 'cancelled by student after deadline' ||
            lesson.status === 'cancelled by teacher' ||
            lesson.status === 'cancelled by admin after deadline' ||
            lesson.status === 'cancelled by admin not due' ||
            lesson.status === 'cancelled by admin not due' ||
            lesson.status === 'declined' ||
            lesson.status === 'declined by student'
          ) {
            if (lessonDate < this.today) {
              if (lesson.driving_exam_id !== null) {
                classes.pastCancelledExam = true;
              } else {
                classes.pastCancelledLesson = true;
              }
            } else {
              if (lesson.driving_exam_id !== null) {
                classes.cancelledExam = true;
              } else {
                classes.cancelledLesson = true;
              }
            }
          }
        }
      }
    }
    // add today only if no event for the date
    if (Object.keys(classes).length === 0) {
      if (!this.editable && (this.todayMonth + '-' + this.todayDay + '-' + this.todayYear) === (this.month + '-' + (value + 1 - this.dayNo) + '-' + this.year)) {
        classes.today = true;
      }
      // convert Date Obj to string
      if (this.userDate instanceof Date) {
        this.userDate = (this.userDate.getMonth() + 1) + '-' + this.userDate.getDate() + '-' +  this.userDate.getFullYear();
      }
      const cleanDate = (this.utils.isDefined(this.userDate)) ? this.utils.dateFr(this.userDate) : '';
      if ((this.userSelectedDate && this.utils.isSameDate(cleanDate, ((this.month + 1) + '-' + (value + 1 - this.dayNo) + '-' + this.year))) || this.utils.isSameDate(cleanDate, ((this.month + 1) + '-' + (value + 1 - this.dayNo) + '-' + this.year))) {
        classes.selectedDate = true;
      }
    }
    return classes;
  }

  populateTooltip(value) {
    let tooltipText = '';
    // check lessons
    const isLesson = '';
    for (const lesson of this.lessons) {
      const lessonDate = new Date(lesson.starts_at);
      const lessonDay = lessonDate.getDate(); // 0-31
      const lessonMonth = lessonDate.getMonth(); // 0-11
      const lessonYear = lessonDate.getFullYear(); // 2018
      if ((lessonMonth + '-' + lessonDay + '-' + lessonYear) === (this.month + '-' + (value + 1 - this.dayNo) + '-' + this.year)) {
        if (lesson.status === 'awaiting confirmation' || lesson.status === 'awaiting student confirmation') {
          tooltipText = 'En attente de confirmation';
        }
        if (lesson.status === 'confirmed') {
          tooltipText = 'Leçon confirmée';
        }
        if (lesson.status === 'cancelled by student not due') {
          tooltipText = 'Leçon annulée par l\'élève avant la date limite';
        }
        if (lesson.status === 'cancelled by student after deadline') {
          tooltipText = 'Leçon annulée par l\'élève après la date limite';
        }
        if (lesson.status === 'cancelled by teacher') {
          tooltipText = 'Leçon annulée par l\'enseignant';
        }
        if (lesson.status === 'cancelled by admin after deadline') {
          tooltipText = 'Leçon annulée par l\'administrateur après la date limite';
        }
        if (lesson.status === 'cancelled by admin not due') {
          tooltipText = 'Leçon annulée par l\'administrateur avant la date limite';
        }
        if (lesson.status === 'declined') {
          tooltipText = 'Leçon refusée par l\'enseignant';
        }
        if (lesson.status === 'declined by student') {
          tooltipText = 'Leçon refusée par l\'élève';
        }
      }
    }
    if (tooltipText === '') {
      // check today
      if ((this.todayMonth + '-' + this.todayDay + '-' + this.todayYear) === (this.month + '-' + (value + 1 - this.dayNo) + '-' + this.year)) {
        tooltipText = 'Aujourd\'hui';
      }
      // convert Date Obj to string
      if (this.userDate instanceof Date) {
        this.userDate = (this.userDate.getMonth() + 1) + '-' + this.userDate.getDate() + '-' +  this.userDate.getFullYear();
      }
      const cleanDate = (this.utils.isDefined(this.userDate)) ? this.utils.dateFr(this.userDate) : '';
      if ((this.userSelectedDate && this.utils.isSameDate(cleanDate, ((this.month + 1) + '-' + (value + 1 - this.dayNo) + '-' + this.year))) || this.utils.isSameDate(cleanDate, ((this.month + 1) + '-' + (value + 1 - this.dayNo) + '-' + this.year))) {
        tooltipText = this.userText;
      }
    }
    return tooltipText;
  }

  rotateHours(hour) {
    hour = (hour === 12) ? 0 : hour;
    if (this.utils.isDefined(this.hoursContainer)) {
      this.renderer.setStyle(
        this.hoursContainer.nativeElement,
        'transform',
        'rotate(' + (hour * 30) + 'deg)'
      );
    }
  }

  rotateMinutes(minute) {
    minute = (minute === 12) ? 0 : minute;
    if (this.utils.isDefined(this.minutesContainer)) {
      this.renderer.setStyle(
        this.minutesContainer.nativeElement,
        'transform',
        'rotate(' + (minute * 30) + 'deg)'
      );
    }
  }

  setAmPm(type) {
    this.timeIsAm = (type === 'am') ? true : false;
  }

  setHours(hour) {
    hour = (Number(hour) < 10) ? '0' + Number(hour) : hour;
    this.hourSelected = (!this.timeIsAm) ? (Number(hour) + 12) : hour;
    this.showHours = false;
    this.showMinutes = true;
  }

  setMinutes(minutes) {
    if (Number(this.hourSelected) === 12 && minutes > 0) {
      this.timeIsAm = false;
      this.hourSelected = (Number(this.hourSelected - 12) === 0) ? '00' : Number(this.hourSelected) - 12;
    }
    this.hourSelected = (Number(this.hourSelected) < 10) ? '0' + Number(this.hourSelected) : this.hourSelected; // 1:15 become 01:15
    this.minuteSelected = (minutes < 10) ? '0' + Number(minutes) : minutes;
    if (!this.loaded) {
      if (this.editType !== 'time') {
        this.showHours = false;
        this.showMinutes = false;
        this.showTime = false;
      } else {
        this.timeIsAm = false;
        this.showHours = true;
        this.showMinutes = false;
        this.showTime = true;
      }
    }
    this.selectedTime = this.hourSelected + ':' + this.minuteSelected;
    let dateToSend = '';
    // if date => send too
    if (this.utils.isDefined(this.userDate)) {
      if (this.userDate instanceof Date) {
        this.userDate = (this.userDate.getMonth() + 1) + '-' + this.userDate.getDate() + '-' +  this.userDate.getFullYear();
      }
      const cleanDate = (this.utils.isDefined(this.userDate)) ? this.utils.dateFr(this.userDate) : '';
      dateToSend += cleanDate;
    }
    // if time => send too
    if (this.utils.isDefined(this.selectedTime)) {
      dateToSend += (dateToSend === '') ? this.selectedTime : ' ' + this.selectedTime;
    }
    // reset
    this.hourSelected = null;
    this.minuteSelected = null;
    this.rotateHours(0);
    this.rotateMinutes(0);
    // send
    this.dateSelected.emit(dateToSend);
  }

  openLink(link) {
    if (this.utils.isDefined(link)) {
      this.navController.navigateForward(link);
    } else {
      this.navController.back();
    }
  }


}
