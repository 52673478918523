import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {

  randomMinMax(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  preventDefault(event) {
    if (window.event) {
      window.event.returnValue = false;
    } else if (event.preventDefault) {
      event.preventDefault();
    } else {
      event.returnValue = false;
    }
  }

  isDefined(elt) {
    if (elt == null || elt === null || typeof elt === 'undefined') {
      return false;
    }
    return true;
  }

  getFirstLetter(value) {
    return value.substring(0, 1);
  }

  numberToArray(length) {
    let i = 0;
    const tmpArray = [];
    for (let j = 0; j < length; j++) {
      tmpArray.push(i);
      i++;
    }
    return tmpArray;
  }

  isChildren(parent, node) {
    if (node !== parent) {
      let found = false;
      if (this.isDefined(parent) && this.isDefined(node)) {
        const children = parent.childNodes;
        for (const child of children) {
          if (child.hasChildNodes()) {
            if (this.isChildren(child, node)) {
              found = true;
              break;
            } else {
              continue;
            }
          } else {
            if (child === node) {
              found = true;
              break;
            }
          }
        }
      }
      return found;
    }
    return true;
  }

  isValidDate(d) {
    return !isNaN(Date.parse(d));
  }

  isSameDate(d1, d2) {
    const td1 = new Date(d1);
    const td2 = new Date(d2);
    return (td1.getTime() === td2.getTime());
  }

  dateFr(date) {
    date = date.replace(/\//g, '-');
    if (this.isDefined(date)) {
      if (!this.isValidDate(date)) {
        const datePart = date.match(/\d+/g);
        if (this.isDefined(datePart)) {
          const day = datePart[0];
          const month = datePart[1];
          const year = datePart[2];
          const cleanedDate = [month, day, year].join('-');
          if (this.isValidDate(cleanedDate)) {
            return cleanedDate;
          } else {
            return ''; // no date valid = no custom date to component
          }
        } else {
          return ''; // no date valid = no custom date to component
        }
      } else {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();
        if (month.length < 2) {
          month = '0' + month;
        }
        if (day.length < 2) {
          day = '0' + day;
        }
        return [month, day, year].join('-');
      }
    }
    return '';
  }

  unify(event) {
    return event.changedTouches ? event.changedTouches[0] : event;
  }

  addListenerMulti(element, eventNames, fn) {
    eventNames.split(' ').forEach(e => element.addEventListener(e, fn, false));
  }

  slugify(str: string) {
    const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;';
    const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnooooooooprrsssssttuuuuuuuuuwxyyzzz------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return str.toString().toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w\-]+/g, '') // Remove all non-word characters
      .replace(/\-\-+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, '') // Trim - from end of text
      ;
  }

  getPercent(value, total) {
    if (!!value && !!total) {
      return Math.floor((100 * Number(value) / total));
    } else {
      return value;
    }
  }

  ucfirst(str) {
    if (!!str && str.length > 0) {
      return str[0].toUpperCase() + str.substring(1);
    } else {
      return str;
    }
  }

}
