import { Injectable } from '@angular/core';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Injectable({
  providedIn: 'root',
})
export class NotEmptyService {

  notEmpty() {
    return true;
  }

}
