import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { Initialable } from './app-init.service';
import { NetworkStatusService } from './network-status.service';

import { environment } from '../../environments/environment';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@Injectable({ providedIn: 'root' })
@Initialable({ step: 'init3', initializer: 'onInit' })
@EasyDebugDecorator
export class MapTeacherService {

  constructor(
    private http: HttpClient,
    private networkService: NetworkStatusService,
  ) { }

  async onInit() {
    // console.log('MapTeacherService done');
    return 'MapTeacherService done';
  }

  async fetchLocations(): Promise<any> {
    if (this.networkService?.isOffline()) {
      return of(null).toPromise();
    }

    const url = `${environment.token_auth_config.apiBase}/v3/locations`;

    return this.http.get(url).pipe(
      switchMap((data: any) => {
        if (!!data && !!data.data) {
          return of(data.data);
        } else {
          return of(null);
        }
      }),
      catchError(
        async err => {
          return { errorCode: 'E301', errorMessage: 'MapTeacherService fetchLocations failed', errorOriginal: err};
        }
      )
    ).toPromise();
  }

  async fetchNearest(lng: any, lat: any, gearbox_type: any): Promise<any> {
    if (this.networkService?.isOffline()) {
      return of(null).toPromise();
    }

    const url = `${environment.token_auth_config.apiBase}/v3/locations/nearest?longitude=${lng}&latitude=${lat}&gearbox_type=${gearbox_type}`;

    return this.http.get(url).pipe(
      switchMap((data: any) => {
        if (!!data && !!data.data) {
          return of(data.data);
        } else {
          return of(null);
        }
      }),
      catchError(
        async err => {
          return { errorCode: 'E301', errorMessage: 'MapTeacherService fetchNearest failed', errorOriginal: err};
        }
      )
    ).toPromise();
  }

  async fetchAvaibility(id: any): Promise<any> {
    if (this.networkService?.isOffline()) {
      return of(null).toPromise();
    }

    const url = `${environment.token_auth_config.apiBase}/v3/availabilities/index_by_location/${id}`;

    return this.http.get(url).pipe(
      switchMap((data: any) => {
        if (!!data && !!data.data) {
          return of(data.data);
        } else {
          return of(null);
        }
      }),
      catchError(
        async err => {
          return { errorCode: 'E301', errorMessage: 'MapTeacherService fetchAvaibility failed', errorOriginal: err};
        }
      )
    ).toPromise();
  }

}
