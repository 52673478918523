import {
  environment
} from '@app/env';

import * as moment from 'moment-timezone';

export class EasyDebugLogger {
  private static easyDebugLogger: EasyDebugLogger;

  private _logs: Array<any> = [];
  private _isEasyDebugActive = false;
  private _getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key: any, value: any) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  }

  private constructor() { }

  dateToFormatFr(input?: any) {
    // console.log('-------------------');
    // console.log('dateToFormatFr input', input, typeof input);
    let output = this.dateToMoment(); // today by default
    if (!!input) {
      output = this.dateToMoment(input);
      if (typeof input === 'string') {
        output = this.dateToMoment(input);
        // if (!input.includes('+')) {
        //   console.log('input string', input);
        // }
        if (input.includes('/')) {
          console.error('input string', input);
        }
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'number') {
        // timestamp
        output = this.dateToMoment(input);
        // console.log('input timestamp', input);
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'object') {
        if (!moment.isMoment(input) && !!input.year && !!input.month && !!input.day) {
          // custom object
          const year = input.year;
          const month = (Number(input.month) < 10) ? '0' + Number(input.month) : Number(input.month);
          const day = (input.day < 10) ? '0' + input.day : input.day;
          const str = year + '-' + month + '-' + day + ' 00:00:00';
          output = this.dateToMoment(str);
          // console.log('input custom object', input, str);
          // console.log('output', output);
          // console.log('-------------------');
        } else {
          // date object
          output = this.dateToMoment(input);
          // console.log('input date', input, input.isValid());
          // console.log('output', output);
        }
      }
    }
    if (!output.isValid()) {
      console.log('-------------------');
      console.error('dateToFormatFr input', input, typeof input);
      console.error('dateToFormatFr output', output, output.toString(), output.isValid());
      console.error('-------------------');
    }
    // console.log('dateToFormatFr output', output, output.toString(), output.isValid());
    // console.log('-------------------');
    return output;
  }

  dateToMoment(input?: any) {
    const zone = "Europe/Paris";
    moment.tz.setDefault(zone);
    moment.locale('fr');
    if (!!input) {
      return moment(input).tz(zone);
    }
    return moment().tz(zone);
  }

  public static getInstance(): EasyDebugLogger {
    if (!this.easyDebugLogger) {
      this.easyDebugLogger = new EasyDebugLogger();
    }
    return this.easyDebugLogger;
  }

  public logMethod(method: string, args: any, onlyMethod?: boolean): void {
    if (environment.env !== 'production') {
      // console.log(method, args);
    }

    if (this._isEasyDebugActive) {
      const date = this.dateToFormatFr();
      let currentRoute = '';
      let allowedArgs = '';

      currentRoute = window.location.pathname.substring(1);

      if (onlyMethod) {
        allowedArgs = 'Only method logged';
      } else if (JSON.stringify(args, this._getCircularReplacer()).length > 2000000) {
        allowedArgs = 'Arguments too long (>2Mb)';
      } else {
        allowedArgs = JSON.stringify(args, this._getCircularReplacer());
      }

      const log: any = {
        timestamp: date.format('DD/MM/YYYY hh:mm:ss:SSS'),
        route: currentRoute,
        method: method,
        args: allowedArgs
      };
      this._logs.push(log);
    }
  }

  public logListener(event: string, path: any): void {
    if (environment.env !== 'production') {
      // console.log(event, path);
    }

    if (this._isEasyDebugActive) {
      const date = this.dateToFormatFr();

      const cleanedPath = [];
      for (let i = 0, len = path.length; i < len; i++) {
        let temp = '';
        if (!!path[i].localName && !!path[i].className) {
          temp += path[i].localName + ' -> ' + path[i].className;
        } else if (!!path[i].nodeName) {
          temp += path[i].nodeName.toLowerCase();
        } else {
          temp += '#window';
        }
        cleanedPath.push(temp);
      }

      const log: any = {
        timestamp: date.format('DD/MM/YYYY hh:mm:ss:SSS'),
        event: event,
        path: cleanedPath.join(' | ')
      };
      this._logs.push(log);
    }
  }

  public logError(type: string, message: any): void {
    if (environment.env !== 'production') {
      // console.log(type, message);
    }

    if (this._isEasyDebugActive) {
      const date = this.dateToFormatFr();
      const log: any = {
        timestamp: date.format('DD/MM/YYYY hh:mm:ss:SSS'),
        type: type,
        message: message.toString()
      };
      this._logs.push(log);
    }
  }

  public logManual(message: string, args?: any): void {
    if (environment.env !== 'production') {
      // console.log(message, args);
    }

    if (this._isEasyDebugActive) {
      const date = this.dateToFormatFr();
      const log: any = {
        timestamp: date.format('DD/MM/YYYY hh:mm:ss:SSS'),
        type: '*** MANUAL ***',
        message: message,
        args: !!args ? JSON.stringify(args, this._getCircularReplacer()) : '*** NO ARGS ***'
      };
      this._logs.push(log);
    }
  }

  public get logs(): Array<any> {
    return this._logs;
  }

  public set logs(logs: Array<any>) {
    this._logs = logs;
  }

  public get isEasyDebugActive(): boolean {
    return this._isEasyDebugActive;
  }

  public set isEasyDebugActive(isEasyDebugActive: boolean) {
    this._isEasyDebugActive = isEasyDebugActive;
  }
}
