import { CancelAsync } from './../../../app/models/cancelAsync';
import { StatsService } from './../../../app/services/stats.service';
import { Component, HostListener } from "@angular/core";
import { ViewChild, Output, EventEmitter } from '@angular/core';
import { NavigationService, AppInitService, StudentService } from '@app/services';
import { IonSlides, Platform } from '@ionic/angular';

import { ModalController } from '@ionic/angular';
import { ModalMarketingComponent, ModalIframeComponent } from '@app/modals';

import {
  Student,
  StudentPurshasesDetails
} from '@app/app.models';

import { UtilsService } from '@commons/services';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'opening-slider-comp',
  templateUrl: 'opening-slider.html',
  styleUrls: ['opening-slider.scss']
})
export class OpeningSlider extends CancelAsync {
  @ViewChild(IonSlides) slides: IonSlides;
  @Output() endSlider = new EventEmitter<boolean>();

  iconPath = './uikit-assets/icons/orion-svg-sprite.svg#';

  sliderIndex = 0;

  randomScreen = 0;

  isDesktop = (window.innerWidth > 767) ? true : false;
  isCordova = false;
  isPlatformDesktop = false;

  studentPurshases: StudentPurshasesDetails;
  payments: any = [];
  gifts = [];
  coupons = [];
  paymentsGiftsCoupons: any = [];
  offers: any = [];
  isPackPermis = false;
  isPackCode = false;
  isPackCodePremium = false;
  isHourOnly = false;
  hasHours = false;
  paymentsDone = false;

  cardsContent = [
    {
      subTitle: `Réserve des leçons de 6h à 23h, même les dimanches et jours fériés !`,
      studentQuote: `"Toute l’équipe est à l’écoute et réactive ! Les créneaux sont arrangeants et ma monitrice a pu me suivre pendant toute ma formation."`,
      student: `Victoria`,
      asset: 'assets/svg/asset-best.svg',
      itemPromoContent: 'temoignage_eleve1'
    },
    {
      subTitle: `Plus de 100 000 leçons notées 4,9/5`,
      studentQuote: `"J’ai vécu une très belle formation avec un très bon suivi, En voiture Simone m’a accompagnée tout au long avec un service client opérationnel."`,
      student: `Iptyssem`,
      asset: 'assets/svg/asset-permis-2.svg',
      itemPromoContent: 'temoignage_eleve2'
    },
    {
      subTitle: `L’auto-école préférée de ses élèves`,
      studentQuote: `"Super ! Le service client est top et on est très bien accompagné. De plus, les enseignants sont très qualifiés."`,
      student: `Awa`,
      asset: 'assets/svg/asset-lecons.svg',
      itemPromoContent: 'temoignage_eleve3'
    }
  ];

  callCenterPhone = '0800 0200 0200';
  callCenterPhoneFormatted = '080002000200';

  fetchOffersFailed = false;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.isDesktop = (window.innerWidth > 767) ? true : false;
  }

  constructor(
    private navigationService: NavigationService,
    private modalController: ModalController,
    public utils: UtilsService,
    public appInitService: AppInitService,
    public studentService: StudentService,
    private statsService: StatsService,
    public platform: Platform
  ) {
    super({ cancel: false, subscription: null });

    this.isCordova = this.platform.is('cordova');
    this.isPlatformDesktop = this.platform.is('desktop');

    this.randomScreen = this.utils.randomMinMax(0, 2);
    
    this.subscription = this.appInitService.onStepChange().subscribe(
      (state) => {
        if (state?.initDone) {
          const item = {
            item_id: 'big_pack',
            item_promo_type: 'regular',
            item_location_id: 'marketingopen',
            item_location_format: 'modale',
            item_promo_content: this.cardsContent[this.randomScreen].itemPromoContent,
          };
      
          this.statsService.send({
            name: 'item:viewed', payload: {
              item: item
            }
          });
          this.getPayments();
          const callCenterElement = document.getElementById('call-center-phone');
          this.callCenterPhone = !!callCenterElement && !!callCenterElement.innerHTML ? callCenterElement.innerHTML : '';
          this.callCenterPhoneFormatted = !!callCenterElement && !!callCenterElement.innerHTML ? callCenterElement.innerHTML.replaceAll(' ', '') : '';
        }
      }
    );
  }

  getPayments() {
    this.studentPurshases = this.studentService.getStudentPurshases();

    const resGetOffers = this.studentPurshases.offers;
    if (!!resGetOffers && !!resGetOffers.errorCode && resGetOffers.errorCode === 'E301') {
      this.fetchOffersFailed = true;
    } else {
      this.offers = resGetOffers;
    }
    this.gifts = this.studentPurshases.gifts;
    this.studentPurshases.linkOfferTopayment();
    this.studentPurshases.removeCouponsIfPresentInPayments();
    this.payments = this.studentPurshases.payments;
    this.coupons = this.studentPurshases.coupons;

    this.hasHours = this.studentPurshases.hasHours();
    this.isHourOnly = this.studentPurshases.isHourOnly();
    this.isPackCode = this.studentPurshases.isPackCode();
    this.isPackCodePremium = this.studentPurshases.isPackCodePremium();
    this.isPackPermis = this.studentPurshases.isPackPermis();

    const tmp = this.gifts.concat(this.coupons);
    this.paymentsGiftsCoupons = tmp.concat(this.payments);

    this.paymentsDone = true;
  }

  slideChanged() {
    this.slides.getActiveIndex().then(index => {
      this.sliderIndex = index;
    });
  }

  previousSlide() {
    this.slides.slidePrev();
  }

  nextSlide() {
    this.slides.slideNext();
  }

  async closeSlider() {
      const student = await this.studentService.getProfil();
      const userLifeCycle = this.studentService.getUserLifeCycle(student);
      if (userLifeCycle.isUserGuest) {
        this.navigationService.open('code');
      } else if (userLifeCycle.isUserRegistered) {
        this.navigationService.open('code');
      } else {
        if (this.isPackPermis || this.isPackCode || this.isPackCodePremium || this.hasHours) {
          this.navigationService.open('accueil');
        } else {
          this.navigationService.open('code');
        }
      }
  }

  savoirplus() {
    const item = {
      item_id: 'big_pack',
      item_promo_type: 'regular',
      item_location_id: 'marketingopen',
      item_location_format: 'modale',
      item_promo_content: this.cardsContent[this.randomScreen].itemPromoContent,
    };

    this.statsService.send({
      name: 'item:clicked', payload: {
        item: item
      }
    });

    this.openLink('permis');
  }

  openAuth() {
    this.navigationService.open('login/signIn');
  }
  openCreateAccount() {
    this.navigationService.open('login/register');
  }

  async openModalIframe(url: any, type?: string, noClose = true) {
    if (!type) {
      type = 'iframe';
    }
    let modal;
    if (noClose) {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        backdropDismiss: false,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          'type': type,
          'url': url,
        },
      });
    } else {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          'type': type,
          'url': url,
        },
      });
    }
    return await modal.present();
  }

  async openLink(link, options = {}) {
    let callbackUrl: any;
    callbackUrl = await this.navigationService.open(link, options);
    if (!!callbackUrl && callbackUrl !== '') {
      this.openModalIframe(callbackUrl);
    }
  }
}
