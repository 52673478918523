import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatPrices' })
export class PricesFormater implements PipeTransform {

  constructor() {}

  transform(input: any): any {
    if (!!input) {
      return (Number.isInteger(Number(input))) ? input.toString() : input.toFixed(2).replace('.', ',');
    } else {
      return null;
    }
  }

}
