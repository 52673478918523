import { EasyDebugLogger } from '../easy-debug-logger/easy-debug-logger';

const easyDebugLogger = EasyDebugLogger.getInstance();

export function EasyDebugDecorator(target: Function) {
  // Use '*' as 'methodName' to exclude all methods from given 'className' to get logged 
  // Add 'onlyMethod' property and set it to boolean: 'true' if you want to log the method without its args (works with '*' as 'methodName')
  const methodToAvoidLogging: any = [
    {className: 'ConfettiParticle', methodName: 'draw'},
    {className: 'ConfettisComponent', methodName: 'stepParticle'},
    {className: 'ConfettisComponent', methodName: 'draw'},
    {className: 'ConfettisComponent', methodName: 'update'},
    {className: 'ConfettisComponent', methodName: 'checkForReposition'},
    {className: 'ConfettisComponent', methodName: 'repositionParticle'},
    {className: 'AppComponent', methodName: 'checkIfReportNeedToBeSent'},
    {className: 'PageLoaderService', methodName: 'getTimeSpent'},
    {className: 'PageLoaderService', methodName: 'getStartTimeOnSplash'},
    {className: 'EasyDebugService', methodName: 'sendReport', onlyMethod: true},
    {className: 'GamificationService', methodName: 'translateCategory'},
    {className: 'GamificationService', methodName: 'getCategoryByName'},
    {className: 'GamificationService', methodName: 'buildCta'},
    {className: 'GamificationService', methodName: 'isBadgeDisplayable'},
    {className: 'GamificationService', methodName: 'getBadgesInGroup'},
    {className: 'CalendarDashboardComponent', methodName: 'checkIndex'},
    {className: 'CalendarDashboardComponent', methodName: 'addClasses'},
    {className: 'CalendarDashboardComponent', methodName: 'populateTooltip'},
    {className: 'CalendarDashboardComponent', methodName: 'getFirstLetterDay'},
    // {className: 'ConfettisComponent', methodName: '*', onlyMethod: true}, // toutes les methods de la class
    // {className: 'ConfettisComponent', methodName: 'repositionParticle', onlyMethod: true}, // que la method en question
  ];

  for (const propertyName of Object.getOwnPropertyNames(target.prototype)) {
    const originalDescriptor = Object.getOwnPropertyDescriptor(target.prototype, propertyName);
    const isMethod = originalDescriptor.value instanceof Function;
    let descriptor = null;

    if (isMethod) {
      if (!isMethodToAvoidLogging(target.name, propertyName)) {
        descriptor = MethodLoggerDecorator(target, propertyName, originalDescriptor, isMethodLoggedWithoutArgs(target.name, propertyName));
      }
    }

    if (descriptor) {
      Object.defineProperty(target.prototype, propertyName, descriptor);
    }
  }

  function isMethodToAvoidLogging(className: string, methodName: string): boolean {
    return methodToAvoidLogging.findIndex((elt) => {
      if (!!elt.onlyMethod) {
        return false;
      }

      if (elt.methodName === '*') {
        return elt.className === className;
      }

      return elt.className === className && elt.methodName === methodName;
    }) < 0 ? false : true;
  }

  function isMethodLoggedWithoutArgs(className: string, methodName: string): boolean {
    return methodToAvoidLogging.findIndex((elt) => {
      if (elt.methodName === '*') {
        return elt.className === className && !!elt.onlyMethod;
      }

      return elt.className === className && elt.methodName === methodName && !!elt.onlyMethod;
    }) < 0 ? false : true;
  }
}

export function MethodLoggerDecorator(target: any, propertyKey: string, descriptor: PropertyDescriptor, onlyMethod?: boolean): PropertyDescriptor {
  const originalMethod = descriptor.value;

  descriptor.value = function (...args) {
    // -----------------------------------------------------
    // --- DO WHAT YOU WANT TO DO INSIDE THE METHOD HERE ---
    // -----------------------------------------------------
    easyDebugLogger.logMethod(`${target.name} > ${propertyKey}`, args, onlyMethod);
    // -----------------------------------------------------
    // ------------------------ END ------------------------
    // -----------------------------------------------------

    return originalMethod.apply(this, args);
  };

  return descriptor;
}
