import {
  Component,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  HostListener,
} from '@angular/core';
import { NavigationService } from '@app/services';

import { UtilsService } from '@commons/services';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'bloc-error-comp',
  templateUrl: 'bloc-error.html',
  styleUrls: ['bloc-error.scss']
})
export class BlocErrorComponent implements OnChanges {
  @Input() compOptions: any;
  @Output() outputHeader = new EventEmitter<any>();

  isDebug = false;
  lightMode = false;
  isDesktop = (window.innerWidth > 767) ? true : false;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.isDesktop = (window.innerWidth > 767) ? true : false;
  }

  constructor(
    private navigationService: NavigationService,
    private utils: UtilsService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    // detect @inputs changes
    const compOptions: SimpleChange = changes.compOptions;
    if (this.utils.isDefined(this.compOptions)) {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  setGlobals() {
    this.isDebug = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.debug)) ? this.compOptions.debug : this.isDebug;
    this.lightMode = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.lightMode)) ? this.compOptions.lightMode : this.lightMode;
  }

}
