import { EvsRecord } from './evsRecord';
import { Answer } from './answer';

export interface ISession {
  id: string;
  fakeId?: string;
  syncState?: string;
  type: string;
  mode: string; // (exam, entrainement)
  state: string; // (pending, wrong, right)
  lastAnsweredAt: string;
  serieId: string;
  answersCount: number;
  answers: Array<Answer>;
  stats: {
    badAnswers: number,
    goodAnswers: number
  };
}

export class Session extends EvsRecord implements ISession {
  private _id: string;
  private _fakeId?: string;
  private _type: string;
  private _mode: string; // (exam, entrainement)
  private _state: string; // (pending, wrong, right)
  private _lastAnsweredAt: string;
  private _serieId: string;
  private _answersCount: number;
  private _answers: Array<Answer>;
  private _stats: {
    badAnswers: number;
    goodAnswers: number;
  };
  private _syncState?: string;

  constructor(obj: ISession) {
    super(obj);
  }
  public get id(): string { return this._id; }
  public set id(value: string) { this._id = value; }
  public get fakeId(): string { return this._fakeId; }
  public set fakeId(value: string) { this._fakeId = value; }
  public get syncState(): string { return this._fakeId; }
  public set syncState(value: string) { this._fakeId = value; }
  public get type(): string { return this._type; }
  public set type(value: string) { this._type = value; }
  public get mode(): string { return this._mode; }
  public set mode(value: string) { this._mode = value; }
  public get state(): string { return this._state; }
  public set state(value: string) { this._state = value; }
  public get lastAnsweredAt(): string { return this._lastAnsweredAt; }
  public set lastAnsweredAt(value: string) { this._lastAnsweredAt = value; }
  public get serieId(): string { return this._serieId; }
  public set serieId(value: string) { this._serieId = value; }
  public get answersCount(): number { return this._answersCount; }
  public set answersCount(value: number) { this._answersCount = value; }
  public get answers(): Array<Answer> { return this._answers; }
  public set answers(value: Array<Answer>) { this._answers = value; }
  public get stats(): { badAnswers: number; goodAnswers: number; } { return this._stats; }
  public set stats(value: { badAnswers: number; goodAnswers: number; }) { this._stats = value; }
}
