import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { SeriesStrategy } from './seriesStrategy.interface';

import { Serie } from '../../models/serie';
import { Student } from '../../models/student';
import { Series } from '../../models/series';

import { switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { QuestionsService } from '../../services/questions.service';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
export class RemoteSeries extends Series implements SeriesStrategy {

  constructor(
    private http: HttpClient,
  ) {
    super();
  }

  init() {
    this.series = [];
    this.seriesList = [];
    this.individualSeries = [];
    this.individualSeriesWithQuestions = [];
    this.themes = [];
  }

  async getSeriesList(student: Student, local?: boolean): Promise<Serie[]> {
    if (!!this.seriesList && this.seriesList.length > 0) {
      return this.seriesList;
    }
    this.seriesList = await this.getSeries(student);
    return this.seriesList;
  }

  async getSerie(serieId, student: Student, withQuestions: boolean, local = false): Promise<any> {
    if (!!this.individualSeries && this.individualSeries.find(elt => elt.id === serieId) && !withQuestions) {
      return this.individualSeries.find(elt => elt.id === serieId);
    } else {
      let url: string;
      if (!!student && !!student.remoteId && student.remoteId !== 'GUEST') {
        url = `${environment.cdrBase}/v${environment.apiOptiVersion}/account/${student.remoteId}/series/${serieId}`;
      } else {
        url = `${environment.cdrBase}/v${environment.apiOptiVersion}/series/${serieId}`;
      }

      return this.http.get(url).pipe(
        switchMap(
          (dataSerie: any) => {
            if (!!dataSerie && !!dataSerie.data) {
              const serie = this.fillSerie(dataSerie.data);
              serie.questions = dataSerie.data.relationships.questions.data.map(elt => elt.id);
              this.individualSeries.push(serie);
              return of(serie);
            }
            return of({ errorCode: 'E301', errorMessage: 'remoteGetSerie Service failed', errorOriginal: 'No seriesData or no data in seriesData'});
          }
        ),
        catchError(
          (err) => {
            return of({ errorCode: 'E301', errorMessage: 'remoteGetSerie Service failed', errorOriginal: err});
          }
        )
      ).toPromise();
    }
  }

  async getSeries(student: Student, local?: boolean): Promise<any> {
    if (!!this.series && this.series.length > 0) {
      return this.series;
    }
    let url: string;

    if (!!student && !!student.remoteId && student.remoteId !== 'GUEST') {
      url = `${environment.cdrBase}/v${environment.apiOptiVersion}/account/${student.remoteId}/series`;
    } else {
      url = `${environment.cdrBase}/v${environment.apiOptiVersion}/series`;
    }

    return this.http.get(url).pipe(
      switchMap(
        (seriesData: any) => {
          if (!!seriesData && !!seriesData.data) {
            const series = this.fillSeries(seriesData.data);
            this.series = series;
            return of(series);
          } else {
            return of({ errorCode: 'E301', errorMessage: 'remoteGetSeries Service failed', errorOriginal: 'No seriesData or no data in seriesData'});
          }
        }
      ),
      catchError(
        err => {
          return of({ errorCode: 'E301', errorMessage: 'remoteGetSeries Service failed', errorOriginal: err});
        }
      )
    ).toPromise();
  }

  async setSeries(series: Serie[], student: Student): Promise<Serie[]> {
    this.series = series;
    return this.series;
  }

  async setSerie(serie: Serie, student: Student): Promise<Serie> {
    if (!!this.individualSeries && !!this.individualSeries.find(elt => elt.id === serie.id)) {
      this.individualSeries.splice(this.individualSeries.findIndex(elt => elt.id === serie.id), 1, serie);
    } else {
      this.individualSeries.push(serie);
    }
    if (!!this.series && this.series.find(elt => elt.id === serie.id)) {
      this.series.splice(this.series.findIndex(elt => elt.id === serie.id), 1, serie)
    } else {
      this.series.push(serie);
    }
    return serie;
  }

  async setSeriesList(seriesList: Serie[], student: Student): Promise<any[]> {
    this.seriesList = seriesList;
    return this.seriesList;
  }

  async getThemes(student: Student, local = true): Promise<any> {
    // console.log('REMOTE LOCAL', local);
    if (!!this.themes && this.themes.length > 0 && (!!local && local)) {
      return this.themes;
    }
    let url: string;

    url = `${environment.cdrBase}/v${environment.apiVersion}/question/themes`;

    return this.http.get(url).pipe(
      switchMap(
        (themesData: any) => {
          if (!!themesData && !!themesData.data) {
            const themes = this.fillThemes(themesData.data);
            this.themes = themes;
            return of(themes);
          } else {
            return of({ errorCode: 'E301', errorMessage: 'remoteGetThemes Service failed', errorOriginal: 'No themesData or no data in themesData'});
          }
        }
      ),
      catchError(
        err => {
          return of({ errorCode: 'E301', errorMessage: 'remoteGetThemes Service failed', errorOriginal: err});
        }
      )
    ).toPromise();
  }

  async setThemes(themes: any[], student: Student): Promise<any[]> {
    this.themes = themes;
    return this.themes;
  }
}
