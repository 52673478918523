import { Platform } from '@ionic/angular';
import { Component, Input, Output, ViewChild, OnChanges, SimpleChanges, SimpleChange, EventEmitter, OnInit } from '@angular/core';
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'video-comp',
  templateUrl: 'video.component.html',
  styleUrls: ['video.component.scss']
})
export class VideoComponent implements OnChanges {
  @Input() compOptions: any;
  @Output() videoEvent = new EventEmitter<any>();
  @Output() failEvent = new EventEmitter<any>();

  iconPath = './uikit-assets/icons/orion-svg-sprite.svg#';

  debug = false;

  moviePlayer: any = null;
  videoErrors = [];

  pause = false;
  stop = false;

  withSound = false;
  fullscreen = false;
  isControls = false;
  isEnded = false;
  controlsList = 'nodownload';

  withTimer = false;
  timerDuration = 5000;
  timerEnded = false;
  timerValue = 0;
  timerInterval = null;

  playVideoFail = false;

  videoLoadStart = null;
  videoProgress = null;
  videoSuspend = null;
  videoAbort = null;
  videoError = null;
  videoEmptied = null;
  videoStalled = null;
  videoLoadedMetadata = null;
  videoLoadedData = null;
  videoCanPlay = null;
  videoCanPlayThrough = null;
  videoPlaying = null;
  videoWaiting = null;
  videoSeeking = null;
  videoSeeked = null;
  videoEnded = null;
  videoDurationChange = null;
  videoTimeUpdate = null;
  videoPlay = null;
  videoPause = null;
  videoRateChange = null;
  videoResize = null;
  videoVolumeChange = null;

  constructor(
    private webview: WebView,
    public platform: Platform,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    const compOptions: SimpleChange = changes.compOptions;
    if (!!this.compOptions) {
      this.compOptions = compOptions.currentValue;
      this.setGlobals();
    }
  }

  async setGlobals() {
    if (!!this.compOptions && !!this.compOptions.src) {
      if (!!this.compOptions && !!this.compOptions.fullscreen) {
        this.fullscreen = true;
      }
      if (this.moviePlayer === null) {
        setTimeout(() => {
          if (this.checkNativeElement()) {
            this.displayVideoCallBacks();
          }
        }, 0);
      }
    }
    if (!!this.compOptions && !!this.compOptions.debug) {
      this.debug = true;
    }
    if (!!this.compOptions && !!this.compOptions.withSound) {
      this.withSound = true;
    }
    if (!!this.compOptions && !!this.compOptions.controls) {
      this.isControls = true;
    }
    if (!!this.compOptions && !!this.compOptions.timer) {
      this.withTimer = this.compOptions.timer;
    }
    if (!!this.compOptions && !!this.compOptions.duration) {
      this.timerDuration = (this.compOptions.duration < 1000) ? this.compOptions.duration * 1000 : this.compOptions.duration;
    }
    if (!!this.compOptions && !!this.compOptions.pause) {
      this.pause = true;
      this.pauseVideo();
    }
    if (!!this.compOptions && !this.compOptions.pause) {
      this.playBackVideo();
      this.pause = false;
    }
    if (!!this.compOptions && this.compOptions.stop !== null && this.compOptions.stop !== undefined) {
      this.stop = this.compOptions.stop;
      if (this.stop) {
        await this.pauseVideo();
        this.moviePlayer = null;
      }
    }
    if (this.debug) {
      console.log('compOptions video => ', this.compOptions);
    }
    this.timerCalculator();
  }

  async wrapError(event, callbackName) {
    // console.log('wrapError videoMedia', event, callbackName);

    // if (callbackName === 'Error') {
    //   this.failEvent.emit({type: 'video', status: 'failed'});
    // }

    try {
      this[`video${callbackName}`] = event;
      if (this.debug) {
        console.log(this[`video${callbackName}`]);
      }
      if (callbackName === 'LoadedMetadata') {
        if (this.checkNativeElement()) {
          await this.moviePlayer.play();
          this.videoEvent.emit({started: true});
        }
      }
    } catch (err) {
     this.videoErrors.push(err);
     if (err.name === 'NotAllowedError') {
       this.playVideoFail = true;
     }
    }
  }

  checkNativeElement(): boolean {
    if (this.moviePlayer === undefined || this.moviePlayer === null) {
      let id = 'videoPlayer';
      if (this.fullscreen) {
        id = 'videoPlayerFullscreen';
      }
      this.moviePlayer = document.getElementById(id);
      if (!!this.moviePlayer) {
        this.moviePlayer.src = this.compOptions.src;
        this.moviePlayer.addEventListener('mousedown', () => {
          console.log('clicked video');
          this.videoEvent.emit({clicked: true});
          this.closeVideo();
        });
      }
    }
    if (!!this.moviePlayer) {
      return true;
    }
    return null;
  }

  async playVideo() {
    try {
      if (this.checkNativeElement()) {
        await this.moviePlayer.play();
      }
    } catch (err) {
     if (err.name === 'NotAllowedError') {
       this.playVideoFail = true;
     }
     this.videoErrors.push(err);
    }
    this.playVideoFail = false;
  }

  async pauseVideo() {
    if (!this.isEnded && !!this.moviePlayer) {
      await this.moviePlayer.pause();
    }
  }

  timerCalculator() {
    if (this.withTimer) {
      this.timerInterval = setInterval(() => {
        this.timerValue = this.timerDuration / 1000;
        this.timerDuration -= 1000;
        if (this.timerDuration < 0) {
          this.timerEnded = true;
          clearInterval(this.timerInterval);
        }
        // console.log('timerDuration => ', this.timerDuration);
      }, 1000);
    }
  }

  async playBackVideo() {
    if (this.pause && !this.isEnded) {
      try {
        await this.moviePlayer.play();
      } catch (err) {
       if (err.name === 'NotAllowedError') {
         this.playVideoFail = true;
       }
       this.videoErrors.push(err);
      }
      this.playVideoFail = false;
    }
  }

  async displayVideoCallBacks() {
    this.moviePlayer.addEventListener('loadstart', (async event => await this.wrapError(event, 'LoadStart')));
    this.moviePlayer.addEventListener('progress', (async event => await this.wrapError(event, 'Progress')));
    this.moviePlayer.addEventListener('suspend', (async event => await this.wrapError(event, 'Suspend')));
    this.moviePlayer.addEventListener('abort', (async event => await this.wrapError(event, 'Abort')));
    this.moviePlayer.addEventListener('error', (async event => await this.wrapError(event, 'Error')));
    this.moviePlayer.addEventListener('emptied', (async event => await this.wrapError(event, 'Emptied')));
    this.moviePlayer.addEventListener('stalled', (async event => await this.wrapError(event, 'Stalled')));
    this.moviePlayer.addEventListener('loadedmetadata', (async event => await this.wrapError(event, 'LoadedMetadata')));
    this.moviePlayer.addEventListener('loadeddata', (async event => await this.wrapError(event, 'LoadedData')));
    this.moviePlayer.addEventListener('canplay', (async event => await this.wrapError(event, 'CanPlay')));
    this.moviePlayer.addEventListener('canplaythrough', (async event => await this.wrapError(event, 'canPlayThrough')));
    this.moviePlayer.addEventListener('playing', (async event => await this.wrapError(event, 'Playing')));
    this.moviePlayer.addEventListener('waiting', (async event => await this.wrapError(event, 'Waiting')));
    this.moviePlayer.addEventListener('seeking', (async event => await this.wrapError(event, 'Seeking')));
    this.moviePlayer.addEventListener('seeked', (async event => await this.wrapError(event, 'Seeked')));
    this.moviePlayer.addEventListener('ended', (async event => await this.wrapError(event, 'Ended')));
    this.moviePlayer.addEventListener('durationchange', (async event => await this.wrapError(event, 'DurationChange')));
    this.moviePlayer.addEventListener('timeupdate', (async event => await this.wrapError(event, 'TimeUpdate')));
    this.moviePlayer.addEventListener('play', (async event => await this.wrapError(event, 'Play')));
    this.moviePlayer.addEventListener('pause', (async event => await this.wrapError(event, 'Pause')));
    this.moviePlayer.addEventListener('ratechange', (async event => await this.wrapError(event, 'RateChange')));
    this.moviePlayer.addEventListener('resize', (async event => await this.wrapError(event, 'Resize')));
    this.moviePlayer.addEventListener('volumechange', (async event => await this.wrapError(event, 'VolumeChange')));
  }

  closeVideo() {
    if (this.timerEnded) {
      if (!this.isEnded) {
        this.pauseVideo();
      }
      this.videoEvent.emit({close: true});
    }
  }

  videoEnd() {
    this.isEnded = true;
    this.moviePlayer = null;
    this.videoEvent.emit({ended: true});
  }
}
