import { Injectable } from '@angular/core';
import { Initialable } from '../../app/services/app-init.service';
import { BehaviorSubject } from 'rxjs';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

import { AppInitService } from '../services/app-init.service';
import { NetworkStatusService } from '../services/network-status.service';
import { ToasterService } from '@commons/services';
import { ModalController, Platform } from '@ionic/angular';
import { ModalErrorComponent } from '../modals/modal-error/modal-error.component';

/*
Services addError mais non traités ici car traités localement sur la page :
- getSeries
*/

@Injectable({providedIn: 'root'})
@Initialable({ step: 'init0', initializer: 'onInit' })
@EasyDebugDecorator
export class UserErrorHandlerService {

  errorCatched = [];
  isCordova = false;
  initDone = false;

  isCriticalFlow = false;
  siteOpenerFailed = false;

  seriesListFailed = false;
  getSerieFailed = false;

  badgesWarningDone = false;
  sessionsWarningDone = false;
  examCodeWarningDone = false;
  drivingExamWarningDone = false;

  constructor(
    private appInitService: AppInitService,
    public platform: Platform,
    private toasterService: ToasterService,
    private modalController: ModalController,
    private networkStatus: NetworkStatusService,
  ) {
    this.isCordova = this.platform.is('cordova');
    this.appInitService.onStepChange().subscribe(
      (state) => {
        if (state?.initDone) {
          this.initDone = true;
          this.handleErrors();
        }
      }
    );
  }

  async onInit() {
    return 'UserErrorHandler done';
  }

  async addError(errorCatched: object) {
    console.log('addError errorCatched', this.errorCatched);
    // console.log('addError isCriticalFlow', this.isCriticalFlow);
    const modalOpened = await this.modalController.getTop();
    if (!modalOpened) {
      this.errorCatched.push(errorCatched);
      if (this.initDone && !this.isCriticalFlow) {
        this.handleErrors();
      }
    }
  }

  resetErrors() {
    this.errorCatched = [];
  }

  handleErrors() {
    console.log('handleErrors errorCatched', this.errorCatched);
    console.log('handleErrors isCriticalFlow', this.isCriticalFlow);
    if (!!this.errorCatched && this.errorCatched.length > 0 && !this.networkStatus?.isOffline()) {
      this.errorCatched.sort((a, b) => (a.criticity > b.criticity) ? 1 : ((b.criticity > a.criticity) ? -1 : 0));
      for (const error of this.errorCatched) {
        if (
          error.service === 'student' ||
          error.service === 'user_properties' ||
          error.service === 'gifts' ||
          error.service === 'payments' ||
          error.service === 'coupons'
        ) {
          this.showCriticError(error);
          break;
        } else {
          if (
            error.service === 'getSeries' ||
            error.service === 'getSeriesList'
          ) {
            this.seriesListFailed = true;
          }
          if (
            error.service === 'fetchSerie'
          ) {
            this.getSerieFailed = true;
          }
          if (
            error.service === 'siteOpener' ||
            error.service === 'getSecuredUrl'
          ) {
            console.log('ERROR => ', error);
            this.siteOpenerFailed = true;
            this.openModalPack('unavaibleExternalContent', true, error);
          }
          if (
            error.service === 'sync-cdr'
          ) {
            const toastConf = {
              text: `Nous ne sommes pas parvenus à synchroniser tes données`,
              bgcolor: 'var(--color-danger)',
              duration: '5000'
            };
            this.launchToaster(toastConf);
          }
          if (
            error.service === 'badges' && !this.badgesWarningDone
          ) {
            this.badgesWarningDone = true;
            const toastConf = {
              text: `Nous ne sommes pas parvenus à récupérer tes réussites les plus récentes. Le contenu affiché peut ne pas être à jour`,
              bgcolor: 'var(--color-danger)',
              duration: '5000'
            };
            this.launchToaster(toastConf);
          }
          if (
            !this.seriesListFailed &&
            error.service === 'fetchSessions' && !this.sessionsWarningDone
          ) {
            this.sessionsWarningDone = true;
            const toastConf = {
              text: `Nous ne sommes pas parvenus à récupérer l'historique de tes séries. Le contenu affiché peut ne pas être à jour`,
              bgcolor: 'var(--color-danger)',
              duration: '5000'
            };
            this.launchToaster(toastConf);
          }
          if (
            !this.seriesListFailed &&
            error.service === 'fetchSession'
          ) {
            const toastConf = {
              text: `Nous ne sommes pas parvenus à récupérer l'historique de ta série`,
              bgcolor: 'var(--color-danger)',
              duration: '5000'
            };
            this.launchToaster(toastConf);
          }
          if (
            error.service === 'fetchSerie' ||
            error.service === 'getSerie'
          ) {
            const toastConf = {
              text: `Nous ne sommes pas parvenus à récupérer cette série`,
              bgcolor: 'var(--color-danger)',
              duration: '5000'
            };
            this.launchToaster(toastConf);
          }
          if (
            !this.examCodeWarningDone &&
            error.service === 'fetchExamenCodeByUserId'
          ) {
            this.examCodeWarningDone = true;
            const toastConf = {
              text: `Nous ne sommes pas parvenus à récupérer tes informations liées à l'examen du code`,
              bgcolor: 'var(--color-danger)',
              duration: '5000'
            };
            this.launchToaster(toastConf);
          }
          if (
            !this.drivingExamWarningDone &&
            error.service === 'fetchDrivingExamenById'
          ) {
            this.drivingExamWarningDone = true;
            const toastConf = {
              text: `Nous ne sommes pas parvenus à récupérer tes données liées à l'examen de la conduite. Le contenu affiché peut ne pas être à jour`,
              bgcolor: 'var(--color-danger)',
              duration: '5000'
            };
            this.launchToaster(toastConf);
          }
          this.resetErrors();
          break;
        }
      }
    } else {
      // offline
      this.resetErrors();
    }
  }

  showCriticError(error: any) {
    if (this.isCordova && !!error.platform && (error.platform === 'both' || error.platform === 'device')) {
      const toastConf = {
        text: `Nous ne sommes pas parvenus à récupérer tes données les plus récentes. Le contenu affiché peut ne pas être à jour`,
        bgcolor: 'var(--color-danger)',
        duration: '5000'
      };
      this.launchToaster(toastConf);
    }
    if (!this.isCordova && !!error.platform && (error.platform === 'both' || error.platform === 'browser')) {
      this.isCriticalFlow = true;
      this.openModalPack('critical', true, error);
    }
    this.resetErrors();
  }

  launchToaster(config: object) {
    this.toasterService.create(config);
  }

  async openModalPack(type?: string, noClose = false, error = null, customClass = '') {
    // evs-global.scss : small-modal | medium-modal
    if (!type) {
      type = 'marketingRegular';
    }
    const props = {
      type: type,
      error: error,
      closable: !noClose,
      bannerInfo: null
    };
    let modal;
    if (noClose) {
      modal = await this.modalController.create({
        component: ModalErrorComponent,
        cssClass: customClass,
        backdropDismiss: false,
        componentProps: props
      });
    } else {
      modal = await this.modalController.create({
        component: ModalErrorComponent,
        cssClass: customClass,
        componentProps: props,
      });
    }
    return await modal.present();
  }

  private closeModals() {
    const modals = document.getElementsByTagName('ion-modal');
    while (modals.length > 0) {
      modals[0].parentNode.removeChild(modals[0]);
    }
  }

}
