<div class="collabsible-wrap" #collapsible>
  <div class="collapsible-header" [ngClass]="{'collapsible-active': !isClose}" (click)="toggle()" #collapsibleHeader>
    <span class="collapsible-counter" *ngIf="isCounter">
      {{counter}}
      <span *ngIf="isCounterMax">/{{counterMax}}</span>
    </span>
    <span class="collapsible-progressbar" *ngIf="isProgressBar">
      <span class="collapsible-progressbar-percent" [ngStyle]="{'width': percent + '%'}"></span>
    </span>
    <span class="collapsible-title">
      <div [class]="'icon-title ' + iconTitle" *ngIf="iconTitle !== ''">
        <img [src]="'assets/2022/icons/' + iconTitle + '.svg'" alt="EnvoitureSimone">
      </div>
      <span [innerHTML]="title"></span>
    </span>
  </div>
  <div class="collapsible-content" #contentContainer>
    <div class="collapsible-content-inner" #contentInner>
      <ng-content></ng-content>
    </div>
  </div>
</div>