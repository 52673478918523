import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Initialable } from './app-init.service';
import { NetworkStatusService } from './network-status.service';
import { StorageService } from './storage.service';

import { CerfaModel } from '../models/cerfa';
import { Student } from '../models/student';

import { environment } from '../../environments/environment';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@Injectable({ providedIn: 'root' })
@Initialable({ step: 'init3', initializer: 'onInit'})
@EasyDebugDecorator
export class CerfaService {

  private _cerfa: any;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private networkService: NetworkStatusService
  ) { }

  async onInit() {
    // console.log('Cerfa done');
    return 'Cerfa done';
  }

  fetchCerfa(accountId: string): Observable<any> {
    let url: string;

    if (this.networkService?.isOffline()) {
      return of(null);
    }

    if (!!accountId && accountId !== 'GUEST') {

      url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${accountId}/cerfa`;

      return this.http.get(url).pipe(
        switchMap(data => {
          this.storageService.set(this.storageKey(accountId), data);
          this._cerfa = this.buildCerfaFromRawData(data);
          return of(this._cerfa);
        }),
        catchError(
          async err => {
            return {errorCode: 'E301', errorMessage: 'Cerfa Service failed', errorOriginal: err};
          }
        )
      );
    } else {
      return null;
    }
  }

  async loadStudentCerfa(student: Student) {
    if (student.isGuest()) {
      this._cerfa = null;
    } else {
      // TODO: Handle offline with localstorage
      const transformed = await this.storageService.get('transformed');

      if (this.networkService?.isOffline() || (!!transformed && transformed)) {
        this.loadFromStorage(student);
      } else {
        this.fetchCerfa(student.remoteId).subscribe((res) => {
          this._cerfa = res;
        });
      }
    }
  }

  ///////////////////////////////////
  /////          GETTERS       /////
  ///////////////////////////////////

  get cerfa(): any {
    return this._cerfa;
  }

  private

  async loadFromStorage(student: Student) {
    this.storageService.get(this.storageKey(student.remoteId)).then((res) => {
      this._cerfa = !!res ? this.buildCerfaFromRawData(res) : null;
    });
  }

  storageKey(accountId: String): string {
    return `${accountId}-cerfa`;
  }

  /// Serialize data ///
  buildCerfaFromRawData(data: any): CerfaModel {
    const textData = JSON.stringify(data);
    const elt = JSON.parse(textData);

    let cerfa = null;

    if (!!elt && Object.keys(elt).length > 0) {
      cerfa = new CerfaModel({
        id: elt.data.id,
        type: elt.data.type,
        created_at: elt.data.attributes.created_at,
        updated_at: elt.data.attributes.updated_at,
        confirmed_at: elt.data.attributes.confirmed_at,
        document_type: elt.data.attributes.document_type,
        recto_content_type: elt.data.attributes.recto_content_type,
        recto_file_name: elt.data.attributes.recto_file_name,
        recto_file_size: elt.data.attributes.recto_file_size,
        recto_updated_at: elt.data.attributes.recto_updated_at,
        recto_url: elt.data.attributes.recto_url,
        verso_content_type: elt.data.attributes.verso_content_type,
        verso_file_name: elt.data.attributes.verso_file_name,
        verso_file_size: elt.data.attributes.verso_file_size,
        verso_updated_at: elt.data.attributes.verso_updated_at,
        verso_url: elt.data.attributes.verso_url,
      });
    }
    return cerfa;
  }
}
