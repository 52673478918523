<ng-template #statText let-title="title" let-stat="stat">
  <div [ngClass]="{'bloc-stat-text': calledFrom === 'stats', 'bloc-stat-text-dashboard': calledFrom === 'dashboard'}">
    <div *ngIf="isLoading" class="padding-5 content-center flex-column">
      <span class="wpe-70 border-radius-6 padding-6 display-block margin-bottom-10 shimmer"></span>
      <span class="wpe-50 border-radius-6 padding-6 display-block shimmer"></span>
    </div>
    <span *ngIf="!isLoading" class="stat"><b>{{stat}}</b></span>
    <span *ngIf="!isLoading" class="title">{{title}}</span>
  </div>
</ng-template>

<div [ngClass]="{'evs-card-ui margin-bottom-15': calledFrom === 'stats', 'dashboard-fix': calledFrom === 'dashboard'}">
  <div class="stats-text">
    <div class="stats-selector" *ngIf="isLoading">
      <span class="wpi-120 border-radius-6 padding-6 shimmer"></span>
    </div>
    <div class="stats-selector" *ngIf="!isLoading && !showConduite && !isGuest">
      <span *ngIf="calledFrom != 'dashboard'"><b>Tes chiffres</b></span>
      <div *ngIf="!seriesListFailed && !getSessionsFailed && !fetchLessonFailed && !fetchBookletFailed" class="block-right" [ngClass]="{'wpe-70': isDesktop && calledFrom != 'dashboard', 'wpe-100': !isDesktop || calledFrom == 'dashboard'}">
        <custom-radio-comp [compOptions]="{'options': customRadioOptions, selected: 'Semaine', isSmall: true}" (userSelection)="getCustomRadioSelection($event)"></custom-radio-comp>
        <!-- <form class="evs-form">
          <div>
            <select name="selectedTimeSpan" [(ngModel)]="selectedTimeSpan" class="padding-0 small-text">
              <option value="Semaine" [selected]="selectedTimeSpan === 'Semaine'">Cette semaine</option>
              <option value="Mois" [selected]="selectedTimeSpan === 'Mois'">Ce Mois</option>
              <option value="Tout" [selected]="selectedTimeSpan === 'Tout'">Total</option>
            </select>
          </div>
        </form> -->
      </div>
    </div>
    <div *ngIf="isLoading">
      <ng-container [ngTemplateOutlet]="statText" [ngTemplateOutletContext]="{title: 'Note moyenne', stat: '0/40'}"></ng-container>
      <ng-container [ngTemplateOutlet]="statText" [ngTemplateOutletContext]="{title: 'Meilleure note', stat: '0/40'}"></ng-container>
      <ng-container [ngTemplateOutlet]="statText" [ngTemplateOutletContext]="{title: 'Questions', stat: '0/40'}"></ng-container>
      <ng-container [ngTemplateOutlet]="statText" [ngTemplateOutletContext]="{title: 'Plus longue série', stat: '0/40'}"></ng-container>
      <ng-container [ngTemplateOutlet]="statText" [ngTemplateOutletContext]="{title: 'Entraînements', stat: '0/40'}"></ng-container>
      <ng-container [ngTemplateOutlet]="statText" [ngTemplateOutletContext]="{title: 'Examens blancs', stat: '0/40'}"></ng-container>
    </div>
    <div *ngIf="!isLoading && isGuest">
      <p class="text-center" [ngClass]="{'no-stats-text': isDesktop, 'padding-20': !isDesktop, 'margin-top-40': calledFrom === 'dashboard' && isDesktop && !isIpad}">
        C'est ici que tu retrouveras tes statistiques d'entraînement. Crée gratuitement ton compte pour sauvegarder tes progrès et accéder au détail de tes performances
      </p>
      <button class="btn full-width" (click)="openLink('login/register')">Créer mon compte gratuit</button>
    </div>
    <!-- CODE -->
    <div *ngIf="!isLoading && !showConduite && !isGuest && !seriesListFailed && !getSessionsFailed && !fetchLessonFailed && !fetchBookletFailed" [ngClass]="{
      'margin-top-60': ((noStatsWeek && selectedTimeSpan === 'Semaine') || (noStatsMonth && selectedTimeSpan === 'Mois') || (noStatsTotal && selectedTimeSpan === 'Tout')) && (!isIpad && calledFrom === 'dashboard'),
      'margin-top-30': ((noStatsWeek && selectedTimeSpan === 'Semaine') || (noStatsMonth && selectedTimeSpan === 'Mois') || (noStatsTotal && selectedTimeSpan === 'Tout')) && (isIpad || calledFrom !== 'dashboard'),
      'animated fadeIn': !isCordova}">
      <div *ngIf="noStatsWeek && selectedTimeSpan === 'Semaine'" class="no-stats-text">
        <p>Garde le rythme&nbsp;! Prêt à réaliser ton premier entraînement de la semaine&nbsp;?</p>
        <button class="btn" (click)="launchSerie()">Lancer une série</button>
      </div>
      <div *ngIf="noStatsMonth && selectedTimeSpan === 'Mois'" class="no-stats-text">
        <p>Tu n'as pas encore réalisé de série ce mois-ci. C'est le moment de reprendre l'entraînement, les progrès seront immanquables&nbsp;! Go, go, go&nbsp;!</p>
        <button class="btn" (click)="launchSerie()">Lancer une série</button>
      </div>
      <div *ngIf="noStatsTotal && selectedTimeSpan === 'Tout'" class="no-stats-text">
        <p>Le talent, c'est bien. Mais on n'imagine pas Kylian Mbappé ou Serena Williams tout gagner sans s'entraîner. Alors au travail&nbsp;!</p>
        <button class="btn" (click)="launchSerie()">Lancer une série</button>
      </div>
      <ng-container *ngIf="!isGuest && ((!noStatsWeek && selectedTimeSpan === 'Semaine') || (!noStatsMonth && selectedTimeSpan === 'Mois') || (!noStatsTotal && selectedTimeSpan === 'Tout'))" [ngTemplateOutlet]="statText" [ngTemplateOutletContext]="{title: 'Note moyenne', stat: allStats[selectedTimeSpan].moyenne + '/40'}"></ng-container>
      <ng-container *ngIf="!isGuest && ((!noStatsWeek && selectedTimeSpan === 'Semaine') || (!noStatsMonth && selectedTimeSpan === 'Mois') || (!noStatsTotal && selectedTimeSpan === 'Tout'))" [ngTemplateOutlet]="statText" [ngTemplateOutletContext]="{title: 'Meilleure note', stat: allStats[selectedTimeSpan].best + '/40'}"></ng-container>
      <ng-container *ngIf="!isGuest && ((!noStatsWeek && selectedTimeSpan === 'Semaine') || (!noStatsMonth && selectedTimeSpan === 'Mois') || (!noStatsTotal && selectedTimeSpan === 'Tout'))" [ngTemplateOutlet]="statText" [ngTemplateOutletContext]="{title: (allStats[selectedTimeSpan].answeredQuestionsTotal > 1) ? 'Questions' : 'Question', stat: allStats[selectedTimeSpan].answeredQuestionsTotal}"></ng-container>
      <ng-container *ngIf="!isGuest && ((!noStatsWeek && selectedTimeSpan === 'Semaine') || (!noStatsMonth && selectedTimeSpan === 'Mois') || (!noStatsTotal && selectedTimeSpan === 'Tout'))" [ngTemplateOutlet]="statText" [ngTemplateOutletContext]="{title: 'Plus longue série', stat: allStats[selectedTimeSpan].streak}"></ng-container>
      <ng-container *ngIf="!isGuest && ((!noStatsWeek && selectedTimeSpan === 'Semaine') || (!noStatsMonth && selectedTimeSpan === 'Mois') || (!noStatsTotal && selectedTimeSpan === 'Tout'))" [ngTemplateOutlet]="statText" [ngTemplateOutletContext]="{title: (allStats[selectedTimeSpan].entrainements > 1) ? 'Entraînements' : 'Entraînement', stat: allStats[selectedTimeSpan].entrainements}"></ng-container>
      <ng-container *ngIf="!isGuest && ((!noStatsWeek && selectedTimeSpan === 'Semaine') || (!noStatsMonth && selectedTimeSpan === 'Mois') || (!noStatsTotal && selectedTimeSpan === 'Tout'))" [ngTemplateOutlet]="statText" [ngTemplateOutletContext]="{title: (allStats[selectedTimeSpan].examens > 1) ? 'Examens blancs' : 'Examen blanc', stat: allStats[selectedTimeSpan].examens}"></ng-container>
    </div>
    <!-- CONDUITE -->
    <div *ngIf="!isLoading && showConduite && !seriesListFailed && !getSessionsFailed && !fetchLessonFailed && !fetchBookletFailed" [ngClass]="{'animated fadeIn': !isCordova}">
      <ng-container [ngTemplateOutlet]="statText" [ngTemplateOutletContext]="{title: (heuresFinishedTotalCount > 1) ? 'Heures réalisées' : 'Heure réalisée', stat: heuresFinishedTotalCount}"></ng-container>
      <ng-container [ngTemplateOutlet]="statText" [ngTemplateOutletContext]="{title: (scheduledHours > 1) ? 'Heures à venir' : 'Heure à venir', stat: scheduledHours}"></ng-container>
      <ng-container [ngTemplateOutlet]="statText" [ngTemplateOutletContext]="{title: (acquiredSkills > 1) ? 'Compétences acquises' : 'Compétence acquise', stat: acquiredSkills}"></ng-container>
      <ng-container [ngTemplateOutlet]="statText" [ngTemplateOutletContext]="{title: (beingAcquiredSkills > 1) ? 'Compétences en cours d\'acquisition' : 'Compétence en cours d\'acquisition', stat: beingAcquiredSkills}"></ng-container>
      <ng-container [ngTemplateOutlet]="statText" [ngTemplateOutletContext]="{title: (allTeachers.length > 1) ? 'Enseignants formateurs' : 'Enseignant formateur', stat: allTeachers.length}"></ng-container>
      <ng-container [ngTemplateOutlet]="statText" [ngTemplateOutletContext]="{title: (userCredits > 1) ? 'Crédits de conduite restants' : 'Crédit de conduite restant', stat: userCredits}"></ng-container>
    </div>
    <ng-container *ngIf="!isLoading && (seriesListFailed || getSessionsFailed || fetchLessonFailed || fetchBookletFailed)">
      <bloc-error-comp [compOptions]="{'lightMode': true}"></bloc-error-comp>
    </ng-container>
  </div>
</div>