import { Injectable } from '@angular/core';
import { Initialable } from './app-init.service';
import { EasyDebugDecorator } from '../decorators/easy-debug.decorator';

@Injectable({providedIn: 'root'})
@Initialable({ step: 'init0', initializer: 'init' })
@EasyDebugDecorator

export class ErrorRow {
  code: string;
  message: string;
}

export class ErrorMessage {
  errorCode: string;
  errorMessage: string;
  errorOriginale: any;
}

// ERROR CODE
// E100 => DATA ERROR // famille data
// E101: MISSING DATA
// E200 => FUNCTION ERROR // famille function
// E201: ERROR CATCHED
// E202: CHECK FAILED
// E203: FAILED PROMISE
// E300 => REQUEST ERROR // famille request
// E301: REQUEST FAILED

export class ErrorCatcherService {
  errorTable = [
    {code: 'ME001', message: 'Auto Log Auth Service No param found in url'},
    {code: 'ME002', message: 'Validate Token Token Service error catched'},
    {code: 'ME003', message: 'Open Site Opener Service isSigned check failed'},
    {code: 'ME004', message: 'Tu dois d\'abord sélectionner un code postal avant de continuer'},
    {code: 'ME005', message: 'Postal code doit être rempli'},
  ];

  init() {
    return 'ErrorCatcher done';
  }

  constructor() {
  }

  getRow(code: string): ErrorRow {
    if (!!code) {
      if (!!this.errorTable && this.errorTable.length > 0) {
        for (const row of this.errorTable) {
          if (!!row && !!row.code && row.code === code) {
            return row;
          }
        }
      }
    }
    return null;
  }
}
