import { IParagraph } from './CDRLesson';
import { EvsRecord } from './evsRecord';

export class Paragraph extends EvsRecord implements IParagraph {
  public parentRemoteId: number;
  public remoteId: number;
  public lessonSubtheme: string;
  public lessonName: string;
  public type: string;
  public title: string;
  public text: string;
  public photo: string;

  constructor(obj: IParagraph) {
    super(obj);
  }

}
