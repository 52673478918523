import {
  Component,
  Input,
  Output,
  ViewChild,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  HostListener,
} from '@angular/core';
import { NavigationService } from '@app/services';

import { UtilsService } from '@commons/services';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'bloc-header-comp',
  templateUrl: 'bloc-header.html',
  styleUrls: ['bloc-header.scss']
})
export class BlocHeaderComponent implements OnChanges {
  @Input() compOptions: any;
  @Output() outputHeader = new EventEmitter<any>();

  isDebug = false;
  id = '';
  isDesktop = true;
  isMobile = true;
  title = 'Chargement..';
  navigate = 'forward';
  isBackBtn = false;
  backTitle = 'Retour';
  url = null;
  withOutputClose = false;
  withOutputBack = false;
  loading = true;
  showLoaderBar = false;
  loaderBarPercent = 0;
  loaderBarHasBorder = false;
  loaderBarWhiteBg = false;
  loaderBarAnimated = false;
  showMute = false;
  muted = false;
  serie: any = null;

  constructor(
    private navigationService: NavigationService,
    private utils: UtilsService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    // detect @inputs changes
    const compOptions: SimpleChange = changes.compOptions;
    if (this.utils.isDefined(this.compOptions)) {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  setGlobals() {
    this.isDebug = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.debug)) ? this.compOptions.debug : this.isDebug;
    this.title = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.title)) ? this.compOptions.title : this.title;
    this.isMobile = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.isMobile)) ? this.compOptions.isMobile : this.isMobile;
    this.isDesktop = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.isDesktop)) ? this.compOptions.isDesktop : this.isDesktop;
    this.navigate = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.navigate)) ? this.compOptions.navigate : this.navigate;
    this.isBackBtn = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.isBackBtn)) ? this.compOptions.isBackBtn : this.isBackBtn;
    this.withOutputClose = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.withOutputClose)) ? this.compOptions.withOutputClose : this.withOutputClose;
    this.withOutputBack = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.withOutputBack)) ? this.compOptions.withOutputBack : this.withOutputBack;
    // this.backTitle = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.backTitle)) ? this.compOptions.backTitle : this.backTitle;
    this.url = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.url)) ? this.compOptions.url : this.url;
    this.loading = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.loading)) ? this.compOptions.loading : false;
    this.showLoaderBar = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.showLoaderBar)) ? this.compOptions.showLoaderBar : this.showLoaderBar;
    this.loaderBarPercent = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.loaderBarPercent)) ? this.compOptions.loaderBarPercent : this.loaderBarPercent;
    this.loaderBarHasBorder = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.hasBorder)) ? this.compOptions.hasBorder : this.loaderBarHasBorder;
    this.loaderBarWhiteBg = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.whiteBg)) ? this.compOptions.whiteBg : this.loaderBarWhiteBg;
    this.loaderBarAnimated = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.loaderBarAnimated)) ? this.compOptions.loaderBarAnimated : this.loaderBarAnimated;
    this.showMute = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.showMute)) ? this.compOptions.showMute : this.showMute;
    this.muted = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.muted)) ? this.compOptions.muted : this.muted;
    this.serie = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.serie)) ? this.compOptions.serie : this.serie;
    // console.log('url', this.url);
    // console.log('isBackBtn', this.isBackBtn);
    // console.log('title', this.title);
  }

  muteMedia() {
    this.muted = !this.muted;
    this.outputHeader.emit({action: 'mute', value: this.muted});
  }

  openLink(link) {
    if (link.includes('?tunnelTestAB')) {
      link = 'code';
    }
    if (this.utils.isDefined(link)) {
      if (this.withOutputClose) {
        this.outputHeader.emit({action: this.withOutputClose, value: true});
      } else if (this.withOutputBack) {
        this.outputHeader.emit(this.withOutputBack);
      } else {
        if (this.navigate === 'root') {
          this.navigationService.open(link, {forceRoot: true});
        } else {
          this.navigationService.open(link, {});
        }
      }
    } else {
      // this.navController.back();
    }
  }

}
