import { ToasterService } from '@commons/services';

import { Injectable } from '@angular/core';

import { Initialable } from './app-init.service';

import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Platform } from '@ionic/angular';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import { BehaviorSubject } from 'rxjs';

export enum ConnectionStatusEnum {
  Online,
  Offline
}


@Injectable({
  providedIn: 'root'
})
@Initialable({step: `init0`, initializer: `init`})
@EasyDebugDecorator
export class NetworkStatusService {
  networkStatus: number;
  networkStatusObs = new BehaviorSubject<boolean>(true);
  networkStatusObs$ = this.networkStatusObs.asObservable();

  offToastConf = {
    text: 'Tu es hors ligne : certaines actions sont indisponibles',
    bgcolor: 'var(--color-danger)',
    duration: '5000'
  };

  onToastConf = {
    text: 'Tu es de retour en ligne !',
    bgcolor: 'var(--color-success)',
    duration: '5000'
  };

  constructor(
    public network: Network,
    public platform: Platform,
    private toasterService: ToasterService,
  ) {
    // console.log('Network Status service instanciate');
    if (this.isOffline()) {
      this.networkStatus = ConnectionStatusEnum.Offline;
    } else {
      this.networkStatus = ConnectionStatusEnum.Online;
    }
  }

  async init() {
    // console.log(`Launching Network Status init`);
    return new Promise((resolve, reject) => {
      if (this.isOffline()) {
        this.networkStatus = ConnectionStatusEnum.Offline;
        this.toasterService.create(this.offToastConf);
      } else {
        this.networkStatus = ConnectionStatusEnum.Online;
      }
      this.initializeNetworkEvents();
      // console.log('Network Status init done => ', this.networkStatus);
      resolve(this.networkStatus);
    });
  }

  initializeNetworkEvents(): void {
    this.network.onDisconnect().subscribe(() => {
      if (this.networkStatus === ConnectionStatusEnum.Online) {
        this.networkStatusObs.next(false);
        this.toasterService.create(this.offToastConf);
      }
      this.networkStatus = ConnectionStatusEnum.Offline;
    });
    this.network.onConnect().subscribe(() => {
      if (this.networkStatus === ConnectionStatusEnum.Offline) {
        this.networkStatusObs.next(true);
        this.toasterService.create(this.onToastConf);
      }
      this.networkStatus = ConnectionStatusEnum.Online;
    });
  }

  isOffline(): boolean {
    if (this.platform.is('cordova')) {
      if (this.network.type === 'unknown' || this.network.type === 'none') {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }
}
