<ng-template #errorTemplate let-field="field" let-doubleIcon="doubleIcon" let-iconOnly="iconOnly" let-fieldName="fieldName">
  <ng-container>
    <div class="field-error" [ngClass]="{'show': !field.valid && (field.dirty || field.touched) && !iconOnly && field.errors?.required}">Ce champ est obligatoire</div>
    <div class="field-error" [ngClass]="{'show': !field.valid && (field.dirty || field.touched) && !iconOnly && field.errors?.pattern && fieldName != 'phone_number' && fieldName != 'email'}">Ce champ n'est pas valide</div>
    <div class="field-error" [ngClass]="{'show': !field.valid && (field.dirty || field.touched) && !iconOnly && field.errors?.pattern && fieldName == 'email' && displayEMailPatternError}">Ce champ n'est pas valide</div>
    <div class="field-error" [ngClass]="{'show': !field.valid && (field.dirty || field.touched) && !iconOnly && field.errors?.minlength}">6 caractères minimum</div>
    <div class="field-error" [ngClass]="{'show': !field.valid && (field.dirty || field.touched) && !iconOnly && field.errors?.maxlength}">50 caractères maximum</div>
    <div class="field-error" [ngClass]="{'show': !field.valid && (field.dirty || field.touched) && !iconOnly && field.errors?.validatePasswords}">Les mots de passe ne correspondent pas</div>
    <div class="field-error" [ngClass]="{'show': !field.valid && (field.dirty || field.touched) && !iconOnly && field.errors?.pattern && fieldName == 'phone_number'}">Saisir un numéro valide en 06 ou 07</div>
    <div class="field-error" [ngClass]="{'show': !field.valid && (field.dirty || field.touched) && !iconOnly && field.errors?.wrongPassword}">Mot de passe incorrect</div>
  </ng-container>
</ng-template>

<div class="tunnel-password-container">
  <div class="content">
    <div class="content-container">
      <div class="content-header">
        <div class="tunnel-back-controls">
          <div class="back-btn" (click)="sendCompData({action: 'navigate', data: {direction: 'prev'}})">
            <img src="assets/svg/chevron-left-red.svg" alt="EnVoitureSimone">
          </div>
        </div>
      </div>
      <div class="content-body">
        <h5 id="title">Ton mot de passe</h5>
        <form [formGroup]="passwordForm" class="evs-form">
          <div id="password-form">
            <input *ngIf="!isActive" [type]="showPassword" formControlName="password" id="password" (input)="validatePasswords($event.target.value)" autocomplete="off" data-lpignore="true" class="double-icon" disabled>
            <input *ngIf="isActive" [type]="showPassword" formControlName="password" id="password" (input)="validatePasswords($event.target.value)" autocomplete="off" data-lpignore="true" class="double-icon">
            <img src="assets/2022/icons/show-off.svg" alt="EnVoitureSimone" *ngIf="showPassword == 'password'" class="password-eye view" (click)="toggleshowPassword()">
            <img src="assets/2022/icons/hide-off.svg" alt="EnVoitureSimone" *ngIf="showPassword == 'text'" class="password-eye no-view" (click)="toggleshowPassword()">
            <label for="password" [ngClass]="{'label-up': !!getPasswordForm.password.value && getPasswordForm.password.value !== ''}">Mot de passe</label>
          </div>
          <ng-container [ngTemplateOutlet]="errorTemplate" [ngTemplateOutletContext]="{field: getPasswordForm.password, doubleIcon: true}"></ng-container>
          <mark class="clear-float padding-5 display-block margin-v-5 text-font" *ngIf="enablePasswordValidator && passwordValidatorStates.isValid">Ton mot de passe est sûr 🎉</mark>
          <ion-grid class="ion-no-padding clear-float margin-bottom-20 password-validator" *ngIf="enablePasswordValidator && !passwordValidatorStates.isValid">
            <ion-row>
              <ion-col size="12" size-md>
                <ion-grid class="ion-no-padding">
                  <ion-row>
                    <ion-col size="6" class="text-left small-text" [ngClass]="{'text-gray': !passwordValidatorStates.isMinLength}">
                      <i *ngIf="!passwordValidatorStates.isMinLength" class="icon ion-md-close-circle"></i>
                      <i *ngIf="passwordValidatorStates.isMinLength" class="icon ion-md-checkmark-circle"></i>
                      6 caractères min.
                    </ion-col>
                    <ion-col size="6" class="text-left small-text" [ngClass]="{'text-gray': !passwordValidatorStates.isUppercase}">
                      <i *ngIf="!passwordValidatorStates.isUppercase" class="icon ion-md-close-circle"></i>
                      <i *ngIf="passwordValidatorStates.isUppercase" class="icon ion-md-checkmark-circle"></i>
                      1 majuscule
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-col>
              <ion-col size="12" size-md>
                <ion-grid class="ion-no-padding">
                  <ion-row>
                  <ion-col size="6" class="text-left small-text" [ngClass]="{'text-gray': !passwordValidatorStates.isSpecialChar}">
                    <i *ngIf="!passwordValidatorStates.isSpecialChar" class="icon ion-md-close-circle"></i>
                    <i *ngIf="passwordValidatorStates.isSpecialChar" class="icon ion-md-checkmark-circle"></i>
                    1 caractère spécial
                    <br><span>#?!@$%^&*-</span>
                  </ion-col>
                  <ion-col size="6" class="text-left small-text" [ngClass]="{'text-gray': !passwordValidatorStates.isNumber}">
                    <i *ngIf="!passwordValidatorStates.isNumber" class="icon ion-md-close-circle"></i>
                    <i *ngIf="passwordValidatorStates.isNumber" class="icon ion-md-checkmark-circle"></i>
                    1 chiffre
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </ion-col>
            </ion-row>
          </ion-grid>
          <div>
            <button id="bouton-continue" [disabled]="!passwordForm.valid || !isActive || dataSent" class="btn full-width" (click)="sendCompData({action: 'navigate', data: {direction: 'next'}})">Se connecter</button>
          </div>
          <p class="forgotterPasswordLink clickable" (click)="resetPwd()">Mot de passe oublié&nbsp;?</p>
        </form>
      </div>
    </div>
  </div>
  <div id="image" class="visuel"></div>
</div>