import { environment } from './../../../environments/environment';
import { Session } from '../../models/session';
import { SessionsStrategy } from './sessionsStrategy.interface';

import { Student } from '../../models/student';
import { Sessions } from '../../models/sessions';
import { StorageService } from '../../services/storage.service';
import { HttpClient } from '@angular/common/http';
import { switchMap, catchError } from 'rxjs/operators';
import { SeriesService } from '../../services/series.service';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';
import { of } from 'rxjs';

@EasyDebugDecorator
export class LocalSessions extends Sessions implements SessionsStrategy {

  sessionError: Session;

  constructor(
    private storageService: StorageService,
    private http: HttpClient,
    private seriesService: SeriesService,
  ) {
    super();
  }

  init() {
  }

  async getSessions(student: Student, local?: boolean): Promise<Session[]> {
    const sessions: Session[] = [];
    let localSessions = [];
    if (!!student && !!student.remoteId) {
      localSessions = await this.storageService.get(`${student.remoteId}-Sessions`);
      if (!!localSessions && localSessions.length > 0) {
        for (const sessionData of localSessions) {
          const session = new Session(sessionData);
          session.answers = this.serializeAnswers(session.answers);
          sessions.push(session);
        }
      } else {
        if (!!local && local) {
          return [];
        }
        if (student.remoteId !== 'GUEST') {
          const url = `${environment.cdrBase}/v${environment.apiOptiVersion}/account/${student.remoteId}/sessions`;
          return this.http.get(url).pipe(
            switchMap(
              async data => {
                if (!!data && Array.isArray(data)) {
                  const lSeries = await this.seriesService.seriesContext.getSeriesList(student);
                  for (const serieSessions of data) {
                    const serieId = serieSessions.serie_id;
                    if (!!serieSessions && !!serieSessions.sessions && !!serieSessions.sessions.data && serieSessions.sessions.data.length > 0) {
                      for (const sessionData of serieSessions.sessions.data) {
                        if (!!sessionData) {
                          const session = this.fillSession(sessionData, serieId);
                          const serie = lSeries.find(elt => elt.id === session.serieId);
                          if (!!serie) {
                            session.type = serie.type;
                          }
                          sessions.push(session);
                        }
                      }
                    }
                  }
                  await this.setSessions(sessions, student);
                  return sessions;
                }
                return [];
              }
            ),
            catchError(
              async () => {
                return [];
              }
            )
          ).toPromise();
        }
      }
    }
    return sessions;
  }

  async getSession(sessionId: string, student: Student): Promise<Session> {
    let sessionToSend: Session = null;
    if (!!student && !!sessionId) {
      const sessions = await this.getSessions(student);
      if (!!sessions && sessions.length > 0) {
        sessionToSend = sessions.find(elt => elt.id === sessionId);
      }
      // console.log('answer count => ', sessionToSend.answersCount);
      // console.log('answer length => ', sessionToSend.answers.length);
      if (
        !!sessionToSend && !!sessionToSend.answers &&
        !!sessionToSend.type && !!sessionToSend.answersCount &&
        (
          sessionToSend.answersCount === sessionToSend.answers.length ||
          sessionToSend.type === 'Error'
        )
      ) {
        return sessionToSend;
      } else {
        if (!!student.remoteId && student.remoteId !== 'GUEST') {
          const url = `${environment.cdrBase}/v${environment.apiVersion}/account/${student.remoteId}/sessions/${sessionId}`;
          return this.http.get(url).pipe(
            switchMap(
              async (data: any) => {
                let session: Session = null;
                if (!!data && !!data.data && !!data.data.attributes && !!data.data.attributes.serie_id) {
                  session = this.fillSession(data.data, data.data.attributes.serie_id);
                  if (!!session) {
                    const lSeries = await this.seriesService.seriesContext.getSeriesList(student);
                    const serie = lSeries.find(elt => elt.id === session.serieId);
                    if (!!serie) {
                      session.type = serie.type;
                    }
                    await this.setSession(session, student);
                  }
                }
                // console.log('localSessions getSession return session', JSON.parse(JSON.stringify(session)));
                return session;
              }
            ),
            catchError(
              async () => {
                return sessionToSend;
              }
            )
          ).toPromise();
        }
      }
    }
    return sessionToSend;
  }

  async setSessions(sessions: Session[], student: Student): Promise<Session[]> {
    return await this.storageService.set(`${student.remoteId}-Sessions`, sessions);
  }

  async setSession(session: Session, student: Student) {
    let sessions = await this.getSessions(student);
    // console.log('setSession param session ' + JSON.stringify(session));
    // console.log('setSession sessions ' + JSON.stringify(sessions));
    if (!!session && !!session.id && !!sessions && !!sessions.find(elt => elt.id === session.id)) {
      // console.log('cas 1');
      sessions.splice(sessions.findIndex(elt => elt.id === session.id), 1, session);
    } else if (!!sessions && sessions.find(elt => elt.id === session.id) === undefined) {
      // console.log('cas 2');
      sessions.push(session);
    } else {
      // console.log('cas 3');
      sessions = [];
      sessions.push(session);
    }
    await this.setSessions(sessions, student);
    return session;
  }

  async getErrorSession(student: Student): Promise<Session> {
    if (!!student) {
      const sessions = await this.getSessions(student);
      const errorSession = sessions.find(elt => elt.type === 'Error');
      if (!!errorSession) {
        return errorSession;
      } else {
        if (!!this.sessionError) {
          return this.sessionError;
        }
        if (!!student.remoteId && student.remoteId !== 'GUEST') {
          const url = `${environment.cdrBase}/v${environment.apiVersion}/error_session`;
          return this.http.get(url).pipe(
            switchMap(
              async (data: any) => {
                if (!!data && !!data.data && !!data.data.attributes && !!data.data.attributes.serie_id) {
                  this.sessionError = this.fillSession(data.data, data.data.attributes.serie_id);
                }
                return this.sessionError;
              }
            ),
            catchError(
              err => {
                return of(this.sessionError);
              }
            )
          ).toPromise();
        }
      }
    }
    return null;
  }
}
