import { HttpClient } from '@angular/common/http';
import { QuestionsService } from './../../services/questions.service';
import { environment } from './../../../environments/environment';
import { QuestionsStrategy } from './questionsStrategy.interface';

import { Student } from '../../models/student';
import { Questions } from '../../models/questions';
import { Question } from '../../models/question';
import { StorageService } from '../../services/storage.service';
import { switchMap, catchError } from 'rxjs/operators';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
export class LocalQuestions extends Questions implements QuestionsStrategy {

  constructor(
    private storageService: StorageService,
    private http: HttpClient,
  ) {
    super();
  }

  init() {
  }

  async getQuestion(questionId, student: Student, local?: boolean): Promise<Question> {
    if (!!student && !!student.remoteId && !!questionId) {
      const data = await this.storageService.get(`${student.remoteId}-Question-${questionId}`);
      if (!!data) {
        return new Question(data);
      } else {
        if (!!local && local) {
          return null;
        }
        const url = `${environment.cdrBase}/v${environment.apiVersion}/questions/${questionId}`;

        return this.http.get(url).pipe(
          switchMap(
            async (questionData: any) => {
              if (!!questionData && !!questionData.data) {
                const question = this.fillQuestion(questionData.data);
                return await this.setQuestion(question, student);
              }
              return null;
            }
          ),
          catchError(
            async () => {
              return null;
            }
          )
        ).toPromise();
      }
    }
    return null;
  }

  async getQuestions(student: Student, local?: boolean): Promise<Question[]> {
    if (!!student && !!student.remoteId) {
      const datas = await this.storageService.get(`${student.remoteId}-Questions`);
      // console.log('localQuestions getQuestions datas', JSON.parse(JSON.stringify(datas)));
      if (!!datas && datas.length > 0) {
        return this.serializeQuestions(datas);
      } else {
        if (!!local && local) {
          return [];
        }
        const url = `${environment.cdrBase}/v${environment.apiVersion}/questions`;

        return this.http.get(url).pipe(
          switchMap(
            async (questionsData: any) => {
              if (!!questionsData && !!questionsData.data && questionsData.data.length > 0) {
                const questions = this.fillQuestions(questionsData.data, 'remote');
                await this.setQuestions(questions, student);
                return questions;
              }
              return [];
            }
          ),
          catchError(
            async () => {
              return [];
            }
          )
        ).toPromise();
      }
    }
    return [];
  }

  async setQuestions(questions: Question[], student: Student): Promise<Question[]> {
    if (!!student && !!student.remoteId && !!questions) {
      const datas = await this.storageService.set(`${student.remoteId}-Questions`, questions);
      if (!!datas) {
        return this.serializeQuestions(datas);
      }
      return questions;
    }
    return [];
  }

  async setQuestion(question: Question, student: Student): Promise<Question> {
    if (!!student && !!student.remoteId && !!question && !!question.id) {
      const data = await this.storageService.set(`${student.remoteId}-Question-${question.id}`, question);
      if (!!data) {
        return new Question(data);
      }
      return question;
    }
    return null;
  }
}
