<ion-content>
  <button class="close-modal small-clickable" (click)="closeModalPack()" *ngIf="closable">
    <img src="assets/2022/icons/close-off.svg" alt="EnVoitureSimone" height="24">
  </button>

  <!-- MODAL CRITICAL -->
  <div class="modal-errors content-center" *ngIf="modalType == 'critical'">
    <div class="modal-errors-emoji">
      <img src="assets/png/face-with-head-bandage.png" alt="EnVoitureSimone">
      <!-- 🤕 -->
    </div>
    <h3>Nous ne sommes pas parvenus à récupérer tes informations</h3>
    <p>Reconnecte-toi. Si le problème persiste contacte le Support.</p>
    <button class="btn full-width light margin-top-16" (click)="login()">Me connecter</button>
    <button class="btn full-width margin-top-8" (click)="register()">Créer un compte gratuit</button>
    <p *ngIf="!!error && !!error.errorCode" class="text-center small-text text-info-shade margin-top-16">Code erreur : {{error.errorCode}}</p>
  </div>
  
  <!-- MODAL CRITICAL -->
  <div class="modal-errors content-center" *ngIf="modalType == 'unavaibleExternalContent'">
    <div class="modal-errors-emoji">
      <img src="assets/png/face-with-head-bandage.png" alt="EnVoitureSimone">
      <!-- 🤕 -->
    </div>
    <h3>Ce contenu est indisponible pour le moment</h3>
    <p>Une erreur est survenue sur notre service. Réessaie plus tard.</p>
    <button class="btn full-width light margin-top-16" (click)="goHome()">Revenir à l’accueil</button>
    <button class="btn full-width margin-top-8" (click)="retry()">Réessayer</button>
    <p *ngIf="!!error && !!error.errorCode" class="text-center small-text text-info-shade margin-top-16">Code erreur : {{error.errorCode}}</p>
  </div>
</ion-content>
