import { RemoteSessions } from './../Strategy/sessionsStrategy/remoteSessions';
import { ServiceLocator } from './serviceLocator.service';
import { SessionsContext } from './../Strategy/sessionsStrategy/sessionsContext';
import { Sessions } from './../models/sessions';
import { Session } from './../models/session';
import { Student } from './../models/student';
import { Serie } from '../models/serie';

import { environment } from '../../environments/environment';

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { NetworkStatusService } from './network-status.service';
import { SeriesService } from './series.service';
import { Initialable } from './app-init.service';
import { StorageService } from './storage.service';
import { StudentService } from './student.service';

import { of, Observable, from } from 'rxjs';
import { switchMap, mergeMap, catchError } from 'rxjs/operators';
import { LocalSessions } from '../Strategy/sessionsStrategy/localSessions';
import { Platform } from '@ionic/angular';
import { StatsService } from './stats.service';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import { EasyDebugLogger } from '../easy-debug-logger/easy-debug-logger';
import { SentryService } from '@app/app.services';
import { UserErrorHandlerService } from './user-error-handler.service';
// import { DevDebugModeService } from '../app.services';
import * as moment from 'moment-timezone';

@Injectable({
  providedIn: 'root'
})
@Initialable({ step: 'init4', initializer: 'onInit'})
@EasyDebugDecorator
export class SessionsService extends Sessions {

  private _sessionsContext: SessionsContext;
  easyDebugLogger = EasyDebugLogger.getInstance();

  constructor(
    private storageService: StorageService,
    private studentService: StudentService,
    private http: HttpClient,
    private seriesService: SeriesService,
    private networkService: NetworkStatusService,
    private platform: Platform,
    private statsService: StatsService,
    private sentryService: SentryService,
    private userErrorHandlerService: UserErrorHandlerService,
    // private devDebugModeService: DevDebugModeService,
  ) {
    super();
    // console.log('Sessions service instanciate');
  }

  async onInit() {
    // console.log(`Launching Sessions Service init`);
    const student = this.studentService.student;
    const transformed = await this.storageService.get('transformed');
    // const transformed = true;

    if (this.platform.is('cordova') || student.status === 'guest' || transformed) {
      this._sessionsContext = new SessionsContext(new LocalSessions(this.storageService, ServiceLocator.injector.get(HttpClient), this.seriesService));
    } else {
      this._sessionsContext = new SessionsContext(new RemoteSessions(ServiceLocator.injector.get(HttpClient)));
      this._sessionsContext.init();
    }
    return Promise.resolve('Sessions done');
  }

  formatDebug(data: any) {
    let str = '';
    if (!!data) {
      if (Array.isArray(data)) {
        data.forEach(function(entry: object) {
          str = (str !== '') ? str + '\n ######### \n' : str;
          if (typeof entry === 'object' &&
            !Array.isArray(entry) &&
            entry !== null
          ) {
            for (const [key, value] of Object.entries(entry)) {
              if (!!key && !!value) {
                if ((key === '_answers' || key === 'answers') && Array.isArray(value)) {
                  str += `${key}: ${value.length} - `;
                } else {
                  str += `${key}: ${JSON.stringify(value)} - `;
                }
              }
            }
          }
        });
      } else {
        if (typeof data === 'object' &&
          !Array.isArray(data) &&
          data !== null
        ) {
          for (const [key, value] of Object.entries(data)) {
            if (!!key && !!value) {
              if ((key === '_answers' || key === 'answers') && Array.isArray(value)) {
                str += `${key}: ${value.length} - `;
              } else {
                str += `${key}: ${JSON.stringify(value)} - `;
              }
            }
          }
        } else {
          str += JSON.stringify(data) + ' - ';
        }
      }
    }
    return str;
  }

  fetchSession(student: Student, sessionId: string, serieId: string): Observable<any> {
    if (!!student && !!student.remoteId && student.remoteId !== 'GUEST') {
      const url = `${environment.cdrBase}/v${environment.apiVersion}/account/${student.remoteId}/sessions/${sessionId}`;
      return this.http.get(url).pipe(
        switchMap(
          (res: any) => {
            // console.log('sessionsService fetchSession res ', res.data);
            return of(this.fillSession(res.data, serieId));
          }
        ),
        catchError(
          async err => {
            this.userErrorHandlerService.addError({criticity: 30, service: 'fetchSession', platform: 'both', data: err, errorCode: 'ssfs'});
            return null;
          }
        )
      );
    }
  }

  fetchSessions(updatedAt: string, student: Student): Observable<Session[]> {
    let query = '';
    const sessions: Session[] = [];

    if (!!updatedAt) {
      if (!!student && !!student.remoteId && student.remoteId !== 'GUEST') {
        query = `?updated_at=${updatedAt}`;
        query = query.replace('+', '%2B');
        const url = `${environment.cdrBase}/v${environment.apiVersion}/account/${student.remoteId}/sessions${query}`;
        return this.http.get(url).pipe(
          switchMap(
            data => {
              if (!!data && Array.isArray(data)) {
                for (const serieSessions of data) {
                  if (!!serieSessions.serie_id) {
                    const serieId = serieSessions.serie_id;
                    if (!!serieSessions && !!serieSessions.sessions && !!serieSessions.sessions.data && serieSessions.sessions.data.length > 0) {
                      for (const sessionData of serieSessions.sessions.data) {
                        sessions.push(this.fillSession(sessionData, serieId));
                      }
                    }
                  }
                }
                return of(sessions);
              }
              return of(sessions);
            }
          ),
          catchError(
            err => {
              this.userErrorHandlerService.addError({criticity: 30, service: 'fetchSessions', platform: 'both', data: err, errorCode: 'ssfss'});
              return of(sessions);
            }
          )
        );
      }
    }
    return of(sessions);
  }

  async getSessionsType(student: Student): Promise<Session[]> {
    const sessions: Session[] = [];
    if (!!student) {
      const lSessions = await this._sessionsContext.getSessions(student, true);
      const lSeries = await this.seriesService.seriesContext.getSeriesList(student, true);
      for (const lSession of lSessions) {
        if (!!lSeries) {
          const serie = lSeries.find(elt => elt.id === lSession.serieId);
          if (!!serie) {
            lSession.type = serie.type;
          }
        }
        sessions.push(lSession);
      }
      return await this._sessionsContext.setSessions(sessions, student);
    }
    return sessions;
  }

  syncSessions(updatedAt: string, student: Student): Observable<Session[]> {
    if (!!student && !!updatedAt && updatedAt !== '') {
      return this.fetchSessions(updatedAt, student).pipe(
        switchMap(
          async sessions => {
            // console.log('syncSessions updatedAt = ' + updatedAt + ' => ', sessions);
            let sessionsToSync: Session[] = [];
            if (!!sessions && sessions.length > 0) {
              const localSessions = await this._sessionsContext.getSessions(student, true);
              sessionsToSync = this.sessionsNeedSync(sessions, localSessions);
            }
            // console.log('syncSessions sessionsToSync = ' + updatedAt + ' => ', sessionsToSync);
            return sessionsToSync;
          }
        ),
        switchMap(
          async toSync => {
            let syncedSessions = [];
            if (!!toSync && toSync.length > 0) {
              syncedSessions = await this.getSessionsDetails(toSync, student);
              // console.log('sessionsService syncedSessions sessionsDetails', syncedSessions);
              syncedSessions = await this.replaceSessions(syncedSessions, student);
              // console.log('sessionsService syncedSessions replaced', syncedSessions);
            }
            return syncedSessions;
          }
        ),
        switchMap(
          async sessions => {
            if (!!sessions && sessions.length > 0) {
              return sessions;
            }
            const lSessions = await this._sessionsContext.getSessions(student, true);
            return lSessions;
          }
        )
      );
    }
    return of([]);
  }

  async replaceSessions(toReplace: Session[], student: Student): Promise<Session[]> {
    let sessions: Session[] = [];
    if (!!student) {
      sessions = await this._sessionsContext.getSessions(student, true);
      if (!!toReplace && toReplace.length > 0) {
        for (const session of toReplace) {
          // console.log('sessionsService replaceSessions ', session);
          if (!!sessions.find(elt => elt.id === session.id)) {
            const index = sessions.findIndex(elt => elt.id === session.id);
            if (index !== -1) {
              sessions[index] = session;
            }
          } else {
            sessions.push(session);
          }
        }
        return this._sessionsContext.setSessions(sessions, student);
      }
    }
    return sessions;
  }

  getSessionsDetails(sessionsToSync: Session[], student: Student): Promise<Session[]> {
    return new Promise((resolve) => {
      const sessions = [];
        this.handleSessionCalls(sessionsToSync, student).subscribe(
          async session => {
            // console.log('sessionsService getSessionsDetails session ', session);
            sessions.push(session);
            if (sessions.length === sessionsToSync.length) {
              resolve(sessions);
            }
          }
        );
    });
  }

  handleSessionCalls(sessions: Session[], student: Student): Observable<Session> {
    if (!!sessions && sessions.length > 0) {
      return from(sessions).pipe(
        mergeMap(
          session => {
            // console.log('handleSessionCalls session ', session);
            return this.fetchSession(student, session.id, session.serieId).pipe(
              switchMap(
                sessionDetails => {
                  // console.log('handleSessionCalls session returned sessionDetails', sessionDetails);
                  return of(sessionDetails);
                }
              )
            );
          }
        )
      );
    }
    return of(null);
  }

  // force la creation d'une session (recommencer)
  createSessionBySerie(serie: Serie, student: Student, options: any): Observable<Session> {
    if (!!serie && !!student && !!options) {
      return this.getNewSession(serie, student, options);
    }
    return of(null);
  }

  // lance une serie qu'elle soit pending ou pas (create ou resume)
  openSessionBySerie(serie: Serie, student: Student, options: any): Observable<Session> {
    if (!!serie && !!student && !!options) {
      return of([]).pipe(
        switchMap(
          async () => {
            let serieDetails = null;
            if (this.platform.is('cordova')) {
              serieDetails = serie;
            } else {
              serieDetails = serie;
            }
            // console.log('opensession by serie => ', serieDetails);
            let sessions = [];
            const resgetSessions = await this._sessionsContext.getSessions(student);
            if (!!resgetSessions && !!resgetSessions.errorCode && resgetSessions.errorCode === 'E301') {
              // this.userErrorHandlerService.addError({criticity: 30, service: 'fetchSessions', platform: 'both', data: resgetSessions.errorOriginal, errorCode: 'ssfss'});
            } else {
              sessions = resgetSessions;
            }
            let index = 0;
            if (options.mode === 'Exam') {
              index = 1;
            }
            const lastSessions: any = (!!sessions) ? sessions.find(elt => elt.id === serieDetails.lastSessions[index] && elt.state === 'pending') : null;
            if (!!lastSessions && !!lastSessions.answersCount && lastSessions.answersCount < serieDetails.nbQuestions) {
              return lastSessions;
            }
            // console.log('open session by serie sessions => ', sessions);
            let scopedSessions = null;

            if (this.networkService?.isOffline()) {
              scopedSessions = (!!sessions) ? sessions.filter(elt => elt.serieId === serie.id && elt.state === 'pending' && elt.mode === options.mode) : [];
            } else {
              scopedSessions = (!!sessions) ? sessions.filter(elt => elt.serieId === serie.id && elt.state === 'pending' && elt.mode === options.mode && elt.fakeId === null) : [];
            }

            if (!!scopedSessions && scopedSessions.length > 0) {
              return scopedSessions[0];
            }
            return null;
          }
        ),
        switchMap(
          session => {
            if (!!session) {
              return of(session);
            } else {
              return this.createSessionBySerie(serie, student, options);
            }
          }
        )
      );
    }
    return of(null);
  }

  // reprendre ou créer des sessions avec les règles Estelle (pas une série en particulier)
  resumeOrCreateSession(student: Student, options: any): Observable<Session> {
    if (!!student && !!options) {
      return of([]).pipe(
        switchMap(
          async () => {
            return await this.resumeSession(student, options);
          }
        ),
        switchMap(
          session => {
            if (!!session) {
              return of(session);
            }
            return this.getSerieAndCreateSession(student, options);
          }
        )
      );
    }
    return of(null);
  }

  getSerieAndCreateSession(student: Student, options: any): Observable<Session> {
    if (!!student && !!options) {
      return of([]).pipe(
        switchMap(
          async () => {
              let serie = await this.getNotDoneSerie(student);
              if (serie === null) {
                serie = await this.getLessDoneSerie(student);
              }
              if (serie === null) {
                serie = await this.getSerieWithMoreError(student);
              }
              if (serie === null) {
                serie = await this.getRandomSerie(student);
              }
              return serie;
            }
        ),
        switchMap(
          serie => {
            if (!!serie) {
              return this.createSessionBySerie(serie, student, options);
            }
          }
        )
      );
    }
    return of(null);
  }

  async resumeSession(student: Student, options: any): Promise<Session> {
    if (!!student && !!options) {
      const session = await this.openPendingSession(student, options);
      if (!!session) {
        return session;
      }
    }
    return null;
  }

  dateToFormatFr(input?: any) {
    // console.log('-------------------');
    // console.log('dateToFormatFr input', input, typeof input);
    let output = this.dateToMoment(); // today by default
    if (!!input) {
      output = this.dateToMoment(input);
      if (typeof input === 'string') {
        output = this.dateToMoment(input);
        // if (!input.includes('+')) {
        //   console.log('input string', input);
        // }
        if (input.includes('/')) {
          console.error('input string', input);
        }
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'number') {
        // timestamp
        output = this.dateToMoment(input);
        // console.log('input timestamp', input);
        // console.log('output', output);
        // console.log('-------------------');
      } else if (typeof input === 'object') {
        if (!moment.isMoment(input) && !!input.year && !!input.month && !!input.day) {
          // custom object
          const year = input.year;
          const month = (Number(input.month) < 10) ? '0' + Number(input.month) : Number(input.month);
          const day = (input.day < 10) ? '0' + input.day : input.day;
          const str = year + '-' + month + '-' + day + ' 00:00:00';
          output = this.dateToMoment(str);
          // console.log('input custom object', input, str);
          // console.log('output', output);
          // console.log('-------------------');
        } else {
          // date object
          output = this.dateToMoment(input);
          // console.log('input date', input, input.isValid());
          // console.log('output', output);
        }
      }
    }
    if (!output.isValid()) {
      console.log('-------------------');
      console.error('dateToFormatFr input', input, typeof input);
      console.error('dateToFormatFr output', output, output.toString(), output.isValid());
      console.error('-------------------');
    }
    // console.log('dateToFormatFr output', output, output.toString(), output.isValid());
    // console.log('-------------------');
    return output;
  }

  dateToMoment(input?: any) {
    const zone = "Europe/Paris";
    moment.tz.setDefault(zone);
    moment.locale('fr');
    if (!!input) {
      return moment(input).tz(zone);
    }
    return moment().tz(zone);
  }

  async openPendingSession(student: Student, options: any): Promise<Session> {
    let session: Session = null;
    let sessions: Session[];
    if (!!student && !!options) {
      if (!!options.mode) {
        sessions = (await this._sessionsContext.getSessions(student)).filter(elt => elt.state === 'pending' && elt.mode.includes(options.mode) && elt.type !== 'Discovery');
      } else {
        sessions = (await this._sessionsContext.getSessions(student)).filter(elt => elt.state === 'pending' && elt.type !== 'Discovery');
      }
      if (!!sessions && sessions.length > 1) {
        // DATE TO CHANGE
        const lastDate = this.dateToFormatFr(new Date(Math.max.apply(null, sessions.map(s => new Date(s.lastAnsweredAt)))));
        // DATE TO CHANGE
        session = sessions.find(elt => this.dateToFormatFr(elt.lastAnsweredAt).valueOf() === lastDate.valueOf());
      } else if (!!sessions && sessions.length === 1) {
        session = sessions[0];
      }
    }
    return session;
  }

  async getRandomSerie(student: Student): Promise<Serie> {
    if (!!student) {
      const series = (await this.seriesService.seriesContext.getSeriesList(student)).filter(elt => elt.type !== 'Discovery');
      return series[(Math.floor(Math.random() * 1000000000)) % series.length];
    }
    return null;
  }

  async getSerieWithMoreError(student: Student): Promise<Serie> {
    if (!!student) {
      const series = (await this.seriesService.seriesContext.getSeriesList(student)).filter(elt => elt.type !== 'Discovery');
      let nbWrong = 0;
      let retSerie = null;
      for (const serie of series) {
        if (!!serie.lastSessions && !!serie.lastSessions[0]) {
          const session = await this._sessionsContext.getSession(serie.lastSessions[0], student);
          if (!!session && !!session.stats && !!session.stats.badAnswers) {
            if (session.stats.badAnswers > nbWrong) {
              nbWrong = session.stats.badAnswers;
              retSerie = serie;
            } else if (session.stats.badAnswers === nbWrong) {
              nbWrong = 0;
            }
          }
        }
      }
      return retSerie;
    }
    return null;
  }

  async getLessDoneSerie(student: Student): Promise<Serie> {
    let retSerie: Serie = null;
    if (!!student) {
      const series = (await this.seriesService.seriesContext.getSeriesList(student)).filter(elt => elt.type !== 'Discovery');
      const sessions = (await this._sessionsContext.getSessions(student)).filter(elt => elt.type !== 'Discovery');
      let nbSessions = sessions.length;
      for (const serie of series) {
        const serieSessions = sessions.filter(elt => elt.serieId === serie.id);
        if (serieSessions.length < nbSessions) {
          nbSessions = serieSessions.length;
          retSerie = serie;
        }
      }
    }
    return retSerie;
  }

  async getNotDoneSerie(student: Student): Promise<Serie> {
    if (!!student) {
      const series = (await this.seriesService.seriesContext.getSeriesList(student)).filter(elt => elt.type !== 'Discovery');
      const sessions = (await this._sessionsContext.getSessions(student)).filter(elt => elt.type !== 'Discovery');
      if (!!series && series.length > 0) {
        for (const serie of series) {
          const doneSessions = sessions.filter(elt => elt.serieId === elt.id);
          if (doneSessions.length === 0) {
            return serie;
          }
        }
      }
    }
    return null;
  }

  getNewSession(serie: Serie, student: Student, options: any): Observable<Session> {
    if (!!student && !!serie && !!options) {
      return this.fetchNewSession(serie, student, options).pipe(
        switchMap(
          async session => {
            if (!!session) {
              let sessions = [];
              const resgetSessions = await this._sessionsContext.getSessions(student);
              if (!!resgetSessions && !!resgetSessions.errorCode && resgetSessions.errorCode === 'E301') {
                // this.userErrorHandlerService.addError({criticity: 30, service: 'fetchSessions', platform: 'both', data: resgetSessions.errorOriginal, errorCode: 'ssfss'});
              } else {
                sessions = resgetSessions;
              }
              sessions.push(session);
              await this._sessionsContext.setSessions(sessions, student);
              return session;
            }
            return null;
          }
        )
      );
    }
    return of(null);
  }

  fetchNewSession(serie: Serie, student: Student, options: any): Observable<Session> {
    if (!!student && !!student.remoteId && !!serie && !!serie.id && !!serie.type && !!options) {
      const url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/account/${student.remoteId}/series/${serie.id}/session`;
      const data: any = {};
      if (!!options.mode) {
        options.mode = `Cdr::${options.mode}Session`;
        data.session_type = options.mode;
      }

      if (!this.networkService?.isOffline() && student.remoteId !== 'GUEST') {
        return this.http.post(url, data).pipe(
          switchMap((res: any) => {
            const tmpData = (!!res.data) ? res.data : res;
            const session: Session = new Session({
              answers: [],
              answersCount: 0,
              lastAnsweredAt: null,
              serieId: (!!serie.id) ? serie.id : null,
              state: 'pending',
              stats: {
                badAnswers: 0,
                goodAnswers: 0
              },
              mode: (!!options.mode) ? `${options.mode}` : null,
              fakeId: null,
              type: (!!serie.type) ? serie.type : null,
              id: (!!tmpData.id) ? tmpData.id : null,
            });
            this.statsService.send({ name: 'session:started', payload: { serie: serie } });
            return of(session);
          }),
          catchError(
            err => {
              return of(null);
            }
          )
        );
      }
      this.statsService.send({ name: 'session:started', payload: { serie: serie } });
      return from(this.createFakeSession(serie.id, serie.type, options));
    }
    return null;
  }

  async createFakeSession(serieId: string, serieType: string, options: any): Promise<Session> {
    const fakeId = (!!options && !!options.sessionId) ? options.sessionId : await this.getSessionIdUnused(Math.floor(Math.random() * 1000000000).toString());
    const session: Session = new Session({
      state: 'pending',
      answersCount: 0,
      answers: [],
      id: fakeId,
      serieId: (!!serieId) ? serieId : null,
      lastAnsweredAt: null,
      fakeId: 'offline',
      stats: {
        badAnswers: 0,
        goodAnswers: 0
      },
      mode: (!!options && !!options.mode) ? `${options.mode}` : null,
      syncState: 'offline',
      type: (!!serieType) ? serieType : null
    });
    return session;
  }

  async getSessionIdUnused(sessionId: string): Promise<string> {
    let sessions = [];
    const resgetSessions = await this._sessionsContext.getSessions(this.studentService.student);
    if (!!resgetSessions && !!resgetSessions.errorCode && resgetSessions.errorCode === 'E301') {
      // this.userErrorHandlerService.addError({criticity: 30, service: 'fetchSessions', platform: 'both', data: resgetSessions.errorOriginal, errorCode: 'ssfss'});
    } else {
      sessions = resgetSessions;
    }
    if (sessions.length > 0 && !!sessionId) {
      for (const session of sessions) {
        if (!!session.id && session.id === sessionId) {
          return await this.getSessionIdUnused(Math.floor(Math.random() * 1000000000).toString());
        }
      }
    }
    return (sessionId);
  }

  async deleteSessionFromLastSession(sessionId: string): Promise<boolean> {
    const student = this.studentService.student;
    const seriesList = await this.seriesService.seriesContext.getSeriesList(student);
    // On est pas censé avoir plus d'une serie concerné par la presence d'une session dans ses lastSessions mais dans le doute on boucle pour tout clean
    let seriesConcerned = [];
    if (seriesList.length > 0 && !!sessionId) {
      seriesConcerned = seriesList.filter(elt => elt.lastSessions.includes(sessionId));
      if (seriesConcerned.length > 0) {
        let serieHasBeenModified = false;
        for (const serie of seriesConcerned) {
          const serieConcerned = await this.seriesService.seriesContext.getSerie(serie.id, student, true);
          const serieIndex = seriesList.findIndex(elt => elt.id === serie.id);
          let isSerieModified = false;
          if (
            !!seriesList[serieIndex] && !!seriesList[serieIndex].lastSessions &&
            seriesList[serieIndex].lastSessions.length > 0 &&
            seriesList[serieIndex].lastSessions[0] === sessionId
          ) {
            serieConcerned.lastSessions[0] = null;
            seriesList[serieIndex].lastSessions[0] = null;
            isSerieModified = true;
            serieHasBeenModified = true;
          } else if (
            !!seriesList[serieIndex] && !!seriesList[serieIndex].lastSessions &&
            seriesList[serieIndex].lastSessions.length > 1 &&
            seriesList[serieIndex].lastSessions[1] === sessionId
          ) {
            serieConcerned.lastSessions[0] = null;
            seriesList[serieIndex].lastSessions[1] = null;
            isSerieModified = true;
            serieHasBeenModified = true;
          }
          if (isSerieModified) {
            const serieModified = await this.seriesService.seriesContext.setSerie(serieConcerned, student);
          }
        }
        if (serieHasBeenModified) {
          const seriesModified = await this.seriesService.seriesContext.setSeriesList(seriesList, student);
        }
      } else {
        this.easyDebugLogger.logManual('SessionsService -> deleteSessionFromLastSession() -> seriesConcerned not found - no session found in series last session - should find at least one', seriesConcerned);
        this.sentryService.sendToSentry('SessionsService -> deleteSessionFromLastSession() -> seriesConcerned not found - no session found in series last session - should find at least one', this.studentService.student);
      }
    } else {
      this.easyDebugLogger.logManual('SessionsService -> deleteSessionFromLastSession() -> seriesConcerned not found - no session found in series last session - should find at least one', seriesConcerned);
      this.sentryService.sendToSentry('SessionsService -> deleteSessionFromLastSession() -> seriesConcerned not found - no session found in series last session - should find at least one', this.studentService.student);
    }
    return;
  }

  async deleteSession(sessionId: string) {
    if (!!sessionId) {
      const student = this.studentService.student;
      let sessions = [];
      const resgetSessions = await this.sessionsContext.getSessions(student);
      if (!!resgetSessions && !!resgetSessions.errorCode && resgetSessions.errorCode === 'E301') {
        // this.userErrorHandlerService.addError({criticity: 30, service: 'fetchSessions', platform: 'both', data: resgetSessions.errorOriginal, errorCode: 'ssfss'});
      } else {
        sessions = resgetSessions;
      }
      const session = await this.sessionsContext.getSession(sessionId, student);
      if (!!session) {
        const indexSession = sessions.findIndex(elt => elt.id === session.id);
        if (indexSession !== -1) {
          sessions.splice(indexSession, 1);
          const newSessions = await this.sessionsContext.setSessions(sessions, student);
          await this.deleteSessionFromLastSession(sessionId);
        }
      }
    }
    return;
  }

  public get sessionsContext(): SessionsContext { return this._sessionsContext; }
}
