import { SessionsService } from './../../services/sessions.service';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';

import { SessionsStrategy } from './sessionsStrategy.interface';

import { Session } from '../../models/session';
import { Student } from '../../models/student';
import { Sessions } from '../../models/sessions';

import { switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
export class RemoteSessions extends Sessions implements SessionsStrategy {

  constructor(private http: HttpClient) {
    super();
  }

  init() {
    this.sessions = [];
    this.sessionsDetails = [];
  }

  async getSessions(student: Student, local?: boolean): Promise<any> {
    if (!!this.sessions && this.sessions.length > 0) {
      return this.sessions;
    }
    if (!!student && !!student.remoteId && student.remoteId !== 'GUEST') {
      const url = `${environment.cdrBase}/v${environment.apiOptiVersion}/account/${student.remoteId}/sessions`;
      return this.http.get(url).pipe(
        switchMap(
          data => {
            const sessions: Session[] = [];
            if (!!data && Array.isArray(data)) {
              for (const serieSessions of data) {
                const serieId = serieSessions.serie_id;
                const serieType = serieSessions.serie_type;
                if (!!serieSessions && !!serieSessions.sessions && !!serieSessions.sessions.data && serieSessions.sessions.data.length > 0) {
                  for (const sessionData of serieSessions.sessions.data) {
                    if (!!sessionData) {
                      sessions.push(this.fillSession(sessionData, serieId, serieType));
                    }
                  }
                }
              }
              this.sessions = sessions;
              return of(this.sessions);
            }
            return of({ errorCode: 'E301', errorMessage: 'remoteGetSessions Service failed', errorOriginal: 'No data in response'});
          }
        ),
        catchError(
          err => {
            return of({ errorCode: 'E301', errorMessage: 'remoteGetSessions Service failed', errorOriginal: err});
          }
        )
      ).toPromise();
    }
    return [];
  }

  async getSession(sessionId: string, student: Student): Promise<any> {
    if (!!this.sessionsDetails && this.sessionsDetails.length > 0 && !!this.sessionsDetails.find(elt => elt.id === sessionId)) {
      return this.sessionsDetails.find(elt => elt.id === sessionId);
    }
    if (!!student && !!student.remoteId && student.remoteId !== 'GUEST') {
      const url = `${environment.cdrBase}/v${environment.apiVersion}/account/${student.remoteId}/sessions/${sessionId}`;
      return this.http.get(url).pipe(
        switchMap(
          (data: any) => {
            let session: Session;
            if (!!data && !!data.data) {
              session = this.fillSession(data.data, data.data.attributes.serie_id);
              this.sessionsDetails.push(session);
            }
            return of(session);
          }
        ),
        catchError(
          err => {
            return of({ errorCode: 'E301', errorMessage: 'remoteGetSession Service failed', errorOriginal: err});
          }
        )
      ).toPromise();
    }
    return null;
  }

  async setSession(session: Session) {
    if (!!this.sessionsDetails && !!this.sessionsDetails.find(elt => elt.id === session.id)) {
      this.sessionsDetails.splice(this.sessionsDetails.findIndex(elt => elt.id === session.id), 1, session);
    } else {
      this.sessionsDetails.push(session);
    }
  }

  async setSessions(sessions: Session[], student: Student): Promise<Session[]> {
    this.sessions = sessions;
    return sessions;
  }

  async getErrorSession(student: Student): Promise<any> {
    let sessions = [];
    const resGetSessions = await this.getSessions(student);
    if (!!resGetSessions && !!resGetSessions.errorCode && resGetSessions.errorCode === 'E301') {
      return { errorCode: 'E301', errorMessage: 'getErrorSession remoteGetSessions Service failed', errorOriginal: resGetSessions.errorOriginal};
    } else {
      sessions = resGetSessions;
      const errorSession = sessions.find(elt => elt.mode === 'Cdr::ErrorSession');
      return errorSession;
    }
    return null;
  }
}
