import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

import { Initialable } from './app-init.service';
import { StudentService } from './student.service';
import { StorageService } from './storage.service';

import { NgxZendeskWebwidgetService } from '@nitsanzo/ngx-zendesk-webwidget';
import { Student } from '../models/student';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';
import { NetworkStatusService } from '../../app/services/network-status.service';

@Injectable({
  providedIn: 'root'
})
@Initialable({ step: 'init3', initializer: `init` })

@EasyDebugDecorator
export class ConfigUserService {

  zendeskTags = [];
  zendeskInitDone = false;
  tunnelTestAB = null;

  constructor(
    private platformService: Platform,
    private studentService: StudentService,
    private storageService: StorageService,
    private networkStatusService: NetworkStatusService,
    private zendeskService: NgxZendeskWebwidgetService,
  ) {
    // console.log('Config User service instanciate');
  }

  async init() {
    // console.log(`Launching Config User init`);
    const student = await this.studentService.getProfil();
    let firstName = 'Prénom';
    let lastName = 'Nom';
    let email = 'email@email.com';

    if (!!student && student.status !== 'guest') {
      firstName = student.firstName;
      lastName = student.lastName;
      email = student.email;
      this.zendeskInitDone = false;
    }
    // console.log('Zendesk IS initIALIZED', this.zendeskService.isInitialized);
    if (this.zendeskService.isInitialized) {
      if (!this.zendeskInitDone) {
        // console.log('Zendesk init default', firstName, lastName, email);
        this.setZendesk(firstName, lastName, email);
      }
    }
    this.networkStatusService.networkStatusObs$.subscribe((isNetworkOnline) => {
      if (isNetworkOnline && !this.zendeskInitDone) {
        if (this.zendeskService.isInitialized) {
          // console.log('Zendesk init update', firstName, lastName, email);
          this.setZendesk(firstName, lastName, email);
        }
      }
    });
    let config = await this.getUserConfig(student);
    // console.log(`Launching Config User init get config`, config);
    if (config === null || config === undefined) {
      config = await this.initUserConfig(student);
      // console.log(`Launching Config User init autre get config if null`, config);
    }
    // reset
    // console.log(`Launching Config User init reset`);
    if (this.studentService.studentInitLaunched) {
      config.learningContractStickyToasterShown = false;
      config.validateEmailStickyToasterShown = false;
      config.validateEmailStickyToasterClicked = false;
      config.outstandingPaymentModalShown = false;
      config.impayesStickyToasterShown = false;
      // console.log(`Launching Config User init reset set config`);
      await this.setUserConfig(student, config);
      // console.log(`Launching Config User init reset set config done`);
      this.studentService.studentInitLaunched = false;
    }
    // console.log(`Config User init done`);
    return config;
  }

  async initUserConfig(student: Student): Promise<any> {
    const config = {
      firstScreen: false,
      coursProgression: [],
      appRatingStepDone: 0,
      appRatingDone: false,
      learningContractStickyToasterShown: false,
      validateEmailStickyToasterShown: false,
      validateEmailStickyToasterClicked: false,
      outstandingPaymentModalShown: false,
      showPostRegistration: false,
      mute: true,
      modalSeries: [],
      iOSATTSliderShown: false,
      iOSATTFromRegister: false,
      iOSATTAnswered: false,
      iOSATTFirstTime: true,
      notationModalShownLastDate: null,
      pushNotifConsentActive: false
    };

    return this.setUserConfig(student, config);
  }

  setZendesk(fName: string, lName: string, email: string) {
    let tag = 'web_app_student';
    if (!this.networkStatusService?.isOffline()) {
      if (this.platformService.is('cordova')) {
        tag = (this.platformService.is('ios')) ? 'ios_app_student' : 'android_app_student';
      }
      this.zendeskTags.push(tag);
      this.zendeskService.zE('webWidget', 'identify', {
        name: `${fName} ${lName}`,
        email: email,
      });
      this.zendeskService.zE('webWidget', 'prefill', {
        name: {
          value: `${fName} ${lName}`,
          readOnly: true
        },
        email: {
          value: email,
          readOnly: true
        }
      });
      this.zendeskService.zE('webWidget', 'updateSettings', {
        webWidget: {
          position: {
            horizontal: 'right'
          },
          contactForm: {
            tags: [...this.zendeskTags]
          },
          offset: {
            mobile: {
              horizontal: '230px',
              vertical: '100px'
            }
          }
        }
      });
      this.zendeskInitDone = true;
    }
  }

  updateZendeskTags(tags) {
    this.zendeskService.zE('webWidget', 'updateSettings', {
      webWidget: {
        contactForm: {
          tags: [...this.zendeskTags, ...tags]
        }
      }
    });
  }

  async switchBoolean(student: Student, prop: string): Promise<boolean> {
    const userConfig = await this.getUserConfig(student);
    userConfig[prop] = !userConfig[prop];
    await this.setUserConfig(student, userConfig);
    return userConfig[prop];
  }

  async getUserConfig(student: Student): Promise<any> {
    if (!!student && !!student.remoteId) {
      return this.storageService.get(`${student.remoteId}-Config`);
    }
    return null;
  }

  async setUserConfig(student: Student, config: any): Promise<any> {
    if (!!student && !!student.remoteId) {
      return this.storageService.set(`${student.remoteId}-Config`, config);
    }
    return null;
  }
}
