<div class="tunnel-location-container" [ngClass]="{'with-no-results': !isDesktop && userNearestLocation !== '' && dispoResults.length === 0}">
  <div class="content">
    <div class="content-container">
      <div class="content-header big-padding">
        <div class="tunnel-back-controls">
          <div class="back-btn" (click)="sendCompData({action: 'navigate', data: {direction: 'prev'}})">
            <img src="assets/svg/chevron-left-red.svg" alt="EnVoitureSimone">
          </div>
        </div>
      </div>
      <div class="content-body">
        <div class="geolocation-form">
          <h5 id="question-title">Où souhaites-tu réaliser des leçons de conduite ?</h5>
          <div class="form-container">
            <form class="evs-form">
              <div id="search-form" class="margin-bottom-0">
                <input id="userInput" type="text" name="userInput" autocomplete="off" data-lpignore="true" (input)="checkUserInputValue()">
                <label for="email" [ngClass]="{'label-up': userIsTyping || placeLocationsSent}">Saisir une adresse</label>
              </div>
              <!-- <div *ngIf="isDesktop && !isCordova" class="geoloc-btn" (click)="showCurrentLocation()">
                <img *ngIf="!geolocationLoading && !placeLocationsSent && !userIsTyping" src="assets/svg/geolocation-v2.svg" alt="EnvoitureSimone">
                <span *ngIf="geolocationLoading">
                  <ion-spinner class="btn-spinner" name="lines-small"></ion-spinner>
                </span>
              </div> -->
              <div *ngIf="placeLocationsSent || userIsTyping" class="delete-btn small-clickable" (click)="cleanMap()">
                <img *ngIf="!apiLoading" src="assets/svg/tunnel-delete-ico.svg" alt="EnvoitureSimone">
                <ion-spinner *ngIf="apiLoading" class="btn-spinner" name="lines-small"></ion-spinner>
              </div>
            </form>
            <div class="nearest-location-container animated fadeIn" *ngIf="userNearestLocation !== '' && dispoResults.length === 0">
              <div class="text-box">
                <p>Il n’y a pas encore de points de RDV à moins de 15kms de chez toi, le plus proche se trouve à : </p>
                <span><img src="https://app.envoituresimone.com/assets/png/pin-active.png" alt="EnvoitureSimone"> {{userNearestLocation}}</span>
              </div>
            </div>
            <div class="tunnel-gmap-container animated fadeIn">
              <div #gMap class="tunnel-gmap" [ngClass]="{'display-none': userNearestLocation === '' || dispoResults.length > 0}">
              </div>
              <div *ngIf="!!cardNearestLocationData && !!cardNearestLocationData.name" class="info-card">
                <div class="ico"><img src="https://app.envoituresimone.com/assets/png/pin-active.png" alt="EnvoitureSimone"></div>
                <div class="text">
                  <p class="name">{{cardNearestLocationData.name}}</p>
                  <p class="distance">{{cardNearestLocationData.distance}}</p>
                </div>
              </div>
            </div>
            <div class="nearest-location-controls animated fadeIn" [ngClass]="{'small-device': !isDesktop}" *ngIf="userNearestLocation !== '' && dispoResults.length === 0">
              <button id="bouton-confirm" [disabled]="!isActive || dataSent" class="btn full-width" (click)="sendCompData({action: 'navigate', data: {direction: 'next'}})">Acheter le Pack Permis</button>
            </div>
          </div>
        </div>
        <!-- <button class="btn xsmall" (click)="sendCompData({action: 'navigate', data: {direction: 'next'}})">Suivant</button> -->
      </div>
      <div class="next-controls" *ngIf="isDesktop && userNearestLocation === ''">
        <button id="bouton-confirm" [disabled]="!locationFound || !isActive" class="btn full-width" (click)="sendCompData({action: 'navigate', data: {direction: 'next'}})">Continuer</button>
      </div>
    </div>
  </div>
  <div id="image" class="visuel"></div>
  <div class="nearest-location-controls outside-content animated fadeIn" *ngIf="!isDesktop && userNearestLocation !== '' && dispoResults.length === 0">
    <button id="bouton-confirm" class="btn full-width" (click)="sendCompData({action: 'navigate', data: {direction: 'next'}})">Acheter le Pack Permis</button>
  </div>
  <div class="next-controls" *ngIf="!isDesktop && userNearestLocation === ''">
    <button id="bouton-confirm" [disabled]="!locationFound || !isActive || dataSent" class="btn full-width" (click)="sendCompData({action: 'navigate', data: {direction: 'next'}})">Continuer</button>
  </div>
</div>
  
<modal-comp [compOptions]="{'id': 'customModalNoGeoloc', size: 'small'}" *ngIf="modalNoGeoloc" (canClose)="hideModalNoGeoloc()">
  <div class="text-center">
    <p>{{noGeolocText}}</p>
    <button class="btn full-width" (click)="hideModalNoGeoloc()">J'ai compris</button>
  </div>
</modal-comp>