<ion-content>
  <button class="close-modal" (click)="closeModalPack()">
    <img src="assets/2022/icons/close-off.svg" alt="EnVoitureSimone" height="24">
  </button>

  <div class="expired-modal-container">
    <h1>Session expirée</h1>
    <p>Ta session a expiré. Connecte-toi à ton compte pour reprendre l’entraînement&nbsp;!</p>
    <button class="btn full-width margin-top-20" (click)="openLink('login')">Se connecter</button>
  </div>
</ion-content>
