import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'formatCdrLesson' })
export class CdrLessonsFormater implements PipeTransform {

  constructor(private _sanitizer: DomSanitizer) {

  }

  transform(txt: string): SafeHtml {
    if (!!txt) {
      // txt = this.apply(txt)
      return this._sanitizer.bypassSecurityTrustHtml(this.apply(txt));
    } else {
      return null;
    }
  }

  apply(txt: string): string {
    txt = this.replaceLists(txt);
    txt = this.replaceLineBreaks(txt);
    return txt;
  }


  replaceLineBreaks(txt: string): string {
    txt = txt.replace(/(\\n)+/g, '<br><br>');
    return txt;
  }

  replaceLists(txt: string): string {
    const matches = this.matchList(txt);
    if (matches === null || matches.length === 0) {
      return txt;
    }

    for (let index = 0; index < matches.length; index++) {
      txt = txt.replace(/-\s/g, '<span class="margin-right-10 margin-left-20">-</span>');
    }
    return txt;
  }

  replaceListHead(txt: string): string {
    txt = txt.replace(/:\\n-/, '<ul>\n-');
    return txt;
  }

  replaceListTail(txt: string): string {
    return txt.replace(/\\n+|$/, '</ul>');
  }

  replaceInnerBullets(txt: string): string {
    const tmp = txt.replace(/-(.*?)(,|\.|<\/li>\\n)/g, '<li>$1</li>');
    return tmp; // .replace(/<\/li>(.*)<\/ul>/, '</li><li>$1</li></ul>')
  }

  matchList(txt: string): any {
    return txt.match(/:\\n-/);
  }

}
