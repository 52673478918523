import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange, HostListener } from '@angular/core';
import { Platform } from '@ionic/angular';
import { UtilsService } from '@commons/services';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'page-loader-comp',
  templateUrl: 'page-loader.html',
  styleUrls: ['page-loader.scss']
})
export class PageLoaderComponent implements OnInit, OnChanges {

  @Input() compOptions: any;

  debug = false;

  isDesktop = (window.innerWidth > 767) ? true : false;
  isCordova = false;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.isDesktop = (window.innerWidth > 767) ? true : false;
  }

  constructor(
    private utils: UtilsService,
    public platform: Platform,
  ) {
    this.isCordova = this.platform.is('cordova');
  }

  ngOnChanges(changes: SimpleChanges) {
    const compOptions: SimpleChange = changes.compOptions;
    // detect @input changes
    if (this.utils.isDefined(compOptions)) {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  ngOnInit() {
    this.setGlobals();
  }

  setGlobals() {
    this.debug = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.debug)) ? this.compOptions.debug : this.debug;
  }

}
