import {
  Component,
  ViewChild,
  OnInit,
  HostListener,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';

import { UtilsService } from '@commons/services';

// import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

// @EasyDebugDecorator
class ConfettiParticle {

  canvas: any;
  ctx: CanvasRenderingContext2D = null;

  oneShoot = false;

  screenWidth: number = window.innerWidth;
  W: any;
  H: any;
  mp = Math.floor(this.screenWidth / 10); // max particles

  x: any;
  y: any;
  r: any;
  d: any;
  color: any;
  tilt: any;
  tiltAngleIncremental: any;
  tiltAngle: any;

  constructor(canvas: any, color: any, oneShoot: any) {
    if (!!canvas) {
      this.canvas = canvas;
      this.ctx = this.canvas.getContext('2d');
      this.W = this.canvas.width;
      this.H = this.canvas.height;
      this.x = (Math.random() * this.W) - 35; // x-coordinate
      this.y = (Math.random() * this.H) - this.H; // y-coordinate
      this.r = this.randomFromTo(15, 30); // radius
      this.d = (Math.random() * this.mp) + 10; // density
      this.tilt = Math.floor(Math.random() * 10) - 10;
      this.tiltAngleIncremental = (Math.random() * 0.07) + .05;
      this.tiltAngle = 0;
      const maxP = (oneShoot) ? 5 : 10;
      this.mp = Math.floor(this.screenWidth / maxP); // max particles
    }
    if (!!color) {
      this.color = color;
    }
  }

  randomFromTo(from, to) {
    return Math.floor(Math.random() * (to - from + 1) + from);
  }

  draw() {
    this.ctx.beginPath();
    this.ctx.lineWidth = this.r / 2;
    this.ctx.strokeStyle = this.color;
    this.ctx.moveTo(this.x + this.tilt + (this.r / 4), this.y);
    this.ctx.lineTo(this.x + this.tilt, this.y + this.tilt + (this.r / 4));
    this.ctx.stroke();
  }
}

@Component({
  selector: 'confettis-comp',
  templateUrl: 'confettis.html',
  styleUrls: ['confettis.scss']
})

// @EasyDebugDecorator
export class ConfettisComponent implements OnInit {
  @Input() compOptions: any;
  @Output() confettisFinished = new EventEmitter<any>();
  @ViewChild('htmlCanvas', { static: true }) htmlCanvas: any;

  pageTitle = 'Confettis';
  activeLink = 'code';

  screenWidth: number = window.innerWidth;

  isDesktop: boolean = (this.screenWidth > 767) ? true : false;
  oneShoot = false;

  canvas: any;
  ctx: any;
  W: any;
  H: any;
  mp = Math.floor(this.screenWidth / 10); // max particles
  particles = [];
  angle = 0;
  tiltAngle = 0;
  confettiActive = true;
  animationComplete = true;
  deactivationTimerHandler: any;
  reactivationTimerHandler: any;
  animationHandler: any;
  animationFrame: any;

  emittedOutput = false;

  // objects
  particleColors = {
      colorOptions: [
        'DodgerBlue',
        'OliveDrab',
        'Gold',
        'pink',
        'SlateBlue',
        'lightblue',
        'Violet',
        'PaleGreen',
        'SteelBlue',
        'SandyBrown',
        'Chocolate',
        'Crimson'
      ],
      colorIndex: 0,
      colorIncrementer: 0,
      colorThreshold: 10,
      getColor: function () {
        if (this.colorIncrementer >= 10) {
          this.colorIncrementer = 0;
          this.colorIndex++;
          if (this.colorIndex >= this.colorOptions.length) {
              this.colorIndex = 0;
          }
        }
        this.colorIncrementer++;
        return this.colorOptions[this.colorIndex];
      }
  };

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.screenWidth = window.innerWidth;
    this.mp = Math.floor(this.screenWidth / 10); // max particles
    this.isDesktop = (this.screenWidth > 767) ? true : false;
    this.restartConfetti();
  }

  constructor(
    private utils: UtilsService,
  ) {
    window.requestAnimationFrame = window.requestAnimationFrame;
  }

  ngOnInit() {
    this.setGlobals();
    this.initializeConfetti();
  }

  setGlobals() {
    this.oneShoot = (this.utils.isDefined(this.compOptions) && this.utils.isDefined(this.compOptions.oneShoot)) ? this.compOptions.oneShoot : this.oneShoot;
    this.canvas = this.htmlCanvas.nativeElement;
    this.ctx = this.canvas.getContext('2d');
    this.W = window.innerWidth;
    this.H = window.innerHeight;
    this.canvas.width = this.W;
    this.canvas.height = this.H;
    const maxP = (this.oneShoot) ? (this.isDesktop) ? 5 : 3 : 10;
    this.mp = Math.floor(this.screenWidth / maxP); // max particles
  }

  initializeConfetti() {
    this.particles = [];
    this.animationComplete = false;
    for (let i = 0; i < this.mp; i++) {
      const particleColor = this.particleColors.getColor();
      this.particles.push(new ConfettiParticle(this.canvas, particleColor, this.oneShoot));
    }
    this.startConfetti();
  }

  draw() {
    this.animationFrame = requestAnimationFrame(() => {
      this.draw();
    });

    this.ctx.clearRect(0, 0, this.W, this.H);
    for (let i = 0; i < this.mp; i++) {
      if (!!this.particles[i]) {
        this.particles[i].draw();
      }
    }
    this.update();
  }

  update() {
    let remainingFlakes = 0;
    let particle;
    this.angle += 0.01;
    this.tiltAngle += 0.1;

    for (let i = 0; i < this.mp; i++) {
      particle = this.particles[i];
      if (this.animationComplete) {
        return;
      }

      if (!this.confettiActive && particle.y < -15) {
        particle.y = this.H + 100;
        continue;
      }

      this.stepParticle(particle, i);

      if (particle.y <= this.H) {
        remainingFlakes++;
      }
      if (!this.oneShoot) {
        this.checkForReposition(particle, i);
      }
    }

    if (remainingFlakes === 0) {
      this.stopConfetti();
    }
  }

  checkForReposition(particle, index) {
    if ((particle.x > this.W + 20 || particle.x < -20 || particle.y > this.H) && this.confettiActive) {
      if (index % 5 > 0 || index % 2 === 0) { // 66.67% of the flakes
        this.repositionParticle(particle, Math.random() * this.W, -10, Math.floor(Math.random() * 10) - 10);
      } else {
        if (Math.sin(this.angle) > 0) {
          // Enter from the left
          this.repositionParticle(particle, -5, Math.random() * this.H, Math.floor(Math.random() * 10) - 10);
        } else {
          // Enter from the right
          this.repositionParticle(particle, this.W + 5, Math.random() * this.H, Math.floor(Math.random() * 10) - 10);
        }
      }
    }
  }

  stepParticle(particle, particleIndex) {
    const speed = (this.oneShoot) ? 6 : 3;
    particle.tiltAngle += particle.tiltAngleIncremental;
    particle.y += (Math.cos(this.angle + particle.d) + speed + particle.r / 2) / 2;
    particle.x += Math.sin(this.angle);
    particle.tilt = (Math.sin(particle.tiltAngle - (particleIndex / speed))) * 15;
  }

  repositionParticle(particle, xCoordinate, yCoordinate, tilt) {
    particle.x = xCoordinate;
    particle.y = yCoordinate;
    particle.tilt = tilt;
  }

  startConfetti() {
    this.W = window.innerWidth;
    this.H = window.innerHeight;
    this.canvas.width = this.W;
    this.canvas.height = this.H;
    this.draw();
  }

  clearTimers() {
    clearTimeout(this.reactivationTimerHandler);
    clearTimeout(this.animationHandler);
  }

  deactivateConfetti() {
    this.confettiActive = false;
    this.clearTimers();
  }

  stopConfetti() {
    if (!this.emittedOutput) {
      this.emittedOutput = true;
      this.confettisFinished.emit(true);
    }
    this.animationComplete = true;
    if (this.ctx === undefined) {
      return;
    }
    this.ctx.clearRect(0, 0, this.W, this.H);
  }

  restartConfetti() {
    this.clearTimers();
    this.stopConfetti();
    window.cancelAnimationFrame(this.animationFrame);
    this.reactivationTimerHandler = setTimeout(() => {
      this.confettiActive = true;
      this.animationComplete = false;
      this.initializeConfetti();
    }, 100);
  }

}
