<div class="DS-calendar">
  <div *ngIf="isDesktop" class="circle-btn" (click)="changeMonthEvent('previous')">
    <img [src]="'assets/2022/icons/arrow-left-2-on.svg'" alt="EnvoitureSimone">
  </div>
  <div class="DS-wrapper" #dailyStreakCalendar>
    <h4 *ngIf="isDesktop"><span class="DS-month-scribble">&nbsp;&nbsp;{{currentMonth}}&nbsp;&nbsp;</span></h4>
    <h5 *ngIf="!isDesktop"><span class="DS-month-scribble">&nbsp;&nbsp;{{currentMonth}}&nbsp;&nbsp;</span></h5>
    <div class="DS-calendar-wrapper">
      <div class="DS-calendar-header">
        <span class="DS-calendar-day" *ngFor="let day of daysFirstLetter">{{day}}</span>
      </div>
      <div class="DS-calendar-day-rows" *ngFor="let week of currentMonthDays">
        <ng-container *ngFor="let day of week">
          <div class="DS-calendar-day-wrapper" *ngIf="!!day">
            <span class="DS-calendar-day" [ngClass]="{'is-streak': day.isStreak}">{{day.dayNumber}}</span>
            <span *ngIf="day.isActive" class="active-indicator"></span>
            <span *ngIf="!day.isActive" class="inactive-indicator"></span>
          </div>
          <span *ngIf="!day" class="DS-calendar-day"></span>
        </ng-container>
      </div>
    </div>
  </div>
  <div *ngIf="isDesktop && !isMaxMonthYear" class="circle-btn" (click)="changeMonthEvent('next')">
    <img [src]="'assets/2022/icons/arrow-right-2-on.svg'" alt="EnvoitureSimone">
  </div>
  <div *ngIf="isDesktop && isMaxMonthYear" class="circle-btn off">
  </div>
</div>