import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { Initialable, AppInitService } from './app-init.service';

import { ModalController } from '@ionic/angular';

import { AuthService } from './auth.service';

import { Subject, Subscription } from 'rxjs';

import { DisconectedModalComponent } from '@app/modals';
import { ConfigService } from './config.service';
import { StatsService } from './stats.service';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@Injectable({
  providedIn: 'root'
})
@Initialable({ step: 'init5', initializer: 'onInit' })
@EasyDebugDecorator
export class UserSessionService {

  // The login check is always done at start of the application, we dont want to show the modal at start
  // so let's store the fact we passed once and check it before display the modal
  private passedOnce = false;
  private showingDisconectedModal = false;
  private currentRoute: string;
  private blackListedPath = ['login', 'other', 'splash'];
  sessionExpiredObs = new Subject<boolean>();
  sessionExpiredObs$ = this.sessionExpiredObs.asObservable();
  sessionExpiredObsSub = null;
  disconnectedModalCheckAllowed = false;

  constructor(private auth: AuthService,
    private appInitService: AppInitService,
    private modalController: ModalController,
    private router: ActivatedRoute,
    private configService: ConfigService,
    private statsService: StatsService
  ) { }

  async onInit() {
    this.appInitService.onStepChange().subscribe(
      (state) => {
        if (state?.initDone && this.sessionExpiredObsSub === null) {
          this.subscribeToExpiredSessionEvent();
        }
      }
    );
    return 'UserSession done';
  }

  private

  subscribeToExpiredSessionEvent() {
    this.sessionExpiredObsSub = this.sessionExpiredObs$.subscribe((isSessionExpired) => {
      this.onExpiredSession();
    });
  }

  async onExpiredSession() {
    this.statsService.send({ name: 'user:logout' });
    if (!this.disconnectedModalCheckAllowed) {
      this.disconnectedModalCheckAllowed = true;
      const isAllowed = await this.isAllowedToShowHere();
      if (isAllowed) {
        this.showDisconectedModal();
      }
    }
  }

  showDisconectedModal() {
    this.modalController.create({
      component: DisconectedModalComponent
    }).then(
      async modal => {
        await this.configService.switchBoolean('sessionExpired');
        this.showingDisconectedModal = true;
        modal.present();
        modal.onDidDismiss().then(async event => {
          await this.configService.switchBoolean('sessionExpired');
          this.showingDisconectedModal = false;
          this.disconnectedModalCheckAllowed = false;
        });
      }
    );
  }

  async isAllowedToShowHere(): Promise<boolean> {
    const config = await this.configService.getAppConfig();
    const isDisplayed = this.configService.checkSessionExpiredDisplayed(config);
    if (isDisplayed && !this.showingDisconectedModal) {
      return false;
    }
    if (!!this.router.firstChild.snapshot.routeConfig.path) {
      return !this.blackListedPath.includes(this.router.firstChild.snapshot.routeConfig.path);
    }
    return false;
  }
}
