import { NetworkStatusService } from './../../../app/services/network-status.service';

import { Question } from './../../../app/models/question';
import { Component, Input, Output, OnChanges, SimpleChanges, SimpleChange, EventEmitter } from '@angular/core';
import { WebView } from '@awesome-cordova-plugins/ionic-webview/ngx';
import { ToasterService } from '@commons/services';
import { Platform } from '@ionic/angular';
import { File } from '@awesome-cordova-plugins/file/ngx';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';
import { EasyDebugLogger } from '@app/easy-debug-logger/easy-debug-logger';

@EasyDebugDecorator
@Component({
  selector: 'media-comp',
  templateUrl: 'media.component.html',
  styleUrls: ['media.component.scss']
})
export class MediaComponent implements OnChanges {
  @Input() compOptions: any;
  @Output() mediaEvent = new EventEmitter<any>();
  @Output() failEvent = new EventEmitter<any>();
  @Output() isFullscreen = new EventEmitter<any>();

  easyDebugLogger = EasyDebugLogger.getInstance();

  isDebug = false;
  question: Question = null;
  mode: string = null;
  scope: string = null;
  type: string = null;
  isPaused = false;
  isMuted = false;
  isStoped = false;
  isReloaded = false;
  isResults = false;

  videoSrc: string;
  imgSrc: string;
  audioSrc: string;
  gifSrcs: string[];

  imageLoaded = false;
  gifLoaded = false;

  videoStarted = false;
  videoEnd = false;
  audioEnd = false;

  imageSize = 'medium';
  videoSize = 'large-mp4';
  opacity = 0;

  serieType;

  fullscreen = false;

  toasterNoSoundShown = false;

  constructor(
    private networkService: NetworkStatusService,
    private toasterService: ToasterService,
    private webview: WebView,
    private file: File,
    private platform: Platform
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    const compOptions: SimpleChange = changes.compOptions;
    if (!!this.compOptions) {
      this.compOptions = compOptions.currentValue;
      this.setSrc();
    }
  }

  setSrc() {
    if (!!this.compOptions) {
      if (this.compOptions.debug !== null && this.compOptions.debug !== undefined) {
        this.isDebug = this.compOptions.debug;
      }
      if (this.compOptions.serieType !== null && this.compOptions.serieType !== undefined) {
        this.serieType = this.compOptions.serieType;
      }
      if (!!this.compOptions.question) {
        this.question = this.compOptions.question;
      }
      if (!!this.compOptions.mode) {
        this.mode = this.compOptions.mode;
      }
      if (!!this.compOptions.scope) {
        this.scope = this.compOptions.scope;
      }
      if (this.compOptions.pause !== null && this.compOptions.pause !== undefined) {
        this.isPaused = this.compOptions.pause;
      }
      if (this.compOptions.mute !== null && this.compOptions.mute !== undefined) {
        if (this.isMuted && !this.compOptions.mute) {
          this.toasterNoSoundShown = false;
        }
        this.isMuted = this.compOptions.mute;
      }
      if (this.compOptions.stop !== null && this.compOptions.stop !== undefined) {
        this.isStoped = this.compOptions.stop;
      }
      if (this.compOptions.reload !== null && this.compOptions.reload !== undefined) {
        if (this.compOptions.reload !== this.isReloaded) {
          this.videoEnd = false;
          this.imageLoaded = false;
          this.gifLoaded = false;
          this.audioEnd = false;
          this.opacity = 0;
        }
        this.isReloaded = this.compOptions.reload;
      }
      this.type = this.getMediaType(this.question, this.scope);
      this.mediaEvent.emit({mediaType: this.type});
      this.setMediaSrc();
    }
    if (this.isDebug) {
      console.log('options ===> ', this.compOptions);
    }
  }

  getMediaType(question: Question, scope: string): string {
    if (!!scope) {
      if (this.scope === 'results') {
        this.isResults = true;
      }
      if (!!question.question_video && this.scope === 'question') {
        return 'video';
      } else if (!!question.question_img1 && !!question.question_img2) {
        return 'gif';
      } else {
        return 'image';
      }
    }
    return null;
  }

  async setQuestionMediaSrc() {
    let img1 = null;
    let img2 = null;
    let video = null;
    let audio = null;

    if (!!this.question.question_img1) {
      img1 = this.question.question_img1.replace(/original/, this.imageSize);
    }

    if (!!this.question.question_img2) {
      img2 = this.question.question_img2.replace(/original/, this.imageSize);
    }

    if (!!this.question.question_video) {
      video = this.question.question_video.replace(/original/, this.videoSize);
    }

    if (!!this.question.question_audio) {
      audio = this.question.question_audio;
    }

    if (this.question.downloadState) {
      if (!!this.question.off_question_img1 && await this.checkDownloadedFile(this.question.off_question_img1)) {
        img1 = this.webview.convertFileSrc(this.question.off_question_img1);
      }

      if (!!this.question.off_question_img2 && await this.checkDownloadedFile(this.question.off_question_img2)) {
        img2 = this.webview.convertFileSrc(this.question.off_question_img2);
      }

      if (!!this.question.off_question_video && await this.checkDownloadedFile(this.question.off_question_video)) {
        video = this.webview.convertFileSrc(this.question.off_question_video);
      }

      if (!!this.question.off_question_audio && await this.checkDownloadedFile(this.question.off_question_audio)) {
        audio = this.webview.convertFileSrc(this.question.off_question_audio);
      }
    }

    if (this.scope === 'results') {
      if (this.type === 'gif') {
        this.gifSrcs = [img1, img2];
      } else {
        this.imgSrc = img1;
      }
    } else {
      if (this.type === 'video') {
        this.videoSrc = video;
        this.imgSrc = img1;
      } else if (this.type === 'gif') {
        this.gifSrcs = [img1, img2];
      } else {
        this.imgSrc = img1;
      }
      // anti-couillons
      if (!this.imgSrc) {
        this.imgSrc = 'assets/img/image-non-disponible.png';
      }
      this.audioSrc = audio;
      this.checkIfMediaExist();
    }
  }

  async setAnswerMediaSrc() {
    let img1 = null;
    let img2 = null;
    let audio = null;

    if (!!this.question.answer_img1) {
      img1 = this.question.answer_img1.replace(/original/, this.imageSize);
    }

    if (!!this.question.answer_img2) {
      img2 = this.question.answer_img2.replace(/original/, this.imageSize);
    }
    
    if (!!this.question.answer_audio) {
      audio = this.question.answer_audio;
    }

    if (this.question.downloadState) {
      if (!!this.question.off_answer_img1 && await this.checkDownloadedFile(this.question.off_answer_img1)) {
        img1 = this.webview.convertFileSrc(this.question.off_answer_img1);
      }
      
      if (!!this.question.off_answer_img2 && await this.checkDownloadedFile(this.question.off_answer_img2)) {
        img2 = this.webview.convertFileSrc(this.question.off_answer_img2);
      }
      
      if (!!this.question.off_answer_audio && await this.checkDownloadedFile(this.question.off_answer_audio)) {
        audio = this.webview.convertFileSrc(this.question.off_answer_audio);
      }
    }

    if (this.type === 'gif') {
      this.gifSrcs = [img1, img2];
    } else {
      this.imgSrc = img1;
    }
    // anti-couillons
    if (!this.imgSrc) {
      this.imgSrc = 'assets/img/image-non-disponible.png';
    }
    this.audioSrc = audio;
    this.checkIfMediaExist();
  }

  async checkDownloadedFile(filePathName: string) {
    const filepath = filePathName.substring(0, filePathName.lastIndexOf('/') + 1);
    const filename = filePathName.substring(filePathName.lastIndexOf('/') + 1, filePathName.length);

    if (this.platform.is('cordova')) {
      return await this.file.checkFile(filepath, filename).then((file) => {
          return true;
        })
        .catch(
          (err) => {
            return false;
          }
        );
    } else {
      return false;
    }
  }

  checkIfMediaExist() {
    const toasterConf = {text: 'Cette question n’a pas de son', duration: 2000};
    if (!this.audioSrc && !this.toasterNoSoundShown && !this.isMuted) {
      this.toasterService.create(toasterConf);
      this.toasterNoSoundShown = true;
    }
  }

  setMediaSrc() {
    if (this.scope === 'question' || this.scope === 'results') {
      this.setQuestionMediaSrc();
    } else if (this.scope === 'answer' || this.scope === 'answerResults') {
      this.setAnswerMediaSrc();
    }
  }

  handleVideoEvent(event) {
    if (!!event && !!event.ended) {
      this.videoEnd = true;
      this.opacity = 1;
    }
    if (!!event && !!event.started) {
      this.videoStarted = true;
    }
  }

  handleAudioEvent(event) {
    if (!!event && !!event.ended) {
      this.audioEnd = true;
      this.mediaEvent.emit({ended: true});
    }
  }

  handleImageEvent(event) {
    if (!!event && !!event.loaded) {
      this.imageLoaded = true;
    }
  }

  handleGifEvent(event) {
    if (!!event && !!event.loaded) {
      this.gifLoaded = true;
    }
  }

  handleFailEvent(event) {
    if (!!event && !!event.status && event.status === 'failed') {
      // console.log('FAIL MEDIA', event);
      this.failEvent.emit({type: 'media ' + event.type, status: 'failed'});
    }
  }

  triggerClick() {
    this.fullscreen = !this.fullscreen;
    this.isFullscreen.emit(this.fullscreen);
  }
}
