import {
  NgZone,
  Component,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  Renderer2,
  HostListener,
  EventEmitter,
  Output,
  ElementRef,
  AfterViewInit,
} from '@angular/core';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import {
  PointsDeRendezVousService,
} from '@app/services';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'tunnel-select-hour-comp',
  templateUrl: 'tunnel-select-hour.html',
  styleUrls: ['tunnel-select-hour.scss']
})
export class TunnelSelectHourComponent implements OnChanges {
  @Input() compOptions: any;
  @Output() compDataSent = new EventEmitter<any>();

  iconPath = './uikit-assets/icons/orion-svg-sprite.svg#';

  isDebug = false;
  userId = null;
  node: any = null;

  isActive: Boolean = false;

  selectHourForm: FormGroup;
  formCompleted = false;

  compDataToSend = null;
  dataSent = false;

  hoursList = [
    {name: '1', value: 1},
    {name: '2', value: 2},
    {name: '3', value: 3},
    {name: '4', value: 4},
    {name: '5', value: 5},
    {name: '6', value: 6},
    {name: '7', value: 7},
    {name: '8', value: 8},
    {name: '9', value: 9},
    {name: '10', value: 10},
    {name: '20', value: 20},
    {name: '30', value: 30}
  ];

  isDesktop = (window.innerWidth > 767) ? true : false;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.ngZone.run(() => {
      this.isDesktop = (window.innerWidth > 767) ? true : false;
    });
  }

  constructor(
    private ngZone: NgZone,
    private pointsDeRendezVousService: PointsDeRendezVousService,
    private formBuilder: FormBuilder,
  ) {
    this.constructForms();
   }

  ngOnChanges(changes: SimpleChanges) {
    // detect @inputs changes
    const compOptions: SimpleChange = changes.compOptions;
    if (typeof compOptions !== 'undefined') {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  setGlobals() {
    this.isDebug = (this.compOptions && typeof this.compOptions.debug !== 'undefined') ? this.compOptions.debug : this.isDebug;
    this.userId = (this.compOptions && typeof this.compOptions.userId !== 'undefined') ? this.compOptions.userId : this.userId;
    this.node = (this.compOptions && typeof this.compOptions.node !== 'undefined') ? this.compOptions.node : this.node;
    this.isActive = (this.compOptions && typeof this.compOptions.isActive !== 'undefined') ? this.compOptions.isActive : this.isActive;
    if (this.isDebug) {
      // console.log('TunnelSelectHourComponent - compOptions: ', this.compOptions);
    }
    if (!!this.node?.data?.selection) {
      
      this.hoursList = this.node.data.selection.reduce((acc, current) => {
        acc.push({name: current.resume_text, value: current});
        return acc;
      }, []);
    }
    this.dataSent = false; // reset
  }

  constructForms() {
    this.selectHourForm = this.formBuilder.group({
      selectHour: ['', Validators.required]
    });
  }

  get getSelectHourForm() {
    return this.selectHourForm.controls;
  }

  setSelectHour(value: any) {
    if (!!value && value.toString().trim() !== '') {
      this.selectHourForm.controls['selectHour'].patchValue(value);
      this.selectHourForm.controls['selectHour'].markAsTouched();
      this.formCompleted = true;
      // this.sendCompData({action: 'navigate', data: {direction: 'next'}}); // auto navigate to next step
    } else {
      this.selectHourForm.controls['selectHour'].patchValue('');
      this.selectHourForm.controls['selectHour'].markAsTouched();
    }
    // console.log(this.getSelectHourForm.selectHour);
  }

  setCompDataToSend(data: any) {
    this.compDataToSend = {
      'select_hour': {
        'quantity': this.selectHourForm.value.selectHour.quantity
      }
    };
    console.log('compDataToSend: ', this.selectHourForm.value.alreadyDrive, this.compDataToSend);
  }

  sendCompData(data: any) {
    if (!this.dataSent) {
      this.dataSent = true;
      // console.log('TunnelLocationComponent - sendCompData: ', data);
      this.setCompDataToSend(null);
      if (!!data) {
        data.compData = this.compDataToSend;
        this.compDataSent.emit(data);
      } else {
        this.compDataSent.emit({action: 'error', data: 'No data sent from TunnelLocationComponent'});
      }
    }
  }

}
