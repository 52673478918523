<ng-template #errorTemplate let-field="field" let-doubleIcon="doubleIcon" let-iconOnly="iconOnly" let-fieldName="fieldName">
  <ng-container>
    <div class="field-error" [ngClass]="{'show': !field.valid && (field.dirty || field.touched) && !iconOnly && field.errors?.required}">Ce champ est obligatoire</div>
    <div class="field-error" [ngClass]="{'show': !field.valid && (field.dirty || field.touched) && !iconOnly && field.errors?.pattern && fieldName != 'phone_number' && fieldName != 'email'}">Ce champ n'est pas valide</div>
    <div class="field-error" [ngClass]="{'show': !field.valid && (field.dirty || field.touched) && !iconOnly && field.errors?.pattern && fieldName == 'email' && displayEMailPatternError}">Ce champ n'est pas valide</div>
    <div class="field-error" [ngClass]="{'show': !field.valid && (field.dirty || field.touched) && !iconOnly && field.errors?.minlength}">6 caractères minimum</div>
    <div class="field-error" [ngClass]="{'show': !field.valid && (field.dirty || field.touched) && !iconOnly && field.errors?.maxlength}">50 caractères maximum</div>
    <div class="field-error" [ngClass]="{'show': !field.valid && (field.dirty || field.touched) && !iconOnly && field.errors?.validatePasswords}">Les mots de passe ne correspondent pas</div>
    <div class="field-error" [ngClass]="{'show': !field.valid && (field.dirty || field.touched) && !iconOnly && field.errors?.pattern && fieldName == 'phone_number'}">Saisir un numéro valide en 06 ou 07</div>
  </ng-container>
</ng-template>

<div class="tunnel-email-container">
  <div class="content">
    <div class="content-container">
      <div class="content-header">
        <div class="tunnel-back-controls">
          <div class="back-btn" (click)="sendCompData({action: 'navigate', data: {direction: 'prev'}})">
            <img src="assets/svg/chevron-left-red.svg" alt="EnVoitureSimone">
          </div>
        </div>
      </div>
      <div class="content-body">
        <h5>Saisir ton adresse mail pour<br>réinitialiser ton mot de passe</h5>
        <form [formGroup]="emailForm" class="evs-form">
          <div>
            <input *ngIf="!isActive" type="email" formControlName="email" id="email" (blur)="tunnelFormHasErrors()" autocomplete="off" data-lpignore="true" disabled>
            <input *ngIf="isActive" type="email" formControlName="email" id="email" (blur)="tunnelFormHasErrors()" autocomplete="off" data-lpignore="true">
            <label for="email" [ngClass]="{'label-up': !!getEmailForm.email.value && getEmailForm.email.value !== ''}">Adresse e-mail</label>
          </div>
          <ng-container [ngTemplateOutlet]="errorTemplate" [ngTemplateOutletContext]="{field: getEmailForm.email, fieldName: 'email'}"></ng-container>
          <div class="mail-suggestion text-red-500" *ngIf="!!mailAlreadyTaken">Adresse mail déjà utilisée. <span (click)="openLink('login/signIn')">Me connecter</span></div>
          <div class="mail-suggestion" *ngIf="!!mailSuggestion">Voulais-tu dire <span (click)="updateMail('tunnelFormAB')">{{mailSuggestion.full}}</span> ?</div>
          <div>
            <button [disabled]="!emailForm.valid || !isActive || dataSent" class="btn full-width" (click)="sendCompData({action: 'navigate', data: {direction: 'next'}})">Réinitialiser</button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="visuel"></div>
</div>