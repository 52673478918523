/* tslint:disable:member-ordering */
import { Directive, ElementRef, HostListener, EventEmitter, Output } from '@angular/core';

// SERVICES
import { UtilsService } from '../services/utils.service';

@Directive({
  selector: '[appSwipe]'
})
export class SwipeDirective {
  @Output() swipeEvent = new EventEmitter();

  parent: any;
  mouseX: any;
  mouseY: any;
  diffX: any;
  diffY: any;
  hasSwipe = false;

  constructor(
    private el: ElementRef,
    private utils: UtilsService,
  ) {
    this.parent = this.el.nativeElement;
  }

  @HostListener('mousedown', ['$event']) onMouseDown(event: MouseEvent) {
    this.processMouseDown(event);
  }
  @HostListener('mousemove', ['$event']) onMouseMove(event: MouseEvent) {
    this.processMouseMove(event);
  }
  @HostListener('mouseup', ['$event']) onMouseUp(event: MouseEvent) {
    this.processMouseUp(event);
  }

  @HostListener('touchstart', ['$event']) onTouchStart(event) {
    this.processMouseDown(event);
  }
  @HostListener('touchmove', ['$event']) onTouchMove(event) {
    this.processMouseMove(event);
  }
  @HostListener('touchend', ['$event']) onTouchEnd(event) {
    this.processMouseUp(event);
  }

  processMouseDown(event) {
    this.hasSwipe = false;
    this.mouseX = this.utils.unify(event).clientX;
    this.mouseY = this.utils.unify(event).clientY;
  }

  processMouseMove(event) {
    this.diffX = this.utils.unify(event).clientX - this.mouseX;
    this.diffY = this.utils.unify(event).clientY - this.mouseY;
    const tmpDiffX = (this.diffX < 0) ? (this.diffX * -1) : this.diffX;
    const tmpDiffY = (this.diffY < 0) ? (this.diffY * -1) : this.diffY;
    if (tmpDiffX > tmpDiffY) {
      // swipe = stop scroll
      this.prevent_default(event);
      this.hasSwipe = true;
    } else {
      this.hasSwipe = false;
    }
  }

  processMouseUp(event) {
    if (this.hasSwipe) {
      // swipe = stop scroll
      this.prevent_default(event);
      let swipeLongEnough = false;
      if (!!this.parent.offsetWidth && this.parent.offsetWidth > 0) {
        if (Math.abs(this.diffX) > (this.parent.offsetWidth / 3)) {
          swipeLongEnough = true;
        }
      } else {
        if (Math.abs(this.diffX) > 160) { // mid 320 iphone 5 width
          swipeLongEnough = true;
        }
      }
      if (swipeLongEnough) {
        const direction = (this.diffX > 0) ? 'right' : 'left';
        this.swipeEvent.emit(direction);
      }
    }
    this.mouseX = 0;
    this.mouseY = 0;
  }

  prevent_default(event) {
    if (window.event) {
      window.event.returnValue = false;
    } else if (event.preventDefault) {
      event.preventDefault();
    } else {
      event.returnValue = false;
    }
  }

}
