import { EvsRecord } from './evsRecord';

export interface ICPFSubscription {
  id: string,
  type: string,
  created_at: string;
  updated_at: string;
  offer_id: string,
  name: string,
  offer_type: string,
  ttc_price: number,
  external_id: string,
  offer_external_id: string,
  active: boolean,
  credits: number,
  code_exam_credits: number,
  driving_exam_credits: number,
  cpf_subscription_displayed_at: string,
  video_displayable: boolean,
  session_start_date: string,
  state: string
}

export enum CPFOfferTypes {
  PackHours = 'pack_hours',
  ETGPackHours = 'etg_pack_hours'
}

export class CPFSubscriptionModel extends EvsRecord implements ICPFSubscription {
  id: string;
  type: string;
  created_at: string;
  updated_at: string;
  offer_id: string;
  name: string;
  offer_type: CPFOfferTypes;
  ttc_price: number;
  external_id: string;
  offer_external_id: string;
  active: boolean;
  credits: number;
  code_exam_credits: number;
  driving_exam_credits: number;
  cpf_subscription_displayed_at: string;
  video_displayable: boolean;
  session_start_date: string;
  state: string;

  constructor(obj: ICPFSubscription) {
    super(obj);
  }
}
