import { AdjustService } from './../../app/services/adjust.service';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, of, throwError, timer } from 'rxjs';
import * as jsSHA from 'jssha';
import { switchMap, catchError, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Platform } from '@ionic/angular';
import { Injectable, ErrorHandler } from '@angular/core';

import { EasyDebugLogger } from '../../app/easy-debug-logger/easy-debug-logger';
import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

import { ToasterService } from '@commons/services';
import { ModalController } from '@ionic/angular';
import { ModalMarketingComponent } from '@app/modals';
import { EvsApiHeaderService } from './EvsApiHeader.service';
import { NavigationService, StorageService } from '@app/app.services';

@Injectable({
  providedIn: 'root',
})
export class EvsApiHeaderInterceptor implements HttpInterceptor {

  private easyDebugLogger = EasyDebugLogger.getInstance();
  subscriptionArray = [];
  index = 0;

  timers = [];

  constructor(
    private platform: Platform,
    private adjustService: AdjustService,
    private errorHanlerService: ErrorHandler,
    private toasterService: ToasterService,
    private modalController: ModalController,
    private evsApiHeaderService: EvsApiHeaderService,
    private storageService: StorageService,
    private navigationService: NavigationService
  ) {
  }

  async openModalPack(bannerInfo: any, type?: string, noClose = false, customClass = '') {
    // evs-global.scss : small-modal | medium-modal
    if (!type) {
      type = 'marketingRegular';
    }
    let props;
    if (!!bannerInfo) {
      props = {
        'type': type,
        bannerInfo: {
          item_id: bannerInfo.itemId,
          item_promo_type: bannerInfo.itemPromoType,
          item_location_id: bannerInfo.itemLocationId,
          item_location_format: bannerInfo.itemLocationFormat,
          item_promo_content: bannerInfo.itemPromoContent,
        }
      };
    } else {
      props = {
        'type': type,
        bannerInfo: null
      };
    }
    let modal;
    if (noClose) {
      modal = await this.modalController.create({
        component: ModalMarketingComponent,
        backdropDismiss: false,
        cssClass: customClass,
        componentProps: props
      });
    } else {
      modal = await this.modalController.create({
        component: ModalMarketingComponent,
        cssClass: customClass,
        componentProps: props
      });
    }
    return await modal.present();
  }

  private closeModals() {
    const modals = document.getElementsByTagName('ion-modal');
    while (modals.length > 0) {
      modals[0].parentNode.removeChild(modals[0]);
    }
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // this.handleErrorTypes({status: 504}); // to test each error

    // TIMEOUT CLIENT BASED ON 8000 milliseconds
    if (this.requestMatchEvsDomain(request)) {
      this.timers.push(timer(8000));
      const url = request.url;
      const index = this.index;
      const subscription = this.timers[this.timers.length - 1].subscribe(
        res => {
          if (!this.evsApiHeaderService.timeoutShown && !this.evsApiHeaderService.modalShown) {
            this.evsApiHeaderService.timeoutShown = true;
            this.evsApiHeaderService.modalShown = true;
            this.openModalPack(null, 'modalTimeout', true);
          }
          if (!!subscription) {
            const arrayIndex = this.subscriptionArray.findIndex(elt => elt.index === index);
            subscription.unsubscribe();
            this.subscriptionArray.splice(arrayIndex, 1);
          }
          return res;
        }
      );

      this.subscriptionArray.push(
        {
          url : url,
          subscription: subscription,
          index: this.index
        }
      )
      this.index++;

      return this.updateHeader(request, next).pipe(
        switchMap(
          req => {
            if (req instanceof HttpResponse) {
              if (this.evsApiHeaderService.timeoutShown && this.evsApiHeaderService.modalShown) {
                this.evsApiHeaderService.timeoutShown = false;
                this.evsApiHeaderService.modalShown = false;
                this.closeModals();
              }
              if (!!subscription) {
                const arrayIndex = this.subscriptionArray.findIndex(elt => elt.index === index);
                subscription.unsubscribe();
                this.subscriptionArray.splice(arrayIndex, 1);
              }
              if (this.evsApiHeaderService.maintenanceShown) {
                this.evsApiHeaderService.maintenanceShown = false;
                this.toasterService.create({ text: 'Ton accès aux fonctionnalités est rétabli !', bgcolor: 'var(--color-success)', color: 'var(--color-font)', duration: 8000 });
              }
            }
            return of(req);
          }
        ),
        catchError((error: any) => {
          if (this.evsApiHeaderService.timeoutShown && this.evsApiHeaderService.modalShown) {
            this.evsApiHeaderService.timeoutShown = false;
            this.evsApiHeaderService.modalShown = false;
            this.closeModals();
          }
          if (!!subscription) {
            const arrayIndex = this.subscriptionArray.findIndex(elt => elt.index === index);
            subscription.unsubscribe();
            this.subscriptionArray.splice(arrayIndex, 1);
          }
          if (request.url !== `${environment.cmsBase}/omniauth_callback/google` && error.status !== 401) {
            this.handleErrorTypes(error);
          }
          return throwError(error);
        }
      ));
    } else {
      // Todo: Add Raven call on error
      return next.handle(request);
    }
  }

  private handleErrorTypes(error: any) {
    if (error.status === 503) {
      // HTTP 503 (Maintenance)
      if (!this.evsApiHeaderService.maintenanceShown) {
        this.evsApiHeaderService.maintenanceShown = true;
        this.easyDebugLogger.logError('Error', 'Maintenance 503');
        this.toasterService.create({ text: 'Une maintenance est en cours, certaines fonctionnalités peuvent être inaccessibles', bgcolor: 'var(--color-danger)', color: 'white', duration: 8000 });
      }
    } else {
      this.easyDebugLogger.logError('Error', error.status + ' : ' + JSON.stringify(error));
      this.errorHanlerService.handleError(new Error(`Update header error ${JSON.stringify(error)}`));
    }
  }

  private updateHeader(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return of([]).pipe(
      switchMap(
        async () => {
          const hmac = this.computeToken(request);
          const version = environment.version;
          let headers = {};
          if (this.platform.is('cordova')) {
            let type = '';
            let data = '';
            if (this.platform.is('ios')) {
              type = 'IDFA';
              data = this.adjustService.idfa;
              if (data === '' || !data || data === this.adjustService.defaultAdId) {
                type = 'ADID';
                data = this.adjustService.adid;
              }
            } else if (this.platform.is('android')) {
              type = 'GAID';
              data = this.adjustService.googleAdId;
            }

            headers  = {
              EVS_AUTH_TOKEN: hmac[1],
              EVS_AUTH_ISSUED_AT: hmac[0],
              'x-device-id': `${type}:${data}`,
              'x-app-version': version,
              'X-Requested-With': 'XMLHttpRequest'
            };
          } else {
            headers  = {
              EVS_AUTH_TOKEN: hmac[1],
              EVS_AUTH_ISSUED_AT: hmac[0],
              'x-app-version': version,
              'X-Requested-With': 'XMLHttpRequest'
            };
          }
          const jwtToken = await this.storageService.get('jwtToken');
          // console.log('get jwtToken => ', jwtToken);
          // alert(`get jwtToken => , ${jwtToken}`);
          if (!!jwtToken) {
            headers['Authorization'] = jwtToken;
          }
          headers['Cache-Control'] = 'no-cache';
          const req = request.clone({
            setHeaders: headers
          });
          return req;
        }
      ),
      switchMap(
        req => {
          return next.handle(req);
        }
      )
    );
  }

  private requestMatchEvsDomain(request) {
    if (request.url.includes(`${environment.cmsBase}`) || request.url.includes(`${environment.cdrBase}`) || request.url.includes(`api-dev.envoituresimone.com/`)) {
      return true;
    }
    return false;
  }

  // Should match server-side computation. Order is important.
  // data = ([created_at] + params).join(',')
  // app_digest == 'SHA256'
  // OpenSSL::HMAC.hexdigest(app_digest, ENV['EVS_API_SECRET'], data)
  private computeToken(request: HttpRequest<any>): [string, string] {
    const dateTs = Date.now();
    const tokenParams = [`${dateTs}`, 'EVS_AUTH_API', this.extractPathFromUrl(request.url)].join(',');

    const hmac = new jsSHA('SHA-256', 'TEXT');
    hmac.setHMACKey(environment.evsSecretApi, 'TEXT');
    hmac.update(tokenParams);
    return [dateTs.toString(), hmac.getHMAC('HEX')];
  }

  private extractPathFromUrl(url: string): string {
    return url.match(/(http[s]?:\/\/)?([^\/\s]+)(.*)/)[3];
  }
}
