import { Injectable } from '@angular/core';

import { Initialable } from './app-init.service';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@Injectable({
  providedIn: 'root'
})
@Initialable({step: `init1`, initializer: `init`})
@EasyDebugDecorator
export class MigrationService {

  constructor() {
    // console.log('migrations service instanciate');
  }

  async init() {
    // console.log(`Launching migrations init`);
    return new Promise<string>((resolve, reject) => {
      setTimeout(() => {
        resolve('Migration done');
        // console.log(`init migrations done`);
      }, Math.floor(Math.random() * 1000) + 500);
    });
  }
}
