import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { Initialable } from './app-init.service';
import { NetworkStatusService } from './network-status.service';
import { StorageService } from './storage.service';

import { StudentService } from '../services/student.service';
import { Paragraph } from '../models/Paragraph';
import { CDRLesson } from '../models/CDRLesson';
import { Student } from '../models/student';

import { environment } from '../../environments/environment';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

@Injectable({ providedIn: 'root' })
@Initialable({ step: 'init3', initializer: 'onInit' })
@EasyDebugDecorator
export class CDRLessonsService {

  private _cdrLessons: Array<CDRLesson> = [];
  private student: Student;

  constructor(
    private http: HttpClient,
    private networkService: NetworkStatusService,
    private studentService: StudentService,
    private storageService: StorageService,
  ) { }

  async onInit() {
    // console.log('CDRLessons init');
    this.student = this.studentService.student;
    this._cdrLessons = await this.fetchCDRLessons(this.student.remoteId).toPromise();
    // console.log('CDRLessons done');
    return 'CDRLessons done';
  }

  private fetchCDRLessons(accountId: string): Observable<CDRLesson[]> {
    let url: string;

    if (this.networkService?.isOffline()) {
      return of([]);
    }

    url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/cdr_lessons`;

    return this.http.get(url).pipe(
      switchMap(
        async data => {
          // await this.storageService.set(this.storageKey(accountId), data);
          const cdrLessons = this.buildCDRLessonsFromRawData(data);
          return cdrLessons;
      }),
      catchError(
        async err => {
          return [];
        }
      )
    );
  }

  async loadCDRLessons(student: Student) {
    // loadStudentCDR renamed loadCDR because not part of student mechanic
    if (this.networkService?.isOffline()) {
      this._cdrLessons = await this.loadFromStorage(this.student.remoteId);
    } else {
      this._cdrLessons = await this.fetchCDRLessons(this.student.remoteId).toPromise();
    }
  }

  ///////////////////////////////////
  /////          GETTERS       /////
  ///////////////////////////////////

  get cdrLessons(): Array<CDRLesson> {
    return this._cdrLessons;
  }

  set cdrLessons(cdrLesson: CDRLesson[]) {
    this._cdrLessons = cdrLesson;
  }

  private

  async loadFromStorage(accountId: string) {
    const cdrLessonData = await this.storageService.get(this.storageKey(accountId));
    const cdrLesson = this.buildCDRLessonsFromRawData(cdrLessonData);
    return cdrLesson;
  }

  storageKey(accountId: String): string {
    return `${accountId}-cdrLessons`;
  }

  buildCDRLessonsFromRawData(data: any): Array<CDRLesson> {
    const arr: Array<CDRLesson> = [];
    const textData = JSON.stringify(data);
    const body = JSON.parse(textData);

    if (!!body) {
      for (const elt of body.data) {
        let CDRRank: number;

        if (elt.attributes.theme.toLowerCase() === 'signalisation') {
          CDRRank = 0;
        } else if (elt.attributes.theme.toLowerCase() === 'circulation') {
          CDRRank = 1;
        } else if (elt.attributes.theme.toLowerCase() === 'route') {
          CDRRank = 2;
        } else if (elt.attributes.theme.toLowerCase() === 'autres usagers') {
          CDRRank = 3;
        } else if (elt.attributes.theme.toLowerCase() === 'conducteur') {
          CDRRank = 4;
        } else if (elt.attributes.theme.toLowerCase() === 'divers') {
          CDRRank = 5;
        } else if (elt.attributes.theme.toLowerCase() === 'prendre/quitter le véhicule') {
          CDRRank = 6;
        } else if (elt.attributes.theme.toLowerCase() === 'sécurité passagers/véhicule') {
          CDRRank = 7;
        } else if (elt.attributes.theme.toLowerCase() === 'mécanique/équipement') {
          CDRRank = 8;
        } else if (elt.attributes.theme.toLowerCase() === 'environnement') {
          CDRRank = 9;
        } else if (elt.attributes.theme.toLowerCase() === 'accidents/secours') {
          CDRRank = 10;
        }

        const objLesson = new CDRLesson({
          remoteId: elt.id,
          type: elt.type,
          name: elt.attributes.name,
          slug: elt.attributes.slug,
          theme: elt.attributes.theme.toLowerCase(),
          subtheme: elt.attributes.subtheme,
          paragraphs: elt.attributes.paragraphs,
          rank: CDRRank
        });

        arr.push(objLesson);
      }
    }
    return arr;
  }

  fetchCDRLessonDetails(CDRLessonRemoteId): Observable<Paragraph[]> {
    let url: string;

    url = `${environment.token_auth_config.apiBase}/v${environment.apiVersion}/cdr_lessons/${CDRLessonRemoteId}`;

    if (this.networkService?.isOffline()) {
      return of([]);
    }

    return this.http.get(url).pipe(
      switchMap(data => {
        const arr: Array<Paragraph> = [];

        const textData = JSON.stringify(data);
        const body = JSON.parse(textData);

        let CDRLessonName: string;
        let CDRLessonSubtheme: string;
        CDRLessonName = body.data.attributes.name;
        CDRLessonSubtheme = body.data.attributes.subtheme;

        for (const elt of body.data.attributes.paragraphs.data) {
          const objLessonParagrah = new Paragraph({
            parentRemoteId: CDRLessonRemoteId,
            remoteId: elt.id,
            lessonSubtheme: CDRLessonSubtheme,
            lessonName: CDRLessonName,
            type: elt.type,
            title: elt.attributes.title,
            text: elt.attributes.text,
            photo: elt.attributes.photo.data.links.url

          });
          arr.push(objLessonParagrah);
        }

        return of(arr);
      }),
      catchError(
        async err => {
          return [];
        }
      )
    );

  }

  getMultipleCDRLessonDetails(cdrLessons: any): Observable<Paragraph[]> {

    const observables: Array<Observable<Paragraph[]>> = [];

    for (const lesson of cdrLessons) {
      observables.push(this.fetchCDRLessonDetails(lesson.remoteId));
    }

    return forkJoin(observables).pipe(map(
      CDRParagraph => {
        const paragraphs: Array<Paragraph> = [];

        for (const session of CDRParagraph) {
          for (const paragraph of session) {
            paragraphs.push(paragraph);
          }
        }
        return paragraphs;
      }
    ));
  }

}
