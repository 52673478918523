import {
  NgZone,
  Component,
  Input,
  ViewChild,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  Renderer2,
  HostListener,
  EventEmitter,
  Output,
  ElementRef,
  AfterViewInit,
} from '@angular/core';

import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { ModalIframeComponent } from '@app/modals';
import { ModalController } from '@ionic/angular';

import {
  NavigationService,
  MailcheckerService,
  PostalCodeService,
} from '@app/services';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Component({
  selector: 'tunnel-register-comp',
  templateUrl: 'tunnel-register.html',
  styleUrls: ['tunnel-register.scss']
})
export class TunnelRegisterComponent implements OnChanges {
  @Input() compOptions: any;
  @Output() compDataSent = new EventEmitter<any>();

  iconPath = './uikit-assets/icons/orion-svg-sprite.svg#';

  isDebug = false;
  userId = null;

  node: any = null;

  isActive: Boolean = false;

  registerForm: UntypedFormGroup;
  formCompleted = false;
  showPassword = 'password';

  focusedField: any = '';
  lastFieldFocused: any = null;

  passwordValidatorStates: any = {};
  enablePasswordValidator = false;

  notices = [];
  textNotif = '';

  nlSubscribedChecked: any = true;

  showNotif = false;
  userMail = '';
  userPass = '';
  mailSuggestion: any = null;
  mailAlreadyTaken: any = null;
  displayEMailPatternError: any = false;

  regexEmail = '[a-zA-Z0-9._+-]{1,}@[a-zA-Z0-9._+-]{2,}[.]{1}[a-zA-Z]{2,}';
  regexPhoneNumber = '^(0|\\+33)[6-7]([-. ]?[0-9]{2}){4}$';

  allowNextStep: any = false;

  compDataToSend: any = null;
  dataSent = false;

  isDesktop = (window.innerWidth > 767) ? true : false;

  showBasket = false;

  cgvDataUrl: any = null;
  privacyPolicyDataUrl: any = null;

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.ngZone.run(() => {
      this.isDesktop = (window.innerWidth > 767) ? true : false;
    });
  }

  constructor(
    private navigationService: NavigationService,
    public modalController: ModalController,
    private ngZone: NgZone,
    private formBuilder: UntypedFormBuilder,
    private mailcheckerService: MailcheckerService,
    public postalCodeService: PostalCodeService,
  ) {
    this.constructForms();
   }

  ngOnChanges(changes: SimpleChanges) {
    // detect @inputs changes
    const compOptions: SimpleChange = changes.compOptions;
    if (typeof compOptions !== 'undefined') {
      this.compOptions = compOptions.currentValue;
    }
    this.setGlobals();
  }

  setGlobals() {
    this.isDebug = (this.compOptions && typeof this.compOptions.debug !== 'undefined') ? this.compOptions.debug : this.isDebug;
    this.userId = (this.compOptions && typeof this.compOptions.userId !== 'undefined') ? this.compOptions.userId : this.userId;
    this.userMail = (this.compOptions && typeof this.compOptions.userMail !== 'undefined') ? this.compOptions.userMail : this.userMail;
    this.isActive = (this.compOptions && typeof this.compOptions.isActive !== 'undefined') ? this.compOptions.isActive : this.isActive;
    this.node = (this.compOptions && typeof this.compOptions.node !== 'undefined') ? this.compOptions.node : this.node;
    if (!!this.node) {
      // console.log('this.node', this.node);
      if (!!this.node.data && !!this.node.data.legal_text) {
        let legalText = this.node.data.legal_text.text || null;
        if (!!legalText && !!this.node.data.legal_text.placeholders) {
          // je remplace les ## par %
          // pour utiliser le mm code que dans le cms avec les var dynamiques
          legalText = legalText.replace(/##/g, '%');
          const regex = /\%[a-zA-Z0-9_()?#\/. ]+\%/g;
          const placeholders = legalText.match(regex);
          // console.log(placeholders);
          for (const placeholder of placeholders) {
            const placeholderKey = placeholder.replace(/%/g, '');
            this.node.data.legal_text.placeholders.find((placeholderItem) => {
              if (placeholderItem.key === placeholderKey) {
                legalText = legalText.replace(placeholder, placeholderItem.value);
                if (placeholderKey === 'cgv') {
                  this.cgvDataUrl = {
                    link: placeholderItem.link,
                    target: placeholderItem.target,
                  };
                }
                if (placeholderKey === 'privacy_policy') {
                  this.privacyPolicyDataUrl = {
                    link: placeholderItem.link,
                    target: placeholderItem.target,
                  };
                }
              }
            });
          }
          // console.log(legalText);
        }
      }
    }
    if (!!this.userMail && this.userMail !== '') {
      this.registerForm.controls['email'].patchValue(this.userMail);
      this.registerForm.controls['email'].markAsTouched();
    }
    this.dataSent = false; // reset
    if (this.isDebug) {
      // console.log('TunnelAlreadyDriveComponent - compOptions: ', this.compOptions);
    }
  }

  constructForms() {
    this.registerForm = this.formBuilder.group({
      first_name: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(50),
      ]),
      last_name: new UntypedFormControl('', [
        Validators.required,
        Validators.maxLength(50),
      ]),
      postal_code: ['', Validators.required],
      phone_number: ['', Validators.compose([
        Validators.required,
        Validators.pattern(this.regexPhoneNumber),
      ])],
      email: ['', Validators.compose([
        Validators.required,
        Validators.pattern(this.regexEmail),
      ])],
      password: new UntypedFormControl('', [
        Validators.required,
        Validators.minLength(6),
      ]),
      nl_subscribed: ''
    });

    this.registerForm.valueChanges.subscribe(() => {
      // console.log('valueChanges', this.registerForm);
      // this.formHasErrors();
      Object.keys(this.registerForm.controls).forEach(async key => {
        // console.log('key', key);
        if (document.activeElement.id === key) {
          this.lastFieldFocused = key;
        }
      });
      let hasInvalid = false;
      if (this.registerForm.controls['email'].status !== 'VALID') {
        hasInvalid = true;
      }
      this.allowNextStep = (hasInvalid) ? false : true;
    });
  }

  get getRegisterForm() {
    return this.registerForm.controls;
  }

  toggleshowPassword() {
    this.showPassword = (this.showPassword === 'text') ? 'password' : 'text';
  }

  validatePasswords(field: string): any {
    let isValid = true;
    let isMinLength = false;
    let isUppercase = false;
    let isSpecialChar = false;
    let isNumber = false;

    const format = /[#?!@$%^&*-]/;

    if (field !== '') {
      if (field.length >= 6) {
        isMinLength = true;
      }
      for (let i = 0; i < field.length; i++) {
        if (field.charAt(i) === field.charAt(i).toUpperCase() && isNaN(Number(field.charAt(i)))) {
          isUppercase = true;
        }
        if (!isNaN(Number(field.charAt(i)))) {
          isNumber = true;
        }
      }
      if (format.test(field)) {
        isSpecialChar = true;
      }
    }
    if (isMinLength && isUppercase && isSpecialChar && isNumber) {
      isValid = true;
    } else {
      isValid = false;
    }

    this.passwordValidatorStates = {
      isValid: isValid,
      isMinLength: isMinLength,
      isUppercase: isUppercase,
      isSpecialChar: isSpecialChar,
      isNumber: isNumber,
    };
  }

  async formHasErrors() {
    let noErrors = true;
    Object.keys(this.registerForm.controls).forEach(key => {
      const isRequired = (!!this.registerForm.controls[key].validator) ? true : false;
      if (this.isDesktop && (!this.registerForm.controls[key].pristine || this.registerForm.controls[key].touched) && isRequired && this.registerForm.controls[key].errors !== null && !this.registerForm.valid) {
        noErrors = false;
      }
    });
    if (!!this.lastFieldFocused) {
      this.lastFieldFocused = null;
    }
  }

  tunnelFormHasErrors() {
    Object.keys(this.registerForm.controls).forEach(key => {
      const isRequired = (!!this.registerForm.controls[key].validator) ? true : false;
    });
    // console.log('this.lastFieldFocused', this.lastFieldFocused);
    if (!!this.lastFieldFocused) {
      this.lastFieldFocused = null;
    }
  }

  setFocusedField(field: string) {
    this.focusedField = field;
  }

  clearField(form: any, field: string) {
    form.controls[field].patchValue('');
    form.controls[field].markAsTouched();
  }

  getAutocompleteValueTunnelPaiementRegister(value) {
    if (!!value && value.toString().trim() !== '') {
      this.registerForm.controls['postal_code'].patchValue(value);
      this.registerForm.controls['postal_code'].markAsTouched();
    } else {
      this.registerForm.controls['postal_code'].patchValue('');
      this.registerForm.controls['postal_code'].markAsTouched();
    }
  }

  setCheckbox(field: string) {
    if (field === 'nl_subscribed') {
      this.nlSubscribedChecked = !this.nlSubscribedChecked;
    }
  }

  setCompDataToSend(data: any) {
    this.compDataToSend = {
      'login': {
        'register': {
          'first_name': this.registerForm.value.first_name || null, // string
          'last_name': this.registerForm.value.last_name || null, // string
          'postal_code': this.registerForm.value.postal_code || null, // string
          'phone_number': this.registerForm.value.phone_number || null, // string
          'email': this.registerForm.value.email || null, // string
          'password': this.registerForm.value.password || null, // string
          'nl_subscribed': this.nlSubscribedChecked || null, // string
        }
      }
    };
    // console.log('compDataToSend: ', this.compDataToSend);
  }

  sendCompData(data: any) {
    if (!this.dataSent) {
      this.dataSent = true;
      // console.log('TunnelLocationComponent - sendCompData: ', data);
      this.setCompDataToSend(null);
      if (!!data) {
        data.compData = this.compDataToSend;
        this.compDataSent.emit(data);
      } else {
        this.compDataSent.emit({action: 'error', data: 'No data sent from TunnelLocationComponent'});
      }
    }
  }

  toggleBasketDetails() {
    this.showBasket = !this.showBasket;
  }

  openConditionsGenerales() {
    if (!!this.cgvDataUrl) {
      this.openLink('cgv', {target: '_system'}); // new window
    } else {
      alert('Missing cgv link');
    }
  }

  openPolitiqueConfidentialite() {
    if (!!this.cgvDataUrl) {
      this.openLink('privacy_policy', {target: '_system'}); // new window
    } else {
      alert('Missing privacy_policy link');
    }
  }

  async openModalIframe(url: any, type?: string, noClose = true) {
    if (!type) {
      type = 'iframe';
    }
    let modal;
    if (noClose) {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        backdropDismiss: false,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          'type': type,
          'url': url,
        },
      });
    } else {
      modal = await this.modalController.create({
        component: ModalIframeComponent,
        cssClass: 'custom-modal-iframe',
        componentProps: {
          'type': type,
          'url': url,
        },
      });
    }
    return await modal.present();
  }

  async openLink(link, options?) {
    const callbackUrl = await this.navigationService.open(link, options);
    if (!!callbackUrl && callbackUrl !== '') {
      this.openModalIframe(callbackUrl);
    }
  }

}
