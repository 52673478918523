import { Questions } from '../../models/questions';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Serie } from '../../models/serie';
import { QuestionsStrategy } from './questionsStrategy.interface';
import { Injectable } from '@angular/core';

import { Student } from '../../models/student';
import { Question } from '../../models/question';
import { catchError, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

import { EasyDebugDecorator } from '../../../app/decorators/easy-debug.decorator';

@EasyDebugDecorator
@Injectable({
  providedIn: 'root'
})
export class RemoteQuestions extends Questions implements QuestionsStrategy {

  constructor(private http: HttpClient) {
    super();
  }

  init() {
    this.questions = [];
  }

  async getQuestion(questionId: string, student: Student, local?: boolean): Promise<any> {
    if (!!this.questions && this.questions.length > 0 && this.questions.find(elt => elt.id === questionId)) {
      return this.questions.find(elt => elt.id === questionId);
    }
    const url = `${environment.cdrBase}/v${environment.apiVersion}/questions/${questionId}`;

    return this.http.get(url).pipe(
      switchMap(
        (questionData: any) => {
          if (!!questionData && !!questionData.data) {
            const question = this.fillQuestion(questionData.data);
            this.questions.push(question);
            return of(question);
          }
          return of({ errorCode: 'E301', errorMessage: 'remote getQuestion Service failed', errorOriginal: 'No questionsData or no data in questionsData'});
        }
      ),
      catchError(
        err => {
          return of({ errorCode: 'E301', errorMessage: 'remote getQuestion Service failed', errorOriginal: err});
        }
      )
    ).toPromise();
  }

  async getQuestions(student: Student, local?: boolean): Promise<any> {
    if (!!this.questions && this.questions.length > 0) {
      return this.questions;
    }
    const url = `${environment.cdrBase}/v${environment.apiVersion}/questions`;

    return this.http.get(url).pipe(
      switchMap(
        (questionsData: any) => {
          if (!!questionsData && !!questionsData.data && questionsData.data.length > 0) {
            const questions = this.fillQuestions(questionsData.data, 'remote');
            return of(questions);
          }
          return of({ errorCode: 'E301', errorMessage: 'remote getQuestions Service failed', errorOriginal: 'No questionsData or no data in questionsData'});
        }
      ),
      catchError(
        err => {
          return of({ errorCode: 'E301', errorMessage: 'remote getQuestions Service failed', errorOriginal: err});
        }
      )
    ).toPromise();
  }

  async setQuestions(questions: Array<Question>, student: Student): Promise<Array<Question>> {
    this.questions = questions;
    return questions;
  }

  async setQuestion(question: Question, student: Student): Promise<Question> {
    if (!!this.questions && this.questions.find(elt => elt.id === question.id)) {
      this.questions.splice(this.questions.findIndex(elt => elt.id === question.id), 1, question);
      return this.questions.find(elt => elt.id === question.id);
    }
    this.questions.push(question);
    return question;
  }
}
