import { EvsRecord } from './evsRecord';

export interface ICoupon {
  id: number;
  coupon_usage_id: number;
  type: string;
  coupon_usage_type: string;
  offer_id: number;
  name: string;
  discount: number;
  discount_percent: boolean;
  free_code: boolean;
  free_credits: number;
  message: string;
  payment_necessary: boolean;
  source_type: string;
  source_id: number;
  free_prefecture_registration: boolean;
  free_code_exam_credits: number;
  free_driving_exam_credits: number;
  created_at: string;
  updated_at: string;
  offer_name: string;
  offer_type: string;
  offer_associated: object;
}

export class CouponModel extends EvsRecord implements ICoupon {
  id: number;
  coupon_usage_id: number;
  type: string;
  coupon_usage_type: string;
  offer_id: number;
  name: string;
  discount: number;
  discount_percent: boolean;
  free_code: boolean;
  free_credits: number;
  message: string;
  payment_necessary: boolean;
  source_type: string;
  source_id: number;
  free_prefecture_registration: boolean;
  free_code_exam_credits: number;
  free_driving_exam_credits: number;
  created_at: string;
  updated_at: string;
  offer_name: string;
  offer_type: string;
  offer_associated: object;

  constructor(obj: ICoupon) {
    super(obj);
  }
}
