import { NgxLoggerLevel } from 'ngx-logger';

export const environment = {
  name: 'Staging',
  env: 'staging',
  appName: 'evsStudent',
  database: 'evs',
  version: require('../../package.json').version,
  apiVersion: '1',
  apiOptiVersion: '2',
  cmsBase: 'https://staging-front.envoituresimone.com',
  cdrBase: 'https://api-dev.envoituresimone.com/api',
  legacyBase: 'https://legacy-dev.envoituresimone.com',
  token_auth_config: {
    apiBase: 'https://api-dev.envoituresimone.com/api',
    secondaryApiBase: 'https://api-dev.envoituresimone.com/api',
    oAuthBase: 'https://api-dev.envoituresimone.com/api',
    oAuthPaths: {
      facebook: '/auth/facebook'
    }
  },
  ionic: {
    appId: 'e6a4b2b8'
  },
  nbMaxFaultAllowed: 5,
  screenHeightLimit: 400,
  screenWidthLimit: 600,
  zendeskUrl: 'https://envoituresimone.zendesk.com/api/v2',
  evsSecretApi: 'd6f28d7de0980e859086541f357f9d22000745ecd0f531b348bcf9e3e4165ae7',
  logsUrl: 'https://dev.envoituresimone.com/api/v1/log',
  appLevel: NgxLoggerLevel.WARN,
  serverLevel: NgxLoggerLevel.WARN,
  sentryAddr: 'https://537215d521364f86908bc230baa8d353@sentry.io/1280189',
  apiMaxRetry: 3,
  iosAppId: '1448880045',
  packageName: 'com.envoituresimone.app',
  androidMarketId: null,
  usesUntilPrompt: 5,
  minRandomLimit: 1,
  maxRandomLimit: 3,
  footerNavLink: 'dashboard',
  skipSoftDeploy: false,
  dbVersion: 14,
  evsServices: [
    { name: 'cdr-service', protocol: 'https', hostname: 'cdr.envoituresimone.com', urlBase: '/api/v1' }
  ],
  minGoodAnswers: 35,
  gtmId: 'GTM-MKKWBP',
  firebaseConfig: {
    apiKey: 'AIzaSyCdCIuuucf5i4pIij4u3ej2CS87WBysijk',
    authDomain: 'stellar-sunrise-92815.firebaseapp.com',
    databaseURL: 'https://stellar-sunrise-92815.firebaseio.com',
    projectId: 'stellar-sunrise-92815',
    storageBucket: 'stellar-sunrise-92815.appspot.com',
    messagingSenderId: '24457391814',
    appId: '1:24457391814:web:146673edc3492a94b571c7',
    measurementId: 'G-QBZ0GG6G90'
  },
  callCenterPhone: '01 73 14 81 52'
};
