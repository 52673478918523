import { Injectable } from '@angular/core';

import { EasyDebugDecorator } from '../../app/decorators/easy-debug.decorator';

const mailcheck = require('mailcheck');

class SuggestionModel  {
  address: string
  domain: string
  full: string
}

@Injectable({ providedIn: 'root' })
@EasyDebugDecorator
export class MailcheckerService {

  domains = [
  ];
  secondLevelDomains = [
    'orange',
    'sfr',
    'wanadoo',
    'bouyguestelecom',
    'laposte',
  ];
  topLevelDomains = [];

  constructor(
  ) {
    // console.log(mailcheck.defaultDomains);
    // console.log(mailcheck.defaultSecondLevelDomains);
    // console.log(mailcheck.defaultTopLevelDomains);
    mailcheck.defaultDomains.push(...this.domains);
    mailcheck.defaultSecondLevelDomains.push(...this.secondLevelDomains);
    mailcheck.defaultTopLevelDomains.push(...this.topLevelDomains);
  }
  
  checkeMail(email: string): SuggestionModel | null  {
    return mailcheck.run({
      email: email,
      suggested: function(suggestion): SuggestionModel {
        return suggestion;
      },
      empty: function(): null {
        return null;
      }
    });
  }
}
