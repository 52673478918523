<div [class]="modalSize" #modal>
  <div *ngIf="!closable" class="evs_modal_bg" #modalBg></div>
  <div *ngIf="closable" class="evs_modal_bg" (click)="closeModal()" #modalBg></div>
  <div class="evs_modal_container" #modalContainer>
    <div *ngIf="closable" class="evs_modal_close_btn display-block small-clickable" [ngClass]="{'no-title': title == ''}" (click)="closeModal()" #modalCloseBtn>
      <ion-icon name="close-outline"></ion-icon>
    </div>
    <div class="evs_modal_header" #modalHeader *ngIf="title != ''">{{title}}</div>
    <div class="evs_modal_content" [ngClass]="{'large-content': (title != '' || isFooter)}" #modalContent>
      <ng-content></ng-content>
    </div>
    <div class="evs_modal_footer" *ngIf="isFooter" #modalFooter>
      <ion-button inline size="small" color="medium" class="margin-right-10" (click)="closeModal()">{{footerText}}</ion-button>
    </div>
  </div>
</div>